import React, { useEffect, useState } from "react";
import VendorAvatar from "./../../../../common/VendorAvatar";
import CustomTable from "./../../../../common/CustomTable";
import moment from "moment";
import Button from "components/common/Button";
import useGetProductionForecastList from "hooks/ProductionHooks/useGetProductionForecastList";
import { truncate } from "lodash";
import { formatMoney } from "helpers";
import CustomTableLoader from "components/common/CustomTableLoader";
import Loader from "components/common/Loader";
import EmptyStateComp from "components/common/EmptyStateComp";
import { useNavigate } from "react-router-dom";
import { inventory } from "redux/Ldb/reducers";

type props = {
    chartSearch: string;
};
const ChartView = ({ chartSearch }: props) => {
    const navigate = useNavigate();
    const currentDate = moment();
    const [tooltipIdx, setTooltipIdx] = useState<string>("");
    const [tooltipData, setTooltipData] = useState(null);
    const [formattedForecast, setFormattedForecast] = useState<{ [key: string]: any }[]>([]);
    const [forecastDetails, setForecastDetails] = useState<{ [key: string]: any }>({});

    const {
        data: forecastData,
        isLoading,
        isLoadingMore,
        pagination,
        setDebouncedSearch,
    } = useGetProductionForecastList();

    useEffect(() => {
        setDebouncedSearch(chartSearch);
    }, [chartSearch]);

    const getMonthsRange = () => {
        const months = [];
        // const currentMonth = moment();
        for (let i = 0; i < 12; i++) {
            months.push(currentDate.clone().add(i, "months").format("YYYY-MM"));
        }
        return months;
    };

    // const sampleData = [
    //     {
    //         _id: {
    //             year: 2024,
    //             month: 11,
    //         },
    //         schedules: [
    //             {
    //                 _id: "65abc123def456",
    //                 schedule: "2024-11-15T00:00:00.000Z",
    //                 scheduleString: "2024-11-15",
    //                 forecastedQuantity: 1000,
    //                 inventoryId: "inv123",
    //                 productStockDetails: {
    //                     stockLevel: 100,
    //                     unitOfMeasurement: "pcs",
    //                 },
    //                 productName: "Alps Yoghurt",
    //                 sku: "AL655947",
    //                 productImageDetails: {
    //                     productImages: [],
    //                 },
    //             },
    //             {
    //                 _id: "65abc124def457",
    //                 schedule: "2024-11-28T00:00:00.000Z",
    //                 scheduleString: "2024-11-28",
    //                 forecastedQuantity: 500,
    //                 inventoryId: "inv124",
    //                 productStockDetails: {
    //                     stockLevel: 500,
    //                     unitOfMeasurement: "pcs",
    //                 },
    //                 productName: "Product B",
    //                 sku: "ALcc5947",
    //                 productImageDetails: {
    //                     productImages: [],
    //                 },
    //             },
    //         ],
    //     },
    //     {
    //         _id: {
    //             year: 2024,
    //             month: 12,
    //         },
    //         schedules: [
    //             {
    //                 _id: "65abc125def458",
    //                 schedule: "2024-12-10T00:00:00.000Z",
    //                 scheduleString: "2024-12-10",
    //                 forecastedQuantity: 750,
    //                 inventoryId: "inv125",
    //                 productStockDetails: {
    //                     stockLevel: 800,
    //                     unitOfMeasurement: "pcs",
    //                 },
    //                 productName: "product C",
    //                 sku: "AL6559111",
    //                 productImageDetails: {
    //                     productImages: [],
    //                 },
    //             },
    //             {
    //                 _id: "65abc123def456",
    //                 schedule: "2024-12-15T00:00:00.000Z",
    //                 scheduleString: "2024-12-15",
    //                 forecastedQuantity: 500,
    //                 inventoryId: "inv123",
    //                 productStockDetails: {
    //                     stockLevel: 100,
    //                     unitOfMeasurement: "pcs",
    //                 },
    //                 productName: "Alps Yoghurt",
    //                 sku: "AL655947",
    //                 productImageDetails: {
    //                     productImages: [],
    //                 },
    //             },
    //         ],
    //     },
    //     {
    //         _id: {
    //             year: 2025,
    //             month: 1,
    //         },
    //         schedules: [
    //             {
    //                 _id: "65abc125def458",
    //                 schedule: "2025-01-10T00:00:00.000Z",
    //                 scheduleString: "2025-01-10",
    //                 forecastedQuantity: 850,
    //                 inventoryId: "inv125",
    //                 productStockDetails: {
    //                     stockLevel: 1200,
    //                     unitOfMeasurement: "pcs",
    //                 },
    //                 productName: "product C",
    //                 sku: "AL6559111",
    //                 productImageDetails: {
    //                     productImages: [],
    //                 },
    //             },
    //             {
    //                 _id: "65abc123def456",
    //                 schedule: "2025-01-15T00:00:00.000Z",
    //                 scheduleString: "2025-01-15",
    //                 forecastedQuantity: 500,
    //                 inventoryId: "inv128",
    //                 productStockDetails: {
    //                     stockLevel: 100,
    //                     unitOfMeasurement: "pcs",
    //                 },
    //                 productName: "Alps",
    //                 sku: "AL655947",
    //                 productImageDetails: {
    //                     productImages: [],
    //                 },
    //             },
    //         ],
    //     },
    // ];

    useEffect(() => {
        const allSchedules = forecastData?.flatMap((data) => data.schedules);

        const formatData = (allSchedules) => {
            const monthsRange = getMonthsRange();
            const result = [];
            const currentMonth = moment().format("YYYY-MM");
            const currentMonthNumber = moment().month();

            const tempResult = {};

            allSchedules?.forEach((data) => {
                const scheduleDate = moment(data.scheduleString);
                const scheduleMonth = scheduleDate.format("YYYY-MM");
                const monthNumber = scheduleDate.month();
                if (monthsRange.includes(scheduleMonth)) {
                    if (!tempResult[data.inventoryId]) {
                        tempResult[data.inventoryId] = {
                            productName: data.productName,
                            productImage:
                                data?.productImageDetails?.productAvatar ||
                                data?.productImageDetails?.productImages?.[0],
                            inventoryId: data?.inventoryId,
                            sku: data?.sku,
                            productStocklevel: `${formatMoney().format(
                                data?.productStockDetails?.stockLevel
                            )}  ${data?.productStockDetails?.unitOfMeasurement}`,
                            forecastedQuantity: Array(12).fill(0),
                            schedule: data?.schedule,
                        };
                    }

                    const forecastIndex = (monthNumber - currentMonthNumber + 12) % 12;

                    tempResult[data.inventoryId].forecastedQuantity[forecastIndex] =
                        data.forecastedQuantity || 0;
                }
            });

            Object.values(tempResult).forEach((item) => {
                result.push(item);
            });

            return result;
        };
        const formattedData = formatData(allSchedules);
        setFormattedForecast(formattedData);
    }, [forecastData]);

    useEffect(() => {
        if (tooltipData?.forecastedQuantity[tooltipIdx.split("-")[0]] >= 1) {
            setForecastDetails({
                inventoryId: tooltipData?.inventoryId,
                quantity: tooltipData?.forecastedQuantity[tooltipIdx.split("-")[0]],
            });
        }
    }, [tooltipData]);

    const chartToolTip = () => {
        return (
            <div
                className={`rounded-md bg-[#fff] w-[429px] absolute top-4 transition-all z-[1200] border border-slate-200 ${
                    parseInt(tooltipIdx.split("-")[0]) < 8 ? "-right-[429px]" : "-left-[410px]"
                }`}
            >
                {/* <div className="absolute top-[50%] -left-3 border-x-transparent border-b-[8px] border-b-white border-x-[8px] w-[8px] -rotate-90"></div> */}

                <div className="py-2 px-4 flex items-center justify-between">
                    <div className="flex items-center gap-2">
                        {tooltipData?.productImageDetails?.productAvatar ||
                        tooltipData?.productImageDetails?.productImages?.[0] ? (
                            <VendorAvatar
                                imageSrc={
                                    tooltipData?.productImageDetails?.productAvatar ||
                                    tooltipData?.productImageDetails?.productImages?.[0]
                                }
                                size={32}
                                name={tooltipData?.productName}
                                containerClassname="!rounded-md"
                            />
                        ) : (
                            <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>
                        )}
                        <div className="text-sm">
                            <p className="text-[#142837]"> {tooltipData?.productName}</p>
                            <p className="text-[#64748B]">{tooltipData?.sku}</p>
                        </div>
                    </div>
                    {tooltipData?.forecastedQuantity[tooltipIdx.split("-")[0]] >= 1 && (
                        <Button
                            btnText="Create plan"
                            btnClassname="!px-3 !py-1.5 !bg-tradeally-neutral-20 !text-gm-50 !w-fit"
                            onClick={() =>
                                navigate(`/dashboard/production/create-plan`, {
                                    state: { forecastDetails },
                                })
                            }
                        />
                    )}
                </div>
                <div className="py-1 px-4 text-sm">
                    <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                        <p className="text-slate-500">Production targeted quantity</p>

                        <p className="text-[#00BE41]">
                            {formatMoney()?.format(
                                tooltipData?.forecastedQuantity[tooltipIdx.split("-")[0]]
                            )}
                            {` `}
                            {tooltipData?.productStocklevel.split(" ")[2]}
                        </p>
                    </div>
                    {/* <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                        <p className="text-slate-500">Direct cost</p>
                        <p className="text-g-75">$15,200</p>
                    </div>
                    <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                        <p className="text-slate-500">Indirect cost</p>
                        <p className="text-g-75">$100</p>
                    </div>
                    <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                        <p className="text-slate-500">Total COGS</p>
                        <p className="text-g-75">$7,000</p>
                    </div>
                    <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                        <p className="text-slate-500">Start date</p>
                        <p className="text-g-75">August 1, 2024</p>
                    </div>
                    <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                        <p className="text-slate-500">Completion date</p>
                        <p className="text-g-75">August 31, 2024</p>
                    </div>
                    <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                        <p className="text-slate-500">Batch size</p>
                        <p className="text-g-75">10</p>
                    </div>
                    <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                        <p className="text-slate-500">Production manager</p>
                        <p className="text-g-75"> Leilani Alan</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p className="text-slate-500">Shift</p>
                        <p className="text-g-75">Morning (8 AM - 5 PM)</p>
                    </div> */}
                </div>
            </div>
        );
    };

    const monthArray = [];
    for (let i = 0; i < 12; i++) {
        const month = currentDate.clone().add(i, "months");
        const formattedMonth = i === 0 ? "Current MTH" : month.format("MMM");
        monthArray.push({
            title: formattedMonth,
            widthClass: "w-[120px]",
            titleClass: "!justify-center",
        });
    }

    const initTableHeader = [
        { title: "Product", widthClass: "w-[258px]" },
        { title: "Current stock level", widthClass: "w-[150px]", titleClass: "!justify-center" },
    ];

    const tableHeader = initTableHeader.concat(monthArray);

    const tableBody = formattedForecast?.map((product, idx) => {
        // For each product, create a row and append forecasted quantities
        const row = [
            {
                content: (
                    <div
                        key={`product-image-${idx}`}
                        className={`!py-2 !px-2 font-rocGroteskMedium !border-slate-200 text-sm text-gm-50`}
                    >
                        <div className="flex items-center gap-2">
                            {product?.productImageDetails?.productAvatar ||
                            product?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.productImageDetails?.productAvatar ||
                                        product?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm">
                                <p className="text-[#142837]">
                                    {truncate(product?.productName, { length: 20 })}
                                </p>
                                <p className="text-[#64748B]">{product?.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        key={`product-stock-${idx}`}
                        className={`!py-2 !pl-6 font-rocGroteskMedium border-slate-200 text-sm text-gm-50`}
                    >
                        <p>{product?.productStocklevel}</p>
                    </div>
                ),
            },
        ];

        // map over the forecasted quantities and add them as columns to the row
        product.forecastedQuantity?.forEach((forecast, monthIdx) => {
            row.push({
                content: (
                    <div
                        key={`forecasted-${monthIdx}-${idx}`}
                        onMouseEnter={() => {
                            setTooltipIdx(`${monthIdx}-${idx}`);
                            setTooltipData(product);
                        }}
                        onMouseLeave={() => {
                            setTooltipIdx("");
                            setTooltipData(null);
                        }}
                        className={`!py-2 !pl-5 ${
                            tooltipIdx === `${monthIdx}-${idx}` && "!relative"
                        } font-rocGroteskMedium border-slate-200 text-sm text-gm-50`}
                    >
                        {tooltipIdx === `${monthIdx}-${idx}` && chartToolTip()}
                        <div className="bg-p-25 rounded-full w-[77px] h-[77px] flex items-center justify-center">
                            <p className="text-p-75 text-sm">
                                {formatMoney()?.format(forecast) || 0}
                            </p>
                        </div>
                    </div>
                ),
            });
        });

        return row;
    });

    return (
        <div className="font-rocGroteskMedium">
            {isLoading && forecastData?.length === 0 ? (
                <div className="overflow-x-hidden ">
                    <CustomTableLoader tableHeader={tableHeader} />
                </div>
            ) : forecastData?.length >= 1 ? (
                <>
                    <CustomTable
                        tableBody={tableBody}
                        tableHeader={tableHeader}
                        isCellBordered={false}
                        isInifiniteScroll={true}
                        isCellSelectable={false}
                        isAllSelectable={false}
                        stickyHeader={true}
                        headerContainerClass="!bg-[#F8FAFC] !border-slate-200"
                        headerItemClass="font-rocGroteskMedium !py-[11px] !px-4 !text-slate-700"
                        tableClass="mb-12 !border-collapse"
                        isScrollable={true}
                        bodyItemClass="hover:bg-white"
                    />
                    {isLoadingMore && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}

                    {pagination?.currentPage === pagination?.noOfPages && (
                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </>
            ) : chartSearch && forecastData?.length === 0 ? (
                <div className="h-[200px] flex items-center justify-center border-x border-b border-slate-200">
                    <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                        Sorry, no results match your search. Refine your criteria and try again.
                    </p>
                </div>
            ) : (
                <div className="border-x border-b border-slate-200">
                    <EmptyStateComp
                        title="No Production Forecast Available"
                        description="It looks like there's no forecast data available for your production yet. Once your inventory and production data are in place, a forecast will appear here."
                        icon={<i className="ri-bar-chart-2-fill text text-[40px]"></i>}
                        hasButton={true}
                        btnText="Create production plan"
                        btnAction={() => {
                            navigate("/dashboard/production/create-plan");
                        }}
                        contentContainerClass="flex flex-col items-center max-w-[581px] !px-6  !py-8 !space-x-0 !bg-white"
                        descriptionClass="flex flex-col items-center"
                        className=" !mt-0 !pt-12"
                        textClass="text-center"
                    />
                </div>
            )}
        </div>
    );
};

export default ChartView;
