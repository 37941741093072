import React from "react";

type ModalContainerProps = {
    children: React.ReactNode;
    open: boolean;
    closeModal?: () => void;
    tailwindClassName?: string;
    modalClassName?: string;
    showCloseIcon?: boolean;
    modalRef?: React.RefObject<HTMLDivElement>;
};

const ModalContainer = ({
    children,
    open,
    closeModal,
    tailwindClassName,
    modalClassName,
    showCloseIcon,
    modalRef,
}: ModalContainerProps) => {
    return open ? (
        <div
            id="modal"
            onClick={closeModal}
            className={`bg-gm-50/20 ${modalClassName} fixed left-0 top-0 w-full h-full flex items-center justify-center
             !z-[99991]`}
        >
            <div
                ref={modalRef}
                className={` ${tailwindClassName} overflow-y-auto scrollbar-hide`}
                onClick={(evt) => evt.stopPropagation()}
            >
                {children}
            </div>
            {showCloseIcon && (
                <span className="material-icons text-white text-3xl absolute right-4 sm:right-9 top-6 cursor-pointer font-bold bg-opacity-100">
                    close
                </span>
            )}
        </div>
    ) : null;
};

export default ModalContainer;
