import React from "react";

type InventoryAnalyticsCardProps = {
    title?: string;
    value?: number | string;
    icon?: JSX.Element;
    onClick?: () => void;
    containerClassName?: string;
    tooltipIcon?: JSX.Element;
};

const InventoryAnalyticsCard = ({
    title,
    value,
    icon,
    onClick,
    containerClassName,
    tooltipIcon,
}: InventoryAnalyticsCardProps) => {
    return (
        <div
            onClick={() => onClick?.()}
            className={`p-6 rounded-md flex flex-col justify-center ${containerClassName}`}
        >
            {icon}
            <div className="flex gap-2 items-center">
                <span className="block text-sm font-rocGroteskMedium">{title}</span>
                {tooltipIcon &&  tooltipIcon }
            </div>
            <span className="block text-[28px] max-sm:text-xl max-sm:leading-[28.8px] leading-[40.32px] font-rocGroteskMedium">
                {value}
            </span>
        </div>
    );
};

export default InventoryAnalyticsCard;
