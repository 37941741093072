import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { getCurrencyFromCurrencyCode } from "helpers";
import useGetSingleBOM from "hooks/ProductionHooks/useGetSingleBOM";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { cloneDeep, debounce, truncate } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { productionActions } from "redux/Ldb/actions";

const CreateBillOfMaterial = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const [params] = useSearchParams();
    const currTab = params.get("currTab");
    const [createSuccess, setSuccessModal] = useState<boolean>(false);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [addMaterial, setAddMaterial] = useState<boolean>(false);
    const [addRawMaterialModal, setAddRawMaterialModal] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [productName, setProductName] = useState<string>("");
    const [bomName, setBomName] = useState<string>("");
    const [productList, setProductList] = useState<{ [key: string]: any }[]>([]);
    const [billOfMaterialIds, setBillOfMaterialIds] = useState<{ [key: string]: any }[]>([]);
    const [nonFinishedInventory, setNonFinishedInventory] = useState<{ [key: string]: any }[]>([]);
    const [inventoryId, setInventoryId] = useState<string>("");
    const [bomId, setBomId] = useState<string>("");
    const [bomList, setBomList] = useState<{ [key: string]: any }[]>([{}]);
    const {
        creatingBOM,
        createBOMSuccess,
        updateBOMSuccess,
        updatingBOM,
        fetchingProductWithoutBOM,
        fetchedProductWithoutBOMSuccess,
        fetchingNonFinishedInventory,
        fetchedNonFinishedInventorySuccess,
    } = useAppSelector((state) => state.production);

    const { data: bomDetails, isLoading } = useGetSingleBOM();

    useEffect(() => {
        if (id) {
            setProductName(bomDetails?.productName);
            setBomName(bomDetails?.bomName);
            setBomList(bomDetails?.billOfMaterialIds);
            setInventoryId(id);
        }
    }, [bomDetails]);

    const handleBOMData = (e, idx) => {
        const { name, value } = e.target;
        const copyArr = cloneDeep([...bomList]);
        copyArr[idx][name] = value;
        setBomList(copyArr);
    };

    const handleCreateAndEditBOM = () => {
        const copy = cloneDeep([...bomList]);
        const billOfMaterialIds = copy?.map((item) => {
            return {
                quantity: item?.quantity,
                scrap: item?.scrap,
                inventoryId: item?.inventoryId,
            };
        });
        const body = {
            bomName: bomName,
            billOfMaterialIds: billOfMaterialIds,
        };
        if (!id) {
            dispatch(productionActions?.createBOM(body, inventoryId));
        } else {
            setConfirmModal(false);
            dispatch(productionActions?.updateBOM(body, inventoryId));
        }
    };

    const handleBOMDelete = (idx) => {
        const copyList = [...bomList];
        copyList?.splice(idx, 1);
        setBomList(copyList);
    };

    useEffect(() => {
        if (Boolean(createBOMSuccess)) {
            setBomId(createBOMSuccess?.product?._id);
            setSuccessModal(true);
            setConfirmModal(false);
            dispatch(productionActions?.resetCreateBOM());
        }
    }, [dispatch, createBOMSuccess]);

    useEffect(() => {
        if (Boolean(updateBOMSuccess)) {
            setBomId(updateBOMSuccess?.product?._id);
            setSuccessModal(true);
            dispatch(productionActions?.resetUpdateBOM());
        }
    }, [dispatch, updateBOMSuccess]);

    useEffect(() => {
        if (!id) {
            dispatch(productionActions?.fetchProductWithoutBOM());
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (Boolean(fetchedProductWithoutBOMSuccess)) {
            setProductList(fetchedProductWithoutBOMSuccess?.products);
        }
    }, [fetchedProductWithoutBOMSuccess]);

    useEffect(() => {
        dispatch(productionActions?.getNonFinishedInventory(20, 1));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedNonFinishedInventorySuccess)) {
            setNonFinishedInventory(fetchedNonFinishedInventorySuccess?.products);
        }
    }, [fetchedNonFinishedInventorySuccess]);

    const calcTotalQuantity = (quantity, scrap, unitPrice?: number) => {
        const quantityNum = parseInt(quantity);
        const scrapNum = parseInt(scrap);
        const percentage = (quantityNum * scrapNum) / 100;
        const totalQuantity = percentage + parseInt(quantity);
        if (unitPrice !== undefined) {
            return totalQuantity * unitPrice;
        }
        return Number.isInteger(totalQuantity) ? totalQuantity : totalQuantity.toFixed(2);
    };

    const BOMTableHeader = [
        {
            title: "Product",
            widthClass: "w-[20%] !font-rocGroteskMedium !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Category",
            widthClass: "w-[20%] !font-rocGroteskMedium !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Description",
            widthClass: "w-[20%] !font-rocGroteskMedium !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Quantity",
            widthClass: "w-[15%] !font-rocGroteskMedium !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Scrap",
            widthClass: "w-[12%] !font-rocGroteskMedium !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Total Quantity",
            widthClass: "w-[15%] !font-rocGroteskMedium !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "UOM",
            widthClass: "w-[12%] !font-rocGroteskMedium !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Unit price",
            widthClass: "w-[15%] !font-rocGroteskMedium !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Total price",
            widthClass: "w-[15%] !font-rocGroteskMedium !border !bg-slate-50 !border-slate-200",
        },
        id && {
            title: "",
            widthClass: "w-[10%] !font-rocGroteskMedium !border !bg-slate-50 !border-slate-200",
        },
    ];

    const BOMTableBody = bomList?.map((item, idx) => {
        return [
            {
                content: (
                    <div key={idx} className={`w-full  border-slate-200 text-sm text-slate-500 `}>
                        {/* <SelectInput
                                value={item?.inventoryId}
                                name="inventoryId"
                                inputPlaceholder={"Select"}
                                handleChange={(name, value) => {
                                    if (value) {
                                        setProducts((prevSelectedItems) => {
                                            const updatedItems = [...prevSelectedItems];
                                            const selectedItem = productSearchList.find(
                                                (item) => item._id === value
                                            );
                                            console.log("selectedItem", selectedItem);
                                            if (selectedItem) {
                                                updatedItems[idx] = {
                                                    ...updatedItems[idx],
                                                    unitPrice: selectedItem?.unitCost?.amount,
                                                    productName: selectedItem.productName,
                                                    sku: selectedItem.sku,
                                                    inventoryId: selectedItem._id,
                                                    stockLevel:
                                                        selectedItem?.productStockDetails
                                                            ?.stockLevel,
                                                };
                                            }

                                            return updatedItems;
                                        });
                                    }
                                }}
                                handleInputChange={handleDebouncedChangeTwo}
                                isRequired={true}
                                idx={idx}
                                className="!border-0 !rounded-none"
                                inputContainerClassName="!mb-0"
                                searchLoading={isFetching}
                                clearValue={true}
                                dropdownOptions={productSearchList?.map((item) => ({
                                    label: (
                                        <div className="flex gap-3 items-center">
                                            <div className="flex gap flex-col">
                                                <p className="font-rocGroteskMedium">
                                                    {item?.productName}
                                                </p>
                                            </div>
                                        </div>
                                    ),
                                    value: `${item?._id}`,
                                }))}
                            /> */}

                        <SelectInput
                            value={item?.inventoryId}
                            name="inventoryId"
                            inputPlaceholder={"Select product"}
                            handleChange={(name, value) => {
                                if (value) {
                                    setBomList((prevSelectedItems) => {
                                        const updatedItems = [...prevSelectedItems];
                                        const selectedItem = nonFinishedInventory.find(
                                            (item) => item._id === value
                                        );
                                        if (selectedItem) {
                                            updatedItems[idx] = {
                                                ...updatedItems[idx],
                                                inventoryCategory: selectedItem?.inventoryCategory,
                                                unitPrice: selectedItem?.unitCost?.amount,
                                                currency: selectedItem?.unitCost?.currency,
                                                productDescription:
                                                    selectedItem?.productDescription,
                                                unitOfMeasurement:
                                                    selectedItem?.productStockDetails
                                                        ?.unitOfMeasurement,
                                                productAvatar:
                                                    selectedItem?.productImageDetails
                                                        ?.productAvatar ||
                                                    selectedItem?.productImageDetails
                                                        ?.productImages?.[0],
                                                productSKu: selectedItem?.sku,
                                                inventoryId: selectedItem?._id,
                                            };
                                        }
                                        return updatedItems;
                                    });
                                }
                            }}
                            // handleInputChange={debounce((evt) => {
                            //     setDebouncedSearch(evt.target.value);
                            // }, 800)}
                            isRequired={true}
                            className="!border-0 !rounded-none"
                            inputContainerClassName="!mb-0"
                            // searchLoading={fetchingInventoryList}
                            clearValue={true}
                            dropdownOptions={nonFinishedInventory?.map((inv) => ({
                                label: (
                                    <div className="text-sm">
                                        <p className="text-g-75">
                                            {" "}
                                            {truncate(inv?.productName, { length: 20 })}
                                        </p>
                                        <p className="text-[#64748B]">{inv?.sku}</p>
                                    </div>
                                ),

                                icon: (
                                    <div className="flex items-center gap-2">
                                        {inv?.productImageDetails?.productAvatar ||
                                        inv?.productImageDetails?.productImages?.[0] ? (
                                            <VendorAvatar
                                                imageSrc={
                                                    inv?.productImageDetails?.productAvatar ||
                                                    inv?.productImageDetails?.productImages?.[0]
                                                }
                                                size={32}
                                                name={inv?.productName}
                                                containerClassname="!rounded-md"
                                            />
                                        ) : (
                                            <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                            </div>
                                        )}
                                    </div>
                                ),
                                value: inv._id,
                            }))}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full  border-slate-200 text-sm text-slate-500 `}>
                        <TextInput
                            value={item?.inventoryCategory}
                            name="value"
                            type={"text"}
                            disabled={true}
                            inputPlaceholder="Category"
                            inputContainerClassname={"!border-none !rounded-none !bg-transparent"}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-slate-500 flex items-center px-2.5`}
                    >
                        <TextInput
                            value={
                                item?.productDescription
                                    ? item?.productDescription
                                    : item?.inventoryId && !item?.productDescription && "N/A"
                            }
                            name="productDescription"
                            type={"text"}
                            disabled={true}
                            inputPlaceholder="Description"
                            inputContainerClassname={"!border-none !rounded-none !bg-transparent"}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full border-slate-200 text-sm text-slate-500`}>
                        <TextInput
                            value={item?.quantity}
                            name="quantity"
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={true}
                            onChange={(e) => handleBOMData(e, idx)}
                            inputPlaceholder="Quantity"
                            containerClassname=""
                            inputClassName="!w-fit"
                            inputContainerClassname={"!border-0 !rounded-none "}
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full border-slate-200 text-sm text-slate-500`}>
                        <TextInput
                            value={item?.scrap}
                            name="scrap"
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            required={true}
                            onChange={(e) => handleBOMData(e, idx)}
                            inputPlaceholder="Scrap"
                            inputClassName="w-full"
                            inputContainerClassname={"!border-0 !rounded-none "}
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full border-slate-200 text-sm text-slate-500 px-2.5`}>
                        <TextInput
                            value={String(calcTotalQuantity(item?.quantity, item?.scrap))}
                            name="value"
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            disabled={true}
                            inputPlaceholder="0"
                            containerClassname=""
                            inputContainerClassname={"!border-none !rounded-none !bg-transparent"}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full border-slate-200 text-sm text-slate-500 px-2.5`}>
                        <TextInput
                            value={item?.unitOfMeasurement}
                            name="unitOfMeasurement"
                            type={"text"}
                            disabled={true}
                            inputPlaceholder="UOM"
                            inputContainerClassname={"!border-none !rounded-none !bg-transparent"}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-slate-500 px-2.5 flex items-center`}
                    >
                        <p className="text-slate-500">
                            {getCurrencyFromCurrencyCode(item?.currency)}
                        </p>

                        <div className="!w-[50px]">
                            <TextInput
                                value={item?.unitPrice}
                                name={"unitPrice"}
                                type={"number"}
                                onWheel={(e: any) => e.target.blur()}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                                disabled={true}
                                inputPlaceholder="0"
                                containerClassname=""
                                inputContainerClassname={
                                    "!border-none !rounded-none !bg-transparent"
                                }
                                inputClassName=" "
                            />
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-slate-500 px-2.5 flex items-center`}
                    >
                        <p className="text-slate-500">
                            {getCurrencyFromCurrencyCode(item?.currency)}
                        </p>

                        <div className="">
                            <TextInput
                                value={
                                    item?.unitPrice &&
                                    String(
                                        calcTotalQuantity(
                                            item?.quantity,
                                            item?.scrap,
                                            item?.unitPrice
                                        )
                                    )
                                }
                                name={"totalCost"}
                                type={"number"}
                                onWheel={(e: any) => e.target.blur()}
                                disabled={true}
                                inputPlaceholder="0"
                                containerClassname=""
                                inputContainerClassname={
                                    "!border-none !rounded-none !bg-transparent"
                                }
                                inputClassName=" "
                            />
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            id && {
                content: (
                    <div
                        className={` ${
                            bomList?.length > 1 ? "cursor-pointer" : "cursor-default"
                        } w-full border-slate-200 text-sm text-slate-500 pl-6`}
                        onClick={() => bomList?.length > 1 && handleBOMDelete(idx)}
                    >
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1727444338/close-fill_xxogdp.svg"
                            alt=""
                            className=""
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    return (
        <PageFrame isLoading={isLoading}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    if (id) {
                        setConfirmModal(true);
                    } else {
                        handleCreateAndEditBOM();
                    }
                }}
                className="bg-white rounded-lg border border-n-20 font-rocGroteskMedium pb-12"
            >
                <div className="flex items-center justify-between px-8 py-[15px] border-b border-n-20">
                    <div className="flex items-center space-x-1.5">
                        <i
                            onClick={() => navigate(`/dashboard/production?currTab=${currTab}`)}
                            className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                        ></i>
                        <span className="text-lg font-rocGroteskMedium">
                            {id
                                ? "Edit Bill of Materials (BOM-00000123)"
                                : "Create Bill of Materials (BOM)"}
                        </span>
                    </div>
                    <div className="flex items-center space-x-3">
                        <Button
                            btnText="Cancel"
                            btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                            onClick={() => navigate(`/dashboard/production?currTab=${currTab}`)}
                            disabled={creatingBOM || updatingBOM}
                        />
                        <Button
                            btnText={id ? "Update BOM" : "Create BOM"}
                            type="submit"
                            isLoading={creatingBOM || updatingBOM}
                            disabled={creatingBOM || updatingBOM}
                            btnClassname="!px-4 !py-2.5 !w-fit"
                        />
                    </div>
                </div>
                <div className="px-8">
                    <div className="py-6  max-w-[1082px]">
                        <div className="grid grid-cols-2 max-lg:grid-cols-2 max-sm:grid-cols-1 items-center gap-4">
                            <SelectInput
                                value={productName}
                                name="productName"
                                inputPlaceholder={"Select Finished Product"}
                                handleChange={(name, value) => {
                                    // handleBOMData({ target: { name, value } }, idx);
                                    setProductName(value.split("_")[0]);
                                    setInventoryId(value.split("_")[1]);
                                }}
                                // handleInputChange={debounce((evt) => {
                                //     setDebouncedSearch(evt.target.value);
                                // }, 800)}
                                isRequired={true}
                                className=""
                                inputContainerClassName="!mb-0"
                                searchLoading={fetchingProductWithoutBOM}
                                dropdownOptions={productList.map((item) => ({
                                    label: item.productName,
                                    value: item.productName + "_" + item._id,
                                }))}
                                disabled={id && true}
                                clearValue={true}
                            />
                            <TextInput
                                value={bomName}
                                name={"bom"}
                                type={"text"}
                                placeholder={"BOM name"}
                                required={true}
                                onChange={(e) => setBomName(e?.target.value)}
                                containerClassname=""
                                inputClassName="!text-slate-500"
                            />
                        </div>
                    </div>
                    <div className="mt-4  rounded-lg">
                        <div className="">
                            <div className="flex items-center justify-between space-x-3 p-4  rounded-t-md">
                                <div className="flex items-center gap-1">
                                    <p className="text-gm-75 text-base">
                                        Bill of materials (BOM){" "}
                                        {productName && `for ${productName}`}
                                    </p>
                                    <div>
                                        <a id="bom">
                                            <i className="ri-information-fill text-slate-500 text-base"></i>
                                        </a>

                                        <Tooltip
                                            anchorSelect="#bom"
                                            place={"top"}
                                            content="A comprehensive list of raw materials, components, and quantities required for production."
                                            style={{ width: "250px", zIndex: 9999 }}
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center gap-2">
                                    {/* <Button
                    btnText="Create a new raw material"
                    btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                    // disabled={}
                    // onClick={}
                />
                <Button
                    btnText="Add raw material from Inventory"
                    type="button"
                    // isLoading={}
                    // disabled={}
                    onClick={() => setAddRawMaterialModal(true)}
                    btnClassname="!px-4 !py-2.5 !w-fit"
                /> */}
                                </div>
                            </div>
                        </div>
                        <CustomTable
                            tableBody={BOMTableBody}
                            tableHeader={BOMTableHeader}
                            isScrollable={false}
                            isCellBordered={true}
                            isCellSelectable={false}
                            isAllSelectable={false}
                            scrollWithin={false}
                            headerContainerClass="!bg-slate-50 !border-slate-200"
                            headerItemClass="font-rocGroteskMedium !text-[#334155] "
                            tbodyClass="!border !border-slate-200"
                            tableClass="!border-slate-200"
                            bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                        />
                    </div>
                    <div className="mt-6">
                        <Button
                            btnText="Add more"
                            btnClassname="!text-[13px] !py-2 !px-3 !bg-n-20 !w-fit text-gm-50 !rounded-[4px] "
                            onClick={() => {
                                setBomList([...bomList, {}]);
                            }}
                        />
                    </div>
                </div>
            </form>

            <ModalContainer
                open={createSuccess}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setSuccessModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            navigate(`/dashboard/production?currTab=${currTab}`);
                            setSuccessModal(false);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 w-12 h-12 bottom-0 right-[-13px] rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-[24px] text-white"></i>
                            </div>
                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    {id ? "BOM updated successfully!" : "BOM created successfully!"}
                                </span>
                                <p className="mt-1 text-base text-slate-500 text-center ">
                                    {id
                                        ? `Your Bill of Materials (BOM) for ${productName} has been successfully updated. All changes have been saved and are now reflected in the production plan.`
                                        : `Your Bill of Materials (BOM) for ${productName} has been  successfully created. You can now proceed with the next steps in your production process.`}
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setSuccessModal(false);
                                    setInventoryId("");
                                    setBomList([{}]);
                                    setProductName("");
                                    navigate(`/dashboard/production?currTab=${currTab}`);
                                }}
                            />
                            <Button
                                btnText={"View BOM"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() =>
                                    navigate(
                                        `/dashboard/production/bom/${bomId}?currTab=${currTab}`
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={confirmModal}
                modalClassName={"p-6"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setConfirmModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="text-left pt-10 mb-8">
                        <div className="px-6 font-rocGroteskMedium">
                            <span className="block mb-2 text-[18px]">
                                Are you sure you want to update this Bill of Materials (BOM)?
                            </span>
                            <p className="mt-1 text-base text-slate-500 ">
                                Your changes to the Bill of Materials have been successfully saved.
                                The previous version has been archived for your reference. You can
                                restore it at any time if needed.
                            </p>
                        </div>
                    </div>
                    <div className=" pb-4 px-6 flex items-center justify-end space-x-4">
                        <Button
                            btnText={"No, cancel"}
                            btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                            onClick={() => {
                                setConfirmModal(false);
                            }}
                        />
                        <Button
                            // btnType="submit"
                            btnText={"Yes, update"}
                            btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                            onClick={() =>
                                //     // setConfirmModal(false)
                                handleCreateAndEditBOM()
                            }
                        />
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={addMaterial}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                closeModal={() => {
                    setAddMaterial(false);
                }}
            >
                <div className="bg-white py-7 px-6  shadow-cardShadow relative rounded-[8px]">
                    <div className="mb-5 mt-2">
                        <h1 className="text-lg text-gm-50 mb-2  font-rocGroteskMedium">
                            Add Material to BOM?
                        </h1>
                        <p className="text-slate-500 font-rocGroteskMedium text-base">
                            Are you sure you want to add this material from Inventory to the BOM?
                            Confirm to proceed.
                        </p>
                    </div>
                    <div className="flex justify-end items-center ">
                        <div className="flex items-center !whitespace-nowrap space-x-2 ">
                            <Button
                                btnText={"No, cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !w-fit !text-gm-50"}
                                onClick={() => setAddMaterial(false)}
                            />
                            <Button
                                btnClassname={"!py-3 !w-fit"}
                                // onClick={() => }
                                btnText={"Add to BOM"}
                                type={"button"}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={addRawMaterialModal}
                // showCloseIcon={false}
                tailwindClassName="w-[90%] lg:w-[700px]"
            >
                <div className="bg-white  rounded-lg max-h-[90vh] overflow-y-scroll">
                    <div className=" px-6 py-3 flex gap-4 items-center justify-between border-b border-b-slate-200">
                        <p className="text-base text-g-75 font-rocGroteskMedium ">
                            Add material from Inventory to BOM
                        </p>
                        <i
                            onClick={() => setAddRawMaterialModal(false)}
                            className="ri-close-fill cursor-pointer text-gm-50 text-2xl "
                        ></i>
                    </div>
                    <div className="my-8 mx-8  ">
                        <div className="">
                            <TextInput
                                name="search"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                // onInput={handleDebouncedChange}
                                inputPlaceholder="Search for raw material"
                                inputClassName={
                                    "!h-[50px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400 placeholder:!text-slate-500 placeholder:!font-rocGroteskMedium"
                                }
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-g-75 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-tl-md !rounded-tr-md !rounded-bl-none !rounded-br-none !h-[50px] !pl-[40px] !bg-white !border !border-slate-200 !border-b-[0.5px]"
                                }
                            />
                        </div>
                        {/* {product?.length > 0 ? (
                            <div className="">
                                <div className="max-h-[300px] overflow-y-auto" id="scrollableTable">
                                    <CustomTable
                                        tableBody={tableModalBody}
                                        tableHeader={tableModalHeader}
                                        isAllSelectable={true}
                                        headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                        headerContainerClass="!bg-slate-50"
                                        isScrollable={false}
                                        isCellBordered={false}
                                    />

                                    {loadMore && fetchingStockList && (
                                        <div className="flex my-4 justify-center">
                                            <Loader color="gm-25" size={4} />
                                        </div>
                                    )}
                                    {paginate?.current === paginate?.number_of_pages && (
                                        <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                            End of list
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="flex my-10 text-slate-500  justify-center text-sm font-rocGroteskMedium h-40">
                                No product
                            </div>
                        )} */}
                    </div>
                    <div className=" px-8 py-4 flex gap-4 items-center justify-between border-t border-t-slate-200">
                        <Button
                            btnText="Create a new raw material"
                            btnClassname="!bg-white !py-3 !pl-4 !pl-5 border border-slate-200 !text-g-75
                                !text-[13px] !w-auto"
                            onClick={() => navigate("")}
                        />
                        <div className="flex items-center gap-3">
                            <Button
                                btnText="Cancel"
                                btnClassname="!px-6 !py-3 !bg-tradeally-neutral-20 !text-g-75 !w-fit"
                                onClick={() => setAddRawMaterialModal(false)}
                            />
                            <Button
                                btnText="Add raw material to BOM"
                                btnClassname="!px-6 !py-3 !w-fit"
                                onClick={() => {}}
                                // disabled={}
                                // isLoading={}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default CreateBillOfMaterial;
