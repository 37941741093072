import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { productionActions } from "redux/Ldb/actions";

const useGetProductionPlanTab = (scrollContent?: HTMLElement) => {
    const { productionId } = useParams();
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [planDetails, setPlanDetails] = useState<any>();
    const [query, setQuery] = useState<string>("");
    const [pagination, setPagination] = useState({
        current: "",
        number_of_pages: "",
    });

    const { fetchingProductionPlanTab, fetchedProductionPlanTabSuccess } = useAppSelector(
        (state) => state.production
    );

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.current, number_of_pages: pagination.number_of_pages },
            10,
            scrollContent
        );

    const handleSearch = (e) => {
        setSearch(e?.target?.value);
    };

    const handleDebouncedSearch = debounce((e) => {
        setUpdatedPage(1);
        setDebouncedSearch(e?.target?.value);
    }, 800);

    useEffect(() => {
        setUpdatedPage(1);
        setPlanDetails({});
    }, [query]);

    useEffect(() => {
        if (Boolean(fetchedProductionPlanTabSuccess)) {
            setPlanDetails(fetchedProductionPlanTabSuccess?.plan);
            console.log(fetchedProductionPlanTabSuccess?.plan);
            setPagination({
                current: fetchedProductionPlanTabSuccess.pagination?.current,
                number_of_pages: fetchedProductionPlanTabSuccess.pagination?.number_of_pages,
            });

            dispatch(productionActions.resetFetchProductionPlanTab());
        }
    }, [
        dispatch,
        fetchedProductionPlanTabSuccess,
        productionId,
        query,
        debouncedSearch,
        updatedPage,
    ]);

    useEffect(() => {
        if (productionId && (query === "bom" || query === "approval_workflow")) {
            dispatch(
                productionActions.fetchProductionPlanTab(
                    productionId,
                    query,
                    10,
                    updatedPage,
                    debouncedSearch
                )
            );
        }
        return () => {
            dispatch(productionActions.resetFetchProductionPlanTab());
        };
    }, [dispatch, productionId, query, debouncedSearch, updatedPage]);

    return {
        data: planDetails,
        setPlanDetails,
        isLoading: fetchingProductionPlanTab,
        setQuery,
        query,
        debouncedSearch,
        setDebouncedSearch,
        handleDebouncedSearch,
        search,
        setSearch,
        handleSearch,
        isLoadingMore,
        pagination,
    };
};

export default useGetProductionPlanTab;
