import Button from "components/common/Button";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BookADemoModal from "./BookADemoModal";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { vendorActions } from "redux/Ldb/actions";

type LandingNavProps = {
    bgClass?: string;
};

const LandingNav = ({ bgClass }: LandingNavProps) => {
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useAppDispatch();
    const { joiningWaitList, joinWaitListSuccess } = useAppSelector((state) => state.vendor);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleBookADemo = () => {
        const data = {};
        dispatch(vendorActions.joinWaitlist(data));
    };

    useEffect(() => {
        if (Boolean(joinWaitListSuccess)) {
            dispatch(vendorActions.resetJoinWaitListSuccess());
            window.open("https://calendly.com/lloyd-synthally/30min", "_self");
        }
    }, [joinWaitListSuccess, dispatch]);

    return (
        <div className="relative flex justify-center">
            <nav
                className={`flex absolute items-center justify-between  max-lg:relative transition-all duration-1000 w-[86%] h-16 bg-transparent ${bgClass} z-[1000]`}
            >
                <div className="flex items-center gap-10 max-lg:hidden font-flame">
                    <a href={"/#benefits"} className="text-nav-green text-sm">
                        Benefits
                    </a>
                    <a href={"/#features"} className="text-nav-green text-sm">
                        Features
                    </a>
                    <a href={"/#integrations"} className="text-nav-green text-sm">
                        Integrations
                    </a>
                </div>

                <Link to={"/"} className="absolute right-[48%] max-lg:relative max-lg:right-0">
                    <img
                        src={
                            "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715104205/SynthLogo_h118c2.svg"
                        }
                        alt="Logo Icon"
                        className="cursor-pointer "
                        height={26.4}
                        width={88}
                    />
                </Link>

                <div className="flex items-center space-x-5">
                    <Link to={"/login"} className="text-sm text-nav-green font-flame">
                        Log in
                    </Link>
                    <Button
                        btnText={"Book a Demo"}
                        btnClassname={
                            "!text-green-2 !w-fit !h-[36px] !text-sm !font-flame !rounded-md !px-3 !py-2 !shadow-[0px_-3px_0px_0px_#0000001A_inset]"
                        }
                        isLoading={joiningWaitList}
                        onClick={handleBookADemo}
                    />
                </div>
            </nav>

            <BookADemoModal openModal={openModal} setOpenModal={setOpenModal} />
        </div>
    );
};

export default LandingNav;
