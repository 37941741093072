import ReactEcharts from "echarts-for-react";
import Button from "components/common/Button";
import AIChatComp from "components/common/AIChatComp";
import Dropdown from "components/common/Dropdown";
import PageTitleSection from "components/common/PageTitleSection";
import PageFrame from "components/layout/PageFrame";
import { AnalyticsCard } from "components/views/Ldb/Dashboard/BusinessAnalytics/Card";
import React, { useEffect, useRef, useState } from "react";
import TopLeastPerformer from "components/views/Synth2.0/Dashboard/TopLeastPerformer";
import DataSyncModal from "components/views/Synth2.0/Dashboard/DataSyncModal";
import ForecastInsights from "components/views/Synth2.0/Dashboard/ForecastInsights";
import ModalContainer from "components/common/ModalContainer";
import SelectInput from "components/common/InputField/SelectInput";
import DateInput from "components/common/DateInput";
import useGetDashboardGWM from "hooks/Dashboard/useGetDashboardGWM";
import { formatMoney, getDashboardChartData } from "helpers";
import useGetSalesChannel from "hooks/Dashboard/useGetSalesChannel";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import { flattenDeep, set, sortBy, truncate, uniqBy } from "lodash";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import MultipleLineChart from "components/common/MultipleLineChart";
import moment from "moment";
import useGetDashboardAnalytics from "hooks/Dashboard/useGetDashboardAnalytics";
import millify from "millify";
import Loader from "components/common/Loader";
import useGetDashboardBusinessStats from "hooks/Dashboard/useGetDashboardBusinessStats";
import TextInput from "components/common/InputField/TextInput";
import useGetDashboardTopOnHandProduct from "hooks/Dashboard/UseGetDashboardTopOnHandProduct";
import useGetDashboardProductProfitMargin from "hooks/Dashboard/useGetDashboardProductProfitMargin";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomTable from "components/common/CustomTable";
import { adjustHeight } from "variables";
import { chatActions, dashboardActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import config from "config/config";
import { firebaseService } from "services/firebaseService";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import useGetDashboardProductProfitList from "hooks/Dashboard/useGetDashboardProductProfitList";
import useGetDashboardTopOnHandProductList from "hooks/Dashboard/useGetDashboardTopOnHandProductList";
import CustomTableLoader from "components/common/CustomTableLoader";
import useGetAllAiConversations from "hooks/Chat/useGetAllAiConversations";
import { useChannel } from "ably/react";
import ToggleInput from "components/common/InputField/ToggleInput";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { productCategories } from "variables/productCategories";
import useGetDashboardConfigList from "hooks/Dashboard/useGetDashboardConfigList";
import EmptyStateComp from "components/common/EmptyStateComp";
import { monifyInputField } from "helpers/monifyInputField";
import SynthTooltip from "components/common/SynthTooltip";
import { getWeeksInMonth } from "helpers/dateTime";
import { Tooltip } from "react-tooltip";

const Dashboard = () => {
    const {
        data: gwmData,
        isFetching: gwmLoading,
        handleDateRangeChange,
        handleChannelChange,
        handleCategoryChange,
        handleProductChange,
        productIds,
        handleStartAndEndDateChange,
    } = useGetDashboardGWM();
    const { data: salesChannel, isFetching: salesChannelLoading } = useGetSalesChannel();
    const { data: businessStats, isFetching: businessStatsLoading } =
        useGetDashboardBusinessStats();

    const {
        data: productData,
        rawData,
        isFetching: productListLoading,
        productSearchList,
        handleDebouncedChange,
        handleDebouncedChangeTwo,
        handleSearch,
        handleSearchTwo,
        search,
        searchTwo,
        setSearch,
        setSearchTwo,
        setDebouncedSearch,
        setDebouncedSearchTwo,
        pagination,
        isLoadingMore,
    } = useGetProductList();

    const {
        data: configList,
        updatedPage,
        setUpdatedPage,
        pagination: configPagination,
        handleDateRangeChange: configHandleDateRangeChange,
        handleChannelChange: configHandleChannelChange,
        handleCategoryChange: configHandleCategoryChange,
        handleProductChange: configHandleProductChange,
        productIds: configProductIds,
    } = useGetDashboardConfigList();

    const [inventoryModal, setInventoryModal] = useState<boolean>(false);
    const [profitMarginModal, setProfitMarginModal] = useState<boolean>(false);
    const scrollContainer = document.getElementById("scrollContainer");

    const {
        data: productProfitList,
        isFetching: productProfitListLoading,
        handleProductProfitDebouncedChange,
        totalProfit,
        setProductInventoryId,
        setProductChannel,
        setProductDateRange,
        productProfitIsLoadingMore,
        productProfitPagination,
        productProfitSetSortValues,
    } = useGetDashboardProductProfitList(profitMarginModal);
    const {
        data: topInventoryList,
        isFetching: topInventoryListLoading,
        handleInventoryDebouncedChange,
        totalInventory,
        setInventoryId,
        setChannel,
        setDateRange,
        inventoryIsLoadingMore,
        inventoryPagination,
        inventorySetSortValues,
    } = useGetDashboardTopOnHandProductList(inventoryModal);
    const { data: allConversations, isLoadingMore: loadingMoreConverstions } =
        useGetAllAiConversations(scrollContainer);
    const [fetchProductSalesMargin, setFetchProductSalesMargin] = useState<boolean>(true);
    const [fetchInventoryLevel, setFetchInventoryLevel] = useState<boolean>(true);
    const { data: topOnHandProduct, isFetching: topOnHandProductLoading } =
        useGetDashboardTopOnHandProduct(fetchInventoryLevel);
    const { data: productProfitMargin, isFetching: productProfitLoading } =
        useGetDashboardProductProfitMargin(fetchProductSalesMargin);

    const {
        fetchingConversation,
        fetchConversationSuccess,
        fetchConversationFailure,
        sendingAiMessage,
        sendAiMessageSuccess,
        creatingConversation,
        createConversationSuccess,
    } = useAppSelector((state) => state.chat);
    const { addingForecastConfig, addForecastConfigSuccess } = useAppSelector(
        (state) => state.dashboard
    );

    const navigate = useNavigate();
    const [durationFilter, setDurationFilter] = useState<string>("This year");
    const [modalDurationFilter, setModalDurationFilter] = useState<string>("This year");
    const [channelFilter, setChannelFilter] = useState<string>("");
    const [modalChannelFilter, setModalChannelFilter] = useState<string>("");
    const [productFilter, setProductFilter] = useState<string[]>([]);
    const [modalProductFilter, setModalProductFilter] = useState<string>("");
    const [analyticsDuration, setAnalyticsDuration] = useState<string>("monthly");
    const [forecastModal, setForecastModal] = useState<boolean>(false);
    const [chartData, setChartData] = useState<{ [key: string]: any }>([]);
    const [activePeriod, setActivePeriod] = useState<number>(0);
    const [chartFilter, setChartFilter] = useState<string>("yearly");
    const [categoryFilter, setCategoryFilter] = useState<string>("");
    const [customValue, customOnChange] = useState("");
    const [showCustomDate, setShowCustomDate] = useState<boolean>(false);
    const [startAndEndDate, setStartAndEndDate] = useState<string[]>([]);
    const [customFilter, setCustomFilter] = useState<string>("");
    const [profitMargin, setProfitMargin] = useState<boolean>(false);
    const [inventory, setInventory] = useState<boolean>(false);
    const [showAIChatHistory, setShowAIChatHistory] = useState<boolean>(false);
    const [showAIAgent, setShowAIAgent] = useState<boolean>(false);
    const [expndAIAgent, setExpandAIAgent] = useState<boolean>(false);
    const [chats, setChats] = useState<{ [key: string]: any }[]>([]);
    const [firebaseChats, setfirebaseChats] = useState<{ [key: string]: any }[]>([]);
    const [chatId, setChatId] = useState<string>("");
    const [message, setMessage] = useState("");
    const [activeConversation, setActiveConversation] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [inventoryIdx, setInventoryIdx] = useState<number>();
    const [profitIdx, setProfitIdx] = useState<number>();
    const [monthlyConfigChange, setMonthlyConfigChange] = useState<{ [key: string]: any }[]>([]);
    const [weeklyConfigChange, setWeeklyConfigChange] = useState<{ [key: string]: any }[]>([]);
    const [trackPerformance, setTrackPerformance] = useState<boolean>(true);
    const [weeklyChange, setWeeklyChange] = useState<{ [key: string]: any }>({});

    const dispatch = useAppDispatch();
    const [param] = useSearchParams();
    const conversationId = param.get("conversationId");
    const customRef = useRef(null);
    const showMoreRef = useRef(null);
    const clearSearchRef = useRef(null);
    const [showMore, setShowMore] = useState<number>();
    const [productSearch, setProductSearch] = useState<string>("");
    const [dateFilter, setDateFilter] = useState<string>("");
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
    // const [weeklyData, setWeeklyData] = useState<any[]>([]);
    const [editedValues, setEditedValues] = useState<{ stockNeeds?: string; vendors?: string[] }[]>(
        []
    );
    const [changedSupplier, setChangedSupplier] = useState<any[]>([]);
    const [screenWidth, setScreenWidth] = useState<number>();
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<number>(0);
    const currentDate = moment();
    const monthlyTab = chartData?.[0]?.filter((month) => {
        const monthDate = moment(month, "MMM YYYY"); // Parse the month using moment.js
        return monthDate.isSameOrAfter(currentDate, "month"); // Check if the month is the current month or a future month
    });

    const forecastTabs = [
        { id: 0, title: "Graph view" },
        { id: 1, title: "Table view" },
    ];

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [showAIAgent]);
    const handleResize = () => {
        const dashboardContent = document.getElementById("dashboardContent");
        setScreenWidth(dashboardContent?.clientWidth);
    };

    useEffect(() => {
        if (screenWidth < 1024 && expndAIAgent) {
            setExpandAIAgent(false);
        }
    }, [screenWidth, expndAIAgent]);

    const [dates, setDates] = useState<{
        startDate: string;
        endDate?: string;
    }>({
        startDate: "",
        endDate: "",
    });

    const dateValues = [
        {
            period: "This year",
            value: "yearly",
        },
        {
            period: "This month",
            value: "monthly",
        },
        // {
        //     period: "This week",
        //     value: "weekly",
        // },
        {
            period: "custom",
            value: "custom",
        },
    ];

    const dateValues2 = [
        {
            period: "This year",
            value: "yearly",
        },
        {
            period: "This month",
            value: "monthly",
        },
        {
            period: "This week",
            value: "weekly",
        },

        {
            period: "Today",
            value: "daily",
        },
        {
            period: "All times",
            value: "",
        },
    ];

    const { data: analyticsData, isFetching: quickStatsLoading } =
        useGetDashboardAnalytics(analyticsDuration);

    const handleChangeValue = (e) => {
        const { value } = e.target;
        profitMarginModal || inventoryModal
            ? setModalChannelFilter(value)
            : setChannelFilter(value);

        if (value === "All channels") {
            profitMarginModal
                ? setProductChannel("")
                : inventoryModal
                ? setChannel("")
                : handleChannelChange("");
        } else {
            profitMarginModal
                ? setProductChannel(value)
                : inventoryModal
                ? setChannel(value)
                : handleChannelChange(value);
        }
    };

    const sortedChat = sortBy(
        uniqBy(
            chats?.filter((chat) => chat?.conversationId === (chatId || conversationId)),
            "_id"
        ),
        [
            function (o) {
                return o.createdAt;
            },
        ]
    );

    const sortedFirebaseChat = sortBy(
        uniqBy(
            firebaseChats?.filter((chat) => chat?.conversationId === (chatId || conversationId)),
            "_id"
        ),
        [
            function (o) {
                return o.createdAt;
            },
        ]
    );

    const handleCategoryValue = (e) => {
        const { value } = e.target;
        setCategoryFilter(value);
        handleCategoryChange(value);
    };

    const handleDateChange = (e) => {
        const { value } = e.target;
        const [val, period] = value.split("-");
        profitMarginModal || inventoryModal
            ? setModalDurationFilter(period)
            : setDurationFilter(period);
        setCustomFilter(val);

        if (val !== "custom") {
            if (!profitMarginModal && !inventoryModal) {
                handleDateRangeChange(val);
                setStartAndEndDate([]);
                setChartFilter(val);
                customOnChange("");
            }

            profitMarginModal ? setProductDateRange(val) : inventoryModal && setDateRange(val);
        } else if (!profitMarginModal && !inventoryModal) {
            setShowCustomDate(true);
        }
    };

    const handleItemChange = (idx: number, name: string, value: string | string[]) => {
        const itemDataCopyArr = [...editedValues];
        itemDataCopyArr[idx][name] = value;

        setEditedValues(itemDataCopyArr);
    };

    useEffect(() => {
        if (Boolean(addForecastConfigSuccess)) {
            toast.custom((t) => (
                <CustomToast t={t} message={"Configurations saved successfully"} type={"success"} />
            ));
            dispatch(dashboardActions.resetAddForecastConfig());
        }
    }, [dispatch, addForecastConfigSuccess]);

    useEffect(() => {
        if (gwmData) {
            let chartDataValues;
            if (customFilter === "custom" && startAndEndDate?.length === 2) {
                chartDataValues = getDashboardChartData(gwmData, "custom", startAndEndDate);
            } else {
                chartDataValues = getDashboardChartData(gwmData, chartFilter);
            }
            setChartData(chartDataValues);
        }
    }, [chartFilter, gwmData, startAndEndDate]);

    useEffect(() => {
        if (durationFilter === "custom" && customValue[0] !== customValue[1]) {
            const dates = [
                moment(customValue?.[0]).format("MMM DD YYYY"),
                moment(customValue?.[1]).format("MMM DD YYYY"),
            ];

            setStartAndEndDate([dates?.[0], dates?.[1]]);
            if (customValue?.[0] !== customValue?.[1]) {
                handleStartAndEndDateChange(customValue);
            }
        }
    }, [durationFilter, customValue]);

    const handleProductValue = (e) => {
        const { value } = e.target;

        if (profitMarginModal) {
            setProductInventoryId(value?.split("-")[0]);
        } else if (inventoryModal) {
            setInventoryId(value?.split("-")[0]);
        } else {
            const prodIdsCopy = [...productIds];
            const productFilterCopy = [...productFilter];
            const newValue = value?.split("-")[0];
            const newName = value?.split("-")[1];
            const isValuePresent = prodIdsCopy?.find((id) => id === newValue);

            if (isValuePresent) {
                handleProductChange(prodIdsCopy?.filter((id) => id !== newValue));
                setProductFilter(productFilterCopy?.filter((name) => name !== newName));
            } else {
                if (newValue === "") {
                    handleProductChange([]);
                    setProductFilter([]);
                } else {
                    setProductFilter((prev) => [...prev, newName]);
                    handleProductChange([...prodIdsCopy, newValue]);
                }
            }
        }

        if (!profitMarginModal && !inventoryModal) {
            handleCategoryChange("");
            setCategoryFilter("");
        }

        if (profitMarginModal || inventoryModal) {
            setModalProductFilter(value);
        }
        // profitMarginModal || inventoryModal
        //     ? setModalProductFilter(value)
        //     : setProductFilter(value);
    };

    useEffect(() => {
        if (!profitMarginModal && !inventoryModal) {
            setModalProductFilter("");
            setModalChannelFilter("");
            setModalDurationFilter("");
            setSearch("");
            setSearchTwo("");
            setDebouncedSearch("");
            setDebouncedSearchTwo("");
            setProductSearch("");
            handleProductProfitDebouncedChange("");
            setProductInventoryId("");
            setProductChannel("");
            setProductDateRange("");
            handleInventoryDebouncedChange("");
            setInventoryId("");
            setChannel("");
            setDateRange("");
            productProfitSetSortValues("");
            inventorySetSortValues("");
        }
    }, [profitMarginModal, inventoryModal]);

    const handleShowMore = (idx) => {
        setShowMore(idx);

        if (idx === 1 && businessStats?.totalInventoryLevelValue !== undefined) {
            setFetchInventoryLevel(true);
        } else if (idx === 2 && businessStats?.productSalesMarginValue !== undefined) {
            setFetchProductSalesMargin(true);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (customRef.current && !customRef.current.contains(event.target)) {
                setShowCustomDate(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [customRef]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showMoreRef.current && !showMoreRef.current.contains(event.target)) {
                setShowMore(0);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showMoreRef]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (clearSearchRef.current && !clearSearchRef.current.contains(event.target)) {
                setSearch("");
                setSearchTwo("");
                setDebouncedSearch("");
                setDebouncedSearchTwo("");
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [clearSearchRef]);

    const analyticsDurationOptions = [
        { label: "This month", value: "monthly" },
        { label: "This year", value: "yearly" },
    ];

    const configureForecastOptions = [
        { label: "General configuration", value: "table" },
        // { label: "Plan for a product", value: "" },
        // { label: "Simulate a campaign", value: "" },
    ];

    const handleSortChange = (idx: number, sortValue: number) => {
        let sortKey;

        if (idx === 0) {
            sortKey = "productName";
        } else if (idx === 1) {
            sortKey = "totalSold";
        } else if (idx === 2) {
            sortKey = "unitCost";
        } else if (idx === 3) {
            sortKey = "actualRevenue";
        } else if (idx === 4) {
            sortKey = "actualRevenue";
        } else if (idx === 5) {
            sortKey = "totalProfit";
        } else if (idx === 6) {
            sortKey = "percentageProfitContribution";
        }
        productProfitSetSortValues({ [sortKey]: sortValue });
    };

    const handleInventorySortChange = (idx: number, sortValue: number) => {
        let sortKey;

        if (idx === 0) {
            sortKey = "productName";
        } else if (idx === 1) {
            sortKey = "stockLevel";
        } else if (idx === 2) {
            sortKey = "inventoryCost";
        } else if (idx === 3) {
            sortKey = "forecastedSales";
        }
        inventorySetSortValues({ [sortKey]: sortValue });
    };

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const tableProfitMarginHeader = [
        { title: `Product`, widthClass: "w-[160px]", sortIcon: true },
        { title: "Sold units", widthClass: "w-[130px]", sortIcon: true },
        { title: "Unit price", widthClass: "w-[120px]", sortIcon: true },
        { title: "Actual revenue", widthClass: "w-[155px]", sortIcon: true },
        { title: "Sales Profit", widthClass: "w-[112px]", sortIcon: true },
        { title: "%Profit Margin", widthClass: "w-[150px]", sortIcon: true },
    ];

    const tableProfitMarginBody = productProfitList?.map((product, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p className="">{truncate(product?.productName, { length: 20 })}</p>
                        <p className="text-slate-500 text-xs ">{`SKU No: ${product?.sku}`}</p>
                    </div>
                ),
            },

            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p className="">{product?.totalSold?.toLocaleString()}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p className="">${formatMoney().format(product?.unitPrice)}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600 py-2 `}
                    >
                        <p className="">${formatMoney().format(product?.actualRevenue)}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div className="!font-rocGroteskMedium border-slate-100 text-sm text-slate-600 py-2  ">
                        <p className="">${formatMoney().format(product?.totalProfit)}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div className="!font-rocGroteskMedium border-slate-100 text-sm text-slate-600 py-2  ">
                        <p className="">{Math.round(product?.percentageProfitContribution)}%</p>
                    </div>
                ),
            },
        ];
    });

    const mobileTableProfitMarginBody = productProfitList?.map((product, idx) => {
        return (
            <div
                key={idx}
                className="border-slate-200 border rounded py-4 px-2.5 font-rocGroteskMedium"
            >
                <div className=" flex justify-between items-center">
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-[13px] text-slate-600  pb-2`}
                    >
                        <p className="">{truncate(product?.productName, { length: 20 })}</p>
                        <p className="text-slate-500 text-[10px] ">{`SKU No: ${product?.sku}`}</p>
                    </div>

                    <i
                        className={`${
                            profitIdx === idx
                                ? "ri-arrow-down-s-line text-[#16C6A4]"
                                : "ri-arrow-right-s-line text-[#042821]"
                        } text-2xl `}
                        onClick={() => {
                            if (idx === profitIdx) {
                                setProfitIdx(-1);
                            } else {
                                setProfitIdx(idx);
                            }
                        }}
                    ></i>
                </div>

                {profitIdx === idx && (
                    <div className="space-y-[10px] border-t border-t-slate-200 text-slate-700 text-[13px] font-rocGroteskMedium">
                        <div className="flex justify-between  pt-2.5">
                            <p className="">Sold units</p>
                            <p className="">{product?.totalSold?.toLocaleString()}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className=""> Unit price </p>
                            <p className="">${formatMoney().format(product?.unitPrice)}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="">Actual Revenue </p>

                            <p className="">${formatMoney().format(product?.actualRevenue)}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className=""> Sales profit </p>
                            <p className="">${formatMoney().format(product?.totalProfit)}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className=""> Profit margin % </p>
                            <p className="">{Math.round(product?.percentageProfitContribution)}%</p>
                        </div>
                    </div>
                )}
            </div>
        );
    });

    const tableInventoryHeader = [
        { title: `Product`, widthClass: "w-[160px]", sortIcon: true },
        { title: "Current Inventory level", widthClass: "w-[195px]", sortIcon: true },
        { title: "Inventory cost", widthClass: "w-[175px]", sortIcon: true },
        { title: "Sales demand forecast", widthClass: "w-[206px]", sortIcon: true },
    ];

    const tableInventoryBody = topInventoryList?.map((product, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p className="">{truncate(product?.productName, { length: 20 })}</p>
                        <p className="text-slate-500 text-xs ">{`SKU No: ${product?.sku}`}</p>
                    </div>
                ),
            },

            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p className="">{product?.stockLevel?.toLocaleString()}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p className="">${formatMoney().format(product?.inventoryCost)}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium pr-4 border-slate-100 text-sm text-slate-600   flex w-full justify-between items-center py-2`}
                    >
                        <p className=""> {product?.forecastedSales?.toLocaleString()} pcs</p>
                        <i className="ri-line-chart-line text-g-55"></i>
                    </div>
                ),
            },
        ];
    });

    const mobileTableInventoryBody = topInventoryList?.map((product, idx) => {
        return (
            <div
                key={idx}
                className="border-slate-200 border rounded py-4 px-2.5 font-rocGroteskMedium"
            >
                <div className=" flex justify-between items-center">
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-[13px] text-slate-600  pb-2`}
                    >
                        <p className="">{truncate(product?.productName, { length: 20 })}</p>
                        <p className="text-slate-500 text-[10px] ">{`SKU No: ${product?.sku}`}</p>
                    </div>

                    <i
                        className={`${
                            inventoryIdx === idx
                                ? "ri-arrow-down-s-line text-[#16C6A4]"
                                : "ri-arrow-right-s-line text-[#042821]"
                        }  text-2xl`}
                        onClick={() => {
                            if (idx === inventoryIdx) {
                                setInventoryIdx(-1);
                            } else {
                                setInventoryIdx(idx);
                            }
                        }}
                    ></i>
                </div>

                {inventoryIdx === idx && (
                    <div className="space-y-[10px] border-t border-t-slate-200 text-slate-700 text-[13px]">
                        <div className="flex justify-between  pt-2.5">
                            <p className="">Current Inventory level</p>
                            <p className="">{product?.stockLevel?.toLocaleString()}</p>
                        </div>
                        <div className="flex justify-between ">
                            <p className="">Inventory cost </p>
                            <p className="">${formatMoney().format(product?.inventoryCost)}</p>
                        </div>
                        <div className="flex justify-between ">
                            <p className="">Sales demand forecast </p>

                            <div className={`flex justify-between items-center gap-2`}>
                                <p className="">{product?.forecastedSales?.toLocaleString()} pcs</p>
                                <i className="ri-line-chart-line text-g-55"></i>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    });

    const handleSaveConfig = () => {
        const parsedData = [
            ...(chartFilter === "yearly" ? monthlyConfigChange : weeklyConfigChange),
        ]?.map((item) => {
            return Object.values(item)[0];
        });
        const body = {
            configs: flattenDeep(parsedData),
        };

        dispatch(dashboardActions.addForecastConfig(body));
    };

    const handleConfigChange = (value, inventory, weekData) => {
        const date = moment(monthlyTab?.[activePeriod], "MMMM YYYY");
        const year = date.year();
        const month = date.month() + 1;
        const today = moment();
        const thisMonth = today.month();
        const thisYear = today.year();
        const currMonth = today?.format("MMM-YYYY");
        const nOfWeeksOfTheMonth = getWeeksInMonth(thisYear, thisMonth);
        const keyFormat = chartFilter === "yearly" ? monthlyTab?.[activePeriod] : currMonth;
        const configCopy = [
            ...(chartFilter === "yearly" ? monthlyConfigChange : weeklyConfigChange),
        ];
        const conf = configCopy.find((item) => item[keyFormat]?.inventoryId === inventory?._id);
        const configIndex = configCopy.findIndex(
            (item) => item[keyFormat]?.inventoryId === inventory?._id
        );
        const weekValueFromMonthValue =
            Number(String(value)?.split(",")?.join("") || 0) / nOfWeeksOfTheMonth;

        const yearlyData = {
            year: `${year}-01-01`,
            channel: inventory?.channel,
            forecastLevel: "global",
            monthlyDemand: {
                month: month,
                quantity: Number(String(value)?.split(",")?.join("") || 0),
            },
            weeklyDemand: Array(nOfWeeksOfTheMonth)
                .fill(0)
                ?.map((val, idx) => {
                    return {
                        week: idx + 1,
                        quantity: weekValueFromMonthValue,
                    };
                }),
            inventoryId: inventory?._id,
            helpTrackPerformance: trackPerformance,
        };

        const weeklyValues = conf?.[keyFormat]?.weeklyDemand?.map((item) => item?.quantity);

        const weeklyData = [...(weeklyValues || [])];
        weeklyData[activePeriod] = Number(String(value)?.split(",")?.join(""));

        const monthlyData = {
            year: `${thisYear}-01-01`,
            channel: inventory?.channel,
            forecastLevel: "global",
            weeklyDemand: [...(weekData?.length > weeklyData?.length ? weekData : weeklyData)]?.map(
                (val, idx) => {
                    return {
                        week: idx + 1,
                        quantity:
                            Number(weeklyData?.[idx]) >= 0
                                ? Number(weeklyData?.[idx])
                                : Number(
                                      String(weekData?.[idx]?.weeklyDemand?.quantity)
                                          ?.split(",")
                                          ?.join("")
                                  ) || 0,
                    };
                }
            ),
            monthlyDemand: {
                month: thisMonth,
                quantity: weeklyData?.reduce((acc, curr) => acc + Number(curr), 0),
            },
            inventoryId: inventory?._id,
            helpTrackPerformance: trackPerformance,
        };

        if (configIndex === -1) {
            configCopy.push({ [keyFormat]: chartFilter === "yearly" ? yearlyData : monthlyData });
        } else {
            configCopy[configIndex] = {
                [keyFormat]: chartFilter === "yearly" ? yearlyData : monthlyData,
            };
        }

        if (chartFilter === "yearly") {
            setMonthlyConfigChange(configCopy);
        } else {
            setWeeklyConfigChange(configCopy);
        }
    };

    const configureForecastTableHeader = [
        {
            title: (
                <div className="flex space-x-1.5 items-center">
                    <span className="text-xs font-rocGroteskMedium ">Product</span>

                    <SynthTooltip
                        content={"Displays the SKU and name of the product being tracked."}
                        divId={"product-column"}
                        icon={<i className="ri-information-fill text-slate-300 text-sm"></i>}
                    />
                </div>
            ),
            widthClass: "w-[200px]",
        },
        {
            title: (
                <div className="flex space-x-1.5 items-center">
                    <span className="text-xs font-rocGroteskMedium ">Current stock level</span>

                    <SynthTooltip
                        content={
                            "Shows the current quantity of the product available in your inventory."
                        }
                        divId={"current-stock-level-column"}
                        icon={<i className="ri-information-fill text-slate-300 text-sm"></i>}
                        position="bottom"
                    />
                </div>
            ),
            widthClass: "w-[200px]",
        },
        {
            title: (
                <div className="flex space-x-1.5 items-center">
                    <span className="text-xs font-rocGroteskMedium ">Synth's Growth rate</span>

                    <SynthTooltip
                        content={
                            "Indicates the projected percentage growth rate for the product as calculated by Synth."
                        }
                        divId={"synth-growth-rate-column"}
                        icon={<i className="ri-information-fill text-slate-300 text-sm"></i>}
                    />
                </div>
            ),
            widthClass: "w-[200px]",
        },
        {
            title: (
                <div className="flex space-x-1.5 items-center">
                    <span className="text-xs font-rocGroteskMedium ">Adjusted demand forecast</span>

                    <SynthTooltip
                        content={
                            "Represents the user-adjusted forecast for product demand based on expected sales."
                        }
                        divId={"adjusted-demand-forecast-column"}
                        icon={<i className="ri-information-fill text-slate-300 text-sm"></i>}
                    />
                </div>
            ),
            widthClass: "w-[230px]",
        },
        {
            title: (
                <div className="flex space-x-1.5 items-center">
                    <span className="text-xs font-rocGroteskMedium ">Synth's demand forecast</span>

                    <SynthTooltip
                        content={
                            "Displays the demand forecast automatically generated by Synth based on historical data and trends."
                        }
                        divId={"synth-demand-forecast-column"}
                        icon={<i className="ri-information-fill text-slate-300 text-sm"></i>}
                    />
                </div>
            ),
            widthClass: "w-[210px]",
        },
        {
            title: (
                <div className="flex space-x-1.5 items-center">
                    <span className="text-xs font-rocGroteskMedium ">Adjusted Stock deficit</span>

                    <SynthTooltip
                        content={
                            "Shows the shortfall in stock based on the adjusted demand forecast."
                        }
                        divId={"adjusted-stock-deficit-column"}
                        icon={<i className="ri-information-fill text-slate-300 text-sm"></i>}
                    />
                </div>
            ),
            widthClass: "w-[200px]",
        },
        {
            title: (
                <div className="flex space-x-1.5 items-center">
                    <span className="text-xs font-rocGroteskMedium ">Synth's Stock deficit</span>

                    <SynthTooltip
                        content={
                            "Indicates the stock deficit calculated by Synth if current trends continue."
                        }
                        divId={"synth-stock-deficit-column"}
                        icon={<i className="ri-information-fill text-slate-300 text-sm"></i>}
                    />
                </div>
            ),
            widthClass: "w-[200px]",
        },
        {
            title: (
                <div className="flex space-x-1.5 items-center">
                    <span className="text-xs font-rocGroteskMedium ">
                        Adjusted revenue forecast
                    </span>

                    <SynthTooltip
                        content={
                            "Represents the projected revenue from this product based on your adjusted demand forecast."
                        }
                        divId={"adjusted-revenue-forecast-column"}
                        icon={<i className="ri-information-fill text-slate-300 text-sm"></i>}
                    />
                </div>
            ),
            widthClass: "w-[230px]",
        },
        {
            title: (
                <div className="flex space-x-1.5 items-center">
                    <span className="text-xs font-rocGroteskMedium ">Synth's Revenue forecast</span>

                    <SynthTooltip
                        content={
                            "Displays the revenue forecast calculated by Synth using its demand forecast."
                        }
                        divId={"synth-revenue-forecast-column"}
                        icon={<i className="ri-information-fill text-slate-300 text-sm"></i>}
                    />
                </div>
            ),
            widthClass: "w-[210px]",
        },
        { title: "Replenishment due", widthClass: "w-[200px]" },
    ];

    const configureForecastTableBody = configList?.map((product, idx) => {
        const currMonth = moment()?.format("MMM-YYYY");
        const prevMonth = moment()?.subtract(1, "months")?.format("MMM-YYYY");
        const currentKeyFormat = monthlyTab?.[activePeriod]?.split(" ")?.join("-");
        const prevKeyFormat = moment(currentKeyFormat)?.subtract(1, "months")?.format("MMM-YYYY");

        const currentConfigObj = product?.configs?.find?.((item) => {
            return chartFilter === "yearly" ? item?.[currentKeyFormat] : item?.[currMonth];
        });

        const prevConfigObj = product?.configs?.find?.((item) => {
            return chartFilter === "yearly"
                ? item?.[activePeriod === 0 ? prevMonth : prevKeyFormat]
                : item?.[currMonth];
        });

        const currentConfigValues =
            chartFilter === "yearly"
                ? currentConfigObj?.[currentKeyFormat]?.[0]
                : currentConfigObj?.[currMonth]?.[activePeriod];

        const prevConfigValues =
            chartFilter === "yearly"
                ? prevConfigObj?.[activePeriod === 0 ? prevMonth : prevKeyFormat]?.[0]
                : prevConfigObj?.[currMonth]?.[activePeriod - 1];

        const currForecastData =
            chartFilter === "yearly"
                ? product?.estinmatedSalesForecast?.monthlyForecast?.find?.((item) => {
                      return item?.yearMonth === currentKeyFormat;
                  })
                : product?.estinmatedSalesForecast?.weeklyForecast?.filter?.((item) => {
                      return item?.yearMonth === currMonth;
                  });

        const prevForecastData =
            chartFilter === "yearly"
                ? product?.estinmatedSalesForecast?.monthlyForecast?.find?.((item) => {
                      return item?.yearMonth === prevKeyFormat;
                  })
                : product?.estinmatedSalesForecast?.weeklyForecast?.filter?.((item) => {
                      return item?.yearMonth === currMonth;
                  });

        const currDemandForecast =
            chartFilter === "yearly"
                ? String(Math.round(currForecastData?.forecastedSale || 0))
                : String(Math.round(currForecastData?.[activePeriod]?.forecastedSale || 0));

        const prevDemandForecast =
            chartFilter === "yearly"
                ? String(Math.round(prevForecastData?.forecastedSale || 0))
                : String(Math.round(prevForecastData?.[activePeriod - 1]?.forecastedSale || 0));

        const currStockLevel = Math.round(
            Number(currentConfigValues?.monthlyDemand?.stockLevel) ||
                Number(product?.inventory?.productStockDetails?.stockLevel)
        );

        const stockDeficit =
            chartFilter === "yearly"
                ? String(
                      Math.round(currForecastData?.forecastedSale || 0) -
                          Number(currStockLevel || 0)
                  )
                : String(
                      Math.round(currForecastData?.[activePeriod]?.forecastedSale || 0) -
                          Number(currStockLevel || 0)
                  );

        const revenueForecast =
            chartFilter === "yearly"
                ? Math.round(currForecastData?.forecastedSale || 0) *
                  (product?.inventory?.salesPrice?.amount || 0)
                : Math.round(currForecastData?.[activePeriod]?.forecastedSale || 0) *
                  (product?.inventory?.salesPrice?.amount || 0);

        const configCopy = [
            ...(chartFilter === "yearly" ? monthlyConfigChange : weeklyConfigChange),
        ];
        const changedConfig = configCopy.find((conf) => {
            return (
                conf?.[chartFilter === "yearly" ? monthlyTab?.[activePeriod] : currMonth]
                    ?.inventoryId === product?.inventory?._id
            );
        });
        const changeValue = Math.round(
            chartFilter === "yearly"
                ? changedConfig?.[monthlyTab?.[activePeriod]]?.monthlyDemand?.quantity
                : changedConfig?.[currMonth]?.weeklyDemand?.[activePeriod]?.quantity
        );
        const defaultValue = Math.round(
            chartFilter === "yearly"
                ? currentConfigValues?.monthlyDemand?.quantity || ""
                : currentConfigValues?.weeklyDemand?.quantity || ""
        );

        const adjustedRevenueForecast =
            Math.round(Number(changeValue || defaultValue || 0)) *
            (product?.inventory?.salesPrice?.amount || 0);

        const adjustedStockDeficit = String(
            Math.round(Number(changeValue || defaultValue || 0)) - Number(currStockLevel || 0)
        );

        const synthGrowthRate =
            Boolean(Number(currDemandForecast)) && Boolean(Number(prevDemandForecast))
                ? Math.round(
                      ((Number(currDemandForecast || 0) - Number(prevDemandForecast || 0)) /
                          ((Number(currDemandForecast || 0) + Number(prevDemandForecast || 0)) /
                              2)) *
                          100
                  )
                : 0;

        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <div>
                            <a data-tooltip-id={`product-column-${idx}`}>
                                <p className="text-slate-500 text-xs ">
                                    SKU {product?.inventory?.sku}
                                </p>

                                <p className="">
                                    {truncate(product?.inventory?.productName, {
                                        length: 20,
                                    })}
                                </p>
                            </a>
                            <Tooltip
                                id={`product-column-${idx}`}
                                place={"top"}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            >
                                <p className="text-slate-500 text-xs ">
                                    SKU {product?.inventory?.sku}
                                </p>

                                <p className="">{product?.inventory?.productName}</p>
                            </Tooltip>
                        </div>
                    </div>
                ),
            },

            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p className="">{currStockLevel?.toLocaleString() || "N/A"}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p className="">
                            {synthGrowthRate && !isNaN(synthGrowthRate)
                                ? synthGrowthRate + "%"
                                : "N/A"}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600 w-full py-2`}
                    >
                        <TextInput
                            name={"adjustedDemandForecast"}
                            type={"text"}
                            onWheel={(e: any) => e.target.blur()}
                            value={monifyInputField(changeValue >= 0 ? changeValue : defaultValue)}
                            onChange={(e) => {
                                handleConfigChange(
                                    monifyInputField(e.target.value),
                                    product?.inventory,
                                    currentConfigObj?.[currMonth] || []
                                );
                            }}
                            placeholder={"Adjusted Demand"}
                            required={true}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(
                                    evt.key
                                ) && evt.preventDefault()
                            }
                            pattern={"^[0-9]+$"}
                            title={"must be digits"}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p className="">{formatMoney().format(currDemandForecast)}</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p
                            className={`${
                                Number(currStockLevel || 0) >= Number(changeValue || defaultValue)
                                    ? ""
                                    : "text-r-55"
                            }`}
                        >
                            {Number(currStockLevel || 0) >= Number(changeValue || defaultValue)
                                ? "0"
                                : formatMoney()?.format(adjustedStockDeficit)}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p
                            className={`${
                                Number(currStockLevel || 0) >= Number(currDemandForecast)
                                    ? ""
                                    : "text-r-55"
                            }`}
                        >
                            {Number(currStockLevel || 0) >= Number(currDemandForecast)
                                ? "N/A"
                                : formatMoney()?.format(stockDeficit)}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p className="">
                            {Boolean(adjustedRevenueForecast)
                                ? formatMoney()?.format(adjustedRevenueForecast)
                                : "N/A"}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p className="">
                            {revenueForecast ? formatMoney()?.format(revenueForecast) : "N/A"}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
                    >
                        <p
                            className={`${
                                Number(currStockLevel || 0) >= Number(currDemandForecast)
                                    ? ""
                                    : "text-r-55"
                            }`}
                        >
                            {Number(currStockLevel || 0) >= Number(currDemandForecast)
                                ? "N/A"
                                : formatMoney()?.format(stockDeficit)}
                        </p>
                    </div>
                ),
            },
            // {
            //     content: (
            //         <div
            //             className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
            //         >
            //             <p className="">50,567</p>
            //         </div>
            //     ),
            // },
            // {
            //     content: (
            //         <div
            //             className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-600  py-2`}
            //         >
            //             <p className="">50,567</p>
            //         </div>
            //     ),
            // },
        ];
    });

    const tooltipColors = ["#2563EB", "#FFB902", "#56B648", "#6562E5"];

    const handleSelectAll = (chat) => {
        const parsedData = chat?.data?.replenishments
            ?.map((data, idx) => {
                return {
                    productId: data?.inventory?._id,
                    stockNeeds: editedValues?.[idx]?.stockNeeds || data?.qtyToBuy?.toFixed(0),
                    suppliers:
                        editedValues?.[idx]?.vendors?.length > 0
                            ? editedValues?.[idx]?.vendors?.[0]
                            : data?.bestSupplier?._id,
                    leadTime: data?.leadTime,
                };
            })
            ?.filter((data) => data?.stockNeeds && data?.suppliers);

        if (parsedData.length === selectedProducts.length) {
            return setSelectedProducts([]);
        }
        if (parsedData.length < selectedProducts?.length && parsedData.length > 0) {
            return setSelectedProducts([]);
        }
        if (parsedData.length < chat?.data?.replenishments.length) {
            toast.custom((t) => (
                <CustomToast
                    t={t}
                    message={"Please fill empty column(s) with valid value(s)"}
                    type="error"
                />
            ));
            return setSelectedProducts(parsedData);
        }
        return setSelectedProducts(parsedData);
    };

    const handleSelectItem = (item, idx) => {
        const parsedData = {
            productId: item?.inventory?._id,
            stockNeeds: editedValues?.[idx]?.stockNeeds || item?.qtyToBuy?.toFixed(0),
            suppliers:
                editedValues?.[idx]?.vendors?.length > 0
                    ? editedValues?.[idx]?.vendors?.[0]
                    : item?.bestSupplier?._id,
            leadTime: item?.leadTime,
        };
        const itemInArray = selectedProducts.find(
            (product) => product.productId === item?.inventory?._id
        );

        if (itemInArray) {
            setSelectedProducts(
                selectedProducts.filter((product) => product.productId !== item?.inventory?._id)
            );
        } else {
            if (parsedData.stockNeeds && parsedData.suppliers) {
                setSelectedProducts([...selectedProducts, parsedData]);
            } else {
                toast.custom((t) => (
                    <CustomToast
                        t={t}
                        message={"Please fill empty column(s) with valid value(s)"}
                        type="error"
                    />
                ));
            }
        }
    };

    const handleCreateConversation = () => {
        dispatch(
            chatActions.createConversation({
                title: "New Chat",
                receiverType: "co-pilot",
                conversationType: "general",
            })
        );
    };

    const handleUpdateChat = (chat) => {
        const body = {
            data: {
                ...chat?.data,
                replenishments: chat?.data?.replenishments?.map((item, id) => ({
                    ...item,
                    bestSupplier: changedSupplier?.[id] || item?.bestSupplier,
                    bestSupplierId: editedValues?.[id]?.vendors?.[0] || item?.bestSupplier?._id,
                    qtyToBuy: Number(editedValues?.[id]?.stockNeeds || item?.qtyToBuy),
                })),
            },
            chatId: sortedFirebaseChat?.slice(-1)?.[0]?._id,
        };

        dispatch(chatActions.updateChatData(body));
    };

    const handleSendMessage = (message: string, conversationId?: string) => {
        if (conversationId) {
            if (chats?.slice(-1)?.[0]?.conversationType?.toLowerCase() === "show_replenishment") {
                const chat = chats?.slice(-1)?.[0];
                const poData = selectedProducts.map((product) => {
                    return {
                        inventoryId: product.productId,
                        qty: product.stockNeeds,
                        supplierId: product.suppliers,
                        leadTime: product.leadTime,
                    };
                });

                handleUpdateChat(chat);
                return dispatch(
                    chatActions.sendAiMessage(
                        {
                            conversationId,
                            message,
                            conversationType: "general",
                            data: {
                                poData,
                            },
                        },
                        setChats
                    )
                );
            }

            if (message?.trim() !== "") {
                return dispatch(
                    chatActions.sendAiMessage(
                        {
                            conversationId,
                            message,
                            conversationType: "general",
                        },
                        setChats
                    )
                );
            }
        }
    };

    useEffect(() => {
        let unSubscribe;
        firebaseService.listenForNewMessages(
            `co-pilot-chat/${conversationId}/messages`,
            (res) => {
                setfirebaseChats((prev) => {
                    if (prev?.[prev.length - 1]?.loading) {
                        return [...prev.slice(0, -1), res];
                    }

                    return [...prev, res];
                });
            },
            (unSubFunc) => {
                unSubscribe = unSubFunc;
            }
        );

        return () => {
            if (unSubscribe) {
                unSubscribe();
            }
        };
    }, [conversationId]);

    const { channel } = useChannel(
        `synth-ai-chat-${process.env.REACT_APP_STAGE === "production" ? "production" : "staging"}`,
        `new-kiri-message-v2-${
            process.env.REACT_APP_STAGE === "production" ? "production" : "staging"
        }-sub`,
        (message) => {
            if (message?.data?.conversationId === conversationId) {
                const data = {
                    conversationId: message?.data?.conversationId,
                    conversationType: message?.data?.conversationType,
                    message: message?.data?.text,
                    createdAt: new Date(message?.timestamp).toISOString(),
                    data: message?.data?.data,
                    _id: message?.id,
                };
                // console.log("dattaaa", data);
                setChats((prev) => {
                    if (prev?.[prev.length - 1]?.loading) {
                        return [...prev.slice(0, -1), data];
                    }

                    return [...prev, data];
                });
            }
        }
    );

    useEffect(() => {
        if (Boolean(sendAiMessageSuccess)) {
            setChats((prev) => [...prev, sendAiMessageSuccess?.chat]);
            setMessage("");

            dispatch(chatActions.resetSendAiMessageSuccess());
        }
    }, [sendAiMessageSuccess, dispatch, chats]);

    useEffect(() => {
        if (Boolean(conversationId)) {
            setChatId(conversationId);
            dispatch(chatActions.getSingleConversation(conversationId as string));
        }
    }, [conversationId, dispatch]);

    useEffect(() => {
        if (Boolean(fetchConversationSuccess)) {
            setChats(fetchConversationSuccess?.data);
        }

        // if (Boolean(fetchConversationFailure)) {
        //     navigate("/dashboard/home");
        // }
    }, [fetchConversationSuccess, fetchConversationFailure, navigate]);
    // console.log("ChartData", chartData);
    return (
        <PageFrame
            // isLoading={gwmLoading && quickStatsLoading}
            containerClassName={"!h-[calc(100vh-108px)]"}
        >
            <div className="relative">
                <div className="w-full relative flex justify-between items-start">
                    <div
                        className={`bg-white overflow-x-hidden pb-12 ${
                            showAIAgent ? "w-0 lg:w-[65.5%] max-lg:hidden" : "w-[100%]"
                        } transition-[width] ease-in-out delay-150 duration-300`}
                    >
                        <div className="">
                            <PageTitleSection
                                leftComp={
                                    <h3 className="text-xl text-g-75 font-rocGroteskMedium">
                                        Dashboard
                                    </h3>
                                }
                            />
                        </div>

                        <div className="flex items-center justify-between mt-6">
                            <h5 className="text-g-75 text-sm lg:text-lg font-rocGroteskMedium">
                                Quick stats
                            </h5>

                            <Dropdown
                                handleChange={(name, value) => setAnalyticsDuration(value)}
                                dropdown={
                                    <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                        <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>

                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block`}
                                        >
                                            {
                                                analyticsDurationOptions?.find(
                                                    (item) => item?.value === analyticsDuration
                                                )?.label
                                            }
                                        </div>
                                    </div>
                                }
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                name="valueRange"
                                dropdownOptions={[
                                    ...analyticsDurationOptions?.map((item, idx) => ({
                                        label: (
                                            <div
                                                key={idx}
                                                className=" text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                        border-b border-b-slate-100 !w-[120px] px-4 py-[10px]"
                                            >
                                                {item?.label}
                                            </div>
                                        ),
                                        value: item?.value,
                                    })),
                                ]}
                            />
                        </div>

                        <div className="grid grid-cols-2 gap-4 lg:flex w-full mt-[25px] max-lg:border-b-slate-200 max-lg:border-b max-lg:pb-6">
                            <AnalyticsCard
                                figure={
                                    analyticsData?.amountSaved !== undefined
                                        ? millify(Number(analyticsData?.amountSaved), {
                                              precision: 2,
                                          })
                                        : "--"
                                }
                                currency={analyticsData?.amountSaved && "$"}
                                description={`Total amount saved this ${
                                    analyticsDuration === "monthly" ? "month" : "year"
                                }`}
                                loader={false}
                                figureClassName="!text-g-75 !font-rocGroteskMedium lg:!font-rocGroteskBold !mt-0 text-lg lg:!text-[32px] !leading-[28px] lg:!leading-[55px]"
                                className="!shadow-none !border-none !p-0  !rounded-none"
                                descriptionClassName="max-lg:!text-slate-500 lg:!text-late-950 !text-[10px] lg:!text-[13px] "
                            />

                            <AnalyticsCard
                                figure={
                                    analyticsData?.amountLost !== undefined
                                        ? millify(Number(analyticsData?.amountLost), {
                                              precision: 2,
                                          })
                                        : "--"
                                }
                                currency={analyticsData?.amountLost && "$"}
                                description={"Potential revenue lost due to product OOS"}
                                loader={false}
                                figureClassName="!text-g-75 !font-rocGroteskMedium lg:!font-rocGroteskBold !mt-0 text-lg lg:!text-[32px] !leading-[28px] lg:!leading-[55px]"
                                className="!shadow-none max-lg:!pl-2.5 max-lg:border-slate-300 max-lg:border-l lg:!border-none !p-0  !rounded-none"
                                descriptionClassName="max-lg:!text-slate-500 lg:!txt-slate-950 !text-[10px] lg:!text-[13px]"
                            />

                            <AnalyticsCard
                                figure={
                                    analyticsData?.productAvailabilty !== undefined
                                        ? `${+analyticsData?.productAvailabilty?.toFixed(2)}% `
                                        : "--"
                                }
                                description={"Your products’ availability rate"}
                                loader={false}
                                figureClassName="!text-g-75 !font-rocGroteskMedium lg:!font-rocGroteskBold !mt-0 text-lg lg:!text-[32px] !leading-[28px] lg:!leading-[55px]"
                                className="!shadow-none !border-none !p-0  !rounded-none"
                                descriptionClassName="max-lg:!text-slate-500 lg:!text-late-950 !text-[10px] lg:!text-[13px] "
                            />
                            <AnalyticsCard
                                figure={
                                    analyticsData?.amountSaved === undefined ||
                                    Math.round(analyticsData?.amountSaved) === 0
                                        ? "--"
                                        : `${(
                                              (analyticsData?.amountSaved /
                                                  (analyticsData?.amountSaved +
                                                      analyticsData?.amountLost)) *
                                              100
                                          ).toFixed(2)}%`
                                }
                                description={"Synth savings ratio"}
                                loader={false}
                                figureClassName="!text-g-75 !font-rocGroteskMedium lg:!font-rocGroteskBold !mt-0 text-lg lg:!text-[32px] !leading-[28px] lg:!leading-[55px]"
                                className="!shadow-none max-lg:!pl-2.5 max-lg:border-slate-300 max-lg:border-l lg:!border-none !p-0  !rounded-none"
                                descriptionClassName="max-lg:!text-slate-500 lg:!text-late-950 !text-[10px] lg:!text-[13px]"
                            />
                        </div>

                        <div className="lg:border lg:border-slate-200 lg:rounded-xl py-[15px] lg:px-5 lg:mt-[56px] mb-10">
                            <div className="lg:flex justify-between">
                                <div className="flex items-center space-x-6">
                                    <p className="text-g-75 font-rocGroteskMedium text-sm lg:text-lg mb-4 lg:mb-0">
                                        Forecast
                                    </p>

                                    <div className="bg-slate-50 !w-fit p-1 flex gap-2 font-rocGroteskMedium font-sm  max-lg:mb-0">
                                        {forecastTabs?.map((tab) => {
                                            return (
                                                <button
                                                    className={`w-auto  py-[7px] px-4  text-sm ${
                                                        activeTab === tab?.id &&
                                                        "!bg-white rounded-[6px] shadow-buttonShadow text-slate-900"
                                                    } text-slate-500 `}
                                                    onClick={() => {
                                                        setActiveTab(tab?.id);
                                                    }}
                                                >
                                                    {tab?.title}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div className=" w-full justify-end flex sm:hidden mb-4 sm:mb-0">
                                    <Dropdown
                                        handleChange={(name, value) => {
                                            setActivePeriod(0);
                                            configHandleDateRangeChange(value?.split("-")[0]);
                                            handleDateChange({ target: { name, value } });
                                        }}
                                        dropdown={
                                            <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-[6px] px-[10px] xs:gap-2 !border !border-slate-100">
                                                <i className="ri-arrow-down-s-line  xs:text-lg text-[#142837]"></i>

                                                <div
                                                    className={`text-g-75 font-rocGroteskMedium text-xs xs:text-[13px] inline-block ${
                                                        durationFilter === "custom" && "capitalize"
                                                    } `}
                                                >
                                                    {startAndEndDate?.length !== 0
                                                        ? ` ${startAndEndDate[0]} to ${startAndEndDate[1]}`
                                                        : durationFilter || "This year"}
                                                </div>
                                            </div>
                                        }
                                        dropdownItemsClasses="!px-0 !py-0"
                                        dropdownClassName="!w-full !mb-10 !-mt-7"
                                        dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                        name="valueRange"
                                        dropdownOptions={[
                                            ...dateValues?.map((item, idx) => ({
                                                label: (
                                                    <div
                                                        key={idx}
                                                        className={`${
                                                            item.period === "custom" && "capitalize"
                                                        } text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                        border-b border-b-slate-100 !w-[120px] px-4 py-[10px]`}
                                                    >
                                                        {item?.period}
                                                    </div>
                                                ),
                                                value: item?.value + "-" + item?.period,
                                            })),
                                        ]}
                                    />
                                </div>

                                <div className="">
                                    <div className="flex sm:space-x-2.5  max-sm:justify-between max-sm: items-end border-b border-b-slate-200 sm:border-b-0">
                                        <Dropdown
                                            handleChange={(name, value) => {
                                                configHandleCategoryChange(value);
                                                handleCategoryValue({
                                                    target: { name, value },
                                                });
                                            }}
                                            dropdown={
                                                <div className="flex items-center justify-between sm:rounded-[6px] sm:shadow-buttonShadow2 pt-[6px] sm:py-[6px] xs:px-[10px] xs:gap-2 !border-0 sm:!border sm:!border-slate-100">
                                                    <div
                                                        className={`text-g-75 font-rocGroteskMedium text-[11px] xs:text-[13px] inline-block`}
                                                    >
                                                        {categoryFilter
                                                            ? categoryFilter
                                                            : "All product types"}
                                                    </div>
                                                    <i className="ri-arrow-down-s-line text-xs xs:text-lg text-g-75"></i>
                                                </div>
                                            }
                                            // wholeContainerClass=" border-r  border-r-slate-200 sm:border-0 "
                                            dropdownItemsClasses="!px-0 !py-0"
                                            dropdownClassName="!mb-10 !-mt-7"
                                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 left-0 sm:right-0 rounded-[6px]  !w-[150px] !min-w-min
                                    `}
                                            name="valueRange"
                                            dropdownOptions={[
                                                {
                                                    label: (
                                                        <div
                                                            className=" text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                        border-b border-b-slate-100 !w-[150px] px-4 py-[10px]"
                                                        >
                                                            All product types
                                                        </div>
                                                    ),
                                                    value: "",
                                                },
                                                ...productCategories?.map((item) => ({
                                                    label: (
                                                        <div
                                                            className=" text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                        border-b border-b-slate-100 !w-[150px] px-4 py-[10px]"
                                                        >
                                                            {item?.label}
                                                        </div>
                                                    ),
                                                    value: item?.value,
                                                })),
                                            ]}
                                        />

                                        <div className="sm:hidden w-[1px] h-[20px] xs:h-[25px] bg-slate-200"></div>

                                        <Dropdown
                                            handleChange={(name, value) => {
                                                const prodIdsCopy = [...configProductIds];
                                                const newValue = value?.split("-")[0];
                                                const isValuePresent = prodIdsCopy?.find(
                                                    (id) => id === newValue
                                                );

                                                if (isValuePresent) {
                                                    configHandleProductChange(
                                                        prodIdsCopy?.filter((id) => id !== newValue)
                                                    );
                                                } else {
                                                    if (newValue === "") {
                                                        configHandleProductChange([]);
                                                    } else {
                                                        configHandleProductChange([
                                                            ...prodIdsCopy,
                                                            newValue,
                                                        ]);
                                                    }
                                                }

                                                handleProductValue({
                                                    target: { name, value },
                                                });
                                            }}
                                            dropdown={
                                                <div className="flex items-center sm:rounded-[6px] sm:shadow-buttonShadow2 pt-[6px] sm:py-[6px] xs:px-[10px] xs:gap-2 sm:!border sm:!border-slate-100">
                                                    <div
                                                        className={`text-g-75 font-rocGroteskMedium text-[11px] xs:text-[13px] inline-block`}
                                                    >
                                                        {productFilter?.length > 0
                                                            ? truncate(productFilter?.join(","), {
                                                                  length: 15,
                                                              })
                                                            : "All product"}
                                                    </div>
                                                    <i className="ri-arrow-down-s-line text-xs xs:text-lg text-g-75"></i>
                                                </div>
                                            }
                                            // wholeContainerClass=" border-r  border-r-slate-200 sm:border-0 "
                                            dropdownItemsClasses="!px-0 !py-0"
                                            dropdownClassName="!w-full !mb-10 !-mt-7"
                                            dropdownContainerClasses={`sm:shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] 
                                            !min-h-[300px] !max-h-[300px] !w-auto !pt-0
                                    `}
                                            loader={isLoadingMore}
                                            // endOfList={
                                            //     pagination?.currentPage === pagination?.noOfPages
                                            // }
                                            name="valueRange"
                                            search={
                                                <div ref={clearSearchRef} className="">
                                                    <TextInput
                                                        value={searchTwo}
                                                        name={"search"}
                                                        type={"text"}
                                                        required={false}
                                                        onChange={handleSearchTwo}
                                                        onInput={handleDebouncedChangeTwo}
                                                        inputPlaceholder="Search product"
                                                        containerClassname=""
                                                        inputContainerClassname={"!h-[40px]"}
                                                        // inputClassName="w-[50%]"
                                                    />
                                                </div>
                                            }
                                            leaveOpen={true}
                                            dropdownOptions={[
                                                {
                                                    label: (
                                                        <div
                                                            className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                        border-b border-b-slate-100 !w-[190px] px-2 py-[10px]"
                                                        >
                                                            <i
                                                                className={`${
                                                                    configProductIds?.length ===
                                                                        0 ||
                                                                    productIds?.length === 0
                                                                        ? "ri-checkbox-line text-carribean-green"
                                                                        : "ri-checkbox-blank-line text-slate-200"
                                                                }    text-xl`}
                                                            ></i>
                                                            All product
                                                        </div>
                                                    ),
                                                    value: "",
                                                },
                                                ...productSearchList?.map((item, idx) => ({
                                                    label: (
                                                        <div
                                                            key={idx}
                                                            className=" text-slate-600 font-rocGroteskMedium text-[13px] gap-2 flex items-center
                                                        border-b border-b-slate-100 !w-[190px] px-2 py-[10px]"
                                                        >
                                                            <i
                                                                className={`${
                                                                    configProductIds?.includes(
                                                                        item?._id
                                                                    ) ||
                                                                    productIds?.includes(item?._id)
                                                                        ? "ri-checkbox-line text-carribean-green"
                                                                        : "ri-checkbox-blank-line text-slate-200"
                                                                }   text-xl`}
                                                            ></i>

                                                            <div className="flex flex-col">
                                                                <p className="text-xs mb-1">{` SKU: ${item?.sku}`}</p>
                                                                <span className="text-[10px] leading-normal">
                                                                    {truncate(item?.productName, {
                                                                        length: 50,
                                                                    })}
                                                                </span>

                                                                {/* <span className="text-xs">
                                                                    {truncate(item?.productName, {
                                                                        length: 38,
                                                                    })}
                                                                </span>

                                                                <p className="text-[10px]">{` SKU No: ${item?.sku}`}</p> */}
                                                            </div>
                                                        </div>
                                                    ),
                                                    value: item?._id + "-" + item?.productName,
                                                })),
                                            ]}
                                        />
                                        <div className="sm:hidden w-[1px] h-[20px] xs:h-[25px] bg-slate-200"></div>

                                        <Dropdown
                                            handleChange={(name, value) => {
                                                configHandleChannelChange(value);
                                                handleChangeValue({
                                                    target: { name, value },
                                                });
                                            }}
                                            dropdown={
                                                <div className="flex items-center justify-between rounded-[6px] sm:shadow-buttonShadow2 pt-[6px] sm:py-[6px] xs:px-[10px] xs:gap-2 border-0 sm:!border sm:!border-slate-100">
                                                    <div
                                                        className={`text-g-75 font-rocGroteskMedium text-[11px] xs:text-[13px] inline-block  ${
                                                            channelFilter && "capitalize"
                                                        }`}
                                                    >
                                                        {truncate(channelFilter, {
                                                            length: 15,
                                                        }) || "All channels"}
                                                    </div>
                                                    <i className="ri-arrow-down-s-line text-xs xs:text-lg text-g-75"></i>
                                                </div>
                                            }
                                            dropdownItemsClasses="!px-0 !py-0"
                                            dropdownClassName="!mb-10 !-mt-7"
                                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[200px] !w-[190px] !min-w-min
                                    `}
                                            name="valueRange"
                                            dropdownOptions={[
                                                {
                                                    label: (
                                                        <div
                                                            className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                        border-b border-b-slate-100 !w-[190px] px-4 py-[10px]"
                                                        >
                                                            {/* <i
                                                                className={`${
                                                                    channelFilter === ""
                                                                        ? "ri-checkbox-line text-carribean-green"
                                                                        : "ri-checkbox-blank-line text-slate-200"
                                                                }   text-xl`}
                                                            ></i> */}
                                                            All channels
                                                        </div>
                                                    ),
                                                    value: "",
                                                },
                                                ...salesChannel?.map((item, idx) => ({
                                                    label: (
                                                        <div
                                                            key={idx}
                                                            className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                        border-b border-b-slate-100 !w-[190px] px-4 py-[10px] capitalize"
                                                        >
                                                            {/* <i
                                                                className={`${
                                                                    channelFilter === item
                                                                        ? "ri-checkbox-line text-carribean-green"
                                                                        : "ri-checkbox-blank-line text-slate-200"
                                                                }   text-xl`}
                                                            ></i> */}
                                                            {item}
                                                        </div>
                                                    ),
                                                    value: item,
                                                })),
                                            ]}
                                        />
                                        <div className="">
                                            <Dropdown
                                                handleChange={(name, value) => {
                                                    setActivePeriod(0);
                                                    configHandleDateRangeChange(
                                                        value?.split("-")[0]
                                                    );
                                                    handleDateChange({ target: { name, value } });
                                                }}
                                                dropdown={
                                                    <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100 max-sm:hidden">
                                                        <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>

                                                        <div
                                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block ${
                                                                durationFilter === "custom" &&
                                                                "capitalize"
                                                            } `}
                                                        >
                                                            {startAndEndDate?.length !== 0
                                                                ? ` ${startAndEndDate[0]} to ${startAndEndDate[1]}`
                                                                : durationFilter || "This year"}
                                                        </div>
                                                    </div>
                                                }
                                                dropdownItemsClasses="!px-0 !py-0"
                                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                                name="valueRange"
                                                dropdownOptions={[
                                                    ...dateValues
                                                        ?.filter((item) =>
                                                            activeTab === 0
                                                                ? true
                                                                : item?.value !== "custom"
                                                        )
                                                        ?.map((item, idx) => ({
                                                            label: (
                                                                <div
                                                                    key={idx}
                                                                    className={`${
                                                                        item.period === "custom" &&
                                                                        "capitalize"
                                                                    } text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                        border-b border-b-slate-100 !w-[120px] px-4 py-[10px]`}
                                                                >
                                                                    {item?.period}
                                                                </div>
                                                            ),
                                                            value: item?.value + "-" + item?.period,
                                                        })),
                                                ]}
                                            />
                                        </div>
                                        <div className="relative">
                                            {showCustomDate && (
                                                <div
                                                    ref={customRef}
                                                    className="w-[630px]  z-[10000] absolute  right-0 top-16 px-[28px] pt-8 pb-8 bg-white
                                                    rounded-2xl shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_32px_rgba(0,0,0,0.06)]"
                                                >
                                                    <CustomCalendar
                                                        showDoubleView={true}
                                                        selectRange={true}
                                                        value={customValue}
                                                        onChange={customOnChange}
                                                    />
                                                    <div className="flex justify-end">
                                                        <Button
                                                            btnText={"Close"}
                                                            btnClassname={"!w-fit !py-2"}
                                                            onClick={() => setShowCustomDate(false)}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="relative">
                                            {activeTab === 0 ? (
                                                <Dropdown
                                                    handleChange={(name, value) => {
                                                        if (value === "table") {
                                                            setActiveTab(1);
                                                        }
                                                    }}
                                                    dropdown={
                                                        <div className="flex items-center justify-between bg-[#56B648] rounded-[6px] sm:shadow-buttonShadow2  xs:gap-2 border-0 sm:!border sm:!border-slate-100">
                                                            <div
                                                                className={`text-g-75 font-rocGroteskMedium text-[11px] xs:text-[13px] flex items-center space-x-1.5 pt-[6px] sm:py-[6px] xs:px-[10px]`}
                                                            >
                                                                <i className="ri-edit-2-fill text-base text-white"></i>
                                                                <span className="text-white">
                                                                    Configure forecast
                                                                </span>
                                                            </div>
                                                            <div className="pt-[6px] sm:py-[6px] xs:px-[10px] border-l border-white">
                                                                <i className="ri-arrow-down-s-line text-base text-white"></i>
                                                            </div>
                                                        </div>
                                                    }
                                                    dropdownItemsClasses="!px-0 !py-0"
                                                    dropdownClassName="!mb-10 !-mt-7"
                                                    dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[200px] !w-full !min-w-min
                                    `}
                                                    name="valueRang"
                                                    dropdownOptions={configureForecastOptions?.map(
                                                        (opt) => ({
                                                            label: (
                                                                <div
                                                                    className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 px-2 py-[10px]"
                                                                >
                                                                    {opt?.label}
                                                                </div>
                                                            ),
                                                            value: opt?.value,
                                                        })
                                                    )}
                                                />
                                            ) : (
                                                <div className="flex items-center space-x-2.5">
                                                    <Button
                                                        btnText="Cancel"
                                                        btnClassname="!bg-white !border !border-slate-100 shadow-[0px_1px_2px_0px_#5B687126] !text-[13px] !px-2 !py-2.5 rounded-[5px] !h-[42px]"
                                                        icon={
                                                            <i className="ri-close-fill text-base"></i>
                                                        }
                                                        onClick={() => {
                                                            setActiveTab(0);
                                                            setMonthlyConfigChange([]);
                                                            setWeeklyConfigChange([]);
                                                        }}
                                                    />
                                                    <Button
                                                        btnText="Save configuration"
                                                        btnClassname="!bg-[#56B648] !w-[164px] !text-white !text-[13px] !whitespace-nowrap !px-2 !py-2.5 rounded-[5px] !h-[42px]"
                                                        icon={
                                                            <i className="ri-check-double-line text-base"></i>
                                                        }
                                                        isLoading={addingForecastConfig}
                                                        onClick={() => handleSaveConfig()}
                                                    />
                                                </div>
                                            )}

                                            {/* <Button
                                                btnText="Adjust Forecast"
                                                btnClassname="!bg-[#56B648] !w-fit !text-white !text-[13px] !px-2 !py-2.5 rounded-[5px]"
                                                icon={<i className="ri-add-line"></i>}
                                                onClick={() => setForecastModal(true)}
                                            /> */}

                                            {/* <ModalContainer
                                                modalClassName="!bg-transparent absolute !w-[530px] !left-auto !right-0 top-[50px] transition ease-in-out delay-150 duration-300 !h-auto shadow-tableShadow !rounded-xl"
                                                closeModal={() => setForecastModal(false)}
                                                tailwindClassName="w-[90%] lg:w-[1000px]"
                                                open={forecastModal}
                                            >
                                                <div className="bg-white  px-4 pt-[15px] pb-[25px] !w-[530px]">
                                                    <div className="flex justify-between items-center border-b border-slate-100 text-slate-800 font-rocGroteskBold">
                                                        <p className="text-base !leading-[23px] ">
                                                            Adjust forecast
                                                        </p>
                                                        <i
                                                            className="ri-close-line  text-2xl cursor-pointer text-slate-800"
                                                            onClick={() => setForecastModal(false)}
                                                        ></i>
                                                    </div>

                                                    <div className="space-y-2.5 mt-5">
                                                        <SelectInput
                                                            floatingPlaceholderClass="!text-slate-400"
                                                            className="!border-gm-25"
                                                            placeholder="Select influencing event"
                                                            iconClassName="!text-slate-400"
                                                        />
                                                        <p className="text-slate-800 font-sm font-rocGroteskMedium">
                                                            Select timeframe
                                                        </p>
                                                        <div className="grid grid-cols-2 items-center gap-2 relative">
                                                            <DateInput
                                                                label="Date from"
                                                                value={""}
                                                                borderContainerClassname="w-full !border-gm-25"
                                                                borderClassname="!border-gm-25"
                                                            />

                                                            <DateInput label="Date to" value={""} />
                                                            <div className="absolute left-[47%] z-[2] top-[20%] rounded-full w-[30px] h-[30px] shadow-buttonShadow2 flex items-center justify-center bg-white">
                                                                <i className="ri-arrow-right-line text-sm cursor-pointer text-slate-400"></i>
                                                            </div>
                                                        </div>
                                                        <p className="text-slate-800 font-sm font-rocGroteskMedium">
                                                            Select other factors
                                                        </p>
                                                        <SelectInput
                                                            floatingPlaceholderClass="!text-slate-400"
                                                            className="!border-gm-25"
                                                            iconClassName="!text-slate-400"
                                                            placeholder="Select channel"
                                                        />
                                                    </div>
                                                    <div className="flex justify-between gap-3.5 mt-6">
                                                        <Button
                                                            btnText="Adjust Forecast"
                                                            btnClassname=" !w-full  !text-[13px] !px-2 !py-4 rounded"
                                                            onClick={() => setForecastModal(false)}
                                                        />
                                                        <Button
                                                            btnText="Cancel"
                                                            btnClassname="!bg-n-20 !w-full !text-[13px] !px-2 !py-4 rounded"
                                                            onClick={() => setForecastModal(false)}
                                                        />
                                                    </div>
                                                </div>
                                            </ModalContainer> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {activeTab === 0 && (
                                <div>
                                    <div className="lg:flex justify-between items-start mt-8">
                                        <div className="flex flex-col gap-3.5 mb-6 lg:mb-0">
                                            <div className="flex items-center">
                                                <img
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1723568484/blue_line_yds7b9.svg"
                                                    alt="this-trend"
                                                    className="w-4 h-4"
                                                />
                                                <p className="text-slate-700 font-rocGroteskMedium text-[10px] leading-3 ml-1">
                                                    Current sales
                                                </p>
                                            </div>

                                            <div className="flex items-center">
                                                <img
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712800595/chartLine2_o41vii.svg"
                                                    alt="this-trend"
                                                    className="w-4 h-4"
                                                />
                                                <p className="text-slate-700 font-rocGroteskMedium text-[10px] leading-3 ml-1">
                                                    Your configured plan
                                                </p>
                                            </div>

                                            <div className="flex items-center">
                                                <img
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1723568484/red_line_yfjgnt.svg"
                                                    alt="this-trend"
                                                    className="w-4 h-4"
                                                />
                                                <p className="text-slate-700 font-rocGroteskMedium text-[10px] leading-3 ml-1">
                                                    Synth’s predicted forecast
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex max-lg:flex-col">
                                            <div className="flex w-full lg:w-fit max-lg:items-center max-lg:justify-between max-lg:border-b max-lg:border-b-slate-200">
                                                <AnalyticsCard
                                                    title={"Forecasted revenue"}
                                                    figure={
                                                        gwmData?.forecastTotalSale !== undefined
                                                            ? millify(
                                                                  Number(
                                                                      gwmData?.forecastTotalSale
                                                                  ),
                                                                  {
                                                                      precision: 2,
                                                                  }
                                                              )
                                                            : "----"
                                                    }
                                                    currency={
                                                        Boolean(gwmData?.forecastTotalSale) && "$"
                                                    }
                                                    customFigure={
                                                        Boolean(gwmData?.configuredRevForecast) &&
                                                        gwmData?.configuredRevForecast !== undefined
                                                            ? millify(
                                                                  Number(
                                                                      gwmData?.configuredRevForecast
                                                                  ),
                                                                  {
                                                                      precision: 2,
                                                                  }
                                                              )
                                                            : "----"
                                                    }
                                                    loader={false}
                                                    iconTwo
                                                    hideDirection={true}
                                                    hasCustomValue={true}
                                                    increaseClassName="!text-g-60"
                                                    innerClassName="!justify-end"
                                                    addTextTwoClass="!whitespace-nowrap"
                                                    addTextTwo={"Synth's revenue forecast"}
                                                    customSubTextClass="!whitespace-nowrap"
                                                    customSubText={"Configured revenue forecast"}
                                                    className="!shadow-none !border-y-0 !border-l-0 lg:!border-r lg:!border-r-slate-200 !py-2 !pl-0 lg:!pl-[15px] !pr-8  !rounded-none flex-1 lg:!w-auto"
                                                    arrUpClass={"!text-g-60"}
                                                    titleClassName="!text-slate-600 !text-[10px]"
                                                    figureClassName="!mt-2.5 !text-slate-600  !text-[20px] !leading-[28px]"
                                                    tooltipClassName="!max-w-[195px] !bg-gm-75  !rounded-[12px] before:!border-y-transparent before:!border-l-transparent before:!border-r-gm-75 before:!top-[40%] before:!-left-[9%] !z-[999] before:!border-[6px] !left-[50px] xs:!left-[120px]"
                                                    toolTipContent={
                                                        <div className="!p-3">
                                                            <p className="underline text-xs font-rocGroteskMedium mb-1">
                                                                Forecasted revenue
                                                            </p>
                                                            <p className="text-[11px]">
                                                                This is the estimated amount of
                                                                money that you are likely to earn
                                                                over a future period. This is
                                                                calculated using external factors
                                                                such as seasonality, social media,
                                                                supplier lead time, and historical
                                                                sales.
                                                            </p>
                                                        </div>
                                                    }
                                                />

                                                <div className="h-[29px] lg:hidden w-[1px] bg-slate-200"></div>

                                                <AnalyticsCard
                                                    title={"Forecasted sales quantity"}
                                                    value={Math.round(gwmData?.totalForecastQty)}
                                                    customFigure={Math.round(
                                                        gwmData?.configuredDemandForecast
                                                    ).toLocaleString()}
                                                    loader={false}
                                                    iconTwo
                                                    hasCustomValue={true}
                                                    className="!shadow-none !border-y-0 !border-l-0 lg:!border-r lg:!border-r-slate-200 !py-2 !pl-[15px] !pr-8  !rounded-none  flex-1 lg:!w-fit"
                                                    titleClassName="!text-slate-500 !text-[10px]"
                                                    innerClassName="!justify-end"
                                                    addTextTwoClass="!whitespace-nowrap"
                                                    addTextTwo={"Synth's demand forecast"}
                                                    customSubTextClass="!whitespace-nowrap"
                                                    customSubText={"Configured demand forecast"}
                                                    figureClassName="!mt-2.5 !text-slate-600  !text-[20px] !leading-[28px]"
                                                    tooltipClassName="!max-w-[195px] !bg-gm-75  !rounded-[12px] before:!border-y-transparent before:!border-r-transparent lg:before:!border-l-transparent before:!border-l-gm-75 lg:before:!border-r-gm-75 before:!top-[40%] before:!-right-[95px] lg:before:!right-auto lg:before:!-left-[9%] !z-[999] before:!border-[6px] !-left-[100px] lg:!left-[120px]"
                                                    toolTipContent={
                                                        <div className="!p-3">
                                                            <p className="underline text-xs font-rocGroteskMedium mb-1">
                                                                Forecasted sales quantity
                                                            </p>
                                                            <p className="text-[11px]">
                                                                This is the estimated number of
                                                                units of all products that you are
                                                                likely to sell in a future period.
                                                            </p>
                                                        </div>
                                                    }
                                                />
                                            </div>

                                            <AnalyticsCard
                                                title={"Forecasted inventory deficit"}
                                                value={Math.round(gwmData?.forecastedStockNeeded)}
                                                loader={false}
                                                iconTwo
                                                addTextTwo={"(oos)"}
                                                addTextTwoClass="!text-r-55 !text-xl"
                                                textAndSubtextContainer={"!justify-end"}
                                                className="!shadow-none !border-none !py-2 !pl-0 lg:!pl-[15px] !pr-8  !rounded-none !w-auto"
                                                titleClassName="!text-slate-600 !text-[10px]"
                                                figureClassName="!mt-2.5 !text-r-55  !text-[20px] !leading-[28px] flex gap-1 items-end"
                                                tooltipClassName="!max-w-[195px] !bg-gm-75  !rounded-[12px] before:!border-y-transparent before:!border-l-transparent lg:before:!border-r-transparent before:!border-r-gm-75 lg:before:!border-l-gm-75 before:!top-[40%] before:!-left-[9%] lg:before:!left-auto lg:before:!-right-[6px] !z-[999] before:!border-[6px] !left-[0px] xs:!left-[120px] lg:!-left-[250px]"
                                                toolTipContent={
                                                    <div className="!p-3">
                                                        <p className="underline text-xs font-rocGroteskMedium mb-1">
                                                            Forecasted inventory deficit
                                                        </p>
                                                        <p className="text-[11px] ">
                                                            This is the estimated number of units of
                                                            all your products that are likely to be
                                                            out of stock in a future period. These
                                                            are the likely out of stock units of
                                                            products with respect to the demand
                                                            forecast.
                                                        </p>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>
                                    {productData?.length !== 0 &&
                                    !gwmLoading &&
                                    gwmData?.currentSales?.length === 0 &&
                                    gwmData?.forcastSales?.length === 0 ? (
                                        <div className="w-[100%] h-[400px] flex items-center justify-center">
                                            <div className="flex items-center  gap-[3px] flex-col w-[353px] justify-center">
                                                <img
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1717426949/icon_w8c4mn.png"
                                                    alt=""
                                                    className=""
                                                />
                                                <p className="text-g-75 font-rocGroteskBold text-base ">
                                                    {" "}
                                                    No forecast data to show for this query{" "}
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="mt-20 mb-6">
                                            <MultipleLineChart mapData={chartData} />
                                        </div>
                                    )}
                                </div>
                            )}

                            {activeTab === 1 && durationFilter !== "custom" ? (
                                <div className="mt-4">
                                    <div className="flex items-center justify-between pt-4 border-t">
                                        <div className="flex items-center space-x-2.5">
                                            {[
                                                ...(chartFilter === "yearly"
                                                    ? monthlyTab
                                                    : chartData?.[0]),
                                            ]?.map((item, idx) => {
                                                return (
                                                    <span
                                                        onClick={() => setActivePeriod(idx)}
                                                        className={`text-sm font-rocGroteskMedium text-tradeally-neutral-200 cursor-pointer pb-2.5 ${
                                                            activePeriod === idx &&
                                                            "text-gm-50 border-b-2 border-[#FFB903]"
                                                        }`}
                                                    >
                                                        {item}
                                                    </span>
                                                );
                                            })}
                                        </div>

                                        <div className="flex items-center space-x-1">
                                            <ToggleInput
                                                checked={trackPerformance}
                                                name={"google trends"}
                                                onChange={(evt) =>
                                                    setTrackPerformance(evt.target.checked)
                                                }
                                            />
                                            <span className="text-[13px] font-rocGroteskMedium text-slate-800">
                                                Help me track the performance
                                            </span>
                                        </div>
                                    </div>

                                    {configureForecastTableBody?.length > 0 ? (
                                        <div className="mt-4">
                                            {productListLoading ? (
                                                <div>
                                                    <CustomTableLoader
                                                        tableHeader={configureForecastTableHeader}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className="overflow-x-scroll"
                                                    onScroll={handleScroll}
                                                >
                                                    <CustomTable
                                                        tableBody={configureForecastTableBody}
                                                        tableHeader={configureForecastTableHeader}
                                                        isScrollable={true}
                                                        isScrolled={isScrolled}
                                                        isCellBordered={true}
                                                        scrollWithin={true}
                                                        isInifiniteScroll={false}
                                                        isCellSelectable={false}
                                                        isAllSelectable={false}
                                                        tableContainerClass="!max-h-[427px]"
                                                        headerContainerClass="!bg-slate-50"
                                                        headerItemClass="font-rocGroteskMedium !text-xs !text-slate-700"
                                                        tableClass="!border !border-slate-200 cursor-pointer"
                                                        bodyItemClass="!border-slate-200 !cursor-default hover:!bg-slate-50"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <EmptyStateComp
                                            title="No products available"
                                            description={`There are no products available `}
                                            icon={
                                                <i className="ri-archive-2-line text-[48px] text-slate-400"></i>
                                            }
                                        />
                                    )}

                                    {/* <div className="mt-4 overflow-x-scroll">
                                        
                                    </div> */}

                                    <div className="flex justify-end mt-5">
                                        <ResponsivePagination
                                            current={Number(configPagination?.currentPage)}
                                            total={Number(configPagination?.noOfPages)}
                                            maxWidth={350}
                                            onPageChange={(page) => setUpdatedPage(page)}
                                        />
                                    </div>
                                </div>
                            ) : activeTab === 1 && durationFilter === "custom" ? (
                                <EmptyStateComp
                                    title="View unavailable for custom date filter"
                                    description="Table view is unavailable for custom date filter, kindly select a different date filter to view the table"
                                />
                            ) : null}
                            {/* <ForecastInsights /> */}
                        </div>

                        <div className="">
                            <p className="text-g-75 font-rocGroteskMedium text-sm lg:text-lg mb-4">
                                Business stats{" "}
                            </p>
                        </div>

                        <div className="">
                            <div
                                className={`mb-7 ${
                                    showAIAgent ? "flex" : "flex flex-col gap-2.5 lg:grid"
                                } lg:flex lg:grid-cols-4 lg:gap-x-[50px] lg:gap-y-[35px]`}
                            >
                                <>
                                    <div
                                        className="relative max-lg:hidden"
                                        onMouseEnter={() => {
                                            handleShowMore(1);
                                        }}
                                    >
                                        <div className="">
                                            <AnalyticsCard
                                                figure={
                                                    businessStats?.totalInventoryLevelValue ===
                                                    undefined
                                                        ? "--"
                                                        : businessStats?.totalInventoryLevelValue
                                                              ?.length > 8
                                                        ? millify(
                                                              Number(
                                                                  businessStats?.totalInventoryLevelValue
                                                              ),
                                                              {
                                                                  precision: 0,
                                                              }
                                                          )
                                                        : formatMoney(true).format(
                                                              Number(
                                                                  businessStats?.totalInventoryLevelValue
                                                              )
                                                          )
                                                }
                                                description={"Current inventory on hand"}
                                                descriptionClassName="!text-slate-500"
                                                loader={false}
                                                figureClassName="!text-g-75 lg:!text-slate-600 !font-rocGroteskMedium text-xl lg:!text-2xl !leading-[34px] !mt-0"
                                                className={`${
                                                    gwmData?.forecastTotalSal && "!cursor-pointer"
                                                }  !shadow-none lg:!border !font-rocGroteskMedium border-x-none border-y border-slate-200 lg:!border-n-50 !px-0 lg:!px-[15px] py-4 lg:!pt-[20px] lg:!pb-[15px] !rounded-lg lg:!min-w-[253px]`}
                                                titleClassName="!text-slate-600 !text-[13px]"
                                                innerClassName=""
                                            />
                                        </div>

                                        {businessStats?.totalInventoryLevelValue !== undefined &&
                                        showMore === 1 ? (
                                            <div
                                                ref={showMoreRef}
                                                className="w-[320px] bg-white rounded-lg top-[-90%] left-[250px] absolute shadow-cardShadow-2 transition-all duration-300 ease-in-out before:absolute  before:top-[40%] before:right-[100%] before:-translate-y-1/2 before:border-8   before:border-transparent before:border-r-white before:content-[''] group-hover:opacity-100 z-[9999]"
                                            >
                                                <div className="flex gap-2 items-center justify-between pb-1  border-b border-b-slate-200 mb-3 pt-2 mx-3 !w-auto">
                                                    <div className="">
                                                        {!topOnHandProductLoading &&
                                                            topOnHandProduct?.length > 0 && (
                                                                <p className="text-[#344054] text-base font-rocGroteskBold">
                                                                    {Math.round(
                                                                        topOnHandProduct[0]
                                                                            ?.totalInventoryStockLevel
                                                                    )?.toLocaleString()}
                                                                </p>
                                                            )}
                                                        <p
                                                            className={` ${
                                                                productProfitMargin?.length > 0
                                                                    ? "mt-0"
                                                                    : "mt-2"
                                                            }text-slate-500 text-[13px] font-rocGroteskMedium`}
                                                        >
                                                            Total inventory on hand
                                                        </p>
                                                    </div>
                                                </div>

                                                {topOnHandProductLoading ? (
                                                    <div className="h-[80px] flex items-center justify-center">
                                                        <Loader size={4} />
                                                    </div>
                                                ) : topOnHandProduct?.length > 0 ? (
                                                    <div>
                                                        {topOnHandProduct
                                                            ?.slice(0, 4)
                                                            ?.map((item, idx) => {
                                                                return (
                                                                    <div className="px-3" key={idx}>
                                                                        <div className="flex gap-2 items-center">
                                                                            <div
                                                                                className={`rounded-r-lg h-[18px] flex justify-end items-center font-rocGroteskMedium px-2 min-w-fit`}
                                                                                style={{
                                                                                    width: `${Math.round(
                                                                                        item?.percentageSizeOfStock
                                                                                    )}%`,
                                                                                    backgroundColor:
                                                                                        tooltipColors[
                                                                                            idx
                                                                                        ],
                                                                                }}
                                                                            >
                                                                                <div className="text-[10px] leading-[12px] rounded-md bg-white text-[#334155] font-rocGroteskBold px-1">
                                                                                    {Math.round(
                                                                                        item?.stockLevel
                                                                                    )?.toLocaleString()}
                                                                                </div>
                                                                            </div>
                                                                            <p className="text-slate-500 text-xs font-rocGroteskMedium w-[90px] ">
                                                                                {`${Math.round(
                                                                                    item?.percentageSizeOfStock
                                                                                )}% `}
                                                                            </p>
                                                                        </div>

                                                                        <p className="text-slate-500 text-[13px] font-rocGroteskMedium mb-3">
                                                                            {item?.productName}{" "}
                                                                            {item?.numberOfweeksToStockout >
                                                                            0
                                                                                ? `(${item.numberOfweeksToStockout} weeks)`
                                                                                : ""}
                                                                        </p>
                                                                    </div>
                                                                );
                                                            })}
                                                        <Button
                                                            btnText="See products"
                                                            btnType="textFirst"
                                                            onClick={() => {
                                                                setShowMore(0);
                                                                setInventoryModal(true);
                                                            }}
                                                            btnClassname="!bg-slate-50 !border-t !border-slate-100 !text-b-50  !text-sm !rounded-none"
                                                            icon={
                                                                <i className="ri-arrow-right-up-line text-lg"></i>
                                                            }
                                                        />
                                                    </div>
                                                ) : (
                                                    topOnHandProduct?.length === 0 && (
                                                        <div className="h-[80px] flex items-center justify-center">
                                                            <p className="text-slate-500 text-[13px] font-rocGroteskMedium mb-3">
                                                                No data
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="lg:hidden border-y border-slate-200 rounded-lg py-[15px]">
                                        <div className="flex justify-between items-center ">
                                            <div className="">
                                                <p className="!text-g-75 lg:!text-slate-600 !font-rocGroteskMedium text-xl lg:!text-2xl !leading-[34px] !mt-0">
                                                    {businessStats?.totalInventoryLevelValue ===
                                                    undefined
                                                        ? "--"
                                                        : businessStats?.totalInventoryLevelValue
                                                              ?.length > 8
                                                        ? millify(
                                                              Number(
                                                                  businessStats?.totalInventoryLevelValue
                                                              ),
                                                              {
                                                                  precision: 0,
                                                              }
                                                          )
                                                        : formatMoney(true).format(
                                                              Number(
                                                                  businessStats?.totalInventoryLevelValue
                                                              )
                                                          )}
                                                </p>
                                                <p className="!text-slate-500 font-rocGroteskMedium text-xs">
                                                    Current inventory on hand
                                                </p>
                                            </div>

                                            <Button
                                                btnText="See products"
                                                btnType="textFirst"
                                                onClick={() => {
                                                    setShowMore(0);
                                                    setInventoryModal(true);
                                                }}
                                                btnClassname="!bg-transparent !border-none !text-b-50  !text-sm !rounded-none !w-fit"
                                                icon={
                                                    <i className="ri-arrow-right-up-line text-lg"></i>
                                                }
                                            />
                                        </div>
                                        {topOnHandProductLoading ? (
                                            <div className="flex items-center justify-center">
                                                <Loader size={4} />
                                            </div>
                                        ) : topOnHandProduct?.length > 0 ? (
                                            <div className="pt-4">
                                                {topOnHandProduct?.slice(0, 4)?.map((item, idx) => {
                                                    return (
                                                        <div key={idx}>
                                                            <div className="flex gap-2 items-center">
                                                                <div
                                                                    className={`rounded-r-lg h-[18px] flex justify-end items-center font-rocGroteskMedium px-2 min-w-fit`}
                                                                    style={{
                                                                        width: `${Math.round(
                                                                            item?.percentageSizeOfStock
                                                                        )}%`,
                                                                        backgroundColor:
                                                                            tooltipColors[idx],
                                                                    }}
                                                                >
                                                                    <div className="text-[10px] leading-[12px] rounded-md bg-white text-[#334155] font-rocGroteskBold px-1">
                                                                        {Math.round(
                                                                            item?.stockLevel
                                                                        )?.toLocaleString()}
                                                                    </div>
                                                                </div>
                                                                <p className="text-slate-500 text-xs font-rocGroteskMedium w-[90px] ">
                                                                    {`${Math.round(
                                                                        item?.percentageSizeOfStock
                                                                    )}% `}
                                                                </p>
                                                            </div>

                                                            <p className="text-slate-500 text-[13px] font-rocGroteskMedium mb-3">
                                                                {item?.productName}{" "}
                                                                {item?.numberOfweeksToStockout > 0
                                                                    ? `(${item.numberOfweeksToStockout} weeks)`
                                                                    : ""}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            topOnHandProduct?.length === 0 && (
                                                <div className="flex items-center justify-center">
                                                    <p className="text-slate-500 text-[13px] font-rocGroteskMedium mb-3">
                                                        No data
                                                    </p>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </>
                                <>
                                    <div
                                        className="relative max-lg:hidden"
                                        onMouseEnter={() => {
                                            handleShowMore(2);
                                        }}
                                    >
                                        <AnalyticsCard
                                            figure={
                                                businessStats?.productSalesMarginValue === undefined
                                                    ? "--"
                                                    : businessStats?.productSalesMarginValue
                                                          ?.length > 8
                                                    ? millify(
                                                          Number(
                                                              businessStats?.productSalesMarginValue
                                                          ),
                                                          {
                                                              precision: 2,
                                                          }
                                                      )
                                                    : formatMoney().format(
                                                          Number(
                                                              businessStats?.productSalesMarginValue
                                                          )
                                                      )
                                            }
                                            currency={businessStats?.productSalesMarginValue && "$"}
                                            description={"Average profit per product"}
                                            descriptionClassName="!text-slate-500"
                                            loader={false}
                                            figureClassName="!text-g-75 lg:!text-slate-600 !font-rocGroteskMedium text-xl lg:!text-2xl !leading-[34px] !mt-0"
                                            className={`${
                                                gwmData?.forecastTotalSal && "!cursor-pointer"
                                            } !shadow-none lg:!border !font-rocGroteskMedium border-x-none border-y border-slate-200 lg:!border-n-50 !px-0 lg:!px-[15px] py-4 lg:!pt-[20px] lg:!pb-[15px] !rounded-lg lg:!min-w-[253px]`}
                                            titleClassName="!text-slate-600 !text-[13px]"
                                            innerClassName=""
                                        />

                                        {businessStats?.productSalesMarginValue !== undefined &&
                                        showMore === 2 ? (
                                            <div
                                                ref={showMoreRef}
                                                className="w-[320px] bg-white rounded-lg top-[-90%] left-[250px] absolute shadow-cardShadow-2 transition-all duration-300 ease-in-out before:absolute before:top-[40%] before:right-[100%] before:-translate-y-1/2 before:border-8 before:border-transparent before:border-r-white before:content-[''] group-hover:opacity-100 z-[9999]"
                                            >
                                                <div className="flex gap-2 items-center justify-between pb-1 border-b border-b-slate-200 mb-3 pt-2 mx-3 !w-auto">
                                                    <div>
                                                        {!productProfitLoading &&
                                                            productProfitMargin?.length > 0 && (
                                                                <p className="text-[#344054] text-base font-rocGroteskBold">
                                                                    {`$${formatMoney().format(
                                                                        productProfitMargin[0]
                                                                            ?.totalAverageSalesMargin
                                                                    )}`}
                                                                </p>
                                                            )}
                                                        <p
                                                            className={` ${
                                                                productProfitMargin?.length > 0
                                                                    ? "mt-0"
                                                                    : "mt-2"
                                                            } text-slate-500 text-[13px] font-rocGroteskMedium `}
                                                        >
                                                            Average profit per product
                                                        </p>
                                                    </div>
                                                </div>

                                                {productProfitLoading ? (
                                                    <div className="h-[80px] flex items-center justify-center">
                                                        <Loader size={4} />
                                                    </div>
                                                ) : productProfitMargin?.length > 0 ? (
                                                    <div>
                                                        {productProfitMargin
                                                            ?.slice(0, 4)
                                                            .map((item, idx) => (
                                                                <div className="px-3" key={idx}>
                                                                    <div className="flex gap-2 items-center">
                                                                        <div
                                                                            className="rounded-r-lg h-[18px] flex justify-end items-center font-rocGroteskMedium px-2 min-w-fit"
                                                                            style={{
                                                                                width: `${Math.round(
                                                                                    item?.percentageProfit
                                                                                )}%`,
                                                                                backgroundColor:
                                                                                    tooltipColors[
                                                                                        idx
                                                                                    ],
                                                                            }}
                                                                        >
                                                                            <div className="text-[10px] leading-[12px] rounded-md bg-white text-[#334155] font-rocGroteskBold px-1">
                                                                                {item?.totalSold
                                                                                    ? Math.round(
                                                                                          item?.totalSold
                                                                                      )?.toLocaleString()
                                                                                    : "---"}
                                                                            </div>
                                                                        </div>
                                                                        <p className="text-slate-500 text-xs font-rocGroteskMedium w-[90px] ">
                                                                            {`${Math.round(
                                                                                item?.percentageProfit
                                                                            )}% profit`}
                                                                        </p>
                                                                    </div>

                                                                    <p className="text-slate-500 text-[13px] font-rocGroteskMedium mb-3">
                                                                        {item?.productName}
                                                                    </p>
                                                                </div>
                                                            ))}

                                                        <Button
                                                            btnText="See products"
                                                            btnType="textFirst"
                                                            onClick={() => {
                                                                setShowMore(0);
                                                                setProfitMarginModal(true);
                                                            }}
                                                            btnClassname="!bg-slate-50 !border-t !border-slate-100 !text-b-50 !text-sm !rounded-none"
                                                            icon={
                                                                <i className="ri-arrow-right-up-line text-lg"></i>
                                                            }
                                                        />
                                                    </div>
                                                ) : (
                                                    productProfitMargin?.length === 0 && (
                                                        <div className="h-[80px] flex items-center justify-center">
                                                            <p className="text-slate-500 text-[13px] font-rocGroteskMedium mb-3">
                                                                No data
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="lg:hidden border-y border-slate-200 rounded-lg py-[15px]">
                                        <div className="flex justify-between items-center ">
                                            <div className="">
                                                <p className="!text-g-75 lg:!text-slate-600 !font-rocGroteskMedium text-xl lg:!text-2xl !leading-[34px] !mt-0">
                                                    {businessStats?.productSalesMarginValue ===
                                                    undefined
                                                        ? "--"
                                                        : businessStats?.productSalesMarginValue
                                                              ?.length > 8
                                                        ? millify(
                                                              Number(
                                                                  businessStats?.productSalesMarginValue
                                                              ),
                                                              {
                                                                  precision: 2,
                                                              }
                                                          )
                                                        : formatMoney().format(
                                                              Number(
                                                                  businessStats?.productSalesMarginValue
                                                              )
                                                          )}
                                                </p>
                                                <p className="!text-slate-500 font-rocGroteskMedium text-xs">
                                                    Average profit per product
                                                </p>
                                            </div>

                                            <Button
                                                btnText="See products"
                                                btnType="textFirst"
                                                onClick={() => {
                                                    setShowMore(0);
                                                    setProfitMarginModal(true);
                                                }}
                                                btnClassname="!bg-transparent !w-fit !border-none !text-b-50 !text-sm !rounded-none"
                                                icon={
                                                    <i className="ri-arrow-right-up-line text-lg"></i>
                                                }
                                            />
                                        </div>

                                        {productProfitLoading ? (
                                            <div className="h-[80px] flex items-center justify-center">
                                                <Loader size={4} />
                                            </div>
                                        ) : productProfitMargin?.length > 0 ? (
                                            <div className="pt-4">
                                                {productProfitMargin
                                                    ?.slice(0, 4)
                                                    .map((item, idx) => (
                                                        <div className="" key={idx}>
                                                            <div className="flex gap-2 items-center">
                                                                <div
                                                                    className="rounded-r-lg h-[18px] flex justify-end items-center font-rocGroteskMedium px-2 min-w-fit"
                                                                    style={{
                                                                        width: `${Math.round(
                                                                            item?.percentageProfit
                                                                        )}%`,
                                                                        backgroundColor:
                                                                            tooltipColors[idx],
                                                                    }}
                                                                >
                                                                    <div className="text-[10px] leading-[12px] rounded-md bg-white text-[#334155] font-rocGroteskBold px-1">
                                                                        {item?.totalSold
                                                                            ? Math.round(
                                                                                  item?.totalSold
                                                                              )?.toLocaleString()
                                                                            : "---"}
                                                                    </div>
                                                                </div>
                                                                <p className="text-slate-500 text-xs font-rocGroteskMedium w-[90px] ">
                                                                    {`${Math.round(
                                                                        item?.percentageProfit
                                                                    )}% profit`}
                                                                </p>
                                                            </div>

                                                            <p className="text-slate-500 text-[13px] font-rocGroteskMedium mb-3">
                                                                {item?.productName}
                                                            </p>
                                                        </div>
                                                    ))}
                                            </div>
                                        ) : (
                                            productProfitMargin?.length === 0 && (
                                                <div className="h-[80px] flex items-center justify-center">
                                                    <p className="text-slate-500 text-[13px] font-rocGroteskMedium mb-3">
                                                        No data
                                                    </p>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </>

                                <div
                                    className="relative"
                                    // onClick={() => {
                                    //     handleShowMore(3);
                                    //     fetchShipmentDetails();
                                    // }}
                                >
                                    <AnalyticsCard
                                        figure={
                                            businessStats?.totalNumberOfShipments !== undefined
                                                ? millify(
                                                      Number(businessStats?.totalNumberOfShipments),
                                                      {
                                                          precision: 2,
                                                      }
                                                  )
                                                : "--"
                                        }
                                        description={"Number of shipments"}
                                        descriptionClassName="!text-slate-500"
                                        loader={false}
                                        figureClassName="!text-g-75 lg:!text-slate-600 !font-rocGroteskMedium text-xl lg:!text-2xl !leading-[34px] !mt-0"
                                        className="cursor-default !shadow-none lg:!border !font-rocGroteskMedium border-x-none border-y border-slate-200 lg:!border-n-50 !px-0 lg:!px-[15px] py-4 lg:!pt-[20px] lg:!pb-[15px] !rounded-lg lg:!min-w-[253px]"
                                        titleClassName="!text-slate-600 !text-[13px]"
                                        innerClassName=""
                                    />

                                    {/* {businessStats?.totalNumberOfShipments && showMore === 3 && (
                                            <div
                                                ref={showMoreRef}
                                                className="w-[320px] bg-white rounded-lg top-[-90%] left-[250px] absolute shadow-cardShadow-2 transition-all duration-300 ease-in-out before:absolute  before:top-[40%] before:right-[100%] before:-translate-y-1/2 before:border-8   before:border-transparent before:border-r-white before:content-[''] group-hover:opacity-100 z-[9999]"
                                            >
                                                <div className="flex gap-2 items-center justify-between pb-1  border-b border-b-slate-200 mb-3 pt-2 mx-3 !w-auto">
                                                    <div>
                                                        {!shipmentDetailsLoading &&
                                                            shipmentData?.length > 0 && (
                                                                <p className="text-[#344054] text-base font-rocGroteskBold">
                                                                    {Math.round(
                                                                        shipmentData[0]
                                                                            ?.totalInventoryStockLevel
                                                                    ).toLocaleString()}
                                                                </p>
                                                            )}
                                                        <p
                                                            className={` ${
                                                                shipmentData?.length > 0 ? "mt-0" : "mt-2"
                                                            } text-slate-500 text-[13px] font-rocGroteskMedium `}
                                                        >
                                                            Number of shipments
                                                        </p>
                                                    </div>
                                                </div>

                                                {shipmentDetailsLoading ? (
                                                    <div className="h-[80px] flex items-center justify-center">
                                                        <Loader size={4} />
                                                    </div>
                                                ) : shipmentData?.length > 0 ? (
                                                    <div>
                                                        {shipmentData?.slice(0, 4)?.map((item, idx) => {
                                                            return (
                                                                <div className="px-3" key={idx}>
                                                                    <div className="flex gap-2 items-center mb-1">
                                                                        <div
                                                                            className={`rounded-r-lg h-[18px] flex justify-end items-center font-rocGroteskMedium px-2 min-w-fit`}
                                                                            style={{
                                                                                width: `${Math.round(item?.percentageSaleSizeOfStock)}%`,
                                                                                backgroundColor:
                                                                                    tooltipColors[idx],
                                                                            }}
                                                                        >
                                                                            <div className="text-[10px] leading-[12px] rounded-md bg-white text-[#334155] font-rocGroteskBold px-1">
                                                                                {Math.round(
                                                                                    item?.totalSales
                                                                                ).toLocaleString()}
                                                                            </div>
                                                                        </div>
                                                                        <p className="text-slate-500 text-xs font-rocGroteskMedium w-[90px] ">
                                                                            {`${Math.round(item?.percentageSaleSizeOfStock)}% profit`}
                                                                        </p>
                                                                    </div>

                                                                    <p className="text-slate-500 text-[13px] font-rocGroteskMedium mb-3">
                                                                        {item?.productName}
                                                                    </p>
                                                                    <p className="text-slate-800 text-[13px] font-rocGroteskMedium mb-1">
                                                                        {item?._id}
                                                                    </p>
                                                                </div>
                                                            );
                                                        })}
                                                        <Button
                                                            btnText="See products"
                                                            btnType="textFirst"
                                                            btnClassname="!bg-slate-50 !border-t !border-slate-100 !text-b-50 !text-sm !rounded-none"
                                                            icon={
                                                                <i className="ri-arrow-right-up-line text-lg"></i>
                                                            }
                                                        />
                                                    </div>
                                                ) : (
                                                    shipmentData?.length === 0 && (
                                                        <div className="h-[80px] flex items-center justify-center">
                                                            <p className="text-slate-500 text-[13px] font-rocGroteskMedium mb-3">
                                                                No data
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )} */}
                                </div>
                            </div>

                            <div className="border-t border-slate-200 lg:border-none">
                                <TopLeastPerformer
                                    isLoading={businessStatsLoading}
                                    bestSellingPercentage={Math.round(
                                        businessStats?.bestSellingProductPercentage
                                    )}
                                    toptotalProfitContribution={Number(
                                        businessStats?.topPerformanceData?.totalProfitContribution
                                    )}
                                    toptotalRevenueContribution={Number(
                                        businessStats?.topPerformanceData?.totalRevenueContribution
                                    )}
                                    topproductStockValue={Number(
                                        businessStats?.topPerformanceData?.productStockValue
                                    )}
                                    topchangeInAnnualSales={Number(
                                        businessStats?.topPerformanceData?.changeInAnnualSales
                                    )}
                                    leasttotalProfitContribution={Number(
                                        businessStats?.worstPerformanceData?.totalProfitContribution
                                    )}
                                    leasttotalRevenueContribution={Number(
                                        businessStats?.worstPerformanceData
                                            ?.totalRevenueContribution
                                    )}
                                    leastproductStockValue={Number(
                                        businessStats?.worstPerformanceData?.productStockValue
                                    )}
                                    leastchangeInAnnualSales={Number(
                                        businessStats?.worstPerformanceData?.changeInAnnualSales
                                    )}
                                    leastSellingPercentage={Math.round(
                                        businessStats?.worstSellingProductPercentage
                                    )}
                                    showAIAgent={showAIAgent}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        className={`${
                            showAIAgent
                                ? "w-[calc(100vw-30px)] left-[15px] lg:left-auto lg:right-[47px] lg:w-[31%] opacity-100"
                                : "w-0 opacity-0"
                        } transition-all ease-in-out delay-150 duration-300 lg:right-[47px] fixed h-[calc(100vh-108px)] bg-white`}
                    >
                        <div className="h-16 lg:bg-[#FFB90326] rounded-t-[12px] flex justify-between items-center p-5">
                            <div className="flex items-center space-x-[22px]">
                                <i
                                    onClick={() => {
                                        setShowAIAgent(false);
                                    }}
                                    className="ri-arrow-left-line cursor-pointer text-[24px] lg:hidden"
                                ></i>
                                <div className="flex items-center space-x-2">
                                    <img
                                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1717452810/synth_ai_logo_colored_kmelc4.svg"
                                        alt="synth logo"
                                        className="w-6 h-6"
                                    />
                                    <span className="text-sm xs:text-base font-rocGroteskBold text-g-75">
                                        Synth SCM
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center space-x-2.5">
                                <Button
                                    btnText={"New chat"}
                                    icon={<i className="ri-add-fill text-white"></i>}
                                    btnClassname={
                                        "!w-fit !h-8 !p-2 !text-white !bg-gradient-to-r from-[#56B648] to-[#FFB902] shadow-[0px_4px_6px_2px_rgba(236,46,35,0.12),0px_12px_16px_0px_rgba(122,80,211,0.12)]"
                                    }
                                    type="button"
                                    disabled={creatingConversation}
                                    // isLoading={creatingConversation}
                                    onClick={() => {
                                        setChats([]);
                                        setChatId("");
                                        setEditedValues([]);
                                        setSelectedProducts([]);
                                        handleCreateConversation();
                                    }}
                                />
                                <div className="lg:hidden">
                                    {showAIChatHistory ? (
                                        <i
                                            onClick={() => setShowAIChatHistory(false)}
                                            className="ri-layout-right-2-line text-slate-400 text-2xl cursor-pointer"
                                        ></i>
                                    ) : (
                                        <i
                                            onClick={() => setShowAIChatHistory(true)}
                                            className="ri-layout-left-2-line text-slate-400 text-2xl cursor-pointer"
                                        ></i>
                                    )}
                                </div>
                                <i
                                    onClick={() => setExpandAIAgent(true)}
                                    className="ri-fullscreen-fill cursor-pointer text-[24px] max-lg:hidden"
                                ></i>
                                <i
                                    onClick={() => {
                                        setShowAIAgent(false);
                                        // navigate(`${window.location.pathname}`, { replace: true });
                                    }}
                                    className="ri-close-fill cursor-pointer text-[24px] max-lg:hidden"
                                ></i>
                            </div>
                        </div>

                        <div
                            className={`${
                                !showAIChatHistory
                                    ? "w-[100%] opacity-100 block"
                                    : "w-0 opacity-0 hidden"
                            } lg:hidden`}
                        >
                            <AIChatComp
                                isExpanded={expndAIAgent}
                                chats={sortedChat}
                                setChats={setChats}
                                message={message}
                                setMessage={setMessage}
                                chatId={chatId}
                                setChatId={setChatId}
                                handleSendMessage={handleSendMessage}
                                editedValues={editedValues}
                                setEditedValues={setEditedValues}
                                handleItemChange={handleItemChange}
                                handleSelectAll={handleSelectAll}
                                handleSelectItem={handleSelectItem}
                                selectedProducts={selectedProducts}
                                setShowAIAgent={setShowAIAgent}
                                changedSupplier={changedSupplier}
                                setChangedSupplier={setChangedSupplier}
                            />
                        </div>
                        <div className="max-lg:hidden">
                            <AIChatComp
                                isExpanded={expndAIAgent}
                                chats={sortedChat}
                                setChats={setChats}
                                message={message}
                                setMessage={setMessage}
                                chatId={chatId}
                                setChatId={setChatId}
                                handleSendMessage={handleSendMessage}
                                editedValues={editedValues}
                                setEditedValues={setEditedValues}
                                handleItemChange={handleItemChange}
                                handleSelectAll={handleSelectAll}
                                handleSelectItem={handleSelectItem}
                                selectedProducts={selectedProducts}
                                setShowAIAgent={setShowAIAgent}
                                changedSupplier={changedSupplier}
                                setChangedSupplier={setChangedSupplier}
                            />
                        </div>
                        <div
                            className={`${
                                showAIChatHistory ? "w-[100%] opacity-100" : "w-0 opacity-0"
                            } transition-all ease-in-out delay-150 duration-300 px-2.5 py-2 lg:hidden `}
                        >
                            <div
                                id="scrollContainer"
                                className={`${
                                    showAIChatHistory ? "absolute w-full" : ""
                                } space-y-2 h-[calc(100vh-172px)] overflow-y-auto pb-[120px] `}
                            >
                                {allConversations?.map((item, idx) => {
                                    return (
                                        <div
                                            key={item?._id}
                                            className={`flex items-center justify-between p-3 border border-slate-50 rounded-md hover:bg-slate-100 cursor-pointer ${
                                                activeConversation === item?._id && "bg-slate-100"
                                            }`}
                                            onClick={() => {
                                                setActiveConversation(item?._id);
                                                navigate(
                                                    `/dashboard/home?conversationId=${item?._id}`,
                                                    { replace: true }
                                                );
                                                setShowAIChatHistory(false);
                                            }}
                                        >
                                            <div className="flex items-center space-x-2">
                                                <i className="ri-chat-4-line text-base"></i>
                                                <span className="text-sm font-rocGroteskMedium">
                                                    {truncate(item?.title, { length: 25 })}
                                                </span>
                                            </div>
                                            <i className="ri-more-2-fill"></i>
                                        </div>
                                    );
                                })}

                                {loadingMoreConverstions && (
                                    <div className="flex justify-center my-4">
                                        <Loader color="gm-25" size={4} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {!showAIAgent && (
                    <div
                        onClick={() => {
                            if (!conversationId) {
                                handleCreateConversation();
                            } else {
                                setShowAIAgent(true);
                            }
                        }}
                        className="fixed bottom-[71px] right-[71px] z-[999] cursor-pointer rounded-[20px] py-4 px-5 flex items-center space-x-2.5 w-fit border border-slate-100 bg-gradient-to-r from-[#56B648] to-[#FFB902]"
                    >
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1717450280/synth_ai_logo_cfjb0e.svg"
                            alt="synth logo"
                            className="w-6 h-6"
                        />

                        <span className="text-sm font-rocGroteskBold text-white max-lg:hidden">
                            Engage Synth SCM
                        </span>
                    </div>
                )}

                <ModalContainer
                    modalClassName="!bg-[rgba(217,217,217,0.3)"
                    open={profitMarginModal || inventoryModal}
                    closeModal={() => {
                        setProfitMarginModal(false);
                        setInventoryModal(false);
                    }}
                    tailwindClassName="w-[97%] lg:w-[817px]"
                >
                    <div
                        className={` bg-white rounded-[6px] h-[90vh] lg:max-h-[60vh] overflow-y-scroll `}
                        id="modalContent"
                    >
                        <div className=" mb-4 relative">
                            <div className="sticky top-0 bg-white z-[9999] py-4 ">
                                <div className="pl-6  flex gap-2 items-center lg:justify-between pb-4  lg:border-b lg:border-b-slate-200 w-full">
                                    <div className="flex gap-[22px] items-center lg:hidden">
                                        <i
                                            className="ri-arrow-left-line text-2xl cursor-pointer text-slate-800 "
                                            onClick={() => {
                                                setProfitMarginModal(false);
                                                setInventoryModal(false);
                                            }}
                                        ></i>
                                    </div>
                                    {profitMarginModal ? (
                                        <div className="flex gap-2 flex-col">
                                            <p className="text-slate-900 text-xl font-rocGroteskMedium">
                                                {totalProfit >= 0
                                                    ? `$${formatMoney().format(totalProfit)}`
                                                    : "---"}
                                            </p>
                                            <p className="text-slate-500 text-[13px] font-rocGroteskMedium">
                                                Average profit per product
                                            </p>
                                        </div>
                                    ) : (
                                        inventoryModal && (
                                            <div className="flex gap-2 flex-col">
                                                <p className="text-slate-900 text-xl font-rocGroteskMedium">
                                                    {totalInventory >= 0
                                                        ? totalInventory?.toLocaleString()
                                                        : "---"}
                                                </p>
                                                <p className="text-slate-500 text-[13px] font-rocGroteskMedium">
                                                    Total inventory on hand
                                                </p>
                                            </div>
                                        )
                                    )}

                                    <i
                                        className="ri-close-line  text-2xl cursor-pointer text-slate-800 pr-6 max-lg:hidden"
                                        onClick={() => {
                                            setProfitMarginModal(false);
                                            setInventoryModal(false);
                                        }}
                                    ></i>
                                </div>

                                <div className="flex justify-between items-center px-4 py-[13px]">
                                    <div className="flex items-center gap-y-2.5 gap-x-0 lg:gap-2.5 flex-wrap lg:flex-nowrap">
                                        <div className="w-full lg:w-[215px]">
                                            <TextInput
                                                name="porductSearch"
                                                type="text"
                                                value={productSearch}
                                                onChange={(e) => setProductSearch(e?.target.value)}
                                                onInput={(e) => {
                                                    profitMarginModal
                                                        ? handleProductProfitDebouncedChange(e)
                                                        : handleInventoryDebouncedChange(e);
                                                }}
                                                inputPlaceholder="Search product"
                                                leftIcon={
                                                    <i className="ri-search-line text-slate-400"></i>
                                                }
                                                leftIconClass="!left-2.5"
                                                inputClassName={
                                                    "!h-fit pl-[30px] !py-3 !mb-0 !font-rocGroteskMedium !text-slate-400"
                                                }
                                                floatingPlaceholderClass="!text-slate-400 !font-rocGroteskMedium "
                                                inputContainerClassname={
                                                    "bg-white lg:!bg-slate-50 !border-slate-100 !h-auto !px-0 !py-0"
                                                }
                                            />
                                        </div>

                                        <Dropdown
                                            handleChange={(name, value) =>
                                                handleProductValue({
                                                    target: { name, value },
                                                })
                                            }
                                            dropdown={
                                                <div className="flex items-center lg:rounded-[6px] lg:shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                                    <div
                                                        className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block`}
                                                    >
                                                        {modalProductFilter
                                                            ? truncate(
                                                                  modalProductFilter?.split("-")[1],
                                                                  {
                                                                      length: 15,
                                                                  }
                                                              )
                                                            : "All product"}
                                                    </div>
                                                    <i className="ri-arrow-down-s-line text-lg text-g-75"></i>
                                                </div>
                                            }
                                            dropdownItemsClasses="!px-0 !py-0"
                                            dropdownClassName="!w-full !mb-10 !-mt-7"
                                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] 
                                    !min-h-[300px] !max-h-[300px] !w-auto !pt-0
                            `}
                                            loader={isLoadingMore}
                                            // endOfList={
                                            //     pagination?.currentPage === pagination?.noOfPages
                                            // }
                                            name="valueRange"
                                            search={
                                                <div ref={clearSearchRef} className="">
                                                    <TextInput
                                                        value={searchTwo}
                                                        name={"search"}
                                                        type={"text"}
                                                        required={false}
                                                        onChange={handleSearchTwo}
                                                        onInput={handleDebouncedChangeTwo}
                                                        inputPlaceholder="Search product"
                                                        containerClassname=""
                                                        inputContainerClassname={"!h-[40px]"}
                                                    />
                                                </div>
                                            }
                                            dropdownOptions={[
                                                {
                                                    label: (
                                                        <div
                                                            className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 !w-[190px] px-4 py-[10px]"
                                                        >
                                                            <i
                                                                className={`${
                                                                    modalProductFilter === ""
                                                                        ? "ri-checkbox-line text-carribean-green"
                                                                        : "ri-checkbox-blank-line text-slate-200"
                                                                }   text-xl`}
                                                            ></i>
                                                            All product
                                                        </div>
                                                    ),
                                                    value: "",
                                                },
                                                ...productSearchList?.map((item, idx) => ({
                                                    label: (
                                                        <div
                                                            key={idx}
                                                            className=" text-slate-600 font-rocGroteskMedium text-[13px] gap-2 flex items-center
                                                border-b border-b-slate-100 !w-[190px] px-4 py-[10px]"
                                                        >
                                                            <i
                                                                className={`${
                                                                    modalProductFilter?.split(
                                                                        "-"
                                                                    )[0] === item?._id
                                                                        ? "ri-checkbox-line text-carribean-green"
                                                                        : "ri-checkbox-blank-line text-slate-200"
                                                                }   text-xl`}
                                                            ></i>

                                                            <div className="flex flex-col">
                                                                {truncate(item?.productName, {
                                                                    length: 18,
                                                                })}
                                                                <p className="text-[10px]">{` SKU No: ${item?.sku}`}</p>
                                                            </div>
                                                        </div>
                                                    ),
                                                    value: item?._id + "-" + item?.productName,
                                                })),
                                            ]}
                                        />

                                        <Dropdown
                                            handleChange={(name, value) =>
                                                handleChangeValue({
                                                    target: { name, value },
                                                })
                                            }
                                            dropdown={
                                                <div className="flex items-center justify-between lg:rounded-[6px] lg:shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                                    <div
                                                        className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block ${
                                                            modalChannelFilter && "capitalize"
                                                        }`}
                                                    >
                                                        {truncate(modalChannelFilter, {
                                                            length: 15,
                                                        }) || "All channels"}
                                                    </div>
                                                    <i className="ri-arrow-down-s-line text-lg text-g-75"></i>
                                                </div>
                                            }
                                            dropdownItemsClasses="!px-0 !py-0"
                                            dropdownClassName="!mb-10 !-mt-7"
                                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[200px] !w-[190px] !min-w-min
                            `}
                                            name="valueRange"
                                            dropdownOptions={[
                                                {
                                                    label: (
                                                        <div
                                                            className="text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 !w-[190px] px-4 py-[10px]"
                                                        >
                                                            <i
                                                                className={`${
                                                                    modalChannelFilter === ""
                                                                        ? "ri-checkbox-line text-carribean-green"
                                                                        : "ri-checkbox-blank-line text-slate-200"
                                                                }    text-xl`}
                                                            ></i>
                                                            All channels
                                                        </div>
                                                    ),
                                                    value: "",
                                                },
                                                ...salesChannel?.map((item, idx) => ({
                                                    label: (
                                                        <div
                                                            key={idx}
                                                            className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 !w-[190px] px-4 py-[10px] capitalize"
                                                        >
                                                            <i
                                                                className={`${
                                                                    modalChannelFilter === item
                                                                        ? "ri-checkbox-line text-carribean-green"
                                                                        : "ri-checkbox-blank-line text-slate-200"
                                                                }    text-xl`}
                                                            ></i>
                                                            {item}
                                                        </div>
                                                    ),
                                                    value: item,
                                                })),
                                            ]}
                                        />

                                        <Dropdown
                                            handleChange={(name, value) =>
                                                handleDateChange({
                                                    target: { name, value },
                                                })
                                            }
                                            dropdown={
                                                <div className="flex items-center justify-between lg:rounded-[6px] lg:shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                                    <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>

                                                    <div
                                                        className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block`}
                                                    >
                                                        {modalDurationFilter || "This year"}
                                                    </div>
                                                </div>
                                            }
                                            dropdownItemsClasses="!px-0 !py-0"
                                            dropdownClassName="!w-full !mb-10 !-mt-7"
                                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                            name="valueRange"
                                            dropdownOptions={[
                                                ...dateValues2?.map((item, idx) => ({
                                                    label: (
                                                        <div
                                                            key={idx}
                                                            className=" text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                border-b border-b-slate-100 !w-[120px] px-4 py-[10px]"
                                                        >
                                                            {item?.period}
                                                        </div>
                                                    ),
                                                    value: item?.value + "-" + item?.period,
                                                })),
                                            ]}
                                        />
                                    </div>
                                    {/* <Button
                                    btnText="Export"
                                    btnClassname="!bg-transparent !w-fit !shadow-buttonShadow2 !px-2.5 !py-2 !rounded-[5px] !border !border-slate-100"
                                    icon={
                                        <i className="ri-arrow-go-forward-fill text-slate-800"></i>
                                    }
                                /> */}
                                </div>
                            </div>

                            <div className="min-h-[300px]">
                                {(
                                    profitMarginModal
                                        ? productProfitListLoading && !productProfitIsLoadingMore
                                        : topInventoryListLoading && !inventoryIsLoadingMore
                                ) ? (
                                    <>
                                        <div className="lg:hidden">
                                            <div className="200px flex items-center justify-center">
                                                <Loader size={6} />
                                            </div>
                                        </div>
                                        <div className="overflow-x-hidden max-lg:hidden">
                                            <CustomTableLoader
                                                tableHeader={
                                                    profitMarginModal
                                                        ? tableProfitMarginHeader
                                                        : tableInventoryHeader
                                                }
                                            />
                                        </div>
                                    </>
                                ) : (
                                      profitMarginModal
                                          ? productProfitList?.length >= 1
                                          : topInventoryList?.length >= 1
                                  ) ? (
                                    <div className="overflow-x-scroll mb-6 overflow-y-scroll">
                                        <div className="max-lg:hidden">
                                            <CustomTable
                                                tableBody={
                                                    profitMarginModal
                                                        ? tableProfitMarginBody
                                                        : tableInventoryBody
                                                }
                                                tableHeader={
                                                    profitMarginModal
                                                        ? tableProfitMarginHeader
                                                        : tableInventoryHeader
                                                }
                                                isScrollable={false}
                                                isCellBordered={true}
                                                isCellSelectable={false}
                                                isAllSelectable={false}
                                                headerContainerClass="!bg-slate-50"
                                                headerItemClass="font-rocGroteskMedium !text-xs !text-slate-700"
                                                tableClass="!border !border-slate-200"
                                                bodyItemClass="!border-slate-200 !cursor-default !bg-transparent"
                                                sort={
                                                    profitMarginModal
                                                        ? handleSortChange
                                                        : handleInventorySortChange
                                                }
                                            />
                                        </div>
                                        <div className="lg:hidden px-6 space-y-2.5">
                                            {profitMarginModal
                                                ? mobileTableProfitMarginBody
                                                : mobileTableInventoryBody}
                                        </div>

                                        {(profitMarginModal
                                            ? productProfitIsLoadingMore
                                            : inventoryIsLoadingMore) && (
                                            <div className="flex justify-center my-4">
                                                <Loader color="gm-25" size={4} />
                                            </div>
                                        )}
                                        {profitMarginModal
                                            ? productProfitPagination?.currentPage ===
                                              productProfitPagination?.noOfPages
                                            : inventoryPagination?.currentPage ===
                                                  inventoryPagination?.noOfPages && (
                                                  <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                                      End of list
                                                  </div>
                                              )}
                                    </div>
                                ) : (
                                    (profitMarginModal
                                        ? productProfitList?.length < 1 && !productProfitListLoading
                                        : topInventoryList?.length < 1 &&
                                          !topInventoryListLoading) && (
                                        <div className="flex items-center justify-center flex-col h-[200px]">
                                            <p className="text-slate-500 text-sm font-rocGroteskMedium">
                                                {" "}
                                                No data matches your search query{" "}
                                            </p>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </ModalContainer>

                <ModalContainer
                    modalClassName="!bg-[rgba(217,217,217,0.3) backdrop-blur-[8px]"
                    open={expndAIAgent}
                    closeModal={() => {
                        setExpandAIAgent(false);
                        // navigate(`${window.location.pathname}`, { replace: true });
                    }}
                    tailwindClassName="w-[80%] "
                >
                    <div className={` bg-white rounded-[10px]  overflow-y-scroll`}>
                        <div className="flex items-center justify-between py-4 px-6">
                            <h4 className="text-lg font-rocGroteskBold inline-block text-transparent bg-clip-text bg-gradient-to-tr from-[#56B648] from-0% to-[#FFB902] to-100%">
                                Synth SCM
                            </h4>

                            <div className="flex items-center space-x-4">
                                <Button
                                    btnText={"New chat"}
                                    icon={<i className="ri-add-fill text-white"></i>}
                                    btnClassname={
                                        "!w-fit !h-8 !p-2 !text-white !bg-gradient-to-r from-[#56B648] to-[#FFB902]"
                                    }
                                    type="button"
                                    disabled={creatingConversation}
                                    // isLoading={creatingConversation}
                                    onClick={() => {
                                        setChats([]);
                                        setChatId("");
                                        setEditedValues([]);
                                        setSelectedProducts([]);
                                        handleCreateConversation();
                                    }}
                                />
                                {showAIChatHistory ? (
                                    <i
                                        onClick={() => setShowAIChatHistory(false)}
                                        className="ri-layout-right-2-line text-slate-400 text-2xl cursor-pointer"
                                    ></i>
                                ) : (
                                    <i
                                        onClick={() => setShowAIChatHistory(true)}
                                        className="ri-layout-left-2-line text-slate-400 text-2xl cursor-pointer"
                                    ></i>
                                )}

                                <i
                                    onClick={() => setExpandAIAgent(false)}
                                    className="ri-close-fill text-2xl text-slate-500 cursor-pointer"
                                ></i>
                            </div>
                        </div>

                        <div className="flex">
                            <div
                                className={`${
                                    showAIChatHistory ? "w-[76%]" : "w-full"
                                } transition-[width] ease-in-out delay-150 duration-300`}
                            >
                                <AIChatComp
                                    isExpanded={expndAIAgent}
                                    chats={sortedChat}
                                    setChats={setChats}
                                    message={message}
                                    setMessage={setMessage}
                                    chatId={chatId}
                                    setChatId={setChatId}
                                    handleSendMessage={handleSendMessage}
                                    editedValues={editedValues}
                                    setEditedValues={setEditedValues}
                                    handleItemChange={handleItemChange}
                                    handleSelectAll={handleSelectAll}
                                    handleSelectItem={handleSelectItem}
                                    selectedProducts={selectedProducts}
                                    setShowAIAgent={setShowAIAgent}
                                    changedSupplier={changedSupplier}
                                    setChangedSupplier={setChangedSupplier}
                                />
                            </div>

                            <div
                                className={`${
                                    showAIChatHistory ? "w-[24%] opacity-100" : "w-0 opacity-0"
                                } transition-all ease-in-out delay-150 duration-300 px-2.5 py-2`}
                            >
                                <div
                                    id="scrollContainer"
                                    className="space-y-2 h-[calc(100vh-172px)] overflow-y-auto pb-5"
                                >
                                    {allConversations?.map((item, idx) => {
                                        return (
                                            <div
                                                key={item?._id}
                                                className={`flex items-center justify-between p-3 border border-slate-50 rounded-md hover:bg-slate-100 cursor-pointer ${
                                                    activeConversation === item?._id &&
                                                    "bg-slate-100"
                                                }`}
                                                onClick={() => {
                                                    setActiveConversation(item?._id);
                                                    navigate(
                                                        `/dashboard/home?conversationId=${item?._id}`,
                                                        { replace: true }
                                                    );
                                                }}
                                            >
                                                <div className="flex items-center space-x-2">
                                                    <i className="ri-chat-4-line text-base"></i>
                                                    <span className="text-sm font-rocGroteskMedium">
                                                        {truncate(item?.title, {
                                                            length: 25,
                                                        })}
                                                    </span>
                                                </div>
                                                <i className="ri-more-2-fill"></i>
                                            </div>
                                        );
                                    })}

                                    {loadingMoreConverstions && (
                                        <div className="flex justify-center my-4">
                                            <Loader color="gm-25" size={4} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalContainer>

                <DataSyncModal showModal={showModal} setShowModal={setShowModal} />
            </div>
        </PageFrame>
    );
};

export default Dashboard;
