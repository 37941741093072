import Button from "components/common/Button";
import Loader from "components/common/Loader";
import React, { useEffect, useState } from "react";
import ProductionSteps from "../ProductionSteps";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { productionActions } from "redux/Ldb/actions";
import { displayFileIcon } from "helpers";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import { Tooltip } from "react-tooltip";

type workTypes = {
    steps: any;
    productName: string;
    isLoading: boolean;
    type?: string;
    workOrderDetails?: any;
};

const ProductionWorkflow = ({
    steps,
    productName,
    isLoading,
    type,
    workOrderDetails,
}: workTypes) => {
    const dispatch = useAppDispatch();
    const { productionId } = useParams();
    const { workOrderId } = useParams();
    const [productionSteps, setProductionSteps] = useState<{ [key: string]: any }[]>([]);
    const [productionStepModal, setProductionStepModal] = useState<boolean>(false);
    const [step, setStep] = useState<{ [key: string]: any }>({});
    const [stepIdx, setStepIdx] = useState<number>(-1);
    const [savedStepId, setSavedStepId] = useState<string>("");
    const [showCheckList, setShowCheckList] = useState<number>(-1);
    const [assignees, setAssignees] = useState<string[]>([]);
    const [attachments, setAttachments] = useState<{ [key: string]: any }[]>([]);

    const [qcData, setQcData] = useState<{ [key: string]: any }[]>([{}]);
    const [updateStep, setUpdateStep] = useState<number>(-1);
    const [prodStatus, setProdStatus] = useState<string>("");
    const [showAttachments, setShowAttachments] = useState<number>(-1);

    const {
        creatingPlanSteps,
        createPlanStepsSuccess,
        updatingPlanSteps,
        updatePlanStepsSuccess,
        updatingProductionStep,
        updateProductionStepSuccess,
        fetchingWorkOrderTabTwo,
    } = useAppSelector((state) => state?.production);

    useEffect(() => {
        if (steps?.length >= 1) {
            const newSteps =
                steps?.length >= 1
                    ? steps?.map((step) => {
                          return {
                              name: step?.name,
                              ...(step?.description && { description: step?.description }),

                              ...(step?.plannedStart && {
                                  plannedStart: moment(step?.plannedStart)?.format(
                                      "YYYY-MM-DDTHH:mm"
                                  ),
                              }),
                              ...(step?.plannedEnd && {
                                  plannedEnd: moment(step?.plannedEnd)?.format("YYYY-MM-DDTHH:mm"),
                              }),
                              id: step?._id,
                              //   isDaily: step?.isDaily,
                              assigneeIds: step?.assigneeIds?.length >= 1 ? step?.assigneeIds : [],
                              qualityChecklist:
                                  step?.qualityChecklist?.length >= 1 ? step?.qualityChecklist : [],
                              actualStart: step?.actualStart,
                              actualEnd: step?.actualEnd,
                              status: step?.status,
                              attachments:
                                  step?.attachments?.length >= 1
                                      ? step?.attachments?.map((item) => {
                                            return { path: item?.path, filename: item?.filename };
                                        })
                                      : [],
                          };
                      })
                    : [];
            setProductionSteps(newSteps);
        } else {
            setProductionSteps([]);
        }
    }, [steps]);

    const handleSubmit = () => {
        const sortData = qcData?.filter((item) => item?.task && item?.assigneeId);
        const modStep = {
            name: step?.name,
            ...(savedStepId
                ? {
                      description: step?.description || "",
                  }
                : step?.description && { description: step?.description }),
            ...(step?.plannedStart && { plannedStart: moment(step?.plannedStart)?.toISOString() }),
            ...(step?.plannedEnd && { plannedEnd: moment(step?.plannedEnd)?.toISOString() }),

            assigneeIds: assignees?.length >= 1 ? assignees : [],
            ...(sortData?.length >= 1 && {
                qualityChecklist: sortData?.map((item) => {
                    return {
                        assigneeId: item?.assigneeId,
                        task: item?.task,
                    };
                }),
            }),
            ...(step?.notes && { notes: step?.notes }),
            attachments:
                attachments?.length >= 1
                    ? attachments?.map((item) => {
                          return { path: item?.path, filename: item?.filename };
                      })
                    : [],
        };
        if (savedStepId) {
            dispatch(productionActions?.updateProductionPlanSteps(modStep, savedStepId));
        } else {
            const save = {
                steps: [modStep],
            };
            dispatch(productionActions?.createProductionPlanSteps(save, productionId));
        }
    };

    const handleUpdateProdStep = (status, stepId) => {
        const body = {
            status,
        };
        workOrderId && dispatch(productionActions?.updateProductionStep(body, workOrderId, stepId));
    };

    useEffect(() => {
        if (Boolean(updateProductionStepSuccess)) {
            dispatch(productionActions?.resetUpdateProductionStep());
            dispatch(productionActions.fetchWorkOrderTabTwoDetails(workOrderId, "steps", 10, 1));
        }
    }, [updateProductionStepSuccess]);

    useEffect(() => {
        if (Boolean(createPlanStepsSuccess)) {
            dispatch(productionActions?.resetCreateProductionPlanSteps());
            dispatch(productionActions.fetchProductionPlanTabTwo(productionId, "steps", 10, 1));
            setProductionStepModal(false);
        }
    }, [createPlanStepsSuccess]);

    useEffect(() => {
        if (Boolean(updatePlanStepsSuccess)) {
            dispatch(productionActions.fetchProductionPlanTabTwo(productionId, "steps", 10, 1));
            dispatch(productionActions?.resetUpdateProductionPlanSteps());
            setProductionStepModal(false);
        }
    }, [updatePlanStepsSuccess]);

    useEffect(() => {
        if (!productionStepModal) {
            // setStep({ isDaily: false });
            setStepIdx(-1);
            setSavedStepId("");
        }
    }, [productionStepModal]);

    return (
        <div className="font-rocGroteskMedium">
            <div className="">
                <div className="flex items-center justify-between gap-2">
                    <div className="flex items-center gap-1  pb-4">
                        <p className="text-gm-75 text-base">
                            Production workflow {productName && `for ${productName}`}
                        </p>
                        <div>
                            <a id="workflow">
                                <i className="ri-information-fill text-slate-500 text-base"></i>
                            </a>

                            <Tooltip
                                anchorSelect="#workflow"
                                place={"top"}
                                content="The step-by-step process flow for completing a production run."
                                style={{ width: "250px", zIndex: 9999 }}
                            />
                        </div>
                    </div>

                    {type !== "workOrder" && productionSteps?.length >= 1 && (
                        <div className="flex gap-2.5">
                            <Button
                                btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px]"
                                btnText={"Add production step"}
                                onClick={() => {
                                    setProductionStepModal(true);
                                    setAssignees([]);
                                    setQcData([{}]);
                                    setAttachments([]);
                                    setStep({});
                                }}
                            />
                            {/* <Button
                                btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px] !bg-transparent !border !border-slate-200"
                                btnText={"Import steps from file"}
                                // onClick={}
                            /> */}
                        </div>
                    )}
                </div>
                {isLoading && productionSteps?.length === 0 ? (
                    <div className="flex justify-center  items-center h-[100px]">
                        <Loader color="gm-25" size={6} />
                    </div>
                ) : type !== "workOrder" && productionSteps?.length === 0 ? (
                    <div className="border border-slate-300 border-dashed mx-auto text-center pt-6 pb-8 rounded-md">
                        <div className=" flex items-center flex-col ">
                            <i className="ri-flow-chart text-g-75 text-[40px]"></i>
                            <h6 className="mb-2 text-base font-rocGroteskMedium text-g-75 ">
                                No production workflow found
                            </h6>
                            <p className="text-sm  mb-4 text-slate-500 max-w-[570px]">
                                You haven't added any production steps or plans yet. Start
                                organizing your production schedule to streamline your processes and
                                improve efficiency.
                            </p>
                            <div className="flex gap-2.5">
                                <Button
                                    btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px]"
                                    btnText={"Add Production Step"}
                                    onClick={() => {
                                        // setProductionStepModal(true);
                                    }}
                                />
                                {/* <Button
                                    btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px] !bg-transparent !border !border-slate-200"
                                    btnText={"Import from document"}
                                    // onClick={}
                                /> */}
                            </div>
                        </div>
                    </div>
                ) : (
                    productionSteps?.map((step, idx) => {
                        const duration = moment.duration(
                            moment(step?.actualEnd).diff(moment(step?.actualStart))
                        );
                        const timeSpent = `${duration.minutes()} hrs ${duration.hours()} mins`;
                        return (
                            <div className="font-rocGroteskMedium" key={idx}>
                                <div className="">
                                    {idx === 0 ? (
                                        <div className="w-6 h-6 rounded-full bg-[#00DB8F] flex items-center justify-center relative z-1">
                                            <div className="w-3 h-3 rounded-full bg-white flex items-center justify-center">
                                                <i className="ri-check-line text-[#00DB8F] text-[10px]"></i>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="w-6 h-6 rounded-full bg-[#FEF3C7] flex items-center justify-center relative z-1">
                                            <i className="ri-time-line text-[#D97706] text-xs"></i>
                                        </div>
                                    )}
                                    <div
                                        className={` pb-4 pl-[26px] ml-2.5 border-l border-slate-200 -mt-4`}
                                    >
                                        <div className="border border-slate-200 rounded-[6px] font-rocGroteskMedium   px-6 py-4 space-y-4 shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                            <div className="">
                                                <div className="flex items-start justify-between">
                                                    <div className="space-y-2 w-auto">
                                                        <p className="text-sm text-g-75">
                                                            Step {idx + 1}: {step?.name}
                                                        </p>
                                                        <p className="text-sm text-slate-500">
                                                            {step?.description}
                                                        </p>
                                                    </div>

                                                    <div className="space-y-2 min-w-[650px]">
                                                        <div className="flex gap-2 justify-end">
                                                            <p className="text-g-75 text-sm">
                                                                Planned Start:{" "}
                                                                <span className="text-slate-500 text-sm ">
                                                                    {step?.plannedStart
                                                                        ? moment(
                                                                              step?.plannedStart
                                                                          )?.format(
                                                                              "YYYY-MM-DD HH:MM:SS A"
                                                                          )
                                                                        : "N/A"}
                                                                </span>
                                                            </p>
                                                            <p className="text-g-75 text-sm">
                                                                Planned End:{" "}
                                                                <span className="text-slate-500 text-sm ">
                                                                    {step?.plannedEnd
                                                                        ? moment(
                                                                              step?.plannedEnd
                                                                          )?.format(
                                                                              "YYYY-MM-DD HH:MM:SS A"
                                                                          )
                                                                        : "N/A"}
                                                                </span>
                                                            </p>
                                                            {/* <p className="text-g-75 text-sm">
                                                                Quantity: {` `}
                                                                <span className="text-slate-500 text-sm ">
                                                                    {step?.amount}
                                                                    {` `}
                                                                    {step?.unitOfMeasurement}
                                                                </span>
                                                            </p> */}
                                                        </div>

                                                        <div className="flex gap-2 justify-end">
                                                            <p className="text-g-75 text-sm">
                                                                Actual Start:{` `}
                                                                <span className="text-slate-500 text-sm ">
                                                                    {step?.actualStart
                                                                        ? moment(
                                                                              step?.actualStart
                                                                          )?.format(
                                                                              "YYYY-MM-DD HH:MM:SS A"
                                                                          )
                                                                        : "N/A"}
                                                                </span>
                                                            </p>

                                                            <p className="text-g-75 text-sm">
                                                                Actual End:{" "}
                                                                <span className="text-slate-500 text-sm ">
                                                                    {step?.actualEnd
                                                                        ? moment(
                                                                              step?.actualEnd
                                                                          )?.format(
                                                                              "YYYY-MM-DD HH:MM:SS A"
                                                                          )
                                                                        : "N/A"}
                                                                </span>
                                                            </p>
                                                        </div>

                                                        <div className="flex gap-2 justify-end">
                                                            <p className="text-g-75 text-sm">
                                                                Total time spent:{" "}
                                                                <span className="text-slate-500 text-sm ">
                                                                    {step?.actualStart &&
                                                                    step?.actualEnd
                                                                        ? timeSpent
                                                                        : "N/A"}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        {type === "workOrder" &&
                                                            workOrderDetails?.status !==
                                                                "planned" &&
                                                            (step?.status === "pending" ||
                                                            step?.status === "on-hold" ? (
                                                                <div className="flex justify-end">
                                                                    <Button
                                                                        btnText={"Start"}
                                                                        btnClassname="!bg-transparent !py-1.5 !px-2 !text-[#00AC59] !w-fit text-sm !border !border-slate-200 !shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !rounded"
                                                                        icon=<i className="ri-play-mini-fill text-[#00AC59]"></i>
                                                                        isLoading={
                                                                            idx === updateStep &&
                                                                            updatingProductionStep
                                                                        }
                                                                        disabled={
                                                                            updatingProductionStep ||
                                                                            fetchingWorkOrderTabTwo
                                                                        }
                                                                        onClick={() => {
                                                                            handleUpdateProdStep(
                                                                                "in-progress",
                                                                                step?.id
                                                                            );
                                                                            setUpdateStep(idx);
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : step?.status !== "completed" ? (
                                                                <div className="flex justify-end gap-3">
                                                                    <Button
                                                                        btnText={"Pause"}
                                                                        btnClassname="!bg-transparent !py-1.5 !px-2 !text-[#FF8A00] !w-fit text-sm !border !border-slate-200 !shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !rounded"
                                                                        icon=<i className="ri-pause-mini-fill text-[#FF8A00]"></i>
                                                                        isLoading={
                                                                            idx === updateStep &&
                                                                            prodStatus ===
                                                                                "pause" &&
                                                                            updatingProductionStep
                                                                        }
                                                                        disabled={
                                                                            updatingProductionStep ||
                                                                            fetchingWorkOrderTabTwo
                                                                        }
                                                                        onClick={() => {
                                                                            handleUpdateProdStep(
                                                                                "on-hold",
                                                                                step?.id
                                                                            );
                                                                            setUpdateStep(idx);
                                                                            setProdStatus("pause");
                                                                        }}
                                                                    />
                                                                    <Button
                                                                        btnText={"Complete step"}
                                                                        btnClassname="!bg-transparent !py-1.5 !px-2 !text-g-75 !w-fit text-sm !border !border-slate-200 !shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !rounded"
                                                                        icon=<i className="ri-stop-fill text-[#E4281D]"></i>
                                                                        isLoading={
                                                                            idx === updateStep &&
                                                                            prodStatus ===
                                                                                "complete" &&
                                                                            updatingProductionStep
                                                                        }
                                                                        disabled={
                                                                            updatingProductionStep ||
                                                                            fetchingWorkOrderTabTwo
                                                                        }
                                                                        onClick={() => {
                                                                            handleUpdateProdStep(
                                                                                "completed",
                                                                                step?.id
                                                                            );
                                                                            setUpdateStep(idx);
                                                                            setProdStatus(
                                                                                "complete"
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                ""
                                                            ))}
                                                    </div>
                                                </div>

                                                {type !== "workOrder" && (
                                                    <div className="flex gap-2 pt-2">
                                                        <Button
                                                            btnText="Edit step"
                                                            btnClassname="!px-4 !py-2 !bg-transparent !w-fit !border !border-slate-200 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !text-[13px]"
                                                            icon={
                                                                <i className="ri-edit-2-line text-gm-50 text-xs"></i>
                                                            }
                                                            onClick={() => {
                                                                setStepIdx(idx);
                                                                setStep(productionSteps[idx]);
                                                                setProductionStepModal(true);
                                                                setSavedStepId(step?.id);

                                                                productionId &&
                                                                    setSavedStepId(step?.id);
                                                                setAssignees(
                                                                    productionSteps[idx]
                                                                        ?.assigneeIds
                                                                );
                                                                setAttachments(
                                                                    productionSteps[idx]
                                                                        ?.attachments || []
                                                                );
                                                                productionSteps[idx]
                                                                    ?.qualityChecklist?.length >= 1
                                                                    ? setQcData(
                                                                          productionSteps[idx]
                                                                              ?.qualityChecklist
                                                                      )
                                                                    : setQcData([{}]);
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            {step?.qualityChecklist?.length >= 1 && (
                                                <div className="border border-slate-200 rounded-[6px] shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                                    <div
                                                        className={`flex justify-between items-center py-3 px-4 cursor-pointer ${
                                                            showCheckList === idx
                                                                ? "border-b border-b-slate-200"
                                                                : ""
                                                        }`}
                                                        onClick={() =>
                                                            showCheckList === idx
                                                                ? setShowCheckList(-1)
                                                                : setShowCheckList(idx)
                                                        }
                                                    >
                                                        <div className="flex items-center gap-2">
                                                            <i className="ri-list-check text-[#000]"></i>
                                                            <p className="text-g-75 text-sm">
                                                                Quality Control checklists
                                                            </p>
                                                        </div>
                                                        <i
                                                            className={`${
                                                                showCheckList === idx
                                                                    ? "ri-arrow-up-s-line"
                                                                    : "ri-arrow-down-s-line"
                                                            } text-gm-50 cursor-pointer`}
                                                        ></i>
                                                    </div>
                                                    {showCheckList === idx && (
                                                        <div className="py-3 px-4">
                                                            <ul className="list-disc py-3 px-4">
                                                                {step?.qualityChecklist?.map(
                                                                    (qc, idx) => (
                                                                        <li
                                                                            key={idx}
                                                                            className="text-[#142837] text-sm"
                                                                        >
                                                                            {qc?.task}
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {step?.attachments?.length >= 1 && (
                                                <div className="border border-slate-200 rounded-[6px] shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                                    <div
                                                        className={`flex justify-between items-center py-3 px-4  cursor-pointer  ${
                                                            showAttachments === idx
                                                                ? "border-b border-b-slate-200"
                                                                : ""
                                                        }`}
                                                        onClick={() =>
                                                            showAttachments === idx
                                                                ? setShowAttachments(-1)
                                                                : setShowAttachments(idx)
                                                        }
                                                    >
                                                        <div className="flex items-center gap-2">
                                                            <i className="ri-file-line text-gm-50"></i>
                                                            <p className="text-g-75 text-sm">
                                                                Documents attached
                                                            </p>
                                                        </div>
                                                        <i
                                                            className={`${
                                                                showAttachments === idx
                                                                    ? "ri-arrow-up-s-line"
                                                                    : "ri-arrow-down-s-line"
                                                            } text-gm-50 cursor-pointer`}
                                                        ></i>
                                                    </div>
                                                    {showAttachments === idx && (
                                                        <div className="py-3 px-4 grid grid-cols-2 gap-8">
                                                            {step?.attachments?.map((item, idx) => {
                                                                return (
                                                                    <div
                                                                        className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
                                                                        key={idx}
                                                                    >
                                                                        <div
                                                                            className="flex items-center gap-2 cursor-pointer"
                                                                            onClick={() => {
                                                                                const a =
                                                                                    document.createElement(
                                                                                        "a"
                                                                                    );
                                                                                a.href = item?.url;
                                                                                a.target = "_blank";
                                                                                document.body.appendChild(
                                                                                    a
                                                                                );
                                                                                a.click();
                                                                                document.body.removeChild(
                                                                                    a
                                                                                );
                                                                            }}
                                                                        >
                                                                            {displayFileIcon(
                                                                                getFileTypeFromUrl(
                                                                                    item?.path
                                                                                ),
                                                                                false,
                                                                                "!w-[20px] !h-[20px]"
                                                                            )}
                                                                            <div className="font-rocGroteskMedium">
                                                                                <p className="text-sm">
                                                                                    {item?.filename}
                                                                                    {` `}
                                                                                    {/* <span className="text-xs text-slate-500">
                                                                                                        |
                                                                                                        4mb
                                                                                                    </span> */}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {/* {step?.qualityChecklist?.filter((item) => item?.defect)
                                                ?.length >= 1 && (
                                                <div className="border border-slate-200 rounded-[6px] shadow-[0px_4px_8px_4px_rgba(16,24,40,0.03)]">
                                                    <div
                                                        className={`flex justify-between items-center py-3 px-4  cursor-pointer ${
                                                            showDefect === idx
                                                                ? "border-b border-b-slate-200"
                                                                : ""
                                                        }`}
                                                        onClick={() =>
                                                            showDefect === idx
                                                                ? setShowDefects(-1)
                                                                : setShowDefects(idx)
                                                        }
                                                    >
                                                        <div className="flex items-center gap-2">
                                                            <i className="ri-information-line text-gm-50"></i>
                                                            <p className="text-g-75 text-sm">
                                                                Defects identified
                                                            </p>
                                                        </div>
                                                        <i
                                                            className={`${
                                                                showDefect === idx
                                                                    ? "ri-arrow-up-s-line"
                                                                    : "ri-arrow-down-s-line"
                                                            } text-gm-50 cursor-pointer`}
                                                        ></i>
                                                    </div>
                                                    {showDefect === idx && (
                                                        <div className="py-3 px-4">
                                                            <ul className="list-disc py-3 px-4">
                                                                {step?.qualityChecklist
                                                                    ?.filter((item) => item?.defect)
                                                                    ?.map((qc, idx) => (
                                                                        <li
                                                                            key={idx}
                                                                            className="text-[#142837] text-sm"
                                                                        >
                                                                            {qc?.defect}
                                                                        </li>
                                                                    ))}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            )} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>

            {productionStepModal && (
                <ProductionSteps
                    productionStepModal={productionStepModal}
                    setProductionStepModal={setProductionStepModal}
                    productionSteps={productionSteps}
                    setProductionSteps={setProductionSteps}
                    step={step}
                    setStep={setStep}
                    stepIdx={stepIdx}
                    setStepIdx={setStepIdx}
                    handleSubmit={handleSubmit}
                    isSaving={creatingPlanSteps || updatingPlanSteps}
                    productionId={productionId}
                    assignees={assignees}
                    setAssignees={setAssignees}
                    setAttachments={setAttachments}
                    attachments={attachments}
                    setQcData={setQcData}
                    qcData={qcData}
                />
            )}
        </div>
    );
};

export default ProductionWorkflow;
