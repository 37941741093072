import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ModalContainer from "./../../../common/ModalContainer";
import Button from "./../../../common/Button";

type modalProps = {
    successModal: boolean;
    setSuccessModal: (data: boolean) => void;
    productionPlanId: string;
    confirmModal: boolean;
    setConfirmModal: (data: boolean) => void;
    handleCreateProductionPlan: () => void;
    creatingProductionPlan: boolean;
};

const ProductionModals = ({
    successModal,
    setSuccessModal,
    productionPlanId,
    confirmModal,
    setConfirmModal,
    handleCreateProductionPlan,
    creatingProductionPlan,
}: modalProps) => {
    const navigate = useNavigate();
    const { productionId } = useParams();
    const [param] = useSearchParams();
    const planTab = param.get("planTab");

    return (
        <div>
            <ModalContainer
                open={confirmModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setConfirmModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            setConfirmModal(false);
                        }}
                    ></i>
                    <div className="">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-6">
                            <i className="mb-4 ri-question-fill text-[40px] text-slate-400"></i>

                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] leading-[32px] text-gm-50">
                                    Are you sure you want to {productionId ? "update" : "create"}{" "}
                                    this production plan?
                                </span>
                                <p className="mt-1 text-base text-tradeally-neutral-200 text-center ">
                                    Kindly confirm that you will like to{" "}
                                    {productionId ? "update" : "create"} this production plan. Any
                                    approver you have selected will be notified afterward.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-gm-50 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setConfirmModal(false);
                                }}
                                disabled={creatingProductionPlan}
                            />
                            <Button
                                btnText={"Create plan"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-[#FFB903] !font-rocGroteskMedium !px-4 !text-gm-50"
                                onClick={() => {
                                    handleCreateProductionPlan();
                                }}
                                disabled={creatingProductionPlan}
                                isLoading={creatingProductionPlan}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={successModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setSuccessModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            navigate(`/dashboard/production?planTab=${planTab}`);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 w-12 h-12 bottom-0 right-[-13px] rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-[24px] text-white"></i>
                            </div>
                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    {productionId
                                        ? "Changes saved successfully!"
                                        : "Production plan created!"}
                                </span>
                                {/* Changes saved successfully! */}
                                <p className="mt-1 text-base text-slate-500 text-center ">
                                    {productionId
                                        ? "Your production plan details have been updated and saved. The changes are now reflected in your schedule."
                                        : "Your production plan has been successfully created. You can now review and manage the production plan details to ensure everything is set for processing."}
                                </p>
                                {/* Your production plan details have been updated and saved. The
                                changes are now reflected in your schedule. */}
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    // setSuccessModal(false);
                                    navigate(`/dashboard/production?planTab=${planTab}`);

                                    // setInventoryId("");
                                    // setBomList([{}]);
                                }}
                            />
                            <Button
                                btnText={"View details"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() =>
                                    navigate(`/dashboard/production/${productionPlanId}`)
                                }
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default ProductionModals;
