import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { productionActions } from "redux/Ldb/actions";

const useGetWorkOrderTabTwoDetails = (scrollContent?: HTMLElement) => {
    const { workOrderId } = useParams();
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [workOrderTwoDetails, setWorkOrderTwoDetails] = useState<any>();
    const [query, setQuery] = useState<string>("");
    const [pagination, setPagination] = useState({
        current: "",
        number_of_pages: "",
    });

    const { fetchingWorkOrderTabTwo, fetchedWorkOrderTabTwoSuccess } = useAppSelector(
        (state) => state.production
    );

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.current, number_of_pages: pagination.number_of_pages },
            10,
            scrollContent
        );

    useEffect(() => {
        setUpdatedPage(1);
        setWorkOrderTwoDetails([])
    }, [query]);

    useEffect(() => {
        if (workOrderId && (query === "purchase_orders" || query === "steps")) {
            dispatch(
                productionActions.fetchWorkOrderTabTwoDetails(workOrderId, query, 10, updatedPage)
            );
        }

    }, [dispatch, workOrderId, query, debouncedSearch, updatedPage]);

    useEffect(() => {
        if (Boolean(fetchedWorkOrderTabTwoSuccess)) {

            if (query === "purchase_orders") {
                setWorkOrderTwoDetails((prev) =>
                    updatedPage === 1
                        ? uniqBy(
                              [...fetchedWorkOrderTabTwoSuccess?.workOrder?.purchaseOrders],
                              "_id"
                          )
                        : uniqBy(
                              [
                                  ...prev,
                                  ...fetchedWorkOrderTabTwoSuccess?.workOrder?.purchaseOrders,
                              ],
                              "_id"
                          )
                );
            } else if (query === "steps") {
                setWorkOrderTwoDetails((prev) =>
                    updatedPage === 1
                        ? uniqBy([...fetchedWorkOrderTabTwoSuccess?.workOrder?.steps], "_id")
                        : uniqBy(
                              [...prev, ...fetchedWorkOrderTabTwoSuccess?.workOrder?.steps],
                              "_id"
                          )
                );
            }

            setPagination({
                current: fetchedWorkOrderTabTwoSuccess.pagination?.current,
                number_of_pages: fetchedWorkOrderTabTwoSuccess.pagination?.number_of_pages,
            });
            dispatch(productionActions.resetFetchWorkOrderTabTwoDetails());

        }

    }, [dispatch, fetchedWorkOrderTabTwoSuccess, query, updatedPage]);

    return {
        data: workOrderTwoDetails,
        setWorkOrderTwoDetails,
        isLoading: fetchingWorkOrderTabTwo,
        setQuery,
        isLoadingMore,
        pagination,
    };
};

export default useGetWorkOrderTabTwoDetails;
