import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";

const useGetBrandComparism = (brandOneId: string, brandTwoId: string, generalDuration?: string) => {
    const dispatch = useAppDispatch();
    const [brandComparism, setBrandComparism] = useState<{ [key: string]: any }>({});
    const [dateRange, setDateRange] = useState<{ label: string; value: string }>({
        label: "",
        value: "yearly",
    });

    const { fetchingBrandComparism, fetchBrandComparismSuccess, fetchBrandComparismFailure } =
        useAppSelector((state) => state.multibrand);

    const handleDurationChange = (value: { label: string; value: string }) => {
        setDateRange(value);
    };

    useEffect(() => {
        if (Boolean(fetchBrandComparismSuccess)) {
            setBrandComparism(fetchBrandComparismSuccess?.metrics);
        }
    }, [fetchBrandComparismSuccess]);

    useEffect(() => {
        if (brandOneId && brandTwoId) {
            dispatch(
                multibrandActions?.getBrandComparism(
                    brandOneId,
                    brandTwoId,
                    dateRange?.value !== generalDuration ? dateRange?.value : generalDuration
                )
            );
        }
    }, [dispatch, dateRange?.value, brandOneId, brandTwoId, generalDuration]);

    return {
        data: brandComparism,
        isFetching: fetchingBrandComparism,
        error: fetchBrandComparismFailure,
        handleDurationChange,
        dateRange,
    };
};

export default useGetBrandComparism;
