import React, { useEffect, useRef, useState } from "react";
import Button from "./Button";
import moment from "moment";
import TextInput from "./InputField/TextInput";
import CustomCalendar from "./CalendarComp.js/Calendar";

type dateInputProp = {
    label: string;
    value: string | Date;
    onChange?: (value?: string | Date | Date[]) => void;
    showCalendar?: boolean;
    onCalendarToggle?: (value: boolean) => void;
    required?: boolean;
    disabled?: boolean;
    disabledShowCalendar?: boolean;
    borderClassname?: string;
    borderContainerClassname?: string;
    calendarClassname?: string;
    doubleValue?: boolean;
    disablePastDate?: boolean;
    showDoubleView?: boolean;
    selectRange?: boolean;
};

const DateInput = ({
    label,
    value,
    onChange,
    showCalendar,
    onCalendarToggle,
    required,
    disabled,
    disabledShowCalendar,
    borderClassname,
    borderContainerClassname,
    calendarClassname,
    doubleValue,
    disablePastDate = false,
    showDoubleView = false,
    selectRange,
}: dateInputProp) => {
    const customRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (customRef.current && !customRef.current.contains(event.target)) {
                onCalendarToggle?.(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [customRef]);

    return (
        <div className="relative">
            <div className="" onClick={() => !disabledShowCalendar && onCalendarToggle(true)}>
                <TextInput
                    value={
                        doubleValue && value
                            ? `${moment(value[0])?.format("MMM DD, YYYY")} - ${moment(
                                  value[1]
                              )?.format("MMM DD, YYYY")}`
                            : value
                            ? moment(value).format("MMM DD, YYYY")
                            : ""
                    }
                    type={"text"}
                    onChange={() => console.log("change")}
                    placeholder={label}
                    inputContainerClassname={`${borderContainerClassname}`}
                    inputClassName={`!cursor-pointer !rounded ${
                        value
                            ? "!border-g-60 !border-[0.5px] !border-b-[1px]"
                            : "!border-gm-25 !border-[0.5px]"
                    } ${borderClassname} `}
                    rightIcon={
                        <i
                            className={`ri-calendar-line text-gm-45 text-base  ${
                                !disabledShowCalendar && "cursor-pointer"
                            }`}
                        ></i>
                    }
                    required={required}
                    // disabled={disabled}
                />
            </div>

            {showCalendar && (
                <div
                    className={`w-auto  z-[10001] absolute  -right-[150px] mt-3 px-[28px] pt-8 pb-8 bg-white
                                           rounded-2xl shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_32px_rgba(0,0,0,0.06)]
                                           ${calendarClassname}`}
                    ref={customRef}
                >
                    <CustomCalendar
                        showDoubleView={doubleValue || showDoubleView}
                        selectRange={doubleValue || showDoubleView || selectRange}
                        value={value}
                        onChange={(value) => {
                            onChange(value as Date);
                            if (!showDoubleView) {
                                onCalendarToggle(false);
                            }
                        }}
                        // disablePastDate={disablePastDate}
                    />
                    <div className="flex justify-end">
                        <Button
                            btnText={"Close"}
                            btnClassname={"!w-fit !py-2"}
                            onClick={() => onCalendarToggle(false)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default DateInput;
