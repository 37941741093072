import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";

const useGetIncidentAnalytics = (generalBrand?: string, generalDuration?: string) => {
    const dispatch = useAppDispatch();
    const [incidentsAnalytics, setIncidentsAnalytics] = useState<{ [key: string]: any }>();

    const {
        fetchingIncidentsAnalytics,
        fetchIncidentsAnalyticsSuccess,
        fetchIncidentsAnalyticsFailure,
    } = useAppSelector((state) => state.multibrand);

    useEffect(() => {
        if (Boolean(fetchIncidentsAnalyticsSuccess)) {
            setIncidentsAnalytics(fetchIncidentsAnalyticsSuccess?.analytics);
        }
    }, [fetchIncidentsAnalyticsSuccess]);

    useEffect(() => {
        dispatch(multibrandActions?.getIncidentsAnalytics(generalDuration, generalBrand));
    }, [dispatch, generalBrand, generalDuration]);

    return {
        data: incidentsAnalytics,
        isFetching: fetchingIncidentsAnalytics,
        error: fetchIncidentsAnalyticsFailure,
    };
};

export default useGetIncidentAnalytics;
