import React, { useEffect, useState } from "react";
import CustomTable from "../../../common/CustomTable";
import VendorAvatar from "../../../common/VendorAvatar";
import Dropdown from "../../../common/Dropdown";
import { useNavigate, useSearchParams } from "react-router-dom";
// import useGetBOMList from "hooks/ProductionHooks/useGetBOMList";
import { truncate } from "lodash";
// import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import CustomTableLoader from "../../../common/CustomTableLoader";
import EmptyStateComp from "../../../common/EmptyStateComp";
import Loader from "../../../common/Loader";
import moment from "moment";
import Button from "components/common/Button";
import TextareaInput from "components/common/InputField/TextareaInput";
import ModalContainer from "components/common/ModalContainer";
import useGetWorkOrderList from "hooks/ProductionHooks/useGetWorkOrderList";
import WorkOrderModal from "./WorkOrderModal";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { productionActions } from "redux/Ldb/actions";
import useGetPendingWorkOrderList from "hooks/ProductionHooks/useGetPendingWorkOrderList";
import { formatMoney } from "helpers";

type WorkOrderProps = {
    search: string;
    data: Record<string, any> | Array<Record<string, any>>;
    isLoadingMore: boolean;
    isLoading: boolean;
    pagination: any;
    assigneeId: string;
    statusFilter: string;
};
const WorkOrder = ({
    search,
    data,
    isLoading,
    isLoadingMore,
    pagination,
    assigneeId,
    statusFilter,
}: WorkOrderProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const currTab = params.get("currTab");
    const [rejectWorkOrder, setRejectWorkOrder] = useState<boolean>(false);
    const [approved, setApproved] = useState<boolean>(false);
    const [workOrderApproved, setWorkOrderApproved] = useState<boolean>(false);
    const [workOrderStarted, setWorkOrderStarted] = useState<boolean>(false);
    const [rejected, setRejected] = useState<boolean>(false);
    const [workOrderRejected, setWorkOrderRejected] = useState<boolean>(false);
    const [productionStopped, setProductionStopped] = useState<boolean>(false);
    const [productionStopModal, setProductionStopModal] = useState<boolean>(false);
    const [lowStockModal, setLowStockModal] = useState<boolean>(false);
    const [updateStatusModal, setUpdateStatusModal] = useState<boolean>(false);
    const [inventoryDepletion, setInventoryDepletion] = useState<boolean>(false);
    const [addToInventory, setAddToInventory] = useState<boolean>(false);
    const [confirmProductionStopModal, setConfirmProductionStopModal] = useState<boolean>(false);
    const [workOrderAutoGenerated, setWorkOrderAutoGenerated] = useState<boolean>(false);
    const [showApproval, setShowApproval] = useState<number>(-1);
    const [status, setStatus] = useState<string>("");
    const [stoppageReason, setStoppageReason] = useState<string>("");
    const [workOrderId, setWorkOrderId] = useState<string>("");
    const [productName, setProductName] = useState<string>("");
    const [workOrderIdx, setWorkOrderIdx] = useState<number>(-1);

    const { updatingWorkOrderStatus, updateWorkOrderStatusSuccess } = useAppSelector(
        (state) => state.production
    );

    const handleWorkOrderStatus = (status: string, workOrderId) => {
        const body = {
            status: status,
            ...(status === "cancelled" && { reason: stoppageReason }),
        };
        dispatch(productionActions?.updateWorkOrderStatus(workOrderId, body));
    };

    useEffect(() => {
        if (Boolean(updateWorkOrderStatusSuccess)) {
            dispatch(productionActions.fetchWorkOrderList(10));
            if (status === "in-progress") {
                setWorkOrderStarted(true);
            } else if (status === "cancelled") {
                setProductionStopModal(false);
                setProductionStopped(true);
            }
        }
    }, [updateWorkOrderStatusSuccess]);

    const displayActionButtons = (
        method: string,
        id: string,
        productName,
        idx,
        yieldAndLoss?: boolean
    ) => {
        switch (method) {
            case "in-progress":
            case "delayed":
            case "partial-completed": {
                return (
                    <div className="flex gap-3 flex-wrap">
                        <Button
                            btnText="Stop"
                            btnClassname="!bg-transparent !py-1.5 !px-2 !text-[#FF0000] !w-fit text-sm !border !border-slate-200 !shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !rounded"
                            icon=<i className="ri-stop-fill text-[#FF0000]"></i>
                            onClick={() => {
                                // handleWorkOrderStatus("cancelled", id)
                                setConfirmProductionStopModal(true);
                                setStatus("cancelled");
                                setWorkOrderId(id);
                                setProductName(productName);
                            }}
                            isLoading={
                                status === "cancelled" &&
                                workOrderIdx === idx &&
                                updatingWorkOrderStatus
                            }
                        />

                        <Button
                            btnText="Pause"
                            btnClassname="!bg-transparent !py-1.5 !px-2 !text-[#FF8A00] !w-fit text-sm !border !border-slate-200 !shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !rounded"
                            icon=<i className="ri-pause-mini-fill text-[#FF8A00]"></i>
                            isLoading={
                                status === "on-hold" &&
                                workOrderIdx === idx &&
                                updatingWorkOrderStatus
                            }
                            onClick={() => {
                                setStatus("on-hold");
                                handleWorkOrderStatus("on-hold", id);
                                setProductName(productName);
                            }}
                        />
                    </div>
                );
            }
            // case "pending":
            case "on-hold":
            case "planned": {
                return (
                    <div className="flex gap-3 flex-wrap">
                        <Button
                            btnText="Start"
                            btnClassname="!bg-transparent !py-1.5 !px-2 !text-[#00AC59] !w-fit text-sm !border !border-slate-200 !shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !rounded"
                            icon=<i className="ri-play-mini-fill text-[#00AC59]"></i>
                            isLoading={
                                status === "in-progress" &&
                                workOrderIdx === idx &&
                                updatingWorkOrderStatus
                            }
                            onClick={() => {
                                setStatus("in-progress");
                                handleWorkOrderStatus("in-progress", id);
                                setWorkOrderId(id);
                                setProductName(productName);
                            }}
                        />
                    </div>
                );
            }
            case "cancelled":
                return (
                    <div className="flex gap-3 flex-wrap">
                        <Button
                            btnText="Restart"
                            btnClassname="!bg-transparent !py-1.5 !px-2 !text-g-75 !w-fit text-sm !border !border-slate-200 !shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !rounded"
                            icon=<i className="ri-restart-line text-g-75"></i>
                            isLoading={
                                status === "restart" &&
                                workOrderIdx === idx &&
                                updatingWorkOrderStatus
                            }
                            onClick={() => {
                                setStatus("restart");
                                handleWorkOrderStatus("in-progress", id);
                                setProductName(productName);
                            }}
                        />
                    </div>
                );
            case "completed":
                return (
                    <div className="flex gap-3 flex-wrap">
                        {yieldAndLoss ? (
                            <Button
                                btnText="View report"
                                btnClassname="!bg-transparent !py-1.5 !px-2 !text-g-75 !w-fit text-sm !border !border-slate-200 !shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !rounded"
                                icon=<i className="ri-file-text-line text-g-75"></i>
                                onClick={() => navigate(`/dashboard/production/report/${id}`)}
                            />
                        ) : (
                            <Button
                                btnText="Calculate yield & loss"
                                btnClassname="!bg-transparent !py-1.5 !px-2 !text-g-75 !w-fit text-sm !border !border-slate-200 !shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !rounded"
                                onClick={() =>
                                    navigate(
                                        `/dashboard/production/work-order/${id}?yieldLoss=true`
                                    )
                                }
                                icon=<i className="ri-calculator-line text-g-75"></i>
                            />
                        )}
                    </div>
                );

            default:
                return <p className="flex gap-3 flex-wrap">---</p>;
        }
    };

    const WorkOrderTableHeader = [
        { title: "Production ID", widthClass: "w-[130px]  !bg-slate-50 " },
        { title: "Product", widthClass: "w-[250px]   !bg-slate-50 " },
        { title: "Start date", widthClass: "w-[130px]  !bg-slate-50 " },
        { title: "End date", widthClass: "w-[130px]  !bg-slate-50 " },
        { title: "Expected Qty", widthClass: "w-[140px]   !bg-slate-50 " },
        { title: "Actual Qty", widthClass: "w-[140px]  !bg-slate-50 " },
        { title: "Yields", widthClass: "w-[100px]  !bg-slate-50 " },
        { title: "Loses", widthClass: "w-[100px]  !bg-slate-50 " },
        { title: "Status", widthClass: "w-[250px]  !bg-slate-50 " },
        { title: "Action", widthClass: "w-[250px]  !bg-slate-50 " },
        { title: "", widthClass: "w-[40px]  !bg-slate-50 " },
    ];
    const WorkOrderTableBody = data?.map((product, idx) => {
        return [
            {
                content: (
                    <div className={`w-full  px-4 `}>
                        <p className="text-g-75 text-sm">WO-{product?.taId}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
                cellClickAction: () => {
                    navigate(`/dashboard/production/work-order/${product?._id}`);
                },
            },
            {
                content: (
                    <div className={`w-full  `}>
                        <div className="flex items-center gap-2 py-2 px-4">
                            {!product ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.productImageDetails?.productAvatar ||
                                        product?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm">
                                <p className="text-g-75">
                                    {" "}
                                    {truncate(product?.productName, { length: 15 })}
                                </p>
                                <p className="text-[#64748B]">{product?.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {product?.startDate
                                ? moment(product?.startDate)?.format("MMM DD, YYYY")
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {product?.endDate
                                ? moment(product?.endDate)?.format("MMM DD, YYYY")
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {`${formatMoney()?.format(product?.quantity?.amount) || ""} ${
                                product?.quantity?.amount
                                    ? product?.quantity?.unitOfMeasurement
                                    : "N/A"
                            }`}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {`${
                                product?.finalQuantity?.amount
                                    ? formatMoney()?.format(product?.finalQuantity?.amount)
                                    : ""
                            } ${
                                product?.finalQuantity?.amount
                                    ? product?.finalQuantity?.unitOfMeasurement
                                    : "N/A"
                            }`}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {`${
                                product?.percentYield
                                    ? formatMoney().format(product?.percentYield)
                                    : ""
                            }${product?.percentYield !== undefined ? "%" : "N/A"}`}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {`${
                                product?.percentLoss
                                    ? formatMoney().format(product?.percentLoss)
                                    : ""
                            }${product?.percentLoss !== undefined ? "%" : "N/A"}`}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <div className="flex items-center gap-2 text-sm">
                            <div
                                className={`w-2 h-2 rounded-full shrink-0 flex items-center justify-center captialize
                                   ${
                                       product?.status === "pending"
                                           ? "bg-[#6366F1]"
                                           : product?.status === "completed"
                                           ? "bg-[#22C55E]"
                                           : product?.status === "in-progress"
                                           ? "bg-[#3B82F6]"
                                           : product?.status === "cancelled"
                                           ? "bg-[#EF4444]"
                                           : product?.status === "on-hold"
                                           ? "bg-[#EAB308]"
                                           : product?.status === "rescheduled"
                                           ? "bg-[#8B5CF6]"
                                           : product?.status === "planned"
                                           ? "bg-[#14B8A6]"
                                           : product?.status === "delayed"
                                           ? "bg-[#F97316]"
                                           : product?.status === "partial-completed"
                                           ? "bg-[#0EA5E9]"
                                           : product?.status && "bg-[#6B7280]"
                                   }
                                    `}
                            ></div>
                            <span className="capitalize  text-sm">
                                {product?.status === "in-progress"
                                    ? "In progress"
                                    : product?.status === "on-hold"
                                    ? "On hold"
                                    : product?.status === "completed" && !product?.yieldLossComplete
                                    ? "Completed - Awaiting Yield & Loss"
                                    : product?.status}
                            </span>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full px-4 py-2`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setWorkOrderIdx(idx);
                        }}
                    >
                        <div className="flex gap-3 flex-wrap">
                            {displayActionButtons(
                                product?.status,
                                product?._id,
                                product?.productName,
                                idx,
                                product?.yieldLossComplete
                            )}
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`} onClick={(e) => e.stopPropagation()}>
                        <Dropdown
                            dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit right-[0px] `}
                            dropdownItemsClasses={``}
                            name={"faq-dropdown"}
                            dropdownOptions={[
                                {
                                    label: "View Work Order",
                                    value: "View Work Order",
                                    action: () => {
                                        navigate(
                                            `/dashboard/production/work-order/${product?._id}`
                                        );
                                    },
                                    labelClassName: "!text-gm-50 !text-sm",
                                },
                                {
                                    label: "Update status",
                                    value: "Update status",
                                    action: () => {
                                        navigate(
                                            `/dashboard/production/work-order/${product?._id}?updateStatus=true`
                                        );
                                    },
                                    labelClassName: "!text-gm-50 !text-sm",
                                },
                            ]}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    return (
        <div className="font-rocGroteskMedium">
            {isLoading && !search && status === "" && data?.length === 0 ? (
                <div className="overflow-x-hidden">
                    <CustomTableLoader tableHeader={WorkOrderTableHeader} />
                </div>
            ) : data?.length >= 1 ? (
                <div>
                    <CustomTable
                        tableBody={WorkOrderTableBody}
                        tableHeader={WorkOrderTableHeader}
                        isInifiniteScroll={true}
                        isCellSelectable={false}
                        isAllSelectable={false}
                        stickyHeader={false}
                        headerContainerClass="!bg-[#F8FAFC] !border-slate-200"
                        headerItemClass="font-rocGroteskMedium !py-[11px] !px-4 !text-slate-700"
                        tableClass="mb-12 !border-collapse"
                        isScrollable={true}
                        // leftFixedColumnCount={1}
                        // rightFixedColumnCount={11}
                    />

                    {isLoadingMore && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}

                    {pagination?.currentPage === pagination?.noOfPages && (
                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </div>
            ) : (search || assigneeId || statusFilter) && data?.length === 0 ? (
                <div className="h-[200px] flex items-center justify-center border-x border-b border-slate-200">
                    <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                        Sorry, no results match your search. Refine your criteria and try again.
                    </p>
                </div>
            ) : (
                <div className="border border-slate-200 rounded-lg py-8">
                    <EmptyStateComp
                        title="No Work Orders Available"
                        description="Once a production plan is created, it will appear here as a work order, allowing you to track and manage each step in the production process. Start by creating a production plan"
                        icon={<i className="ri-file-2-line text-[#64748B] text-[40px]"></i>}
                        hasButton={true}
                        btnText="Create production plan"
                        btnAction={() => {
                            navigate(`/dashboard/production/create-plan?currTab=1`);
                        }}
                        contentContainerClass="flex flex-col items-center max-w-[700px] !bg-white"
                        descriptionClass="flex flex-col items-center justify-center"
                        textClass="text-center"
                    />
                </div>
            )}

            <ModalContainer
                open={lowStockModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setLowStockModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <i className="ri-alarm-warning-fill text-[40px] text-[#E4281D]"></i>

                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Low stock alert!
                                </span>
                                {/* Changes saved successfully! */}
                                <p className="mt-1 text-base text-slate-500 text-center ">
                                    The stock level for this material is running low. To prevent any
                                    production delays, consider reordering now to ensure you have
                                    the necessary materials on hand.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Not now"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setLowStockModal(false);
                                }}
                            />
                            <Button
                                btnText={"Restock materials"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                // onClick={() =>
                                //      navigate(`/dashboard/production/${productionPlanId}`)
                                // }
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={inventoryDepletion || addToInventory}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    inventoryDepletion ? setInventoryDepletion(false) : setAddToInventory(false);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            inventoryDepletion
                                ? setInventoryDepletion(false)
                                : setAddToInventory(false);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 flex items-center justify-center">
                                {inventoryDepletion ? (
                                    <i className="ri-inbox-unarchive-line text-[40px] text-g-75"></i>
                                ) : (
                                    <i className="ri-inbox-archive-line text-[40px] text-g-75"></i>
                                )}
                            </div>
                            <div className="px-4 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] text-gm-50">
                                    {inventoryDepletion
                                        ? "Inventory Depletion Alert"
                                        : "Add finished goods to inventory"}
                                </span>
                                <p className={` mt-1 text-base text-slate-500 text-center `}>
                                    {inventoryDepletion
                                        ? "When you proceed with the production of Kōloa Kauaʻi Gold Rum, the following raw materials will be deducted from your inventory:"
                                        : "The production of Kōloa Kauaʻi Gold Rum has been successfully completed. You can now add the finished goods to your inventory."}
                                </p>
                            </div>
                        </div>

                        <div className="px-6 space-y-4">
                            <div className="">
                                <p className="text-sm text-gm-50 my-4">4 Materials</p>

                                <div className="space-y-2 pb-4">
                                    {[1, 1, 1, 1]?.map((item, idx) => (
                                        <div
                                            className={`${
                                                idx === 3 ? "" : "border-b border-b-[#F1F5F9]"
                                            } flex items-center justify-between gap-2 pb-2 `}
                                        >
                                            <div className="flex items-center gap-2">
                                                {/* {!product ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.productImageDetails?.productAvatar ||
                                        product?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : ( */}
                                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                                </div>
                                                {/* )} */}
                                                <div className="">
                                                    <p className="text-gm-50 text-sm">
                                                        Sugarcane Juice
                                                    </p>
                                                    {/* <p className="text-slate-500 text-xs ">Production Manager</p> */}
                                                </div>
                                            </div>
                                            <p className="text-slate-500 text-sm">200 gallons</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={
                                    inventoryDepletion ? "View Inventory" : "View production report"
                                }
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setConfirmProductionStopModal(false);
                                }}
                            />
                            <Button
                                btnText={inventoryDepletion ? "Okay, got it" : "Okay, got it"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                // onClick={() =>
                                //     navigate(`/dashboard/production/${productionPlanId}`)
                                // }
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <WorkOrderModal
                approved={approved}
                setApproved={setApproved}
                workOrderApproved={workOrderApproved}
                setWorkOrderApproved={setWorkOrderApproved}
                workOrderStarted={workOrderStarted}
                setWorkOrderStarted={setWorkOrderStarted}
                rejected={rejected}
                setRejected={setRejected}
                workOrderRejected={workOrderRejected}
                setWorkOrderRejected={setWorkOrderRejected}
                productionStopped={productionStopped}
                setProductionStopped={setProductionStopped}
                confirmProductionStopModal={confirmProductionStopModal}
                setConfirmProductionStopModal={setConfirmProductionStopModal}
                workOrderAutoGenerated={workOrderAutoGenerated}
                setWorkOrderAutoGenerated={setWorkOrderAutoGenerated}
                showApproval={showApproval}
                setShowApproval={setShowApproval}
                productionStopModal={productionStopModal}
                setProductionStopModal={setProductionStopModal}
                rejectWorkOrder={rejectWorkOrder}
                setRejectWorkOrder={setRejectWorkOrder}
                stoppageReason={stoppageReason}
                setStoppageReason={setStoppageReason}
                workorderId={workOrderId}
                handleWorkOrderStatus={handleWorkOrderStatus}
                updatingWorkOrderStatus={updatingWorkOrderStatus}
                productName={productName}
                page={"workOrderList"}
            />
        </div>
    );
};

export default WorkOrder;
