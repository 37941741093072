import Button from "components/common/Button";
import MultiselectInput from "components/common/InputField/MultiselectInput";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import useGetSingleBrand from "hooks/multibrandHooks/useGetSingleBrand";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useGetTeam from "hooks/teamHooks/useGetTeamList";
import useGetUserProfile from "hooks/useGetUserProfile";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { multibrandActions } from "redux/Ldb/actions";
import { firebaseService } from "services/firebaseService";

const AddBrand = () => {
    const [newBrandData, setNewBrandData] = useState({
        logo: "",
        name: "",
        category: "",
        teamId: [],
    });
    const [uploading, setUploading] = useState<boolean>(false);
    const [deletingImage, setDeletingImage] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { brandId } = useParams();
    const [profile] = useGetUserProfile();
    const {
        data: teamList,
        isFetching: isFetchingTeam,
        handleSearch,
        handleDebouncedChange,
        search,
    } = useGetTeam();
    const { data: singleBrand, isFetching: isFetchingSingleBrand } = useGetSingleBrand();
    const { creatingBrand, createBrandSuccess, updatingBrand, updateBrandSuccess } = useAppSelector(
        (state) => state.multibrand
    );
    const brandCategory = [
        "Apparel",
        "Beverage",
        "Cosmetics",
        "Fitness",
        "Food",
        "Health & Wellness",
        "Personal Care",
        "Pet",
        "Supplements",
        "Others",
    ];

    const handleChange = (name: string, value: any) => {
        setNewBrandData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };
    console.log("newBrandData", newBrandData);

    const handleCreateBrand = () => {
        const body: { [key: string]: any } = {
            // email: "aura1@example.com",
            brandCategory: newBrandData.category,
            brandName: newBrandData.name,
            // assignedTeamMembersId: newBrandData?.teamId,
            // brandLogo: newBrandData?.logo,
        };

        if (newBrandData?.teamId?.length > 0) {
            body.assignedTeamMembersId = newBrandData?.teamId;
        }

        if (newBrandData?.logo) {
            body.brandLogo = newBrandData?.logo;
        }

        if (brandId) {
            dispatch(multibrandActions.updateBrand(body, brandId));
        } else {
            dispatch(multibrandActions.createBrand(body));
        }
    };

    const handleUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploading(true);
            // setDocData(file);
            firebaseService.uploadFile(
                `synthally/${process.env.REACT_APP_STAGE ?? "staging"}/${
                    profile?.profile?.businessName
                }/brandLogo/${fileName}`,
                file,
                (url) => {
                    handleChange("logo", url);
                    setUploading(false);
                }
            );
        }
    };

    const handleDeleteLogo = () => {
        const imagePath = decodeURIComponent(
            newBrandData?.logo?.split("/o/")?.[1]?.split("?alt")?.[0]
        );
        setDeletingImage(true);

        firebaseService.removeFile(imagePath, () => {
            handleChange("logo", "");
            setDeletingImage(false);
        });
    };

    useEffect(() => {
        if (Boolean(createBrandSuccess)) {
            setNewBrandData({
                logo: "",
                name: "",
                category: "",
                teamId: [],
            });

            dispatch(multibrandActions.resetCreateBrandSuccess());
            navigate("/dashboard/portfolio/management");
        }
    }, [createBrandSuccess, dispatch]);

    useEffect(() => {
        if (Boolean(updateBrandSuccess)) {
            setNewBrandData({
                logo: "",
                name: "",
                category: "",
                teamId: [],
            });

            dispatch(multibrandActions.resetUpdateBrandSuccess());
            navigate("/dashboard/portfolio/management");
        }
    }, [updateBrandSuccess, dispatch]);

    useEffect(() => {
        if (singleBrand) {
            setNewBrandData({
                logo: singleBrand?.profile?.avatar,
                name: singleBrand?.brandName,
                category: singleBrand?.brandCategory,
                teamId: singleBrand?.assignedTeamMembersId,
            });
        }
    }, [singleBrand]);

    return (
        <div className="flex justify-center py-12 font-rocGroteskMedium">
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleCreateBrand();
                }}
                className="w-[58%] bg-white rounded-md border border-slate-200 shadow-[0px_20px_24px_-4px_#10182814]"
            >
                <div className="px-6 py-4 border-b border-slate-200 flex items-center space-x-3">
                    <i
                        onClick={() => {
                            navigate(-1);
                        }}
                        className="ri-arrow-left-line text-2xl cursor-pointer"
                    ></i>
                    <h5 className="text-base">Add a new brand</h5>
                </div>

                {isFetchingSingleBrand ? (
                    <div className="flex justify-center w-full py-10">
                        <Loader size={6} />
                    </div>
                ) : (
                    <div className="px-6 py-4">
                        {newBrandData?.logo ? (
                            <div className="relative h-[156px] w-[185px] border rounded border-slate-200 mb-6">
                                <div
                                    className="absolute top-[10px] right-0 justify-end cursor-pointer "
                                    onClick={() => {
                                        if (!uploading && !deletingImage) {
                                            handleDeleteLogo();
                                        }
                                    }}
                                >
                                    {deletingImage ? (
                                        <div className="bg-slate-50 p-2">
                                            <Loader size={6} />
                                        </div>
                                    ) : (
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1705060965/Delete_icon_brttke.svg"
                                            className="w-8 mr-2 object-cover "
                                            alt="delete icon"
                                        />
                                    )}
                                </div>
                                <div>
                                    <img
                                        src={newBrandData?.logo}
                                        className="bg-no-repeat h-[156px] w-[185px] object-cover"
                                        alt="product Avatar"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="relative w-[185px] h-[156px] border border-dashed border-slate-300 bg-slate-50 rounded mb-6">
                                {uploading ? (
                                    <div className="w-full h-full flex items-center justify-center">
                                        <Loader size={8} />
                                    </div>
                                ) : (
                                    <div className="font-rocGroteskMedium flex flex-col justify-center items-center">
                                        <i className="ri-upload-cloud-2-line text-[40px] text-slate-500"></i>
                                        <p className="underline text-sm mb-2 text-center">
                                            Add brand logo
                                        </p>

                                        <p className="text-tradeally-neutral-200 text-xs mt-8 text-center">
                                            file format: jpeg, png
                                        </p>
                                    </div>
                                )}

                                {!uploading && (
                                    <input
                                        type="file"
                                        id="brandLogo"
                                        className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer"
                                        accept="image/png, image/jpeg"
                                        multiple
                                        onChange={(e) => {
                                            // handleInputUploadAction(e);
                                            if (e.target.files?.[0] && !uploading) {
                                                handleUpload(
                                                    e.target.files?.[0]?.name,
                                                    e.target.files?.[0]
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </div>
                        )}

                        <div className="mb-6">
                            <p className="text-sm mb-2 font-rocGroteskMedium">
                                Enter brand name<span className="text-r-50">*</span>
                            </p>
                            <TextInput
                                value={newBrandData?.name}
                                name={"name"}
                                type={"text"}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                inputPlaceholder={"Enter brand name"}
                                required={true}
                                containerClassname=""
                            />
                        </div>

                        <div className="mb-6">
                            <p className="text-sm mb-2 font-rocGroteskMedium">Select brand type</p>
                            <SelectInput
                                value={newBrandData?.category}
                                name="category"
                                inputPlaceholder="Select category"
                                inputContainerClassName="mt-[-10px]"
                                handleChange={(name, value) => {
                                    handleChange(name, value);
                                }}
                                isRequired={true}
                                dropdownOptions={brandCategory?.map((cat) => {
                                    return {
                                        label: cat,
                                        value: cat,
                                    };
                                })}
                            />
                        </div>

                        <div className="flex items-center space-x-3 mb-6">
                            <hr className="text-slate-500 w-[35%]" />
                            <span className="text-sm font-rocGroteskMedium text-slate-500 ">
                                Assign brand to team mates
                            </span>
                            <hr className="text-slate-500 w-[35%]" />
                        </div>

                        <div className="mb-3">
                            <MultiselectInput
                                value={newBrandData?.teamId}
                                name="teamId"
                                placeholder="Select team member"
                                // inputContainerClassName="mt-[-10px]"
                                handleChange={(name, value, valueArr) => {
                                    handleChange(name, valueArr);
                                    handleSearch(value);
                                }}
                                searchLoading={isFetchingTeam}
                                handleInputChange={handleDebouncedChange}
                                isRequired={false}
                                dropdownOptions={teamList?.map((team) => {
                                    return {
                                        label: team?.fullName || team?.email,
                                        value: team?._id,
                                    };
                                })}
                            />
                        </div>
                    </div>
                )}

                {!isFetchingSingleBrand && (
                    <div className="px-6 py-4 border-t border-slate-200 flex items-center justify-end space-x-3">
                        <Button
                            btnText="Cancel"
                            btnClassname="!px-6 !py-3 !w-fit !h-[35px] !text-[13px] !bg-tradeally-neutral-20 !whitespace-nowrap"
                            onClick={() => {
                                navigate(-1);
                            }}
                        />
                        <Button
                            btnText={brandId ? "Update brand" : "Add brand"}
                            btnClassname="!px-6 !py-3 !w-fit !h-[35px] !text-[13px] !whitespace-nowrap"
                            type="submit"
                            isLoading={creatingBrand || updatingBrand}
                        />
                    </div>
                )}
            </form>
        </div>
    );
};

export default AddBrand;
