export const multibrandTypes = {
    FETCH_BRAND_LIST_REQUEST: "FETCH_BRAND_LIST_REQUEST",
    FETCH_BRAND_LIST_SUCCESS: "FETCH_BRAND_LIST_SUCCESS",
    FETCH_BRAND_LIST_FAILURE: "FETCH_BRAND_LIST_FAILURE",
    FETCH_BRAND_LIST_SEARCH_REQUEST: "FETCH_BRAND_LIST_SEARCH_REQUEST",
    FETCH_BRAND_LIST_SEARCH_SUCCESS: "FETCH_BRAND_LIST_SEARCH_SUCCESS",
    FETCH_BRAND_LIST_SEARCH_FAILURE: "FETCH_BRAND_LIST_SEARCH_FAILURE",
    CREATE_BRAND_REQUEST: "CREATE_BRAND_REQUEST",
    CREATE_BRAND_SUCCESS: "CREATE_BRAND_SUCCESS",
    CREATE_BRAND_FAILURE: "CREATE_BRAND_FAILURE",
    UPDATE_BRAND_REQUEST: "UPDATE_BRAND_REQUEST",
    UPDATE_BRAND_SUCCESS: "UPDATE_BRAND_SUCCESS",
    UPDATE_BRAND_FAILURE: "UPDATE_BRAND_FAILURE",
    EXPORT_BRAND_LIST_REQUEST: "EXPORT_BRAND_LIST_REQUEST",
    EXPORT_BRAND_LIST_SUCCESS: "EXPORT_BRAND_LIST_SUCCESS",
    EXPORT_BRAND_LIST_FAILURE: "EXPORT_BRAND_LIST_FAILURE",
    FETCH_SINGLE_BRAND_REQUEST: "FETCH_SINGLE_BRAND_REQUEST",
    FETCH_SINGLE_BRAND_SUCCESS: "FETCH_SINGLE_BRAND_SUCCESS",
    FETCH_SINGLE_BRAND_FAILURE: "FETCH_SINGLE_BRAND_FAILURE",
    FETCH_ASSIGNED_BRAND_LIST_REQUEST: "FETCH_ASSIGNED_BRAND_LIST_REQUEST",
    FETCH_ASSIGNED_BRAND_LIST_SUCCESS: "FETCH_ASSIGNED_BRAND_LIST_SUCCESS",
    FETCH_ASSIGNED_BRAND_LIST_FAILURE: "FETCH_ASSIGNED_BRAND_LIST_FAILURE",
    FETCH_ASSIGNED_BRAND_LIST_SEARCH_REQUEST: "FETCH_ASSIGNED_BRAND_LIST_SEARCH_REQUEST",
    FETCH_ASSIGNED_BRAND_LIST_SEARCH_SUCCESS: "FETCH_ASSIGNED_BRAND_LIST_SEARCH_SUCCESS",
    FETCH_ASSIGNED_BRAND_LIST_SEARCH_FAILURE: "FETCH_ASSIGNED_BRAND_LIST_SEARCH_FAILURE",
    FETCH_BRAND_TOKEN_REQUEST: "FETCH_BRAND_TOKEN_REQUEST",
    FETCH_BRAND_TOKEN_SUCCESS: "FETCH_BRAND_TOKEN_SUCCESS",
    FETCH_BRAND_TOKEN_FAILURE: "FETCH_BRAND_TOKEN_FAILURE",
    FETCH_PROFIT_ANALYTICS_REQUEST: "FETCH_PROFIT_ANALYTICS_REQUEST",
    FETCH_PROFIT_ANALYTICS_SUCCESS: "FETCH_PROFIT_ANALYTICS_SUCCESS",
    FETCH_PROFIT_ANALYTICS_FAILURE: "FETCH_PROFIT_ANALYTICS_FAILURE",
    FETCH_COST_SUMMARY_REQUEST: "FETCH_COST_SUMMARY_REQUEST",
    FETCH_COST_SUMMARY_SUCCESS: "FETCH_COST_SUMMARY_SUCCESS",
    FETCH_COST_SUMMARY_FAILURE: "FETCH_COST_SUMMARY_FAILURE",
    FETCH_CHANNEL_SALES_ANALYTICS_REQUEST: "FETCH_CHANNEL_SALES_ANALYTICS_REQUEST",
    FETCH_CHANNEL_SALES_ANALYTICS_SUCCESS: "FETCH_CHANNEL_SALES_ANALYTICS_SUCCESS",
    FETCH_CHANNEL_SALES_ANALYTICS_FAILURE: "FETCH_CHANNEL_SALES_ANALYTICS_FAILURE",
    FETCH_INCIDENTS_ANALYTICS_REQUEST: "FETCH_INCIDENTS_ANALYTICS_REQUEST",
    FETCH_INCIDENTS_ANALYTICS_SUCCESS: "FETCH_INCIDENTS_ANALYTICS_SUCCESS",
    FETCH_INCIDENTS_ANALYTICS_FAILURE: "FETCH_INCIDENTS_ANALYTICS_FAILURE",
    FETCH_REVENUE_GRAPH_REQUEST: "FETCH_REVENUE_GRAPH_REQUEST",
    FETCH_REVENUE_GRAPH_SUCCESS: "FETCH_REVENUE_GRAPH_SUCCESS",
    FETCH_REVENUE_GRAPH_FAILURE: "FETCH_REVENUE_GRAPH_FAILURE",
    FETCH_REVENUE_TABLE_REQUEST: "FETCH_REVENUE_TABLE_REQUEST",
    FETCH_REVENUE_TABLE_SUCCESS: "FETCH_REVENUE_TABLE_SUCCESS",
    FETCH_REVENUE_TABLE_FAILURE: "FETCH_REVENUE_TABLE_FAILURE",
    FETCH_BRAND_ANALYTICS_REQUEST: "FETCH_BRAND_ANALYTICS_REQUEST",
    FETCH_BRAND_ANALYTICS_SUCCESS: "FETCH_BRAND_ANALYTICS_SUCCESS",
    FETCH_BRAND_ANALYTICS_FAILURE: "FETCH_BRAND_ANALYTICS_FAILURE",
    FETCH_BRAND_COMPARISM_REQUEST: "FETCH_BRAND_COMPARISM_REQUEST",
    FETCH_BRAND_COMPARISM_SUCCESS: "FETCH_BRAND_COMPARISM_SUCCESS",
    FETCH_BRAND_COMPARISM_FAILURE: "FETCH_BRAND_COMPARISM_FAILURE",
    FETCH_BRAND_INVENTORY_REQUEST: "FETCH_BRAND_INVENTORY_REQUEST",
    FETCH_BRAND_INVENTORY_SUCCESS: "FETCH_BRAND_INVENTORY_SUCCESS",
    FETCH_BRAND_INVENTORY_FAILURE: "FETCH_BRAND_INVENTORY_FAILURE",
    FETCH_BRAND_STOCK_DETAILS_REQUEST: "FETCH_BRAND_STOCK_DETAILS_REQUEST",
    FETCH_BRAND_STOCK_DETAILS_SUCCESS: "FETCH_BRAND_STOCK_DETAILS_SUCCESS",
    FETCH_BRAND_STOCK_DETAILS_FAILURE: "FETCH_BRAND_STOCK_DETAILS_FAILURE",
    FETCH_BRAND_WAREHOUSE_STOCK_BREAKDOWN_REQUEST: "FETCH_BRAND_WAREHOUSE_STOCK_BREAKDOWN_REQUEST",
    FETCH_BRAND_WAREHOUSE_STOCK_BREAKDOWN_SUCCESS: "FETCH_BRAND_WAREHOUSE_STOCK_BREAKDOWN_SUCCESS",
    FETCH_BRAND_WAREHOUSE_STOCK_BREAKDOWN_FAILURE: "FETCH_BRAND_WAREHOUSE_STOCK_BREAKDOWN_FAILURE",
    FETCH_BRAND_WAREHOUSE_LIST_REQUEST: "FETCH_BRAND_WAREHOUSE_LIST_REQUEST",
    FETCH_BRAND_WAREHOUSE_LIST_SUCCESS: "FETCH_BRAND_WAREHOUSE_LIST_SUCCESS",
    FETCH_BRAND_WAREHOUSE_LIST_FAILURE: "FETCH_BRAND_WAREHOUSE_LIST_FAILURE",
    EXPORT_BRAND_INVENTORY_LIST_REQUEST: "EXPORT_BRAND_INVENTORY_LIST_REQUEST",
    EXPORT_BRAND_INVENTORY_LIST_SUCCESS: "EXPORT_BRAND_INVENTORY_LIST_SUCCESS",
    EXPORT_BRAND_INVENTORY_LIST_FAILURE: "EXPORT_BRAND_INVENTORY_LIST_FAILURE",
    FETCH_INVENTORY_IN_STOCK_REQUEST: "FETCH_INVENTORY_IN_STOCK_REQUEST",
    FETCH_INVENTORY_IN_STOCK_SUCCESS: "FETCH_INVENTORY_IN_STOCK_SUCCESS",
    FETCH_INVENTORY_IN_STOCK_FAILURE: "FETCH_INVENTORY_IN_STOCK_FAILURE",
    FETCH_INVENTORY_TOTAL_STOCK_REQUEST: "FETCH_INVENTORY_TOTAL_STOCK_REQUEST",
    FETCH_INVENTORY_TOTAL_STOCK_SUCCESS: "FETCH_INVENTORY_TOTAL_STOCK_SUCCESS",
    FETCH_INVENTORY_TOTAL_STOCK_FAILURE: "FETCH_INVENTORY_TOTAL_STOCK_FAILURE",
    FETCH_INVENTORY_OUT_OF_STOCK_REQUEST: "FETCH_INVENTORY_OUT_OF_STOCK_REQUEST",
    FETCH_INVENTORY_OUT_OF_STOCK_SUCCESS: "FETCH_INVENTORY_OUT_OF_STOCK_SUCCESS",
    FETCH_INVENTORY_OUT_OF_STOCK_FAILURE: "FETCH_INVENTORY_OUT_OF_STOCK_FAILURE",
    FETCH_INVENTORY_RESTOCK_REQUEST: "FETCH_INVENTORY_RESTOCK_REQUEST",
    FETCH_INVENTORY_RESTOCK_SUCCESS: "FETCH_INVENTORY_RESTOCK_SUCCESS",
    FETCH_INVENTORY_RESTOCK_FAILURE: "FETCH_INVENTORY_RESTOCK_FAILURE",
    EXPORT_INVENTORY_OUT_OF_STOCK_REQUEST: "EXPORT_INVENTORY_OUT_OF_STOCK_REQUEST",
    EXPORT_INVENTORY_OUT_OF_STOCK_SUCCESS: "EXPORT_INVENTORY_OUT_OF_STOCK_SUCCESS",
    EXPORT_INVENTORY_OUT_OF_STOCK_FAILURE: "EXPORT_INVENTORY_OUT_OF_STOCK_FAILURE",
    EXPORT_INVENTORY_TOTAL_STOCK_REQUEST: "EXPORT_INVENTORY_TOTAL_STOCK_REQUEST",
    EXPORT_INVENTORY_TOTAL_STOCK_SUCCESS: "EXPORT_INVENTORY_TOTAL_STOCK_SUCCESS",
    EXPORT_INVENTORY_TOTAL_STOCK_FAILURE: "EXPORT_INVENTORY_TOTAL_STOCK_FAILURE",
    EXPORT_INVENTORY_IN_STOCK_REQUEST: "EXPORT_INVENTORY_IN_STOCK_REQUEST",
    EXPORT_INVENTORY_IN_STOCK_SUCCESS: "EXPORT_INVENTORY_IN_STOCK_SUCCESS",
    EXPORT_INVENTORY_IN_STOCK_FAILURE: "EXPORT_INVENTORY_IN_STOCK_FAILURE",
};
