import React from "react";
import CustomToast from "components/common/CustomToast";
import { productionTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { Dispatch } from "redux";
import { productionService } from "services/Ldb";

export const productionActions = {
    getBOMList,
    getSingleBOM,
    resetBOMList,
    createBOM,
    updateBOM,
    fetchProductWithoutBOM,
    getNonFinishedInventory,
    getFinishedInventory,
    createProductionPlan,
    updateProductionPlan,
    fetchSingleProductionPlan,
    fetchSingleProductionPlanBasic,
    fetchProductionPlanTab,
    fetchProductionPlanTabTwo,
    fetchProductionPlanList,
    updateProductionPlanSteps,
    createProductionPlanSteps,
    fetchProductionAnalytics,
    createProductionSettings,
    fetchProductionSettings,
    updateProductionSettings,
    resetUpdateProductionSettings,
    resetCreateProductionSettings,
    updateApprovalStatus,
    validateProductionBatchId,
    fetchWorkOrderList,
    fetchSingleWorkOrder,
    fetchWorkOrderTabDetails,
    fetchWorkOrderTabTwoDetails,
    updateProductionRun,
    updateWorkOrderStatus,
    updateWorkOrderApprovalStatus,
    fetchPendingWorkOrder,
    fetchRejectedWorkOrder,
    submitYieldAndLoss,
    updateProductionStep,
    fetchProductionPlanListSearch,
    fetchReportList,
    fetchSingleReport,
    saveReport,
    addToInventory,
    fetchProductionForecastList,
    resetUpdateBOM,
    resetCreateBOM,
    resetCreateProductionPlan,
    resetUpdateProductionPlan,
    resetFetchProductionPlanTab,
    resetFetchProductionPlanTabTwo,
    resetUpdateProductionPlanSteps,
    resetCreateProductionPlanSteps,
    resetUpdateApprovalStatus,
    resetFetchWorkOrderList,
    resetFetchWorkOrderTabDetails,
    resetFetchWorkOrderTabTwoDetails,
    resetUpdateProductionRun,
    resetUpdateWorkOrderStatus,
    resetUpdateWorkOrderApprovalStatus,
    resetPendingWorkOrderList,
    resetRejectedWorkOrderList,
    resetSubmitYieldAndLoss,
    resetUpdateProductionStep,
    resetProductionPlanList,
    resetFetchReportList,
    resetSaveReport,
    resetAddToInventory,
    resetFetchProductionForecastList,
    deleteProductionPlan,
    resetDeleteProductionPlan,
};

function getBOMList(limit?: number, page?: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_BOM_LIST_REQUEST));

        productionService.getBOMList(limit, page, search).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_BOM_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(productionTypes.FETCH_BOM_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}
function getSingleBOM(inventoryId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_SINGLE_BOM_REQUEST));

        productionService.getSingleBOM(inventoryId).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_SINGLE_BOM_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(productionTypes.FETCH_SINGLE_BOM_FAILURE, error?.message));
                }
            }
        );
    };
}

function createBOM(data: Record<string, any> | Array<Record<string, any>>, inventoryId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.CREATE_BOM_REQUEST));

        productionService.createBOM(data, inventoryId).then(
            (res) => {
                dispatch(success(productionTypes.CREATE_BOM_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(productionTypes.CREATE_BOM_FAILURE, error?.message));
                }
            }
        );
    };
}
function updateBOM(data: Record<string, any> | Array<Record<string, any>>, inventoryId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.UPDATE_BOM_REQUEST));

        productionService.editBOM(data, inventoryId).then(
            (res) => {
                dispatch(success(productionTypes.UPDATE_BOM_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(productionTypes.UPDATE_BOM_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchProductWithoutBOM() {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_PRODUCT_WITHOUT_BOM_REQUEST));

        productionService.getProductWithoutBOM().then(
            (res) => {
                dispatch(success(productionTypes.FETCH_PRODUCT_WITHOUT_BOM_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.FETCH_PRODUCT_WITHOUT_BOM_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getNonFinishedInventory(limit?: number, page?: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_NON_FINISHED_INVENTORY_REQUEST));

        productionService.getNonFinishedInventory(limit, page, search).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_NON_FINISHED_INVENTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            productionTypes.FETCH_NON_FINISHED_INVENTORY_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function createProductionPlan(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.CREATE_PRODUCTION_PLAN_REQUEST));

        productionService.createProductionPlan(data).then(
            (res) => {
                dispatch(success(productionTypes.CREATE_PRODUCTION_PLAN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.CREATE_PRODUCTION_PLAN_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateProductionPlan(
    data: Record<string, any> | Array<Record<string, any>>,
    planId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.UPDATE_PRODUCTION_PLAN_REQUEST));

        productionService.updateProductionPlan(data, planId).then(
            (res) => {
                dispatch(success(productionTypes.UPDATE_PRODUCTION_PLAN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.UPDATE_PRODUCTION_PLAN_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getFinishedInventory(category: string, limit?: number, page?: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_FINISHED_INVENTORY_REQUEST));

        productionService.getFinishedInventory(category, limit, page, search).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_FINISHED_INVENTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.FETCH_FINISHED_INVENTORY_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchProductionPlanList(
    limit?: number,
    page?: number,
    search?: string,
    status?: string,
    assigneeId?: string,
    startDate?: string,
    endDate?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_PRODUCTION_PLAN_LIST_REQUEST));

        productionService
            .getProductionPlanList(limit, page, search, status, assigneeId, startDate, endDate)
            .then(
                (res) => {
                    dispatch(
                        success(productionTypes.FETCH_PRODUCTION_PLAN_LIST_SUCCESS, res?.data)
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                productionTypes.FETCH_PRODUCTION_PLAN_LIST_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function fetchProductionPlanListSearch(
    limit?: number,
    search?: string,
    status?: string,
    assigneeId?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_PRODUCTION_PLAN_LIST_SEARCH_REQUEST));

        productionService.getProductionPlanList(limit, 1, search, status, assigneeId).then(
            (res) => {
                dispatch(
                    success(productionTypes.FETCH_PRODUCTION_PLAN_LIST_SEARCH_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            productionTypes.FETCH_PRODUCTION_PLAN_LIST_SEARCH_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function fetchSingleProductionPlan(planId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_REQUEST));

        productionService.getSingleProductionPlan(planId).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function fetchSingleProductionPlanBasic(planId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_BASIC_REQUEST));

        productionService.getSingleProductionPlanBasic(planId).then(
            (res) => {
                dispatch(
                    success(productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_BASIC_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_BASIC_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function fetchProductionPlanTab(
    planId: string,
    query?: string,
    limit?: number,
    page?: number,
    search?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_PRODUCTION_PLAN_TAB_REQUEST));

        productionService.getProductionPlanTab(planId, query, limit, page, search).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_PRODUCTION_PLAN_TAB_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.FETCH_PRODUCTION_PLAN_TAB_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchProductionPlanTabTwo(planId: string, query?: string, limit?: number, page?: number) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_PRODUCTION_PLAN_TAB_TWO_REQUEST));

        productionService.getProductionPlanTabTwo(planId, query, limit, page).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_PRODUCTION_PLAN_TAB_TWO_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            productionTypes.FETCH_PRODUCTION_PLAN_TAB_TWO_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function updateProductionPlanSteps(
    data: Record<string, any> | Array<Record<string, any>>,
    stepId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.UPDATE_PLAN_STEPS_REQUEST));

        productionService.updatePlanSteps(data, stepId).then(
            (res) => {
                dispatch(success(productionTypes.UPDATE_PLAN_STEPS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(productionTypes.UPDATE_PLAN_STEPS_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchProductionAnalytics(startDate: string, endDate: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_PRODUCTION_ANALYTICS_REQUEST));

        productionService.fetchProductionAnalytics(startDate, endDate).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_PRODUCTION_ANALYTICS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.FETCH_PRODUCTION_ANALYTICS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function createProductionPlanSteps(
    data: Record<string, any> | Array<Record<string, any>>,
    planId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.CREATE_PRODUCTION_STEPS_REQUEST));

        productionService.createPlanSteps(data, planId).then(
            (res) => {
                dispatch(success(productionTypes.CREATE_PRODUCTION_STEPS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.CREATE_PRODUCTION_STEPS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function createProductionSettings(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.CREATE_PRODUCTION_SETTINGS_REQUEST));

        productionService.createProductionSettings(data).then(
            (res) => {
                dispatch(success(productionTypes.CREATE_PRODUCTION_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.CREATE_PRODUCTION_SETTINGS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchProductionSettings() {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_PRODUCTION_SETTINGS_REQUEST));

        productionService.getProductionSettings().then(
            (res) => {
                dispatch(success(productionTypes.FETCH_PRODUCTION_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    // toast.custom((t) => (
                    //     <CustomToast t={t} message={error?.message} type="error" />
                    // ));
                    dispatch(
                        failure(productionTypes.FETCH_PRODUCTION_SETTINGS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateProductionSettings(
    data: Record<string, any> | Array<Record<string, any>>,
    id: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.UPDATE_PRODUCTION_SETTINGS_REQUEST));

        productionService.updateProductionSettings(data, id).then(
            (res) => {
                dispatch(success(productionTypes.UPDATE_PRODUCTION_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.UPDATE_PRODUCTION_SETTINGS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateApprovalStatus(
    data: Record<string, any> | Array<Record<string, any>>,
    planId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.UPDATE_APPROVAL_STATUS_REQUEST));

        productionService.updateApprovalStatus(data, planId).then(
            (res) => {
                dispatch(success(productionTypes.UPDATE_APPROVAL_STATUS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.UPDATE_APPROVAL_STATUS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function validateProductionBatchId(id: string) {
    return async (dispatch) => {
        dispatch(request(productionTypes.VALIDATE_PRODUCTION_BATCHID_REQUEST));

        try {
            const res = await productionService.validateProductionBatchId(id);
            dispatch(success(productionTypes.VALIDATE_PRODUCTION_BATCHID_SUCCESS, res?.data));
            return res;
        } catch (error) {
            if (error.message) {
                dispatch(
                    failure(productionTypes.VALIDATE_PRODUCTION_BATCHID_FAILURE, error.message)
                );
            }
            throw error;
        }
    };
}

function fetchWorkOrderList(
    limit?: number,
    page?: number,
    search?: string,
    status?: string,
    assigneeId?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_WORK_ORDER_LIST_REQUEST));

        productionService.getWorkOrderList(limit, page, search, status, assigneeId).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_WORK_ORDER_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.FETCH_WORK_ORDER_LIST_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchSingleWorkOrder(workOrderId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_SINGLE_WORK_ORDER_REQUEST));

        productionService.getSingleWorkOrder(workOrderId).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_SINGLE_WORK_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.FETCH_SINGLE_WORK_ORDER_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchWorkOrderTabDetails(
    workOrderId: string,
    query?: string,
    limit?: number,
    page?: number,
    search?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_WORK_ORDER_TAB_REQUEST));

        productionService.getWorkOrderTab(workOrderId, query, limit, page, search).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_WORK_ORDER_TAB_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(productionTypes.FETCH_WORK_ORDER_TAB_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchWorkOrderTabTwoDetails(
    workOrderId: string,
    query?: string,
    limit?: number,
    page?: number
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_WORK_ORDER_TAB_TWO_REQUEST));

        productionService.getWorkOrderTabTwo(workOrderId, query, limit, page).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_WORK_ORDER_TAB_TWO_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.FETCH_WORK_ORDER_TAB_TWO_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateProductionRun(workOrderId: string, data?: Record<string, any>) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.UPDATE_PRODUCTION_RUN_REQUEST));

        productionService.switchProductionRun(workOrderId, data).then(
            (res) => {
                dispatch(success(productionTypes.UPDATE_PRODUCTION_RUN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.UPDATE_PRODUCTION_RUN_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateWorkOrderStatus(workOrderId: string, data?: Record<string, any>) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.UPDATE_WORK_ORDER_STATUS_REQUEST));

        productionService.updateWorkOrderStatus(workOrderId, data).then(
            (res) => {
                dispatch(success(productionTypes.UPDATE_WORK_ORDER_STATUS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.UPDATE_WORK_ORDER_STATUS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateWorkOrderApprovalStatus(
    data: Record<string, any> | Array<Record<string, any>>,
    wordOrderId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.UPDATE_WORK_ORDER_APPROVAL_STATUS_REQUEST));

        productionService.updateWorkOrderApprovalStatus(data, wordOrderId).then(
            (res) => {
                dispatch(
                    success(productionTypes.UPDATE_WORK_ORDER_APPROVAL_STATUS_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            productionTypes.UPDATE_WORK_ORDER_APPROVAL_STATUS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function fetchPendingWorkOrder(limit?: number, page?: number) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_PENDING_WORK_ORDER_LIST_REQUEST));

        productionService.getPendingWorkOrder(limit, page).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_PENDING_WORK_ORDER_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            productionTypes.FETCH_PENDING_WORK_ORDER_LIST_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function fetchRejectedWorkOrder(limit?: number, page?: number) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_REJECTED_WORK_ORDER_LIST_REQUEST));

        productionService.getRejectedWorkOrder(limit, page).then(
            (res) => {
                dispatch(
                    success(productionTypes.FETCH_REJECTED_WORK_ORDER_LIST_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            productionTypes.FETCH_REJECTED_WORK_ORDER_LIST_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function submitYieldAndLoss(
    data: Record<string, any> | Array<Record<string, any>>,
    wordOrderId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.SUBMIT_YIELD_AND_LOSS_REQUEST));

        productionService.submitYieldAndLoss(data, wordOrderId).then(
            (res) => {
                dispatch(success(productionTypes.SUBMIT_YIELD_AND_LOSS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.SUBMIT_YIELD_AND_LOSS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateProductionStep(
    data: Record<string, any> | Array<Record<string, any>>,
    workOrderId: string,
    stepId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.UPDATE_PRODUCTION_STEP_REQUEST));

        productionService.updateProductionStep(data, workOrderId, stepId).then(
            (res) => {
                dispatch(success(productionTypes.UPDATE_PRODUCTION_STEP_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.UPDATE_PRODUCTION_STEP_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchReportList(limit: number, page: number, search?: string, status?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_REPORT_LIST_REQUEST));

        productionService.getReportList(limit, page, search, status).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_REPORT_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(productionTypes.FETCH_REPORT_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSingleReport(workOrderId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_SINGLE_REPORT_REQUEST));

        productionService.getSingleReport(workOrderId).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_SINGLE_REPORT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(productionTypes.FETCH_SINGLE_REPORT_FAILURE, error?.message));
                }
            }
        );
    };
}

function saveReport(data: Record<string, any> | Array<Record<string, any>>, workOrderId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.SAVE_REPORT_REQUEST));

        productionService.saveReport(data, workOrderId).then(
            (res) => {
                dispatch(success(productionTypes.SAVE_REPORT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(productionTypes.SAVE_REPORT_FAILURE, error?.message));
                }
            }
        );
    };
}

function addToInventory(
    data: Record<string, any> | Array<Record<string, any>>,
    workOrderId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.ADD_TO_INVENTORY_REQUEST));

        productionService.addToInventory(data, workOrderId).then(
            (res) => {
                dispatch(success(productionTypes.ADD_TO_INVENTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(productionTypes.ADD_TO_INVENTORY_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchProductionForecastList(
    limit: number,
    page: number,
    search?: string,
    startDate?: string,
    endDate?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_PRODUCTION_FORECAST_LIST_REQUEST));

        productionService.getProductionForecastList(limit, page, search, startDate, endDate).then(
            (res) => {
                dispatch(
                    success(productionTypes.FETCH_PRODUCTION_FORECAST_LIST_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            productionTypes.FETCH_PRODUCTION_FORECAST_LIST_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function deleteProductionPlan(planId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.DELETE_PRODUCTION_PLAN_REQUEST));

        productionService.deleteProductionPlan(planId).then(
            (res) => {
                dispatch(success(productionTypes.DELETE_PRODUCTION_PLAN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.DELETE_PRODUCTION_PLAN_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function resetBOMList() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.FETCH_BOM_LIST_SUCCESS, null));
    };
}

function resetProductionPlanList() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.FETCH_PRODUCTION_PLAN_LIST_SUCCESS, null));
    };
}

function resetCreateBOM() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.CREATE_BOM_SUCCESS, null));
    };
}

function resetUpdateBOM() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.UPDATE_BOM_SUCCESS, null));
    };
}

function resetCreateProductionPlan() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.CREATE_PRODUCTION_PLAN_SUCCESS, null));
    };
}
function resetUpdateProductionPlan() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.UPDATE_PRODUCTION_PLAN_SUCCESS, null));
    };
}

function resetFetchProductionPlanTab() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.FETCH_PRODUCTION_PLAN_TAB_SUCCESS, null));
    };
}

function resetFetchProductionPlanTabTwo() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.FETCH_PRODUCTION_PLAN_TAB_TWO_SUCCESS, null));
    };
}

function resetUpdateProductionPlanSteps() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.UPDATE_PLAN_STEPS_SUCCESS, null));
    };
}

function resetCreateProductionPlanSteps() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.CREATE_PRODUCTION_STEPS_SUCCESS, null));
    };
}

function resetUpdateProductionSettings() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.UPDATE_PRODUCTION_SETTINGS_SUCCESS, null));
    };
}

function resetCreateProductionSettings() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.CREATE_PRODUCTION_SETTINGS_SUCCESS, null));
    };
}

function resetUpdateApprovalStatus() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.UPDATE_APPROVAL_STATUS_SUCCESS, null));
    };
}

function resetFetchWorkOrderList() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.FETCH_WORK_ORDER_LIST_SUCCESS, null));
    };
}

function resetFetchWorkOrderTabDetails() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.FETCH_WORK_ORDER_TAB_SUCCESS, null));
    };
}

function resetFetchWorkOrderTabTwoDetails() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.FETCH_WORK_ORDER_TAB_TWO_SUCCESS, null));
    };
}

function resetUpdateProductionRun() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.UPDATE_PRODUCTION_RUN_SUCCESS, null));
    };
}

function resetUpdateWorkOrderStatus() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.UPDATE_WORK_ORDER_STATUS_SUCCESS, null));
    };
}

function resetUpdateWorkOrderApprovalStatus() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.UPDATE_WORK_ORDER_APPROVAL_STATUS_SUCCESS, null));
    };
}

function resetPendingWorkOrderList() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.FETCH_PENDING_WORK_ORDER_LIST_SUCCESS, null));
    };
}

function resetRejectedWorkOrderList() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.FETCH_REJECTED_WORK_ORDER_LIST_SUCCESS, null));
    };
}

function resetSubmitYieldAndLoss() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.SUBMIT_YIELD_AND_LOSS_SUCCESS, null));
    };
}

function resetUpdateProductionStep() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.UPDATE_PRODUCTION_STEP_SUCCESS, null));
    };
}

function resetFetchReportList() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.FETCH_REPORT_LIST_SUCCESS, null));
    };
}

function resetSaveReport() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.SAVE_REPORT_SUCCESS, null));
    };
}

function resetAddToInventory() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.ADD_TO_INVENTORY_SUCCESS, null));
    };
}

function resetFetchProductionForecastList() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.FETCH_PRODUCTION_FORECAST_LIST_SUCCESS, null));
    };
}

function resetDeleteProductionPlan() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.DELETE_PRODUCTION_PLAN_SUCCESS, null));
    };
}

function request(type: string) {
    return { type: type };
}
function success(type: string, data?: any) {
    return { type: type, payload: data };
}
function failure(type: string, error?: any) {
    return { type: type, payload: error ?? "" };
}
