import React, { useEffect, useState } from "react";
import ModalContainer from "./../../../common/ModalContainer";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import SelectInput from "components/common/InputField/SelectInput";
import { unitOfMeasurement } from "variables/unitOfMeasurement";
import Button from "components/common/Button";
import moment from "moment";
import MultiselectInput from "components/common/InputField/MultiselectInput";
import VendorAvatar from "components/common/VendorAvatar";
import { authActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import CustomTable from "components/common/CustomTable";
import { debounce } from "lodash";
import { displayFileIcon } from "helpers";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import Loader from "components/common/Loader";
import { isFileSizeLessThanOrEqualTo } from "helpers/compareFileSizeInMb";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import { firebaseService } from "services/firebaseService";
import useGetUserProfile from "hooks/useGetUserProfile";

type stepsProps = {
    productionStepModal: boolean;
    setProductionStepModal: (data: boolean) => void;
    productionSteps: Array<Record<string, any>>;
    setProductionSteps: any;
    step: Record<string, any>;
    setStep: any;
    stepIdx: number;
    setStepIdx: (data: number) => void;
    productionId: string;
    handleSubmit?: any;
    isSaving?: boolean;
    assignees?: string[];
    setAssignees?: any;
    qcData?: Array<Record<string, any>>;
    setQcData?: any;
    attachments?: any;
    setAttachments?: any;
};
const ProductionSteps = ({
    productionStepModal,
    setProductionStepModal,
    productionSteps,
    setProductionSteps,
    step,
    setStep,
    stepIdx,
    setStepIdx,
    productionId,
    handleSubmit,
    isSaving,
    assignees,
    setAssignees,
    qcData,
    setQcData,
    attachments,
    setAttachments,
}: stepsProps) => {
    const dispatch = useAppDispatch();

    const [profile] = useGetUserProfile();
    const [teamMembers, setTeamMembers] = useState<{ [key: string]: any }[]>([]);
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [deletingImage, setDeletingImage] = useState<boolean>(false);
    const [uploadingCustomAttachment, setUploadingCustomAttachment] = useState<boolean>(false);
    const [deleteIdx, setDeleteIdx] = useState<number>(-1);

    const { fetchingTeamMember, fetchedTeamMemberSuccess } = useAppSelector((state) => state.auth);

    const handleStepData = (e) => {
        const { name, value } = e.target;
        setStep((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        dispatch(authActions.getTeamMembers(40, debouncedSearch));
    }, [dispatch, debouncedSearch]);

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e.target.value);
    }, 1500);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    const handleQcData = (e, idx) => {
        const { name, value } = e.target;
        let copyArr = [...qcData];
        copyArr[idx] = {
            ...copyArr[idx],
            [name]: value,
        };
        setQcData(copyArr);
    };

    const handleChange = (value: any) => {
        setAssignees(value);
    };

    const deleteAttachment = (idx) => {
        const newArr = [...attachments];

        const imagePath = decodeURIComponent(
            attachments[idx]?.path?.split("/o/")?.[1]?.split("?alt")?.[0]
        );
        setDeletingImage(true);
        firebaseService.removeFile(imagePath, () => {
            newArr?.splice(idx, 1);
            setAttachments(newArr);
        });
        setDeletingImage(false);
    };

    const handleAttachmentUpload = (fileName: string, file: File) => {
        if (fileName && file) {
            setUploadingCustomAttachment(true);

            firebaseService.uploadFile(
                `tradeally/${process.env.REACT_APP_STAGE ?? "staging"}/${
                    profile?.profile?.businessName
                }/createdByDocument/${fileName}`,
                file,
                (url) => {
                    setAttachments((prev) => [...prev, { path: url, filename: fileName }]);
                    setDeleteIdx(-1);
                    setUploadingCustomAttachment(false);
                }
            );
        }
    };

    const QualityControlTableHeader = [
        { title: "Task", widthClass: "w-[30%] !font-rocGroteskMedium !border-slate-200" },
        { title: "Assign to ", widthClass: "w-[30%] !font-rocGroteskMedium !border-slate-200" },
        { title: "", widthClass: "w-[10%] !font-rocGroteskMedium !border-slate-200" },
    ];

    const QualityControlTableBody = qcData?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full border-slate-200 text-sm text-slate-500 flex items-center  font-rocGroteskMedium `}
                    >
                        <TextInput
                            value={item?.task || ""}
                            name={"task"}
                            type="text"
                            required={item?.assigneeId && true}
                            onChange={(e) => handleQcData(e, idx)}
                            // inputPlaceholder="Enter task"
                            containerClassname=""
                            inputClassName="w-full"
                            inputContainerClassname={"!border-none !rounded-none "}
                            // focusBorder="!border-0"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            // {
            //     content: (
            //         <div
            //             className={`w-full border-slate-200 text-sm text-slate-500 flex items-center  font-rocGroteskMedium `}
            //         >
            //             <TextInput
            //                 value={item?.defect ?? ""}
            //                 name={"defect"}
            //                 type="text"
            //                 required={false}
            //                 onChange={(e) => handleQcData(e, idx)}
            //                 // inputPlaceholder="Enter title"
            //                 containerClassname=""
            //                 inputClassName="w-full"
            //                 inputContainerClassname={"!border-none !rounded-none "}
            //             />
            //         </div>
            //     ),
            //     tableDataDivClass: "!pl-0",
            //     widthClass: "!border-slate-200",
            // },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-slate-500 flex items-center  font-rocGroteskMedium `}
                    >
                        <SelectInput
                            value={item?.assigneeId}
                            name="assigneeId"
                            // placeholder={"Select assignee"}
                            handleChange={(name, value) => {
                                handleQcData({ target: { name, value } }, idx);
                            }}
                            dropdownOptions={teamMembers?.map((member) => ({
                                label: member?.fullName ? member?.fullName : member?.email,
                                value: member?._id,
                            }))}
                            className="!border-none"
                            dropdownClassName="!text-xs !text-gm-50 !font-rocGroteskMedium !max-h-[150px] !w-[250px]"
                            // dropdownContainerClasses=""
                            isRequired={item?.task && true}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            // {
            //     content: (
            //         <div
            //             className={`w-full border-slate-200 text-sm text-slate-500 flex items-center  font-rocGroteskMedium `}
            //         >
            //             <SelectInput
            //                 value={item?.status}
            //                 name="status"
            //                 // placeholder={"Select status"}
            //                 handleChange={(name, value) => {
            //                     handleQcData({ target: { name, value } }, idx);
            //                 }}
            //                 className="!border-0"
            //                 dropdownOptions={[
            //                     {
            //                         label: "QC Pass",
            //                         value: "pass",
            //                     },
            //                     {
            //                         label: "QC pending",
            //                         value: "Pending",
            //                     },
            //                 ]}
            //             />
            //         </div>
            //     ),
            //     tableDataDivClass: "!pl-0",
            //     widthClass: "!border-slate-200",
            // },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-slate-500 flex items-center justify-center  font-rocGroteskMedium `}
                    >
                        <i
                            className="ri-delete-bin-line text-[#64748B]"
                            onClick={() => {
                                const copyArr = [...qcData];
                                copyArr?.splice(idx, 1);
                                setQcData(copyArr);
                            }}
                        ></i>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    return (
        <div>
            <ModalContainer
                open={productionStepModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[700px] max-h-[80vh] rounded-[8px]"
                showCloseIcon={false}
                closeModal={() => {
                    !isSaving ? setProductionStepModal(true) : setProductionStepModal(false);
                }}
            >
                <form
                    className="bg-white rounded-[8px] relative font-rocGroteskMedium"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!productionId) {
                            const sortData = qcData?.filter(
                                (item) => item?.task && item?.assigneeId
                            );
                            const steps = {
                                ...step,
                                assigneeIds: assignees,
                                ...(sortData?.length >= 1 && {
                                    qualityChecklist: sortData?.map((item) => {
                                        return {
                                            assigneeId: item?.assigneeId,
                                            task: item?.task,
                                        };
                                    }),
                                }),

                                ...(attachments?.length >= 1 && {
                                    attachments: attachments,
                                }),
                            };
                            if (stepIdx >= 0) {
                                let copyArr = [...productionSteps];
                                copyArr[stepIdx] = steps;
                                setProductionSteps(copyArr);
                            } else {
                                setProductionSteps((prev) => [...prev, steps]);
                            }

                            setProductionStepModal(false);
                        } else {
                            handleSubmit();
                        }
                    }}
                >
                    <div className="">
                        <div className="sticky top-0 bg-white rounded-t-lg z-[1200] px-6 font-rocGroteskMedium border-b border-b-slate-200 flex items-center justify-between py-3">
                            <p className="mt-1 text-base text-gm-50 ">
                                {stepIdx >= 0 ? "Edit" : "Add"} step for production planning
                            </p>
                            <i
                                className={`${
                                    !isSaving && "cursor-pointer"
                                } ri-close-fill text-gm-50 `}
                                onClick={() => {
                                    !isSaving && setProductionStepModal(false);
                                    !isSaving && setStepIdx(-1);
                                }}
                            ></i>
                        </div>
                        <div className="px-6 pb-8 space-y-3 pt-3">
                            <TextInput
                                value={step.name}
                                name={"name"}
                                type={"text"}
                                onChange={(e) => handleStepData(e)}
                                placeholder={"Step name"}
                                required={true}
                                containerClassname=""
                            />

                            <TextareaInput
                                name={"description"}
                                value={step?.description}
                                onChange={handleStepData}
                                rows={5}
                                placeholder={"Description"}
                                // required={true}
                            />
                            <div className="grid grid-cols-2 gap-3">
                                <div className="relative">
                                    <p className="absolute text-[8px] top-[5px] left-[12px] text-gm-35 font-rocGroteskRegular">
                                        {" "}
                                        Start time
                                    </p>
                                    <TextInput
                                        value={step?.plannedStart}
                                        name={"plannedStart"}
                                        type={"datetime-local"}
                                        // required={true}
                                        onChange={(e) => {
                                            handleStepData(e);
                                        }}
                                        inputPlaceholder={"Planned Start"}
                                        containerClassname=""
                                        min={!productionId && moment()?.format("YYYY-MM-DDTHH:MM")}
                                    />
                                </div>
                                <div className="relative">
                                    <p className="absolute text-[8px] top-[5px] left-[12px] text-gm-35 font-rocGroteskRegular">
                                        {" "}
                                        End time
                                    </p>
                                    <TextInput
                                        value={step?.plannedEnd}
                                        name={"plannedEnd"}
                                        type={"datetime-local"}
                                        // required={true}
                                        onChange={(e) => {
                                            handleStepData(e);
                                        }}
                                        inputPlaceholder={"Planned End"}
                                        containerClassname="relative"
                                        min={!productionId && moment()?.format("YYYY-MM-DDTHH:MM")}
                                    />
                                </div>
                            </div>

                            <div className="flex gap-3">
                                <MultiselectInput
                                    value={assignees}
                                    name="assignees"
                                    placeholder={"Assign step to"}
                                    handleChange={(name, value, selectedItems) => {
                                        handleChange(selectedItems);
                                    }}
                                    searchLoading={fetchingTeamMember}
                                    handleInputChange={(e) => handleDebouncedChange(e)}
                                    //   switchOptions={assignees}
                                    // isRequired={true}
                                    dropdownOptions={teamMembers?.map((member) => ({
                                        label: member?.fullName ? member?.fullName : member?.email,
                                        value: member._id,
                                        icon: (
                                            <VendorAvatar
                                                size={30}
                                                imageSrc={member?.profile?.avatar}
                                                name={
                                                    member?.fullName
                                                        ? member?.fullName
                                                        : member?.email
                                                }
                                            />
                                        ),
                                    }))}
                                />
                            </div>

                            <div className="!mt-8">
                                <p className="text-base text-[#334155] !mb-4">
                                    Quality control checklists
                                </p>
                                <CustomTable
                                    tableBody={QualityControlTableBody}
                                    tableHeader={QualityControlTableHeader}
                                    isScrollable={false}
                                    isCellBordered={true}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    scrollWithin={false}
                                    headerContainerClass="!bg-slate-50 !border-slate-200 "
                                    headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                    tableClass="!border-slate-200"
                                    bodyItemClass="hover:!bg-transparent !border-slate-200"
                                />
                                <Button
                                    btnText="Add more QC checklist"
                                    btnClassname=" !my-4 !text-[13px] !py-2 !px-3 !bg-n-20 !w-fit text-gm-50 !rounded-[4px] "
                                    onClick={() => setQcData((prev) => [...prev, {}])}
                                />
                            </div>
                            <TextareaInput
                                name={"notes"}
                                value={step?.notes}
                                onChange={handleStepData}
                                rows={2}
                                placeholder={"Additional Info"}
                            />
                        </div>

                        <div className="px-6 pb-8 ">
                            <div className="border-t border-slate-200">
                                <p className="text-gm-50 text-base pt-6 pb-3">
                                    Attached document to this production steps
                                </p>

                                {attachments?.length > 0 ? (
                                    <div className="mt-4 ">
                                        {attachments?.map((item, idx) => {
                                            return (
                                                <div
                                                    className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4"
                                                    key={idx}
                                                >
                                                    <div className="flex items-center gap-2">
                                                        {displayFileIcon(
                                                            getFileTypeFromUrl(item?.path),
                                                            false,
                                                            "!w-[28px] !h-[28px]"
                                                        )}
                                                        <div className="font-rocGroteskMedium">
                                                            <p className="text-sm">
                                                                {item?.filename}
                                                            </p>
                                                            {/* <p className="text-xs text-slate-500">
                                                                5mb
                                                            </p> */}
                                                        </div>
                                                    </div>
                                                    {deletingImage && idx === deleteIdx ? (
                                                        <Loader size={3} />
                                                    ) : (
                                                        <i
                                                            className="ri-delete-bin-line text-xl text-r-50 cursor-pointer"
                                                            onClick={() => {
                                                                deleteAttachment(idx);
                                                                setDeleteIdx(idx);
                                                            }}
                                                        ></i>
                                                    )}

                                                    {}
                                                </div>
                                            );
                                        })}

                                        <label
                                            className={`flex items-center gap-4 py-3 px-6 rounded border border-dashed border-slate-200  ${
                                                !uploadingCustomAttachment && "cursor-pointer"
                                            }`}
                                            htmlFor="customAttachment"
                                        >
                                            <div className="flex items-center justify-start gap-4">
                                                <i className="ri-upload-cloud-line text-[#16C6A4] text-[22px]"></i>
                                                <div className="">
                                                    {uploadingCustomAttachment ? (
                                                        <div className="flex justify-start items-start w-fit">
                                                            <Loader size={4} />
                                                        </div>
                                                    ) : (
                                                        <p className="font-rocGroteskMedium">
                                                            <span className="underline text-sm text-gm-50">
                                                                Click to upload document
                                                            </span>
                                                        </p>
                                                    )}

                                                    <p className="lowercase text-[13px] text-tradeally-neutral-200  font-rocGroteskMedium">
                                                        .JPEG .PDF .CSV .DOCX | File size limit: 5MB
                                                    </p>
                                                </div>
                                            </div>
                                        </label>

                                        <input
                                            type="file"
                                            accept=".pdf,.doc,.docx,.txt, image/*"
                                            className={`top-0 w-full h-full outline-none opacity-0 ${
                                                !uploadingCustomAttachment && "cursor-pointer"
                                            }`}
                                            id="customAttachment"
                                            disabled={uploadingCustomAttachment}
                                            onChange={(evt) => {
                                                if (
                                                    !isFileSizeLessThanOrEqualTo(
                                                        evt.target.files?.[0],
                                                        5
                                                    )
                                                ) {
                                                    toast.custom((t) => (
                                                        <CustomToast
                                                            t={t}
                                                            message={"File too large"}
                                                            type={"error"}
                                                        />
                                                    ));
                                                    return;
                                                }

                                                if (evt.target.files?.[0]) {
                                                    handleAttachmentUpload(
                                                        evt.target.files?.[0].name,
                                                        evt.target.files?.[0]
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className={`mb-4`}>
                                        <div className="relative">
                                            <div
                                                className="flex flex-col cursor-pointer justify-center mb-4 py-4 
                                    text-sm rounded border border-dashed border-slate-200 px-6"
                                            >
                                                <div className="flex items-center justify-start gap-4">
                                                    <i className="ri-upload-cloud-line text-[#16C6A4] text-[22px]"></i>
                                                    <div className="">
                                                        {uploadingCustomAttachment ? (
                                                            <div className="flex justify-start items-start w-fit">
                                                                <Loader size={4} />
                                                            </div>
                                                        ) : (
                                                            <p className="font-rocGroteskMedium">
                                                                <span className="underline text-sm text-gm-50">
                                                                    Click to upload document
                                                                </span>
                                                            </p>
                                                        )}
                                                        <p className="lowercase text-[13px] text-tradeally-neutral-200  font-rocGroteskMedium">
                                                            .JPEG .PDF .CSV .DOCX | File size limit:
                                                            5MB
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                type="file"
                                                accept=".pdf,.doc,.docx,.txt, image/*"
                                                className={`${
                                                    !uploadingCustomAttachment &&
                                                    "absolute top-0 cursor-pointer"
                                                }  w-full h-full outline-none opacity-0 `}
                                                id="customAttachment"
                                                onChange={(evt) => {
                                                    if (
                                                        !isFileSizeLessThanOrEqualTo(
                                                            evt.target.files?.[0],
                                                            4
                                                        )
                                                    ) {
                                                        toast.custom((t) => (
                                                            <CustomToast
                                                                t={t}
                                                                message={"File too large"}
                                                                type={"error"}
                                                            />
                                                        ));
                                                        return;
                                                    }

                                                    if (evt.target.files?.[0]) {
                                                        handleAttachmentUpload(
                                                            evt.target.files?.[0].name,
                                                            evt.target.files?.[0]
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div
                            className={`border-t border-t-slate-200 py-4 px-6 flex items-center w-full sticky bottom-0 bg-white ${
                                stepIdx >= 0 && !productionId ? "justify-between" : "justify-end"
                            } space-x-4`}
                        >
                            {stepIdx >= 0 && !productionId && (
                                <Button
                                    btnText={"Delete step"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !bg-[rgba(228,40,29,0.1)] !text-r-50 !border border-[#E4281D] !font-rocGroteskMedium !px-4 !w-fit"
                                    onClick={() => {
                                        const copyArr = [...productionSteps];
                                        copyArr.splice(stepIdx, 1);
                                        setProductionSteps(copyArr);
                                        setProductionStepModal(false);
                                        setQcData([{}]);
                                    }}
                                    icon={<i className="ri-delete-bin-6-fill"></i>}
                                />
                            )}
                            <div className="flex items-center  justify-end gap-4 ">
                                <Button
                                    btnText={"Cancel"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                    onClick={() => {
                                        setProductionStepModal(false);
                                        setStepIdx(-1);
                                        setQcData([{}]);
                                    }}
                                    disabled={isSaving}
                                />
                                <Button
                                    type="submit"
                                    btnText={`${stepIdx >= 0 ? "Edit" : "Add production step"}`}
                                    isLoading={isSaving}
                                    disabled={isSaving}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                    // onClick={() => {
                                    //     if (stepIdx >= 0) {
                                    //         let copyArr = [...productionSteps];
                                    //         copyArr[stepIdx] = step;
                                    //         setProductionSteps(copyArr);
                                    //     } else {
                                    //         setProductionSteps((prev) => [...prev, step]);
                                    //     }
                                    //     setProductionStepModal(false);
                                    //     setStepIdx(-1);
                                    //     setStep({});
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </ModalContainer>
        </div>
    );
};

export default ProductionSteps;
