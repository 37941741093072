import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";
import { debounce, uniqBy } from "lodash";
import useInfiniteScroll from "hooks/useInfinteScroll";

const useGetBrandsInventoryRestockValue = (fetchData: boolean, scrollContent?: HTMLElement) => {
    const dispatch = useAppDispatch();
    const [rawData, setRawData] = useState<{
        [key: string]: any;
    }>([]);
    const [inventoryInStockValue, setInventoryInStockValue] = useState<{ [key: string]: any }>([]);
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [sortValues, setSortValues] = useState<any>({});
    const [startDateRange, setStartDateRange] = useState<string>("");
    const [endDateRange, setEndDateRange] = useState<string>("");
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [inventoryId, setInventoryId] = useState<string>("");
    const [channel, setChannel] = useState<string>("");
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string } | any>({
        currentPage: "1",
        noOfPages: "",
    });

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10,
            scrollContent
        );

    const { fetchingInventoryRestock, fetchInventoryRestockSuccess, fetchInventoryRestockFailure } =
        useAppSelector((state) => state.multibrand);

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e?.target?.value);
    }, 500);

    useEffect(() => {
        if (Boolean(fetchInventoryRestockSuccess)) {
            setInventoryInStockValue((prev: any) =>
                updatedPage === 1
                    ? uniqBy([...fetchInventoryRestockSuccess?.stockInfo], "_id")
                    : uniqBy([...prev, ...fetchInventoryRestockSuccess?.stockInfo], "_id")
            );
            setRawData(() => uniqBy([...fetchInventoryRestockSuccess?.stockInfo], "_id"));

            setPagination({
                currentPage: fetchInventoryRestockSuccess.pagination?.current,
                noOfPages: fetchInventoryRestockSuccess.pagination?.number_of_pages,
            });
            // setLoadMore(false);
        }
    }, [fetchInventoryRestockSuccess, updatedPage]);

    useEffect(() => {
        if (fetchData) {
            dispatch(
                multibrandActions.fetchInventoryRestockValue(
                    warehouseId,
                    channel,
                    10,
                    updatedPage,
                    startDateRange,
                    endDateRange,
                    debouncedSearch,
                    sortValues
                )
            );
            return () => {
                dispatch(multibrandActions.resetFetchInventoryRestockValueSuccess());
            };
        }
    }, [
        fetchData,
        warehouseId,
        channel,
        updatedPage,
        debouncedSearch,
        startDateRange,
        endDateRange,
        sortValues,
    ]);

    return {
        rawData,
        data: inventoryInStockValue,
        isFetching: fetchingInventoryRestock,
        error: fetchInventoryRestockFailure,
        handleDebouncedChange,
        setStartDateRange,
        setEndDateRange,
        pagination,
        setPagination,
        setSortValues,
        setWarehouseId,
        setInventoryId,
        setDebouncedSearch,
        setChannel,
        channel,
        isLoadingMore,
        debouncedSearch,
        setUpdatedPage,
    };
};

export default useGetBrandsInventoryRestockValue;
