import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";

const useGetChannelSalesAnalytics = (generalBrand?: string, generalDuration?: string) => {
    const dispatch = useAppDispatch();
    const [channelSalesAnalytics, setChannelSalesAnalytics] = useState<{ [key: string]: any }>();
    const [dateRange, setDateRange] = useState<{ label: string; value: string }>({
        label: "",
        value: "yearly",
    });
    const [brand, setBrand] = useState<{ label: string; value: string }>({
        label: "",
        value: "",
    });

    const {
        fetchingChannelSalesAnalytics,
        fetchChannelSalesAnalyticsSuccess,
        fetchChannelSalesAnalyticsFailure,
    } = useAppSelector((state) => state.multibrand);

    const handleDurationChange = (value: { label: string; value: string }) => {
        setDateRange(value);
    };

    const handleBrandChangeFilter = (value: { label: string; value: string }) => {
        setBrand(value);
    };

    useEffect(() => {
        if (Boolean(fetchChannelSalesAnalyticsSuccess)) {
            setChannelSalesAnalytics(fetchChannelSalesAnalyticsSuccess?.analytics);
        }
    }, [fetchChannelSalesAnalyticsSuccess]);

    useEffect(() => {
        dispatch(
            multibrandActions?.getChannelSalesAnalytics(
                dateRange?.value !== generalDuration ? dateRange?.value : generalDuration,
                brand?.value !== generalBrand ? brand?.value : generalBrand
            )
        );
    }, [dispatch, dateRange?.value, brand?.value, generalBrand, generalDuration]);

    return {
        data: channelSalesAnalytics,
        isFetching: fetchingChannelSalesAnalytics,
        error: fetchChannelSalesAnalyticsFailure,
        handleDurationChange,
        dateRange,
        brand,
        handleBrandChangeFilter,
    };
};

export default useGetChannelSalesAnalytics;
