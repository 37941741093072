import React, { useState } from "react";
import Dropdown from "components/common/Dropdown";
import { flattenDeep, truncate, uniqBy } from "lodash";
import TextInput from "components/common/InputField/TextInput";
import ReactECharts from "echarts-for-react";
import millify from "millify";
import ProgressBar from "components/common/ProgressBar";
import { COLORS, piechartColors } from "variables/chartColors";
import CustomTable from "components/common/CustomTable";
import useGetRevenueGraph from "hooks/multibrandHooks/useGetRevenueGraph";
import useGetRevenueTable from "hooks/multibrandHooks/useGetRevenueTable";
import useGetAsignedBrands from "hooks/multibrandHooks/useGetAssignedBrands";
import useGetBrandList from "hooks/multibrandHooks/useGetBrandList";
import useGetUserProfile from "hooks/useGetUserProfile";
import useGetProfitAnalytics from "hooks/multibrandHooks/useGetProfitAnalytics";
import useGetCostSummary from "hooks/multibrandHooks/useGetCostSummary";
import useGetChannelSalesAnalytics from "hooks/multibrandHooks/useGetChannelSalesAnalytics";
import { formatMoney } from "helpers";
import jsonToCsvExport from "json-to-csv-export";

type FinancialMetricsType = {
    generalBrand?: string;
    generalDuration?: string;
};

const FinancialMetrics = ({ generalBrand, generalDuration }: FinancialMetricsType) => {
    const [revenueMetricsView, setRevenueMeetricsView] = useState("line");
    const [activeTableNav, setActiveTableNav] = useState(0);

    const [profile] = useGetUserProfile();
    const {
        data: allAssignedBrands,
        brandListSearch: assignedBrands,
        search: searchAssigned,
        handleDebouncedChange: handleDebouncedChangeAssigned,
        handleSearch: handleSearchAsigned,
        handleDebouncedChangeTwo: handleDebouncedChangeTwoAssigned,
        handleSearchTwo: handleSearchTwoAssigned,
        searchTwo: searchTwoAssigned,
        setDebouncedSearchTwo: setDebouncedSearchTwoAssigned,
        setSearchTwo: setSearchTwoAssigned,
    } = useGetAsignedBrands();
    const {
        data: allBrandList,
        brandListSearch: brandList,
        search,
        handleDebouncedChange,
        handleSearch,
        handleDebouncedChangeTwo,
        handleSearchTwo,
        searchTwo,
        setDebouncedSearchTwo,
        setSearchTwo,
    } = useGetBrandList();
    const {
        data: profitAnalytics,
        dateRange,
        handleDurationChange,
        brand,
        handleBrandChangeFilter,
    } = useGetProfitAnalytics(generalBrand, generalDuration);
    const {
        data: costSummary,
        dateRange: costDateRange,
        handleDurationChange: handleCostDurationChange,
        brand: costSummaryBrand,
        handleBrandChangeFilter: handleCostSummaryBrandChangeFilter,
        handleCostTypeFilter,
        costType,
    } = useGetCostSummary(generalBrand, generalDuration);
    const {
        data: channelSales,
        dateRange: channelDateRange,
        handleDurationChange: handleChannelDurationChange,
        brand: channelSalesBrand,
        handleBrandChangeFilter: handleChannelSalesBrandChangeFilter,
    } = useGetChannelSalesAnalytics(generalBrand, generalDuration);

    const {
        data: revenueGraphData,
        dateRange: revenueDateRange,
        handleDurationChange: handleRevenueDurationChange,
        brand: revenueGraphBrand,
        handleBrandChangeFilter: handleRevenueGraphBrandChangeFilter,
    } = useGetRevenueGraph(generalBrand, generalDuration);
    const {
        data: revenueTableData,
        dateRange: revenueTableDateRange,
        handleDurationChange: handleRevenueTableDurationChange,
        handleBrandChangeFilter: handleRevenueTableBrandChangeFilter,
    } = useGetRevenueTable(generalBrand, generalDuration);

    const profitArr = profitAnalytics?.paylaod?.slice(0, 5)?.map((item) => item?.profit) || [];
    const maxProfit = Math.max(...profitArr);
    const minProfit = Math.min(...profitArr);

    const totalCostSummary = costSummary
        ?.slice(0, 5)
        ?.reduce((acc, curr) => acc + Number(curr?.totalCost), 0);
    const channelSalesRevenueArr = channelSales?.payload?.map((item) => item?.totalRevenue);
    const maxChannelSales = Math.max(channelSalesRevenueArr);
    const minChannelSales = Math.min(channelSalesRevenueArr);

    const dateOptions = [
        {
            label: (
                <div
                    className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                    border-b border-b-slate-100 !w-[120px] px-4 py-[10px]`}
                >
                    This week
                </div>
            ),
            value: "This week-weekly",
        },
        {
            label: (
                <div
                    className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                    border-b border-b-slate-100 !w-[120px] px-4 py-[10px]`}
                >
                    This month
                </div>
            ),
            value: "This month-monthly",
        },
        {
            label: (
                <div
                    className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                    border-b border-b-slate-100 !w-[120px] px-4 py-[10px]`}
                >
                    This year
                </div>
            ),
            value: "This year-yearly",
        },
    ];

    const tableNav = flattenDeep(revenueTableData?.map((item) => Object.keys(item)));

    const determineTrendDirection = (value: string | number) => {
        return String(value)?.[0] === "-" ? "down" : "up";
    };

    const bubbleCoordinates = [
        [120, 60], // Coordinates adjusted to center the bubbles (x, y)
        [160, 40],
        [120, 100],
        [210, 110],
        [65, 130],
        [90, 90],
        [90, 155],
    ];

    const bubbleChartOption = {
        tooltip: {
            show: true,
            formatter: function (params) {
                return `<div class="chart"><span style="font-size: 14px;">$${millify(
                    params.value[2],
                    {
                        precision: 2,
                    }
                )}</span></div>`;
            },
        },
        xAxis: {
            show: false, // Hide the x-axis
            type: "value",
            min: 0,
            max: 300, // Adjust the range to ensure space for the bubbles
        },
        yAxis: {
            show: false, // Hide the y-axis
            type: "value",
            min: 0,
            max: 150, // Adjust the range to center the bubbles vertically
        },
        series: [
            {
                type: "scatter",
                symbolSize: (data) => {
                    return (
                        ((data?.[2] || 0) / Number(totalCostSummary?.toFixed(0))) *
                        100 *
                        (costSummary?.slice(0, 5)?.length > 2 ? 3.5 : 1.5)
                    );
                },
                data: costSummary?.slice(0, 5)?.map((costData, idx) => {
                    const coordinateItem: any[] = bubbleCoordinates?.[idx];

                    coordinateItem?.push(Number(costData?.totalCost?.toFixed(2)));

                    return {
                        value: coordinateItem,
                        itemStyle: { color: COLORS[idx] },
                        label: { color: idx === 0 || idx === 3 ? "white" : "black" },
                    };
                }),
                label: {
                    show: true,
                    formatter: (params) =>
                        `$${millify(params.value[2], {
                            precision: 2,
                        })}`,
                    // color: "white",
                    fontWeight: "bold",
                    fontSize: 12,
                },
                itemStyle: {
                    color: "#6562E5", // Adjust bubble color (or use gradient if needed)
                    opacity: 0.8,
                    shadowBlur: 10,
                    shadowColor: "rgba(0, 0, 0, 0.2)",
                },
                emphasis: {
                    itemStyle: {
                        opacity: 1,
                        shadowBlur: 15,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                },
            },
        ],
    };

    const handleExportCostSummary = () => {
        const exportData = costSummary?.map((item) => ({
            "Brand name": item?.brand?.brandName,
            Cost: formatMoney()?.format(Number(item?.totalCost?.toFixed(2))),
        }));

        jsonToCsvExport({
            data: exportData,
            filename: "cost_summary",
        });
    };

    const profitSummaryPieChartOptions = {
        tooltip: {
            trigger: "item",
        },
        color: piechartColors,
        series: [
            {
                name: "Profit summary",
                type: "pie",
                radius: ["50%", "70%"],
                avoidLabelOverlap: false,
                padAngle: 2,
                label: {
                    show: false,
                    position: "center",
                },
                itemStyle: {
                    borderRadius: 5,
                    borderWidth: 2,
                    borderColor: "#fff",
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 40,
                        fontWeight: "bold",
                    },
                },
                labelLine: {
                    show: false,
                },

                data: profitAnalytics?.paylaod?.slice(0, 5)?.map((item) => ({
                    value: Number(item?.profit?.toFixed(2)),
                    name: item?.brand?.brandName,
                })),
            },
        ],
    };

    const handleExportProfitSummary = () => {
        const exportData = profitAnalytics?.paylaod?.map((item) => ({
            "Brand name": item?.brand?.brandName,
            Profit: formatMoney()?.format(Number(item?.profit?.toFixed(2))),
        }));

        jsonToCsvExport({
            data: exportData,
            filename: "profit_summary",
        });
    };

    const channelSummaryPieChartOptions = {
        tooltip: {
            trigger: "item",
            formatter: function (params) {
                const totalBrandRevenue = channelSales?.toolTipBrandBreakDown?.reduce(
                    (acc, cumm) => acc + cumm?.totalRevenue,
                    0
                );

                // console.log("params", params);
                return `<div class="chart" style="width: 651px; ">
                <div style="font-size: 12px; width: fit-content; background-color: #13A589; padding: 9px 13px; color: white; border-radius: 4px; margin-bottom: 16px;">$${formatMoney()?.format(
                    channelSales?.cumulativeAnalytics?.cumulativeRevenue
                )}</div>

                    <div>
                        <p style="font-size: 16px; padding-bottom: 4px; border-bottom: 1px solid #F1F5F9; color: black">${
                            params?.name
                        } sales breakdown</p>
                        <div style="padding: 16px 0; border-bottom: 1px solid #F1F5F9; display: flex">
                            <div style="width: 140px; border-right: 1px solid #F1F5F9;">
                                <p style="font-size: 13px; margin-bottom: 6px;">Av. Lead time</p>
                                <p style="font-size: 12px; color: #64748B; ">${formatMoney()?.format(
                                    channelSales?.toolTipAverageLeadTime
                                )} days</p>
                            </div>
                            <div style="width: 140px; border-right: 1px solid #F1F5F9; padding-left:10px">
                                <p style="font-size: 13px; margin-bottom: 6px;">Av. fulfillment rate</p>
                                <p style="font-size: 12px; color: #64748B; ">${formatMoney()?.format(
                                    channelSales?.toolTipAverageLeadTime
                                )}%</p>
                            </div>
                        </div>

                        <div style="width: 100%; display: flex; padding: 12px 0;">
                            <div style="width: 45%; border-right: solid 1px #E2E8F0;">
                                <p style="font-size: 13px; margin-bottom: 10px; color: black;">Breakdown by brands</p>

                                ${channelSales?.toolTipBrandBreakDown
                                    ?.map((item, idx) => {
                                        const brandRevenuePercentage =
                                            (item?.totalRevenue / totalBrandRevenue) * 100;

                                        return `
                                        <div key={${idx}} style="margin-bottom: 12px" >
                                            <div style="display: flex; gap: 8px; align-items: center">
                                                <div
                                                    className={"min-w-fit"}
                                                    style="width: ${brandRevenuePercentage}%; background-color: #7C3AED; border-top-right-radius: 8px; border-bottom-right-radius: 8px; height: 18px; display: flex; justtify-content: flex-end; align-items: center; padding: 0 8px"
                                                >
                                                    <div
                                                        className="text-[10px] leading-[12px] rounded-md bg-white text-[#334155] font-rocGroteskBold px-1"
                                                        style="font-size: 10px; line-height: 12px; border-radius: 6px; background-color: white; color: #334155; padding: 0 4px;"
                                                    >
                                                        ${formatMoney()?.format(
                                                            item?.percentageCost?.toFixed(0)
                                                        )}%
                                                    </div>
                                                </div>
                                                <p className="text-slate-500 text-xs font-rocGroteskMedium w-[90px]" style="color: #64748B; font-size: 12px; width:90px">
                                                    ${millify(item?.totalRevenue, { precision: 2 })}
                                                </p>
                                            </div>

                                            <p className="text-slate-500 text-[13px] font-rocGroteskMedium mb-3" style="color: #64748B; font-size: 13px; margin-bottom:12px">
                                                ${item?.brand?.brandName}
                                            </p>
                                        </div>
                                    `;
                                    })
                                    ?.join("")}
                                    
                            </div>

                            <div style="padding-left: 28px; width: 55%;">
                                <p style="font-size: 13px; margin-bottom: 10px; color: black">Breakdown by products</p>

                                <div>
                                   ${channelSales?.toolTipProductBreakDown
                                       ?.map((item, idx) => {
                                           return `
                                        <div key={${idx}} style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 14px; width: 100%;">

                                            <div>
                                                <span style="display: block; font-size: 12px; color: #64748B;">
                                                    ${item?.inventory?.productName}
                                                </span>

                                            </div>

                                            <div style="display: flex; align-items: center; ">
                                                <span style="margin-right: 6px; font-size: 14px;">
                                                    ${formatMoney()?.format(item?.totalRevenue)}
                                                </span>
                                                <span
                                                    
                                                style="font-size: 10px; padding: 0 6px; border: solid 1px ${
                                                    determineTrendDirection(
                                                        item?.percentageRevenueChange
                                                    ) === "up"
                                                        ? "#D0F4ED"
                                                        : "#FFD8D2"
                                                }; color: ${
                                               determineTrendDirection(
                                                   item?.percentageRevenueChange
                                               ) === "up"
                                                   ? "#0F846D"
                                                   : "#D43318"
                                           }"
                                                >
                                                    ${formatMoney()?.format(
                                                        item?.percentageRevenueChange
                                                    )}%
                                                </span>
                                            </div>
                                        </div>
                                    `;
                                       })
                                       ?.join("")}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>`;
            },
        },
        color: piechartColors,
        series: [
            {
                name: "Channel summary",
                type: "pie",
                radius: ["50%", "70%"],
                avoidLabelOverlap: false,
                padAngle: 2,
                label: {
                    show: false,
                    position: "center",
                },
                itemStyle: {
                    borderRadius: 5,
                    borderWidth: 2,
                    borderColor: "#fff",
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 40,
                        fontWeight: "bold",
                    },
                },
                labelLine: {
                    show: false,
                },

                data: channelSales?.payload?.map((channelData) => {
                    return {
                        value: channelData?.totalRevenue?.toFixed(1),
                        name: channelData?._id,
                    };
                }),
            },
        ],
    };

    const handleExportChannelSummary = () => {
        const exportData = channelSales?.payload?.map((item) => ({
            "Channel name": item?._id,
            Revenue: formatMoney()?.format(Number(item?.totalRevenue?.toFixed(2))),
        }));

        jsonToCsvExport({
            data: exportData,
            filename: "channel_summary",
        });
    };

    const multiBarChartOption = {
        legend: { show: false },
        tooltip: {
            formatter: function (params) {
                const formatTooltipData = revenueGraphData?.map(
                    (item) => item?.toolTipProductBreakDown
                );

                const tooltipData = formatTooltipData?.find((item) => item?._id === params?.name);

                return `
                    <div style="background-color: white; border-radius: 8px; width: 320px;">
                    ${tooltipData?.profitBreakDown
                        ?.map((item) => {
                            return `
                                <div style="padding-bottom: 10px;">
                            <p style="font-size: 16px; margin-bottom: 6px; font-weight: 600;">${
                                item?.brand?.brandName
                            } (${params?.name})</p>

                            <div>
                                <p style="">
                                    <span style="font-size: 13px">${params?.seriesName}:</span>
                                    <span style="color: #64748B; font-size: 12px">$${
                                        params?.seriesName?.toLowerCase() === "revenue"
                                            ? formatMoney()?.format(item?.totalRevenue)
                                            : params?.seriesName?.toLowerCase() === "profit"
                                            ? formatMoney()?.format(item?.totalProfit)
                                            : formatMoney()?.format(item?.totalCost)
                                    }</span>
                                </p>
                                
                            </div>
                            
                        </div>
                        `;
                        })
                        ?.join("")}
                        
                    </div>
                `;
            },
        },
        color: ["#6562E5", "#56B648", "#E4281D"],
        dataset: {
            source: [
                ["product", "Revenue", "Profit", "Cost"],

                ...revenueGraphData?.map((item) => {
                    return [
                        item?._id,
                        item?.totalRevenue?.toFixed(2),
                        item?.totalProfit?.toFixed(2),
                        item?.totalCost?.toFixed(2),
                    ];
                }),
            ],
        },
        xAxis: {
            type: "category",
            data: [...revenueGraphData?.map((item) => item?._id)],
        },
        yAxis: {
            axisLabel: {
                formatter: "${value}",
            },
        },
        series: [
            {
                type: "bar",
                barWidth: "13%",
                itemStyle: {
                    borderRadius: [20, 20, 0, 0],
                },
            },
            {
                type: "bar",
                barWidth: "13%",
                itemStyle: {
                    borderRadius: [20, 20, 0, 0],
                },
            },
            {
                type: "bar",
                barWidth: "13%",
                itemStyle: {
                    borderRadius: [20, 20, 0, 0],
                },
            },
        ],
    };

    const multilineChartValues = [
        [...revenueGraphData?.map((item) => item?._id)],
        [
            ...revenueGraphData?.map((item) => ({
                value: item?.totalProfit,
                quantity: 0,
                week: 1,
            })),
        ],
        [
            ...revenueGraphData?.map((item) => ({
                value: item?.totalRevenue,
                quantity: 0,
                week: 1,
            })),
        ],
        [...revenueGraphData?.map((item) => ({ value: item?.totalCost, quantity: 0, week: 1 }))],
    ];

    const multilineChartValuesOptions = {
        grid: { top: 8, right: 0, bottom: 24, left: 0, containLabel: true },
        xAxis: {
            type: "category",
            data: multilineChartValues && multilineChartValues?.[0],
            nameTextStyle: {
                borderType: "dotted",
                fontSize: "9",
                fontFamily: "rocGrotesk-regular",
            },
            axisLabel: {
                color: "#89939b",
                align: "center",
            },
            axisLine: {
                lineStyle: {
                    color: "#E2E8F0",
                },
            },
            axisTick: {
                show: false,
            },
        },
        axisLine: {
            lineStyle: {
                color: "#E2E8F0",
            },
        },
        axisTick: {
            show: false,
        },

        yAxis: {
            type: "value",
            axisLabel: {
                color: "#89939b",
                formatter: "${value}",
            },
            splitLine: {
                show: true,
                lineStyle: {
                    type: "dotted",
                    color: "#E2E8F0",
                },
            },
        },

        series: [
            {
                name: "Profit",
                data: multilineChartValues && multilineChartValues?.[1],
                type: "line",
                symbolSize: 5,
                symbol: "circle",
                smooth: true,
                lineStyle: {
                    width: 1,
                    color: "#56B648",
                    type: "dashed",
                },
            },
            {
                name: "Revenue",
                data: multilineChartValues && multilineChartValues?.[2],
                type: "line",
                symbolSize: 5,
                symbol: "circle",
                smooth: true,
                lineStyle: {
                    width: 1,
                    color: "#6562E5",
                    type: "line",
                },
            },
            {
                name: "Cost",
                data: multilineChartValues && multilineChartValues?.[3],
                type: "line",
                symbolSize: 5,
                symbol: "circle",
                smooth: true,
                lineStyle: {
                    width: 1,
                    color: "#E4281D",
                    type: "line",
                },
            },
        ],
        color: ["#56B648", "#6562E5", "#E4281D"],
        tooltip: {
            trigger: "axis",
            backgroundColor: "#fff",
            textStyle: {
                color: "#000",
            },

            axisPointer: {
                snap: true,
                type: "line",
                lineStyle: {
                    color: "#CBD5E1",
                    width: 1,
                    type: "line",
                },
                show: true,
                z: 10,
            },

            formatter: function (params) {
                if (
                    (params?.[0] && params[0]?.data?.value) ||
                    (params?.length === 2 && params?.[1]?.data?.value) ||
                    (params?.length === 3 && params?.[2]?.data?.value)
                ) {
                    const formatTooltipData = revenueGraphData?.map(
                        (item) => item?.toolTipProductBreakDown
                    );

                    const tooltipData = formatTooltipData?.find(
                        (item) => item?._id === params?.[0]?.name
                    );

                    return `
                            <div style="background-color: white; border-radius: 8px; width: 320px;">
                            ${tooltipData?.profitBreakDown
                                ?.map((item, idx) => {
                                    return `
                                    <div key={${idx}} style="padding-bottom: 10px; border-bottom: solid 1px #E2E8F0; margin-bottom: 16px">
                                        <p style="font-size: 16px; margin-bottom: 6px; font-weight: 500;">${
                                            item?.brand?.brandName
                                        } (${params?.[0]?.name})</p>

                                        <div>
                                            <p style="">
                                                <span style="font-size: 13px; color: #56B648;">Profit:</span>
                                                <span style="color: #64748B; font-size: 12px">$${formatMoney()?.format(
                                                    item?.totalProfit
                                                )}</span>
                                            </p>
                                            <p style="">
                                                <span style="font-size: 13px; color: #6562E5">Revenue:</span>
                                                <span style="color: #64748B; font-size: 12px">$${formatMoney()?.format(
                                                    item?.totalRevenue
                                                )}</span>
                                            </p>
                                            <p style="">
                                                <span style="font-size: 13px; color: #E4281D">Cost:</span>
                                                <span style="color: #64748B; font-size: 12px">$${formatMoney()?.format(
                                                    item?.totalCost
                                                )}</span>
                                            </p>
                                        </div>
                                        
                                    </div>
                                `;
                                })
                                ?.join("")}
                                
                            </div>
                    `;
                } else {
                    return "";
                }
            },
        },
    };

    const dynamicTableHeader: { title: string; widthClass: string }[] = uniqBy(
        flattenDeep(
            revenueTableData?.[activeTableNav]?.[tableNav[activeTableNav]]?.map((item) =>
                item?.channels?.map((item) => ({
                    title: item?.channel,
                    widthClass: "w-[19%] font-rocGroteskMedium",
                }))
            )
        ),
        "title"
    );

    const dynamicTableBody = revenueTableData?.[activeTableNav]?.[tableNav[activeTableNav]]?.map(
        (item) => {
            return uniqBy(
                item?.channels?.map((item) => ({
                    title: item?.channel,
                    value: item?.amount,
                    percentage: item?.percentageChange,
                })),
                "title"
            );
        }
    );

    const tableHeader = [
        { title: "Brand name", widthClass: "w-[25%] font-rocGroteskMedium" },
        ...dynamicTableHeader,
        { title: "Total sales", widthClass: "w-[19%] font-rocGroteskMedium" },
    ];

    const tableBody = revenueTableData?.[activeTableNav]?.[tableNav[activeTableNav]]?.map(
        (item, idx) => {
            const total = dynamicTableBody?.[idx]?.reduce(
                (acc, curr) => acc + Number(curr?.value),
                0
            );

            return [
                {
                    content: (
                        <div
                            className={`w-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-2`}
                        >
                            <div>
                                <p className="text-[13px]">{item?.brand?.brandName}</p>
                                {/* <p className="text-[10px] text-slate-500">17 MOPAC Expressway, TX 19870</p> */}
                            </div>
                        </div>
                    ),
                },
                ...dynamicTableBody?.[idx]?.map((channel) => {
                    return {
                        content: (
                            <div
                                className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-2`}
                            >
                                <div className="flex items-center space-x-3">
                                    <p className="text-[13px]">
                                        ${formatMoney().format(channel?.value)}
                                    </p>
                                    <span className="text-[10px] bg-g-25 px-1.5 py-[2px] rounded-[16px] flex items-center  text-g-60">
                                        <i className="ri-arrow-up-line mt-[-2px]"></i>
                                        {formatMoney().format(channel?.percentage)}%
                                    </span>
                                </div>
                            </div>
                        ),
                    };
                }),

                {
                    content: (
                        <div
                            className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-2`}
                        >
                            <div className="flex items-center space-x-3">
                                <p className="text-[13px]">${formatMoney().format(total)}</p>
                            </div>
                        </div>
                    ),
                },
            ];
        }
    );

    const handleExportRevenueSummary = () => {
        const exportData = revenueTableData?.[activeTableNav]?.[tableNav[activeTableNav]]?.map(
            (item, idx) => {
                const total = dynamicTableBody?.[idx]?.reduce(
                    (acc, curr) => acc + Number(curr?.value),
                    0
                );

                const data = {
                    "Brand name": item?.brand?.brandName,

                    total: formatMoney().format(total),
                };

                const parseData = Object?.entries(data)?.map(([key, value]) => ({ [key]: value }));

                dynamicTableBody[idx]?.forEach((element, idx) => {
                    return parseData?.splice(1 + idx, 0, {
                        [dynamicTableHeader?.[idx]?.title]: formatMoney()?.format(element?.value),
                    });
                });

                return parseData;
            }
        );

        const transformedData = exportData.map((parentArray) => {
            return parentArray.reduce((acc, childObject) => {
                const [key, value] = Object.entries(childObject)[0];
                acc[key] = value;
                return acc;
            }, {});
        });

        jsonToCsvExport({
            data: transformedData,
            filename: "revenue_summary",
        });
    };

    return (
        <div>
            <div className="flex space-x-4 mb-6">
                <div className="w-1/2 rounded-lg border border-slate-200 py-4 px-[13px]">
                    <div className="flex items-center justify-between pb-[13px] mb-6">
                        <div className="w-fit rounded p-1 flex items-center space-x-2.5 ">
                            <span className="text-base text-slate-500 font-rocGroteskMedium">
                                Profit summary
                            </span>
                        </div>

                        <div className="flex items-center space-x-1">
                            <Dropdown
                                handleChange={(name, value) => {
                                    handleBrandChangeFilter({
                                        label: value?.split("-")?.[0],
                                        value: value?.split("-")?.[1],
                                    });
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-2.5 px-3 bg-white !h-8 !border !border-slate-100 gap-2">
                                        <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                                        >
                                            {truncate(brand?.label || "All brands", {
                                                length: 20,
                                            })}
                                        </div>
                                    </div>
                                }
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[286px] !w-[200px] !py-0`}
                                name="valueRange"
                                onClose={() => {
                                    setSearchTwo("");
                                    setDebouncedSearchTwo("");
                                    setSearchTwoAssigned("");
                                    setDebouncedSearchTwoAssigned("");
                                }}
                                search={
                                    <div>
                                        <TextInput
                                            value={
                                                profile?.role === "owner" && !profile?.parentId
                                                    ? searchTwo
                                                    : searchTwoAssigned
                                            }
                                            name={"search"}
                                            type={"text"}
                                            required={false}
                                            onChange={(e) =>
                                                profile?.role === "owner" && !profile?.parentId
                                                    ? handleSearchTwo(e)
                                                    : handleSearchTwoAssigned(e)
                                            }
                                            onInput={
                                                profile?.role === "owner" && !profile?.parentId
                                                    ? handleDebouncedChangeTwo
                                                    : handleDebouncedChangeTwoAssigned
                                            }
                                            inputPlaceholder="Search brands"
                                            // containerClassname=""
                                            inputContainerClassname={"!h-[40px]"}
                                            inputClassName="w-full !h-[40px]"
                                        />
                                    </div>
                                }
                                dropdownOptions={[
                                    {
                                        label: (
                                            <div
                                                className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                 px-4 py-[10px]`}
                                            >
                                                All brands
                                            </div>
                                        ),
                                        value: ``,
                                    },
                                    ...(profile?.role === "owner" && !profile?.parentId
                                        ? brandList
                                        : assignedBrands
                                    )?.map((brand) => {
                                        let brandItem = brand;
                                        if (profile?.role === "owner" && !profile?.parentId) {
                                            brandItem = brand?.brand;
                                        }

                                        return {
                                            label: (
                                                <div
                                                    className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                     px-4 py-[10px]`}
                                                >
                                                    {brandItem?.brandName}
                                                </div>
                                            ),
                                            value: `${brandItem?.brandName}-${brandItem?._id}`,
                                        };
                                    }),
                                ]}
                            />

                            <Dropdown
                                handleChange={(name, value) => {
                                    handleDurationChange({
                                        label: value?.split("-")?.[0],
                                        value: value?.split("-")?.[1],
                                    });
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-2.5 px-3 bg-white !h-8 !border !border-slate-100 gap-2">
                                        <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                                        >
                                            {dateRange?.label || "This year"}
                                        </div>
                                    </div>
                                }
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                name="valueRange"
                                dropdownOptions={dateOptions}
                            />

                            <div
                                onClick={handleExportProfitSummary}
                                className="w-8 h-8 rounded shadow-buttonShadow2 border border-slate-200 flex items-center justify-center cursor-pointer"
                            >
                                <i className="ri-share-forward-box-fill text-base"></i>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center">
                        <div className="w-[45%]">
                            <div className="relative">
                                <ReactECharts option={profitSummaryPieChartOptions} />
                                <div className="absolute top-[30%] left-0 right-0 m-auto w-[120px] h-[120px] rounded-full font-rocGroteskMedium flex flex-col items-center justify-center bg-white shadow-[0px_4px_15.6px_-4px_#1018281A]">
                                    <span className="block text-[20px] text-g-75 leading-[40px]">
                                        ${millify(profitAnalytics?.cumulativeAnalysis?.totalProfit)}
                                    </span>
                                    <span className="block text-[12px] text-slate-500">
                                        Total profit
                                    </span>
                                    <span
                                        className={`text-[10px] px-1.5 py-[2px] rounded-[3px] flex items-center space-x-1.5 ${
                                            determineTrendDirection(
                                                profitAnalytics?.cumulativeAnalysis
                                                    ?.percentageChange
                                            ) === "up"
                                                ? "bg-g-25 text-g-60"
                                                : "text-r-55 bg-r-25"
                                        }`}
                                    >
                                        {determineTrendDirection(
                                            profitAnalytics?.cumulativeAnalysis?.percentageChange
                                        ) === "up" ? (
                                            <i className="ri-arrow-up-line "></i>
                                        ) : (
                                            <i className="ri-arrow-down-line "></i>
                                        )}
                                        <span>
                                            {profitAnalytics?.cumulativeAnalysis?.percentageChange?.toFixed(
                                                1
                                            )}
                                            % MoM
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="w-[55%] space-y-5">
                            {profitAnalytics?.paylaod?.slice(0, 5)?.map((data, idx) => {
                                return (
                                    <div key={idx} className="flex items-start justify-between">
                                        <div className="flex items-center space-x-2.5">
                                            <span
                                                className={`w-2.5 h-2.5 rounded-full `}
                                                style={{
                                                    backgroundColor: piechartColors?.[idx],
                                                }}
                                            ></span>
                                            <div className="font-rocGroteskMedium">
                                                <span className="block text-xs text-slate-500">
                                                    {data?.brand?.brandName}
                                                </span>
                                                {maxProfit === data?.profit &&
                                                    profitAnalytics?.paylaod?.length > 1 && (
                                                        <p className="flex space-x-1">
                                                            <span className="text-[10px] bg-g-60 px-1.5 py-[2px] rounded-[20px] text-white">
                                                                Most profitable
                                                            </span>
                                                        </p>
                                                    )}

                                                {minProfit === data?.profit &&
                                                    profitAnalytics?.paylaod?.length > 1 && (
                                                        <p className="flex space-x-1">
                                                            <span className="text-[10px] bg-slate-500 px-1.5 py-[2px] rounded-[20px] text-white">
                                                                Least profitable
                                                            </span>
                                                        </p>
                                                    )}
                                            </div>
                                        </div>

                                        <div className="flex items-center space-x-1.5 font-rocGroteskMedium">
                                            <span className="text-sm">
                                                ${formatMoney()?.format(data?.profit?.toFixed(2))}
                                            </span>
                                            <span
                                                className={`text-[10px] px-1.5 h-[18px] border ${
                                                    determineTrendDirection(
                                                        data?.percentageProfitContribution
                                                    ) === "up"
                                                        ? "border-g-25 text-g-60"
                                                        : "text-r-55 border-r-25"
                                                }`}
                                            >
                                                {data?.percentageProfitContribution?.toFixed(1)}%
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {/* <div className="flex justify-center items-center my-16">
            <span className="text-xl font-rocGroteskMedium text-center ">
                No Data available
            </span>
        </div> */}
                </div>

                <div className="w-1/2 rounded-lg border border-slate-200 py-4 px-[13px]">
                    <div className="flex items-center justify-between pb-[13px] mb-6">
                        <div className="w-fit rounded p-1 flex items-center space-x-2.5 ">
                            <span className="text-base text-slate-500 font-rocGroteskMedium">
                                Cost summary
                            </span>
                        </div>

                        <div className="flex items-center space-x-1">
                            <Dropdown
                                handleChange={(name, value) => {
                                    handleCostTypeFilter(value);
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-2.5 px-3 bg-white !h-8 !border !border-slate-100 gap-2">
                                        <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                                        >
                                            <span className="capitalize">{costType}</span> cost
                                        </div>
                                    </div>
                                }
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                name="valueRange"
                                dropdownOptions={[
                                    {
                                        label: (
                                            <div
                                                className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                    border-b border-b-slate-100 px-4 py-[10px]`}
                                            >
                                                Sales cost
                                            </div>
                                        ),
                                        value: "sales",
                                    },
                                    {
                                        label: (
                                            <div
                                                className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                    border-b border-b-slate-100 px-4 py-[10px]`}
                                            >
                                                Production cost
                                            </div>
                                        ),
                                        value: "production",
                                    },
                                ]}
                            />

                            <Dropdown
                                handleChange={(name, value) => {
                                    handleCostSummaryBrandChangeFilter({
                                        label: value?.split("-")?.[0],
                                        value: value?.split("-")?.[1],
                                    });
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-2.5 px-3 bg-white !h-8 !border !border-slate-100 gap-2">
                                        <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                                        >
                                            {truncate(costSummaryBrand?.label || "All brands", {
                                                length: 20,
                                            })}
                                        </div>
                                    </div>
                                }
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[286px] !w-[200px] !py-0`}
                                name="valueRange"
                                onClose={() => {
                                    setSearchTwo("");
                                    setDebouncedSearchTwo("");
                                    setSearchTwoAssigned("");
                                    setDebouncedSearchTwoAssigned("");
                                }}
                                search={
                                    <div>
                                        <TextInput
                                            value={
                                                profile?.role === "owner" && !profile?.parentId
                                                    ? searchTwo
                                                    : searchTwoAssigned
                                            }
                                            name={"search"}
                                            type={"text"}
                                            required={false}
                                            onChange={(e) =>
                                                profile?.role === "owner" && !profile?.parentId
                                                    ? handleSearchTwo(e)
                                                    : handleSearchTwoAssigned(e)
                                            }
                                            onInput={
                                                profile?.role === "owner" && !profile?.parentId
                                                    ? handleDebouncedChangeTwo
                                                    : handleDebouncedChangeTwoAssigned
                                            }
                                            inputPlaceholder="Search brands"
                                            // containerClassname=""
                                            inputContainerClassname={"!h-[40px]"}
                                            inputClassName="w-full !h-[40px]"
                                        />
                                    </div>
                                }
                                dropdownOptions={[
                                    {
                                        label: (
                                            <div
                                                className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                border-b border-b-slate-100 px-4 py-[10px]`}
                                            >
                                                All brands
                                            </div>
                                        ),
                                        value: ``,
                                    },
                                    ...(profile?.role === "owner" && !profile?.parentId
                                        ? brandList
                                        : assignedBrands
                                    )?.map((brand) => {
                                        let brandItem = brand;
                                        if (profile?.role === "owner" && !profile?.parentId) {
                                            brandItem = brand?.brand;
                                        }

                                        return {
                                            label: (
                                                <div
                                                    className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                    border-b border-b-slate-100 px-4 py-[10px]`}
                                                >
                                                    {brandItem?.brandName}
                                                </div>
                                            ),
                                            value: `${brandItem?.brandName}-${brandItem?._id}`,
                                        };
                                    }),
                                ]}
                            />

                            <Dropdown
                                handleChange={(name, value) => {
                                    handleCostDurationChange({
                                        label: value?.split("-")?.[0],
                                        value: value?.split("-")?.[1],
                                    });
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-2.5 px-3 bg-white !h-8 !border !border-slate-100 gap-2">
                                        <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                                        >
                                            {costDateRange?.label || "This year"}
                                        </div>
                                    </div>
                                }
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                name="valueRange"
                                dropdownOptions={dateOptions}
                            />

                            <div
                                onClick={handleExportCostSummary}
                                className="w-8 h-8 rounded shadow-buttonShadow2 border border-slate-200 flex items-center justify-center cursor-pointer"
                            >
                                <i className="ri-share-forward-box-fill text-base"></i>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="w-[45%]">
                            <div>
                                <ReactECharts option={bubbleChartOption} />{" "}
                            </div>
                        </div>
                        <div className="w-[55%] flex justify-center">
                            <div className="space-y-2">
                                {costSummary?.slice(0, 5)?.map((summary, idx) => {
                                    return (
                                        <div key={String(idx)} className="">
                                            <span className="block text-sm text-slate-600 font-rocGroteskMedium">
                                                {summary?.brand?.brandName}
                                            </span>

                                            <div className="flex items-center space-x-2">
                                                <div className="w-[105px]">
                                                    <ProgressBar
                                                        className={`w-full !h-1.5 !rounded-[5px]`}
                                                        containerClass="!bg-[#F2F4F7] !h-1.5"
                                                        styling={{ background: COLORS[idx] }}
                                                        progress={Number(
                                                            (
                                                                (summary?.totalCost /
                                                                    totalCostSummary) *
                                                                100
                                                            )?.toFixed(0)
                                                        )}
                                                    />
                                                </div>

                                                <span className="text-sm font-rocGroteskMedium text-slate-400">
                                                    ${millify(summary?.totalCost, { precision: 2 })}
                                                </span>
                                                <span
                                                    className={`flex items-center justify-center rounded text-[10px] font-rocGroteskMedium px-1.5 ${
                                                        determineTrendDirection(
                                                            summary?.percentageContribution
                                                        ) === "up"
                                                            ? "bg-g-25 text-g-60"
                                                            : "text-[#AA2913] bg-r-25"
                                                    }`}
                                                >
                                                    {formatMoney()?.format(
                                                        summary?.percentageContribution
                                                    )}
                                                    %{" "}
                                                    {costType === "sales"
                                                        ? "compared to revenue"
                                                        : "compared to total production cost"}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    {/* <div className="flex justify-center items-center my-16">
            
            <span className="text-xl font-rocGroteskMedium text-center ">
                 No Data available
            </span>
            
        </div> */}
                </div>
            </div>

            <div className="flex space-x-4 mb-6">
                <div className="w-[65%] flex-col justify-between space-y-4">
                    <div className="w-full rounded-lg border border-slate-200 py-4 px-[13px]">
                        <div className="flex items-center justify-between pb-[13px]">
                            <span className="text-xl font-rocGroteskMedium ">Revenue metrics</span>

                            <div className="flex items-center space-x-1.5">
                                <div className="flex items-center ">
                                    <div
                                        onClick={() => {
                                            setRevenueMeetricsView("line");
                                        }}
                                        className={`cursor-pointer h-8 w-8 flex items-center justify-center ${
                                            revenueMetricsView === "line" && "bg-slate-100"
                                        } hover:bg-slate-100 border-y border-l border-slate-200 rounded-tl rounded-bl`}
                                    >
                                        <i className="ri-line-chart-line text-base"></i>
                                    </div>
                                    <div
                                        onClick={() => {
                                            setRevenueMeetricsView("bar");
                                        }}
                                        className={`cursor-pointer h-8 w-8 flex items-center justify-center  ${
                                            revenueMetricsView === "bar" && "bg-slate-100"
                                        } hover:bg-slate-100 border-y border-l border-slate-200`}
                                    >
                                        <i className="ri-bar-chart-grouped-line text-base"></i>
                                    </div>
                                    <div
                                        onClick={() => {
                                            setRevenueMeetricsView("table");
                                        }}
                                        className={`cursor-pointer h-8 w-8 flex items-center justify-center  ${
                                            revenueMetricsView === "table" && "bg-slate-100"
                                        } hover:bg-slate-100 border-y border-l border-slate-200`}
                                    >
                                        <i className="ri-table-alt-line text-base"></i>
                                    </div>
                                    <Dropdown
                                        handleChange={(name, value) => {
                                            handleRevenueGraphBrandChangeFilter({
                                                label: value?.split("-")?.[0],
                                                value: value?.split("-")?.[1],
                                            });
                                            handleRevenueTableBrandChangeFilter({
                                                label: value?.split("-")?.[0],
                                                value: value?.split("-")?.[1],
                                            });
                                        }}
                                        dropdown={
                                            <div className="flex whitespace-nowrap border-x border-y border-slate-200 items-center h-[32px] px-1.5 py-2">
                                                <span className={` font-rocGroteskMedium text-xs`}>
                                                    {truncate(
                                                        revenueGraphBrand?.label || "All brands",
                                                        { length: 18 }
                                                    )}
                                                </span>
                                                <i className="ri-arrow-down-s-line text-slate-600"></i>
                                            </div>
                                        }
                                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                        dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0 max-sm:!w-full max-sm:z-[9999] rounded !max-h-[286px] !w-[200px]
                                 !p-0 !top-[105%]`}
                                        dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100 !px-0 !py-0"
                                        search={
                                            <div>
                                                <TextInput
                                                    value={
                                                        profile?.role === "owner" &&
                                                        !profile?.parentId
                                                            ? searchTwo
                                                            : searchTwoAssigned
                                                    }
                                                    name={"search"}
                                                    type={"text"}
                                                    required={false}
                                                    onChange={(e) =>
                                                        profile?.role === "owner" &&
                                                        !profile?.parentId
                                                            ? handleSearchTwo(e)
                                                            : handleSearchTwoAssigned(e)
                                                    }
                                                    onInput={
                                                        profile?.role === "owner" &&
                                                        !profile?.parentId
                                                            ? handleDebouncedChangeTwo
                                                            : handleDebouncedChangeTwoAssigned
                                                    }
                                                    inputPlaceholder="Search brands"
                                                    // containerClassname=""
                                                    inputContainerClassname={"!h-[40px]"}
                                                    inputClassName="w-full !h-[40px]"
                                                />
                                            </div>
                                        }
                                        dropdownOptions={[
                                            {
                                                label: (
                                                    <div
                                                        className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                    border-b border-b-slate-100 px-4 py-[10px]`}
                                                    >
                                                        All brands
                                                    </div>
                                                ),
                                                value: ``,
                                            },
                                            ...(profile?.role === "owner" && !profile?.parentId
                                                ? brandList
                                                : assignedBrands
                                            )?.map((brand) => {
                                                let brandItem = brand;
                                                if (
                                                    profile?.role === "owner" &&
                                                    !profile?.parentId
                                                ) {
                                                    brandItem = brand?.brand;
                                                }

                                                return {
                                                    label: (
                                                        <div
                                                            className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                        border-b border-b-slate-100 px-4 py-[10px]`}
                                                        >
                                                            {brandItem?.brandName}
                                                        </div>
                                                    ),
                                                    value: `${brandItem?.brandName}-${brandItem?._id}`,
                                                };
                                            }),
                                        ]}
                                    />
                                    <Dropdown
                                        handleChange={(name, value) => {
                                            handleRevenueDurationChange({
                                                label: value?.split("-")?.[0],
                                                value: value?.split("-")?.[1],
                                            });
                                            handleRevenueTableDurationChange({
                                                label: value?.split("-")?.[0],
                                                value: value?.split("-")?.[1],
                                            });
                                        }}
                                        dropdown={
                                            <div className="flex whitespace-nowrap border-r border-y border-slate-200 items-center rounded-tr rounded-br h-[32px] px-1.5 py-2">
                                                <span className={` font-rocGroteskMedium text-xs`}>
                                                    {revenueDateRange?.label || "This year"}
                                                </span>
                                                <i className="ri-arrow-down-s-line text-slate-600"></i>
                                            </div>
                                        }
                                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                        dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full max-sm:z-[9999] rounded !max-h-fit
                                 !p-0 !top-[105%]`}
                                        dropdownItemsClasses="!p-0 !bg-transparent border-b-[1px] border-b-slate-100"
                                        dropdownOptions={dateOptions}
                                    />
                                </div>

                                <div
                                    onClick={handleExportRevenueSummary}
                                    className="w-8 h-8 rounded shadow-buttonShadow2 border border-slate-200 flex items-center justify-center cursor-pointer"
                                >
                                    <i className="ri-share-forward-box-fill text-base"></i>
                                </div>
                            </div>
                        </div>

                        <div>
                            {revenueMetricsView !== "table" && (
                                <div className="mb-[30px]">
                                    <div className="flex items-center space-x-6 font-rocGroteskMedium">
                                        <div className="flex items-center space-x-2">
                                            <span
                                                className={`w-[14px] h-[14px] rounded-[2px] bg-[#56B648]`}
                                            ></span>
                                            <span className="text-sm">Profit</span>
                                        </div>

                                        <div className="flex items-center space-x-2">
                                            <span
                                                className={`w-[14px] h-[14px] rounded-[2px] bg-p-50`}
                                            ></span>

                                            <span className="text-sm">Revenue</span>
                                        </div>

                                        <div className="flex items-center space-x-2">
                                            <span
                                                className={`w-[14px] h-[14px] rounded-[2px] bg-[#E4281D]`}
                                            ></span>

                                            <span className="text-sm">Cost</span>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {revenueMetricsView === "bar" && (
                                <div className="w-full h-full">
                                    <ReactECharts
                                        option={multiBarChartOption}
                                        style={{ height: 525 }}
                                    />
                                </div>
                            )}

                            {revenueMetricsView === "line" && (
                                <div>
                                    <ReactECharts
                                        option={multilineChartValuesOptions}
                                        style={{ height: 525 }}
                                    />
                                </div>
                            )}
                        </div>

                        {revenueMetricsView === "table" && (
                            <div>
                                <div className="flex space-x-[14px] mb-5 py-1 border-y border-slate-200 font-rocGroteskMedium">
                                    {tableNav?.map((item, idx) => {
                                        return (
                                            <span
                                                onClick={() => setActiveTableNav(idx)}
                                                key={idx}
                                                className={`text-sm text-tradeally-neutral-200 py-2.5 cursor-pointer ${
                                                    activeTableNav === idx &&
                                                    "border-b-[1.5px] border-y-10"
                                                }`}
                                            >
                                                {revenueDateRange?.value === "monthly"
                                                    ? item?.split("-")?.slice(2)?.join(" ")
                                                    : revenueDateRange?.value === "yearly"
                                                    ? item?.split("-")[0]
                                                    : item}
                                            </span>
                                        );
                                    })}
                                </div>
                                <div>
                                    <CustomTable
                                        tableBody={tableBody}
                                        tableHeader={tableHeader as any}
                                        isAllSelectable={false}
                                        headerContainerClass="!bg-slate-50"
                                        tableClass=" w-full"
                                        isScrollable={false}
                                        isCellBordered={true}
                                    />
                                </div>
                            </div>
                        )}

                        {/* <div className="flex justify-center items-center my-16">
                <span className="text-xl font-rocGroteskMedium text-center ">
                    No Data available
                </span>
            </div> */}
                    </div>
                </div>

                <div className="w-[35%] h-[653px] rounded-lg border border-slate-200 py-4 px-[13px]">
                    <div className="flex items-center justify-between pb-[10px] mb-10">
                        <span className="text-base text-slate-500 font-rocGroteskMedium ">
                            Channel summary
                        </span>

                        <div className="flex items-center space-x-1">
                            <Dropdown
                                handleChange={(name, value) => {
                                    handleChannelSalesBrandChangeFilter({
                                        label: value?.split("-")?.[0],
                                        value: value?.split("-")?.[1],
                                    });
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-2.5 px-3 bg-white !h-8 !border !border-slate-100 gap-2">
                                        <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                                        >
                                            {truncate(channelSalesBrand?.label || "All brands", {
                                                length: 10,
                                            })}
                                        </div>
                                    </div>
                                }
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[286px] !w-[200px] !py-0`}
                                name="valueRange"
                                onClose={() => {
                                    setSearchTwo("");
                                    setDebouncedSearchTwo("");
                                    setSearchTwoAssigned("");
                                    setDebouncedSearchTwoAssigned("");
                                }}
                                search={
                                    <div>
                                        <TextInput
                                            value={
                                                profile?.role === "owner" && !profile?.parentId
                                                    ? searchTwo
                                                    : searchTwoAssigned
                                            }
                                            name={"search"}
                                            type={"text"}
                                            required={false}
                                            onChange={(e) =>
                                                profile?.role === "owner" && !profile?.parentId
                                                    ? handleSearchTwo(e)
                                                    : handleSearchTwoAssigned(e)
                                            }
                                            onInput={
                                                profile?.role === "owner" && !profile?.parentId
                                                    ? handleDebouncedChangeTwo
                                                    : handleDebouncedChangeTwoAssigned
                                            }
                                            inputPlaceholder="Search brands"
                                            // containerClassname=""
                                            inputContainerClassname={"!h-[40px]"}
                                            inputClassName="w-full !h-[40px]"
                                        />
                                    </div>
                                }
                                dropdownOptions={[
                                    {
                                        label: (
                                            <div
                                                className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                border-b border-b-slate-100 px-4 py-[10px]`}
                                            >
                                                All brands
                                            </div>
                                        ),
                                        value: ``,
                                    },
                                    ...(profile?.role === "owner" && !profile?.parentId
                                        ? brandList
                                        : assignedBrands
                                    )?.map((brand) => {
                                        let brandItem = brand;
                                        if (profile?.role === "owner" && !profile?.parentId) {
                                            brandItem = brand?.brand;
                                        }

                                        return {
                                            label: (
                                                <div
                                                    className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                    border-b border-b-slate-100 px-4 py-[10px]`}
                                                >
                                                    {brandItem?.brandName}
                                                </div>
                                            ),
                                            value: `${brandItem?.brandName}-${brandItem?._id}`,
                                        };
                                    }),
                                ]}
                            />

                            <Dropdown
                                handleChange={(name, value) => {
                                    handleChannelDurationChange({
                                        label: value?.split("-")?.[0],
                                        value: value?.split("-")?.[1],
                                    });
                                }}
                                dropdown={
                                    <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-2.5 px-3 bg-white !h-8 !border !border-slate-100 gap-2">
                                        <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                                        >
                                            {channelDateRange?.label || "This year"}
                                        </div>
                                    </div>
                                }
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                name="valueRange"
                                dropdownOptions={dateOptions}
                            />

                            <div
                                onClick={handleExportChannelSummary}
                                className="w-8 h-8 rounded shadow-buttonShadow2 border border-slate-200 flex items-center justify-center cursor-pointer"
                            >
                                <i className="ri-share-forward-box-fill text-base"></i>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col space-y-6">
                        <div className="w-full">
                            <div className="relative">
                                <ReactECharts option={channelSummaryPieChartOptions} />
                                <div className="absolute top-[30%] left-0 right-0 m-auto w-[120px] h-[120px] rounded-full font-rocGroteskMedium flex flex-col items-center justify-center bg-white shadow-[0px_4px_15.6px_-4px_#1018281A]">
                                    <span className="block text-[20px] text-g-75 leading-[40px]">
                                        $
                                        {millify(
                                            channelSales?.cumulativeAnalytics?.cumulativeRevenue,
                                            { precision: 2 }
                                        )}
                                    </span>
                                    <span className="block text-[12px] text-slate-500">
                                        Total sales
                                    </span>
                                    <span
                                        className={`text-[10px] px-1.5 py-[2px] rounded-[3px] flex items-center space-x-1 ${
                                            determineTrendDirection(
                                                channelSales?.cumulativeAnalytics
                                                    ?.percentageSalesPerformance
                                            ) === "up"
                                                ? "bg-g-25 text-g-60"
                                                : "text-r-55 bg-r-25"
                                        }`}
                                    >
                                        {determineTrendDirection(
                                            channelSales?.cumulativeAnalytics
                                                ?.percentageSalesPerformance
                                        ) === "up" ? (
                                            <i className="ri-arrow-up-line "></i>
                                        ) : (
                                            <i className="ri-arrow-down-line "></i>
                                        )}
                                        <span>
                                            {channelSales?.cumulativeAnalytics?.percentageSalesPerformance?.toFixed(
                                                1
                                            )}
                                            % MoM
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="space-y-[14px]">
                            {channelSales?.payload?.map((item, idx) => {
                                return (
                                    <div
                                        key={item?._id}
                                        className="flex w-full items-start justify-between font-rocGroteskMedium"
                                    >
                                        <div className="flex items-start space-x-2.5">
                                            <div
                                                className="w-2 h-2 rounded-full mt-[2px]"
                                                style={{ backgroundColor: piechartColors?.[idx] }}
                                            />
                                            <div>
                                                <p className="text-xs text-slate-500">
                                                    {item?._id}
                                                </p>
                                                {maxChannelSales === item?.totalRevenue &&
                                                    channelSalesRevenueArr?.length > 1 && (
                                                        <p className="flex space-x-1">
                                                            <span className="text-[10px] bg-g-50 px-1.5 py-[2px] rounded-[20px] text-white">
                                                                Most sale
                                                            </span>
                                                        </p>
                                                    )}

                                                {minChannelSales === item?.totalRevenue &&
                                                    channelSalesRevenueArr?.length > 1 && (
                                                        <p className="flex space-x-1">
                                                            <span className="text-[10px] bg-slate-500 px-1.5 py-[2px] rounded-[20px] text-white">
                                                                Least sale
                                                            </span>
                                                        </p>
                                                    )}
                                            </div>
                                        </div>

                                        <div className="flex items-center space-x-1.5">
                                            <span className="text-sm">
                                                $
                                                {formatMoney()?.format(
                                                    item?.totalRevenue?.toFixed(2)
                                                )}
                                            </span>
                                            <span
                                                className={`text-[10px] px-1.5 h-[18px] border ${
                                                    determineTrendDirection(
                                                        item?.percentageSalesPerformance
                                                    ) === "up"
                                                        ? "border-g-25 text-g-60"
                                                        : "text-r-55 border-r-25"
                                                }`}
                                            >
                                                {item?.percentageSalesPerformance?.toFixed(1)}%
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FinancialMetrics;
