import React, { useEffect, useRef, useState } from "react";
import Button from "components/common/Button";
import LandingNav from "components/views/Landing/LandingNav";
import BookADemoModal from "components/views/Landing/BookADemoModal";
import LandingFooter from "components/views/Landing/LandingFooter";
import CookiePrompt from "components/views/Landing/CookiePrompt";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { vendorActions } from "redux/Ldb/actions";

const Home = () => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState(0);

    const dispatch = useAppDispatch();
    const { joiningWaitList, joinWaitListSuccess } = useAppSelector((state) => state.vendor);

    const benefits = [
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715122542/chart-bar-fill_dzatxw.svg",
            title: "Sales Intake & Analysis",
            content:
                "Centralize sales in one place and align your demand forecasts with historical and real-time sales data to avoid missed opportunities and overproduction.",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1727959139/Vector_3_e5qrqg.svg",
            title: "Demand Forecast",
            content: "Eliminate the guesswork by accurately predicting future demand.",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1727959168/Vector_4_ybd1q3.svg",
            title: "Production Management & COGS Analysis",
            content:
                "Automate production schedules/orders (for in-house and contract manufacturing), BOMs, batch sizes, and quality, as well as track and trace.",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715122542/barcode-fill_rfiyfb.svg",
            title: "Inventory & PO Management",
            content:
                "Streamline procurement processes and keep your inventory levels optimized by maintaining the right balance between demand and stock.",
        },
    ];

    const integrationsOne = [
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715394621/Frame_1000003476_alrvcy.svg",
            title: "Spreadsheet",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360125/Frame_1000003475_viyltu.svg",
            title: "Amazon",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360125/Frame_1000003479_sfbrih.svg",
            title: "Instagram",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360127/Frame_1000003503_wq2opv.svg",
            title: "Quickbooks",
        },
    ];

    const integrationsTwo = [
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360125/Frame_1000003478_gcmuu3.svg",
            title: "Shopify",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360125/Frame_1000003477_mwh0sx.svg",
            title: "X (Formerly Twitter)",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360125/Frame_1000003474_wb5clu.svg",
            title: "Gmail",
        },
        {
            icon: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360126/Frame_1000003502_cmsz22.svg",
            title: "Tiktok",
        },
    ];

    const feedback = [
        {
            name: "Leilani Alana",
            role: "Production Manager at Kōloa Rum",
            logo: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728011265/Koloa_Logo_1_gj5pi1.svg",
            img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728013114/Avatar_vpdqut.svg",
            text: "werSynth has streamlined our production process at Kōloa Rum, allowing us to automate key tasks and integrate inventory data from multiple sources. It's been particularly useful for improving our raw material forecasting and ensuring we have everything on hand before production starts. We've saved countless hours and resources thanks to the platform's real-time insights and automated purchase order management. Synth has streamlined our production process at Kōloa Rum, allowing us to automate key tasks and integrate inventory data from multiple sources. It's been particularly useful for improving our raw material forecasting and ensuring we have everything on hand before production starts. We've saved countless hours and resources thanks to the platform's real-time insights and automated purchase order management.",
        },
        {
            name: "Leilani Alana",
            role: "Production Manager at Kōloa Rum",
            logo: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728011265/Koloa_Logo_1_gj5pi1.svg",
            img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728013114/Avatar_vpdqut.svg",
            text: "wassSynth has streamlined our production process at Kōloa Rum, allowing us to automate key tasks and integrate inventory data from multiple sources. It's been particularly useful for improving our raw material forecasting and ensuring we have everything on hand before production starts. We've saved countless hours and resources thanks to the platform's real-time insights and automated purchase order management. Synth has streamlined our production process at Kōloa Rum, allowing us to automate key tasks and integrate inventory data from multiple sources. It's been particularly useful for improving our raw material forecasting and ensuring we have everything on hand before production starts. We've saved countless hours and resources thanks to the platform's real-time insights and automated purchase order management.",
        },
        {
            name: "Leilani Alana",
            role: "Production Manager at Kōloa Rum",
            logo: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728011265/Koloa_Logo_1_gj5pi1.svg",
            img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728013114/Avatar_vpdqut.svg",
            text: "3Synth has streamlined our production process at Kōloa Rum, allowing us to automate key tasks and integrate inventory data from multiple sources. It's been particularly useful for improving our raw material forecasting and ensuring we have everything on hand before production starts. We've saved countless hours and resources thanks to the platform's real-time insights and automated purchase order management. Synth has streamlined our production process at Kōloa Rum, allowing us to automate key tasks and integrate inventory data from multiple sources. It's been particularly useful for improving our raw material forecasting and ensuring we have everything on hand before production starts. We've saved countless hours and resources thanks to the platform's real-time insights and automated purchase order management.",
        },
        {
            name: "Leilani Alana",
            role: "Production Manager at Kōloa Rum",
            logo: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728011265/Koloa_Logo_1_gj5pi1.svg",
            img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728013114/Avatar_vpdqut.svg",
            text: "4Synth has streamlined our production process at Kōloa Rum, allowing us to automate key tasks and integrate inventory data from multiple sources. It's been particularly useful for improving our raw material forecasting and ensuring we have everything on hand before production starts. We've saved countless hours and resources thanks to the platform's real-time insights and automated purchase order management. Synth has streamlined our production process at Kōloa Rum, allowing us to automate key tasks and integrate inventory data from multiple sources. It's been particularly useful for improving our raw material forecasting and ensuring we have everything on hand before production starts. We've saved countless hours and resources thanks to the platform's real-time insights and automated purchase order management.",
        },
        {
            name: "Leilani Alana",
            role: "Production Manager at Kōloa Rum",
            logo: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728011265/Koloa_Logo_1_gj5pi1.svg",
            img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728013114/Avatar_vpdqut.svg",
            text: "5Synth has streamlined our production process at Kōloa Rum, allowing us to automate key tasks and integrate inventory data from multiple sources. It's been particularly useful for improving our raw material forecasting and ensuring we have everything on hand before production starts. We've saved countless hours and resources thanks to the platform's real-time insights and automated purchase order management. Synth has streamlined our production process at Kōloa Rum, allowing us to automate key tasks and integrate inventory data from multiple sources. It's been particularly useful for improving our raw material forecasting and ensuring we have everything on hand before production starts. We've saved countless hours and resources thanks to the platform's real-time insights and automated purchase order management.",
        },
        {
            name: "Leilani Alana",
            role: "Production Manager at Kōloa Rum",
            logo: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728011265/Koloa_Logo_1_gj5pi1.svg",
            img: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728013114/Avatar_vpdqut.svg",
            text: "6Synth has streamlined our production process at Kōloa Rum, allowing us to automate key tasks and integrate inventory data from multiple sources. It's been particularly useful for improving our raw material forecasting and ensuring we have everything on hand before production starts. We've saved countless hours and resources thanks to the platform's real-time insights and automated purchase order management. Synth has streamlined our production process at Kōloa Rum, allowing us to automate key tasks and integrate inventory data from multiple sources. It's been particularly useful for improving our raw material forecasting and ensuring we have everything on hand before production starts. We've saved countless hours and resources thanks to the platform's real-time insights and automated purchase order management.",
        },
    ];

    const features = [
        // {
        //     tab: "Multi-brand management",
        //     videoSrc:
        //         "https://res.cloudinary.com/dvxi7qcmd/video/upload/q_auto/v1715768193/Centralize_inventory_in_one_place_xcpk2a.mp4",
        //     poster: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715392715/Centralize_inventory_in_one_place_d8aabn.svg",
        //     miniPoster:
        //         "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715604735/Centralize_inventory_in_one_place_1_aj0b0r.png",
        //     zoomBy: 1.5,
        //     bottomValue: -160,
        //     color: "bg-[#013D28]",
        // },
        {
            tab: "Centralized sales data",
            videoSrc:
                "https://res.cloudinary.com/dvxi7qcmd/video/upload/q_auto/v1728301879/Centralized_sales_data_gbnpz1.mp4",
            poster: "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1728066886/sales_bic0sz.png",
            miniPoster:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1728066886/sales_bic0sz.png",
            zoomBy: 3,
            bottomValue: -330,
            color: "bg-[#FD700B]",
        },
        {
            tab: "Demand forecasting",
            videoSrc:
                "https://res.cloudinary.com/dvxi7qcmd/video/upload/q_auto/v1728301246/Demand_Forecasting_fjjjqq.mp4",
            poster: "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1728067206/demandForecast_r9iz5r.png",
            miniPoster:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1728067206/demandForecast_r9iz5r.png",
            color: "bg-[#E4281D]",
        },
        {
            tab: "Production optimization",
            videoSrc:
                "https://res.cloudinary.com/dvxi7qcmd/video/upload/q_auto/v1728302120/Production_optimization_qbjoa2.mp4",
            poster: "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1728066071/production_jwqi0i.png",
            miniPoster:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1728066071/production_jwqi0i.png",
            zoomBy: 2.2,
            bottomValue: -270,
            color: "bg-[#FFB902]",
        },
        {
            tab: "COGS insights",
            videoSrc:
                "https://res.cloudinary.com/dvxi7qcmd/video/upload/q_auto/v1728302095/COGS_Insights_pqo1cw.mp4",
            poster: "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1728066335/cog_zzekke.png",
            miniPoster:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1728066335/cog_zzekke.png",
            zoomBy: 2.2,
            bottomValue: -270,
            color: "bg-[#A8C10A]",
        },
        {
            tab: "Automated purchase orders",
            videoSrc:
                "https://res.cloudinary.com/dvxi7qcmd/video/upload/q_auto/v1728302203/Automated_Purchase_Order_xprqwf.mp4",
            poster: "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1728066027/automatedPurchaseOrder_uwp8wf.png",
            miniPoster:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1728066027/automatedPurchaseOrder_uwp8wf.png",
            zoomBy: 2.2,
            bottomValue: -270,
            color: "bg-[#6562E5]",
        },
    ];

    const whoWeHelp = [
        {
            image: "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715379559/Diverse_Team_Partnership_Celebration_1_ejmlex.png",

            title: "CPG Aggregators",
        },
        {
            image: "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715379556/image_4_1_g9odqm.png",
            title: "Finance",
        },
        {
            image: "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715379556/image_3_1_kjtndy.png",
            title: "Supply Chain & Operations",
        },
        {
            image: "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715379432/image_5_1_wibomc.png",
            title: "Founder/CEOs",
        },
    ];

    const brandsSpotLight = [
        {
            productImage:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733142876/PLP-PremiumHSS-web_1_-_Esther_Hong_vroh9z.jpg",
            productLink: "https://holdonbags.com/products/household-essentials-set",
            productName: "Premium Household Staples Set",
            brand: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1722524137/HoldOn_Logos_1_njqily.svg",
            brandName: "Holdon bags",
            smallLogo: true,
        },
        {
            productImage:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733143126/DogBag-SINGLE-Image02-updated-web_2_-_Esther_Hong_pz8hjz.jpg",
            productLink: "https://holdonbags.com/products/compostable-pet-waste-bags",
            productName: "Dog Poop Bags",
            brand: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1722524137/HoldOn_Logos_1_njqily.svg",
            brandName: "Holdon bags",
            smallLogo: true,
        },
        {
            productImage:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733144455/Rum-Coffee-750ML_-_Koloa_Rum_b4uurt.png",
            productLink: "https://koloarum.com/rums/",
            productName: "Koloa Kauai Coffee Rum",
            brand: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728011265/Koloa_Logo_1_gj5pi1.svg",
            brandName: "Koloa rum",
            noBg: true,
        },
        {
            productImage:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733144461/Rum-Coconut-750ml_-_Koloa_Rum_b733kw.png",
            productLink: "https://koloarum.com/rums/",
            productName: "Koloa Kauai Coconut Rum",
            brand: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728011265/Koloa_Logo_1_gj5pi1.svg",
            brandName: "Koloa rum",
            noBg: true,
        },
        //Awkward Essentials
        {
            productImage:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733145621/Awk_DripStick_vr9nlb.webp",
            productLink: "https://awkwardessentials.com/products/dripstick?selling_plan=3538387095",
            productName: "Dripstick -The Cum Sponge",
            brand: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1730473392/nameless_cpg_vexnl3.svg",
            brandName: "Nameless cpg",
            noBg: true,
        },
        {
            productImage:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733145621/Awk_DripStick_-_Eric_Almaraz_t9qoxs.webp",
            productLink:
                "https://awkwardessentials.com/products/mainstream?variant=41269178597527&selling_plan=8762065047",
            productName: "Mainstream - Pee Funnel + TP",
            brand: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1730473392/nameless_cpg_vexnl3.svg",
            brandName: "Nameless cpg",
            noBg: false,
        },
        //Ladybird Provisions
        {
            productImage:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733145920/LBP_Vanilla_-_Eric_Almaraz_aqm4uc.webp",
            productLink:
                "https://ladybirdprovisions.com/collections/shop/products/vanilla-cinnamon-coffee-bombs",
            productName: "Vanilla Cinnamon Coffee Bombs",
            brand: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1730473392/nameless_cpg_vexnl3.svg",
            brandName: "Nameless cpg",
            noBg: false,
        },
        {
            productImage:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733145920/LBP_Cacao_-_Eric_Almaraz_jfzvkl.webp",
            productLink:
                "https://ladybirdprovisions.com/collections/shop/products/happy-cacao-4-pack-coffee-bombs",
            productName: "Happy Cacao Coffee Bombs",
            brand: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1730473392/nameless_cpg_vexnl3.svg",
            brandName: "Nameless cpg",
            noBg: false,
        },
        //Wile
        {
            productImage:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733146090/Wile_Peri_-_Eric_Almaraz_me3vqx.jpg",
            productLink:
                "https://wilewomen.com/products/perimenopause-support-supplement?selling_plan=3112566997&variant=40179378356382",
            productName: "Perimenopause Support",
            brand: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1730473392/nameless_cpg_vexnl3.svg",
            brandName: "Nameless cpg",
            noBg: true,
        },
        {
            productImage:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733146090/Wile_Stress_-_Eric_Almaraz_mkworx.jpg",
            productLink:
                "https://wilewomen.com/products/womens-stress-herbal-supplement?selling_plan=3112566997&variant=40577065451678",
            productName: "Women's Stress",
            brand: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1730473392/nameless_cpg_vexnl3.svg",
            brandName: "Nameless cpg",
            noBg: true,
        },
        //Everly
        {
            productImage:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733146325/Everly_GH_-_Eric_Almaraz_vr8i09.webp",
            productLink:
                "https://drinkeverly.com/collections/grape/products/grape-caffeine-free?variant=29483777196120",
            productName: "Grape Hydration",
            brand: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1730473392/nameless_cpg_vexnl3.svg",
            brandName: "Nameless cpg",
            noBg: true,
        },
        {
            productImage:
                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733146325/Everly_PMH_-_Eric_Almaraz_gtzs46.webp",
            productLink:
                "https://drinkeverly.com/collections/peach-mango/products/peach-mango-hydration?variant=42640555999460&selling_plan=3785851108",
            productName: "Peach Mango Hydration",
            brand: "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1730473392/nameless_cpg_vexnl3.svg",
            brandName: "Nameless cpg",
            noBg: true,
        },
    ];

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleBookADemo = () => {
        const data = {};
        dispatch(vendorActions.joinWaitlist(data));
    };

    useEffect(() => {
        if (Boolean(joinWaitListSuccess)) {
            dispatch(vendorActions.resetJoinWaitListSuccess());
            window.open("https://calendly.com/lloyd-synthally/30min", "_self");
        }
    }, [joinWaitListSuccess, dispatch]);

    const scrollToImage = (index: number) => {
        const container = document.querySelector(".featureContainer") as HTMLElement;
        const image = container.children[index] as HTMLElement;
        const containerWidth = container?.offsetWidth;
        const imageWidth = image.offsetWidth;
        const scrollLeft = image.offsetLeft - (containerWidth - imageWidth) / 2;

        const tabContainer = document.querySelector(".tabsContainer") as HTMLElement;
        const tab = tabContainer.children[index] as HTMLElement;
        const tabContainerWidth = tabContainer?.offsetWidth;
        const tabWidth = tab.offsetWidth;
        const tabScrollLeft = tab.offsetLeft - (tabContainerWidth - tabWidth) / 2;

        setSelectedFeature(index);

        tabContainer.scrollTo({
            left: tabScrollLeft,
            behavior: "smooth",
        });

        setSelectedFeature(index);

        container.scrollTo({
            left: scrollLeft - 90,
            behavior: "smooth",
        });
    };

    let slideIdx = 1;

    const slideShow = (n) => {
        const slides = document.querySelectorAll<HTMLElement>(".slides");
        const control = document.querySelectorAll<HTMLElement>(".controls");
        if (slides?.length > 1 && control?.length > 1) {
            if (n > slides?.length) {
                slideIdx = 1;
            } else if (n < 1) {
                slideIdx = slides?.length;
            } else {
                slideIdx = n;
            }
            slides.forEach((slide) => {
                slide.classList.remove("active", "exit");
            });

            if (slideIdx > 1) {
                slides[slideIdx - 2].classList.add("exit");
            } else {
                slides[slides.length - 1].classList.add("exit");
            }

            slides[slideIdx - 1].classList.add("active");

            control.forEach((ctrl) => {
                ctrl.style.backgroundColor = "#EADFC1";
            });
            control[slideIdx - 1].style.backgroundColor = "#FFB902";
        }
    };

    slideShow(slideIdx);

    const slide = (n) => {
        slideShow(n);
    };

    const slideControl = (n) => {
        slideShow((slideIdx += n));
    };

    const sliderTrackRef = useRef(null);

    useEffect(() => {
        const sliderTrack = sliderTrackRef.current;

        if (sliderTrack) {
            const handleMouseEnter = () => {
                sliderTrack.style.animationPlayState = "paused";
            };

            const handleMouseLeave = () => {
                sliderTrack.style.animationPlayState = "running";
            };

            sliderTrack.addEventListener("mouseenter", handleMouseEnter);
            sliderTrack.addEventListener("mouseleave", handleMouseLeave);

            // Cleanup event listeners on component unmount
            return () => {
                sliderTrack.removeEventListener("mouseenter", handleMouseEnter);
                sliderTrack.removeEventListener("mouseleave", handleMouseLeave);
            };
        }
    }, []);

    useEffect(() => {
        // Check if the URL contains a hash
        const hash = window.location.hash;
        const pathname = window.location.pathname;

        if (hash) {
            // Remove the '#' symbol from the hash
            const elementId = hash.substring(1);
            // Find the element by its ID
            const targetElement = document.getElementById(elementId);
            if (targetElement) {
                // Scroll to the target element
                targetElement.scrollIntoView({ behavior: "smooth" });
            }
        }

        if (pathname === "/wait-list") {
            setOpenModal(true);
            navigate("/", { replace: true });
        }
    }, []);

    return (
        <div className={`relative bg-[#FFFBEB]`}>
            <LandingNav />

            {/* h-[861px] max-lg:h-[451px] */}
            <section className={`  w-screen flex justify-center`}>
                <div className="w-[86%]">
                    <div>
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715106662/landing_right_vector_mcj7ud.svg"
                            alt="right vector"
                            className="right-0 top-[100px] absolute max-lg:hidden"
                        />
                    </div>
                    <div className="mt-[158px] text-center max-lg:mt-[32px] max-lg:w-full px-6 relative z-10 flex flex-col items-center">
                        <h1 className="text-[72px] max-lg:text-[40px] text-green-2 max-lg:leading-[48px] leading-[86.4px] font-flame mb-3">
                            <span className="!font-normal font-instrumentSeirf"> Finally,</span>{" "}
                            Unified Forecasting and Production Management for CPG
                        </h1>
                        <p className="text-xl w-[50%] text-green-2 max-lg:text-base font-interDisplay max-xl:w-full px-10 max-lg:px-2 mb-8">
                            All your essential supply chain tools in one AI-powered platform for
                            DTC, Wholesale & Multi-brand operators.
                        </p>

                        <div className="flex items-center mb-6">
                            <Button
                                btnText={"Book a Demo"}
                                btnClassname={
                                    "!w-fit !h-[48px] !text-base !rounded-md !px-6 !font-flame !shadow-[0px_-3px_0px_0px_#0000001A_inset] !text-[#013D28]"
                                }
                                isLoading={joiningWaitList}
                                onClick={handleBookADemo}
                            />
                        </div>
                        <div className="flex items-center space-x-1.5 max-lg:hidden">
                            <span className="text-base font-flame">Backed by:</span>
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1710501233/techstars_logo_qfwj0t.svg"
                                alt="techstars"
                                className="w-[110.43px] h-[19.71px]"
                            />
                        </div>
                    </div>
                    <div className="mt-[90px]  max-lg:mt-[44px]">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715106662/landing_left_vector_mnvgft.svg"
                            alt="left vector"
                            className="left-0 top-[372px] absolute max-lg:hidden"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715278569/mobile_landing_left_vector_bkhzqi.svg"
                            alt="left vector"
                            className="left-0 top-[500px] 2xs:top-[450px] md:top-[350px] absolute hidden max-lg:block"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715278570/mobile_landing_right_vector_wwlrep.svg"
                            alt="right vector"
                            className="top-[600px] right-0 2xs:top-[530px] md:top-[495px] absolute hidden max-lg:block"
                        />
                        <div className="flex justify-center mt-[-40px] 2xs:mt-[-20px]  relative z-10 ">
                            <div className="bg-white cursor-pointer w-full shrink-0 md:block hidden rounded-[24px] h-[400px] lg:h-[580px] xl:h-[750px] overflow-hidden relative ">
                                <video
                                    poster={
                                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1728067206/demandForecast_r9iz5r.png"
                                    }
                                    src={
                                        "https://res.cloudinary.com/dvxi7qcmd/video/upload/v1728301246/Demand_Forecasting_fjjjqq.mp4"
                                    }
                                    playsInline
                                    controls={false}
                                    autoPlay
                                    controlsList="nodownload"
                                    muted
                                    loop
                                    width={"100%"}
                                    className={`z-10 cursor-pointer  bg-white !border-none  object-cover`}
                                    style={{
                                        // zoom: feature.zoomBy ? feature.zoomBy : 1,
                                        // bottom: feature?.bottomValue,
                                        left: 0,
                                        right: 0,
                                    }}
                                />
                            </div>
                            <div className="overflow-hidden  block md:hidden ">
                                <video
                                    poster={
                                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1728067206/demandForecast_r9iz5r.png"
                                    }
                                    src={
                                        "https://res.cloudinary.com/dvxi7qcmd/video/upload/v1728301246/Demand_Forecasting_fjjjqq.mp4"
                                    }
                                    playsInline
                                    controls={false}
                                    autoPlay
                                    controlsList="nodownload"
                                    muted
                                    loop
                                    className={`z-10 cursor-pointer  object-cover !border-none overflow-hidden block md:hidden   `}
                                    style={{
                                        // zoom: 3,
                                        // position: "absolute",
                                        bottom: 5,
                                        left: 0,
                                        right: 0,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="flex justify-center  px-6 relative text-center mt-[105px] lg:mt-[150px]">
                <div className=" flex flex-col items-center text-center max-lg:w-full mb-8 max-lg:mb-[36px] relative">
                    <h4 className="w-[100%] lg:w-[86%] text-[32px] max-lg:leading-[38.4px] font-flame mb-8 text-green-2">
                        Don't just take our word for it—brands are transforming their operations to
                        save time, reduce waste and scale smarter on Synth
                    </h4>
                    <div className="flex items-center justify-center overflow-hidden w-full sliderT ">
                        <div
                            ref={sliderTrackRef}
                            className="slider-track md:flex items-center gap-[46px] grid grid-cols-2 justify-center"
                        >
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715278009/Eat_Omega_3_Logo_jqjspo.svg"
                                alt="eat omega 3"
                                className="w-[144px] h-[60px] lg:mb-8"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1722524194/FATCO_Logo_umkpjn.svg"
                                alt="fatco"
                                className="w-[131px] h-[37px] lg:mb-8"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715278009/Green_Regimen_Logo_incyoe.svg"
                                alt="green regimen"
                                className="w-[74px] h-[76.64px]"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1722524137/HoldOn_Logos_1_njqily.svg"
                                alt="holdon bag"
                                className="w-[153px] h-[37px] lg:mb-8"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728011265/Koloa_Logo_1_gj5pi1.svg"
                                alt="koloa rum"
                                className="w-[154.84px] lg:mb-8"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1730473392/nameless_cpg_vexnl3.svg"
                                alt="nameless cpg"
                                className="w-[70px] h-[70px] lg:mb-8"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1730473392/54_thrones_n5iobg.svg"
                                alt="54 thrones"
                                className="w-[240px] h-[30px] lg:mb-8"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715278012/Vitavate_Logo_caujhs.svg"
                                alt="vitavate logo"
                                className="w-[154.84px] h-[40px] lg:mb-8"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1722524138/Yellow-V2_2_bounces_1_hjnzof.svg"
                                alt="boobcube logo"
                                className="w-[183.62px] h-[67.22] lg:mb-8 max-md:relative max-md:left-[70%]"
                            />

                            {/* Duplicate the logos for continuous scrolling */}

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715278009/Eat_Omega_3_Logo_jqjspo.svg"
                                alt="eat omega 3 logo"
                                className="w-[144px] h-[60px] lg:mb-8 max-md:hidden"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1722524194/FATCO_Logo_umkpjn.svg"
                                alt="fatco logo"
                                className="w-[131px] h-[37px] lg:mb-8  max-md:hidden"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733853622/wile_nqncbx.png"
                                alt="wile"
                                className="w-[131px] h-[37px] lg:mb-8"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733853622/everly_fvnubu.png"
                                alt="everly"
                                className="w-[128px] h-[37px] lg:mb-8"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733855534/awkward_essentials_u0z0lq.png"
                                alt="awkward essentials"
                                className="w-[160px] h-[70px] lg:mb-8"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1733855534/ladybird_provisions_jol2ns.png"
                                alt="ladybird provisions"
                                className="w-[76px] h-[74px] lg:mb-8"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715278009/Green_Regimen_Logo_incyoe.svg"
                                alt="green regimen logo"
                                className="w-[74px] h-[76.64px] max-md:hidden"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1722524137/HoldOn_Logos_1_njqily.svg"
                                alt="holdon logo"
                                className="w-[153px] h-[37px] lg:mb-8  max-md:hidden"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728011265/Koloa_Logo_1_gj5pi1.svg"
                                alt="koloa rum"
                                className="w-[154.84px]  lg:mb-8 max-md:hidden"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1730473392/nameless_cpg_vexnl3.svg"
                                alt="nameless cpg"
                                className="w-[70px] h-[70px] lg:mb-8 max-md:hidden"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1730473392/54_thrones_n5iobg.svg"
                                alt="54 thrones"
                                className="w-[240px] h-[30px] lg:mb-8 max-md:hidden"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715278012/Vitavate_Logo_caujhs.svg"
                                alt="vitavate logo"
                                className="w-[154.84px] h-[40px] lg:mb-8  max-md:hidden"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1722524138/Yellow-V2_2_bounces_1_hjnzof.svg"
                                alt="boobcube logo"
                                className="w-[183.62px] h-[67.22] lg:mb-8  max-md:hidden"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715278009/Eat_Omega_3_Logo_jqjspo.svg"
                                alt="eat omega 3 logo"
                                className="w-[144px] h-[60px] lg:mb-8 max-md:hidden"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1722524194/FATCO_Logo_umkpjn.svg"
                                alt="fatco logo"
                                className="w-[131px] h-[37px] lg:mb-8 max-md:hidden"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715278009/Green_Regimen_Logo_incyoe.svg"
                                alt="green regimen logo"
                                className="w-[74px] h-[76.64px] max-md:hidden"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1722524137/HoldOn_Logos_1_njqily.svg"
                                alt="holdon logo"
                                className="w-[153px] h-[37px] lg:mb-8 max-md:hidden"
                            />

                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728011265/Koloa_Logo_1_gj5pi1.svg"
                                alt="koloa rum"
                                className="w-[154.84px] lg:mb-8 max-md:hidden"
                            />

                            {/* <img
                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1717146303/Krack_d_Snacks_1_jznqjv.svg"
                alt="krack'd snacks logo"
                className="w-[155.1px] h-[74px] lg:mb-8  max-md:hidden"
            />
        
       
            <img
                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1722524137/Wear_Oya_Logo_pwumnv.svg"
                alt="oya logo"
                className="w-[90px] h-[54.38px] lg:mb-8  max-md:hidden"
            /> */}
                        </div>
                    </div>
                </div>
            </section>

            <section
                id="benefits"
                className="mt-[120px] mb-[127px] max-lg:mt-[100px] relative flex justify-center"
            >
                <div className="w-[86%]">
                    <div className="mb-16">
                        <h4 className=" text-base lg:text-xl font-flame text-orange-1 mb-4">
                            Benefits
                        </h4>
                        <h5 className="text-[36px] lg:text-[46px] max-lg:text-[36px] font-flame leading-[43px] lg:leading-[55px] max-lg:leading-[43.2px] text-green-2">
                            Brands add $1,262,068 worth of inventory every week to make Synth their
                            one source of truth to save time and improve efficiency.
                        </h5>
                    </div>

                    <div className="grid lg:grid-cols-2 gap-6 lg:gap-12 ">
                        {benefits.map((benefit) => (
                            <div key={benefit.title}>
                                <div className="mb-6">
                                    <img src={benefit.icon} alt="chart icon" className="w-8 h-8" />
                                </div>
                                <div className="text-green-2">
                                    <span className="block text-base md:text-[18px] md:leading-[21.6px] font-flame mb-2">
                                        {benefit.title}
                                    </span>
                                    <p className="md:text-base text-sm font-interDisplay">
                                        {benefit.content}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className=" mb-[145px] max-sm:mt-[100px] relative flex justify-center">
                <div className="md:w-[86%] md:pl-0 pl-10 w-[100%]">
                    <div className="mb-16">
                        <h4 className=" text-base lg:text-xl font-flame text-orange-1 mb-4">
                            Who Synth is for
                        </h4>
                        <h5 className="text-[36px] lg:text-[48px] font-flame leading-[43px] lg:leading-[57.6px] max-lg:text-[36px] max-lg:leading-[43.2px] text-green-2">
                            Designed for CPG operators
                        </h5>
                    </div>
                    <div className="md:hidden flex shrink-0 overflow-auto space-x-8">
                        {whoWeHelp.map((who, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className="w-[359px] relative shrink-0 rounded-[16px] h-[359px]"
                                >
                                    <img
                                        src={who.image}
                                        alt={who.title}
                                        className="w-full h-full rounded-[24px] object-cover"
                                        crossOrigin="anonymous"
                                    />
                                    <div className="pl-6 pb-8 flex items-end w-full h-[43.3%] absolute rounded-b-[24px] bottom-0 bg-gradient-to-t from-[#000000] from-[0.25%] via-[rgba(0, 0, 0, 0.254518)] via-[68.23%] to-[rgba(0, 0, 0, 0)] to-[92.15%]">
                                        <span className="text-[22px] font-interDisplay text-white">
                                            {who.title}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="md:grid  grid-cols-2 gap-6 hidden">
                        {whoWeHelp.map((who, idx) => {
                            return (
                                <div key={idx} className="relative rounded-[16px] h-[359px]">
                                    <img
                                        src={who.image}
                                        alt={who.title}
                                        className="w-full h-full rounded-[24px] object-cover"
                                        crossOrigin="anonymous"
                                    />
                                    <div className="pl-6 pb-8 flex items-end w-full h-[43.3%] absolute rounded-b-[24px] bottom-0 bg-gradient-to-t from-[#000000] from-[0.25%] via-[rgba(0, 0, 0, 0.254518)] via-[68.23%] to-[rgba(0, 0, 0, 0)] to-[92.15%]">
                                        <span className="text-[22px] font-interDisplay text-white">
                                            {who.title}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            <section className="flex justify-center mb-[128px] max-lg:mb-[100px]">
                <div className="w-[86%] max-lg:w-full max-lg:rounded-none bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715180536/be_zen_background_z7g7ue.png')] max-lg:bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715267196/Background_1_em4g2w.png')] bg-cover bg-no-repeat rounded-[32px] flex justify-center items-center py-[56px] bg-green-2">
                    <div className="md:w-[60%] w-[91%] text-center">
                        <h4 className=" text-xl font-flame text-orange-1 mb-4">Be Zen</h4>
                        <p className="text-[32px] lg:text-[40px] leading-[38px] lg:leading-[48px] font-flame text-white">
                            Synth rolls a bunch of essential supply chain tools into one in a really
                            cool way and helps you cut costs and speed past your competition.
                        </p>
                    </div>
                </div>
            </section>

            {/* <section className="flex justify-center mb-[150px] md:mb-[170px]">
                <div className="text-center w-[75.8%]">
                    <span className="text-[48px] max-lg:text-[36px] max-lg:leading-[43.2px] block font-flame mb-2 text-green-2">
                        Save time, make money.
                    </span>
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715183262/250_msih7z.svg"
                        alt="250 dollars"
                        className="mx-auto"
                    />
                    <span className="text-[28px] max-lg:text-[24px] mt-2 max-lg:leading-[28.8px] block font-flame mb-8 text-green-2">
                        Average revenue saved per hour on Synth
                    </span>
                    <p className="text-[48px] max-lg:text-[24px] block font-flame mb-8 leading-[57.6px] max-lg:leading-[28.8px] text-green-2">
                        For every hour saved by using Synth, emerging brands generate an additional
                        $250 in revenue.
                    </p>
                    <div className="flex justify-center ">
                        <Button
                            btnText={"Book a Demo"}
                            btnClassname={
                                "!w-fit !h-[48px] !text-base !rounded-md !px-6 !font-flame !shadow-[0px_-3px_0px_0px_#0000001A_inset]"
                            }
                                isLoading={joiningWaitList}
                            onClick={handleBookADemo}
                        />
                    </div>
                </div>
            </section> */}

            {/* 
            <section id="feedback" className="flex justify-center overflow-x-hidden">
                <div className="w-[86%]">
                    <div className="mb-8">
                        <h4 className=" text-xl font-flame text-orange-1 mb-4">Customer feedback</h4>
                        <div className="flex items-center justify-between">
                        <h5 className="text-[48px] max-lg:text-[36px] max-lg:leading-[43.2px] font-flame leading-[57.6px] text-green-2">
                            What our customers are saying about Synth
                        </h5>
                        <div className="flex items-center gap-4">
                        <div className="rounded-full w-10 h-10 bg-[#FFB903] flex items-center justify-center cursor-pointer"
                        onClick={() => slideControl(-1)}>
                            <i className="ri-arrow-left-line  text-g-75 text-md"></i>
                        </div>
                        <div className="rounded-full w-10 h-10 bg-[#FFB903] flex items-center justify-center cursor-pointer"
                        onClick={() => slideControl(1)}>
                            <i className="ri-arrow-right-line  text-g-75 text-md"></i>
                        </div>
                        </div>
                        </div>
                    </div>

                    <div className="flex gap-3 mb-6 ">
                        {feedback?.map((item , idx) => {
                            return(
                                <div className={`w-[192px] h-[4px] rounded-[4px]  cursor-pointer transition-all text-green-2 controls ${idx === 0 ? "bg-[#FFB902]" : "bg-[#EADFC1]"}`}
                                onClick={() => slide(idx+1)}></div>
                            )
                        })}
                    </div>
                    <div className="relative h-[1000px] 2xs:h-[700px] xs:h-[600px]  md:h-[500px]  lg:h-[400px] overflow-hidden">
                        {
                            feedback?.map((item, idx) => {
                                return (
                                    <div className={`${idx === 0 && "active"}  slides `}>
                                    <div className="">

                                        <p className="font-flame text-lg leading-[26px]">
                                        {item?.text}
                                        </p>
                                    </div>

                                    <div className="flex justify-between items-center  mt-8 ">
                                        <div className="flex gap-4 items-center">
                                            <img src={item?.img} alt="" className="w-[48px] h-[48px] rounded-full" />
                                            <div className="">
                                                <p className="text-base text-[#013D28] font-interDisplay !font-semibold">{item?.name}</p>
                                                <p className="text-base text-[#013D28] font-interDisplay !font-semibold">{item?.role}</p>
                                            </div>
                                        </div>
                                        <div className="max-lg:hidden">
                                            <img src={item?.logo} alt="" className="" />
                                        </div>

                                    </div>

                                    </div>
                                )
                            })
                        }
                    </div>

                    
                </div>
            </section> */}

            <section
                id="features"
                className="mb-[80px] lg:mb-[128px] flex justify-center lg:justify-end"
            >
                <div className="lg:w-[93%] w-[86%]">
                    <div className="mb-4">
                        <h4 className="text-base lg:text-xl font-flame text-orange-1 mb-4">
                            Features
                        </h4>
                        <h5 className="text-[48px] max-lg:text-[36px] max-lg:leading-[43.2px] font-flame leading-[57.6px] text-green-2">
                            Enabling excellence in complex CPG operations
                        </h5>
                    </div>

                    <div className="lg:block hidden">
                        <div className="flex tabsContainer items-start space-x-4 mb-10 overflow-auto pr-6">
                            {features.map((feature, idx) => (
                                <span
                                    key={idx}
                                    onClick={() => {
                                        scrollToImage(idx);
                                    }}
                                    className={`text-[18px] font-flame text-green-2 pb-3 cursor-pointer shrink-0 ${
                                        idx === selectedFeature && "border-b-[3px] border-y-10"
                                    }`}
                                >
                                    {feature.tab}
                                </span>
                            ))}
                        </div>
                        <div className="flex featureContainer overflow-auto space-x-8 pb-8 pl-4 pr-10">
                            {features.map((feature, idx) => {
                                return (
                                    <div
                                        key={feature.tab}
                                        onMouseEnter={() => scrollToImage(idx)}
                                        className="bg-white cursor-pointer flex shadow-cardBoxShadow-4 items-end shrink-0 rounded-[24px] h-[550px] overflow-hidden relative"
                                    >
                                        <video
                                            poster={feature.poster}
                                            src={feature.videoSrc}
                                            playsInline
                                            controls={false}
                                            autoPlay
                                            controlsList="nodownload"
                                            muted
                                            loop
                                            className={`z-10 cursor-pointer  object-cover  w-full h-full `}
                                            style={{
                                                left: 0,
                                                right: 0,
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className=" featureContainer lg:hidden block h-full space-y-4">
                        {features.map((feature, idx) => {
                            return (
                                <div
                                    key={feature.tab}
                                    onMouseEnter={() => scrollToImage(idx)}
                                    className=" cursor-pointer w-full  "
                                >
                                    <div
                                        className={`${feature.color} w-full px-2 pt-2 pb-2 h-fit rounded-tr-[12px] rounded-tl-[12px]`}
                                    >
                                        <video
                                            poster={feature.poster}
                                            src={feature.videoSrc}
                                            playsInline
                                            controls={false}
                                            autoPlay
                                            controlsList="nodownload"
                                            muted
                                            loop
                                            className={`z-10 cursor-pointer w-[100%]  h-full !rounded-none `}
                                            style={{
                                                bottom: feature?.bottomValue,
                                            }}
                                        />
                                    </div>
                                    <div className="bg-white py-5 px-3 rounded-br-[12px] shadow-cardBoxShadow-4  rounded-bl-[12px]">
                                        <p className="text-[#013D28] leading-[24px] text-[20px] font-flame ">
                                            {feature.tab}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            <section className="mb-[80px] md:mb-[128px] flex justify-center">
                <div className="w-[86%] flex flex-col items-center text-center max-lg:w-full max-lg:px-5 mb-8 max-lg:mb-[36px] relative">
                    <h4 className="text-[32px] max-lg:text-xl max-lg:leading-[24px] font-flame mb-8 text-green-2">
                        Built and powered with the world's best modern technologies
                    </h4>
                    <div className="flex items-center space-x-[30px] xl:space-x-[56px] max-lg:space-x-0 max-lg:gap-x-6 max-lg:gap-y-12 max-lg:grid max-lg:grid-cols-2">
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715182973/Google_Gemini_logo_1_ln4qtn.svg"
                            alt="gemini"
                            className="w-[93px] h-[38.25px]"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715108145/Netlify_logo_1_tcd5xq.svg"
                            alt="netlify"
                            className="w-[110.25px] h-[30px]"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715182973/MongoDB_SlateBlue_touo0c.svg"
                            alt="mongo db"
                            className="w-[119px] h-[30px]"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715108143/cld-main-logo_pixqoi.svg"
                            alt="cloudinary"
                            className="w-[155px] h-[30px]"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715182973/Langchain_pvqf4s.svg"
                            alt="lang chain"
                            className="w-[154.54px] h-[26.4px]"
                        />
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/q_auto/v1715182973/Google_Cloud_cdc5d0.svg"
                            alt="google cloud"
                            className="w-[138.35px] h-[24px] mt-1.5"
                        />
                    </div>
                </div>
            </section>

            <section className="flex justify-center mb-[164px] max-lg:mb-[72px]">
                <div className="relative rounded-[24px] w-[86%]">
                    <div className=" h-[652px] w-full rounded-[24px] bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715262441/Happy_Redhead_Waitress_1_uscq3a.png')] max-lg:bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715268024/Happy_Redhead_Waitress_1_1_gqdgq0.png')] bg-cover bg-no-repeat"></div>
                    <div className="pl-16 pb-16 max-lg:pl-6 max-lg:pb-6 flex items-end w-full h-[43.3%] absolute rounded-b-[24px] bottom-0 bg-gradient-to-t from-[#000000] from-[0.25%] via-[rgba(0, 0, 0, 0.254518)] via-[68.23%] to-[rgba(0, 0, 0, 0)] to-[92.15%]">
                        <p className="text-[64px] leading-[76.8px] max-lg:text-[32px] max-lg:leading-[38px] font-flame text-white">
                            Grow your CPG <br></br> brand with{" "}
                            <span className="text-y-10">Synth</span>
                        </p>
                    </div>
                </div>
            </section>

            <section id="brands-spotlight" className=" pb-[100px] max-lg:pb-0 max-sm:mb-[105px] ">
                <div className=" max-sm:w-full   ">
                    <div className="mb-12 text-center mx-auto px-6">
                        <h4 className="text-base lg:text-xl font-flame text-orange-1 mb-4">
                            Brands Spotlight
                        </h4>
                        <h5 className="text-[48px] max-lg:text-[32px] max-lg:leading-[38.4px] font-flame leading-[57.6px] text-green-2">
                            Discover products our customers' customers love
                        </h5>
                    </div>

                    <div className=" flex justify-end max-sm:pl-6">
                        <div className="w-[93%] flex mb-12 gap-5 overflow-auto pb-8  items-stretch">
                            {brandsSpotLight?.map((brand, idx) => {
                                return (
                                    <div className="w-[284px] bg-white rounded-[6px] shadow-[0px_20px_24px_-4px_rgba(66,48,0,0.08)]">
                                        <a
                                            href={brand?.productLink}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {brand?.noBg ? (
                                                <div className="h-[226px] w-[284px] py-4 rounded-t-[8px] bg-[#F5E0C3] flex justify-center items-center">
                                                    <img
                                                        src={brand?.productImage}
                                                        alt=""
                                                        className="h-[90%] "
                                                    />
                                                </div>
                                            ) : (
                                                <div className="h-[226px] w-[284px] rounded-t-[8px] bg-[#F5E0C3] flex justify-center items-center">
                                                    <img
                                                        src={brand?.productImage}
                                                        alt=""
                                                        className="h-[226px] w-[284px] rounded-t-[8px]"
                                                    />
                                                </div>
                                            )}
                                        </a>
                                        <div className="rounded-b-[8px] bg-white px-5 pt-5 py-10 ">
                                            <img
                                                src={brand?.brand}
                                                alt={brand?.brandName}
                                                className={` ${
                                                    brand?.smallLogo ? "h-[16px]" : "h-[32px]"
                                                }  lg:mb-2`}
                                            />
                                            <a
                                                href={brand?.productLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <p className="text-[#013D28] text-base font-flame">
                                                    {brand?.productName}
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>

            <section
                id="integrations"
                className="flex justify-center pb-[100px] max-lg:pb-0 max-sm:mb-[105px]"
            >
                <div className="w-[63.8%] max-sm:w-full max-sm:px-6">
                    <div className="mb-12 text-center ">
                        <h4 className="text-base lg:text-xl font-flame text-orange-1 mb-4">
                            Easily integrates
                        </h4>
                        <h5 className="text-[48px] max-lg:text-[32px] max-lg:leading-[38.4px] font-flame leading-[57.6px] text-green-2">
                            Seamlessly connect your inventory and sales data to Synth.
                        </h5>
                    </div>

                    {/* <div className="mb-12 md:flex hidden  justify-center  items-center">
                        <div className="flex items-center ">
                            {integrationsOne.map((integration, idx) => (
                                <div className="mr-[-12px] group cursor-pointer relative" key={idx}>
                                    <img
                                        src={integration.icon}
                                        alt={integration.title.toLowerCase()}
                                        className="w-[90px] h-[90px]"
                                    />
                                    <span className=" pointer-events-none absolute font-interDisplay text-[16px] labsolute top-full left-1/2 transform -translate-x-1/2 transition-all duration-300 opacity-0  group-focus:opacity-100 bg-[#013D28] px-3 py-2 text-white before:absolute before:left-1/2 before:bottom-full before:translate-x-1/2 before:border-4 before:border-transparent before:border-b-gm-50 rounded-[8px] before:content-[''] group-hover:opacity-100">
                                        {integration.title}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className="group cursor-pointer relative">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360124/Frame_1000003473_hrjnfw.svg"
                                alt="synth"
                                className="w-[120px] h-[120px]"
                            />
                            <span className="whitespace-nowrap font-interDisplay pointer-events-none absolute text-[16px] bottom-full left-1/2 transform -translate-x-1/2 transition-all duration-300 opacity-0 group-focus:opacity-100 bg-[#013D28] px-3 py-2 text-white before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-gm-50 rounded-[8px] before:content-[''] group-hover:opacity-100">
                                Synth
                            </span>
                        </div>
                        <div className="flex items-center ">
                            {integrationsTwo.map((integration, idx) => (
                                <div className="-ml-[12px] group cursor-pointer relative" key={idx}>
                                    <img
                                        src={integration.icon}
                                        alt={integration.title.toLowerCase()}
                                        className="w-[90px] h-[90px]"
                                    />
                                    <span className="whitespace-nowrap font-interDisplay pointer-events-none absolute text-[16px] top-full left-1/2 transform -translate-x-1/2 transition-all duration-300 opacity-0 group-focus:opacity-100 bg-[#013D28] px-3 py-2 text-white before:absolute before:left-1/2 before:bottom-full before:translate-x-1/2 before:border-4 before:border-transparent before:border-b-gm-50 rounded-[8px] before:content-[''] group-hover:opacity-100">
                                        {integration.title}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="mb-12 md:hidden flex flex-col  justify-center  items-center">
                        <div className="group cursor-pointer flex justify-center relative">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715360124/Frame_1000003473_hrjnfw.svg"
                                alt="synth"
                                className="w-[96px] h-[96px]"
                            />
                            <span className="whitespace-nowrap font-interDisplay pointer-events-none absolute text-[16px] bottom-full left-1/2 transform -translate-x-1/2 transition-all duration-300 opacity-0 group-focus:opacity-100 bg-[#013D28] px-3 py-2 text-white before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-gm-50 rounded-[8px] before:content-[''] group-hover:opacity-100">
                                Synth
                            </span>
                        </div>
                        <div className="flex justify-center mb-1 items-center ">
                            {integrationsOne.map((integration, idx) => (
                                <div className="-mr-[12px] group cursor-pointer relative" key={idx}>
                                    <img
                                        src={integration.icon}
                                        alt={integration.title.toLowerCase()}
                                        className="w-[76px] h-[76px]"
                                    />
                                    <span className="whitespace-nowrap z-[1000] font-interDisplay pointer-events-none absolute text-[16px] top-full left-1/2 transform -translate-x-1/2 transition-all duration-300 opacity-0 group-focus:opacity-100 bg-[#013D28] px-3 py-2 text-white before:absolute before:left-1/2 before:bottom-full before:translate-x-1/2 before:border-4 before:border-transparent before:border-b-gm-50 rounded-[8px] before:content-[''] group-hover:opacity-100">
                                        {integration.title}
                                    </span>
                                </div>
                            ))}
                        </div>

                        <div className="flex justify-center  items-center ">
                            {integrationsTwo.map((integration, idx) => (
                                <div className="-mr-[12px] group cursor-pointer relative" key={idx}>
                                    <img
                                        src={integration.icon}
                                        alt={integration.title.toLowerCase()}
                                        className="w-[76px] h-[76px]"
                                    />
                                    <span className="whitespace-nowrap font-interDisplay pointer-events-none absolute text-[16px] top-full left-1/2 transform -translate-x-1/2 transition-all duration-300 opacity-0 group-focus:opacity-100 bg-[#013D28] px-3 py-2 text-white before:absolute before:left-1/2 before:bottom-full before:translate-x-1/2 before:border-4 before:border-transparent before:border-b-gm-50 rounded-[8px] before:content-[''] group-hover:opacity-100">
                                        {integration.title}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div> */}

                    <div className="mb-12 flex justify-center">
                        <div className="w-full max-lg:hidden">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728052392/Group_55903_txw4wy.svg"
                                alt=""
                                className=" w-full"
                            />
                        </div>
                        <div className="w-full lg:hidden flex justify-center">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1728052643/Group_55902_1_n6sezf.svg"
                                alt=""
                                className="lg:hidden"
                            />
                        </div>
                    </div>

                    <div className="flex justify-center ">
                        <Button
                            btnText={"Book a Demo"}
                            btnClassname={
                                "!w-fit !h-[48px] !text-base !rounded-md !px-6 !font-flame !shadow-[0px_-3px_0px_0px_#0000001A_inset]"
                            }
                            isLoading={joiningWaitList}
                            onClick={handleBookADemo}
                        />
                    </div>
                </div>
            </section>

            <LandingFooter />
            <BookADemoModal openModal={openModal} setOpenModal={setOpenModal} />
            <CookiePrompt />
        </div>
    );
};

export default Home;
