import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const productionService = {
    getBOMList,
    getSingleBOM,
    createBOM,
    editBOM,
    getProductWithoutBOM,
    getNonFinishedInventory,
    getFinishedInventory,
    createProductionPlan,
    updateProductionPlan,
    getProductionPlanList,
    getSingleProductionPlan,
    getSingleProductionPlanBasic,
    getProductionPlanTab,
    createPlanSteps,
    updatePlanSteps,
    fetchProductionAnalytics,
    createProductionSettings,
    getProductionSettings,
    updateProductionSettings,
    updateApprovalStatus,
    validateProductionBatchId,
    getWorkOrderList,
    getSingleWorkOrder,
    getWorkOrderTab,
    getWorkOrderTabTwo,
    switchProductionRun,
    updateWorkOrderStatus,
    updateWorkOrderApprovalStatus,
    getPendingWorkOrder,
    getRejectedWorkOrder,
    submitYieldAndLoss,
    updateProductionStep,
    getReportList,
    getSingleReport,
    saveReport,
    addToInventory,
    getProductionForecastList,
    getProductionPlanTabTwo,
    deleteProductionPlan,
};

async function fetchProductionAnalytics(startDate?: string, endDate?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/metrics${
            Boolean(startDate && endDate) ? `?startDate=${startDate}&endDate=${endDate}` : ""
        }`,
        requestOptions
    );
    return res;
}

async function getBOMList(limit?: number, page?: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/products/with-bom?limit=${limit}&page=${page}${
            Boolean(search) ? "&search=" + search : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleBOM(inventoryId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/product/${inventoryId}/bom-details`,
        requestOptions
    );
    return res;
}

async function createBOM(data: Record<string, any> | Array<Record<string, any>>, inventoryId) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/product/${inventoryId}/bom`,
        requestOptions
    );
    return res;
}

async function editBOM(data: Record<string, any> | Array<Record<string, any>>, inventoryId) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/product/${inventoryId}/bom`,
        requestOptions
    );
    return res;
}

async function getProductWithoutBOM() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/products/without-bom`,
        requestOptions
    );
    return res;
}

async function getNonFinishedInventory(limit?: number, page?: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${
            config.API_URL
        }/api/v1/ldb/inventory/list/non-finished-inventory?limit=${limit}&page=${page}${
            Boolean(search) ? "&search=" + search : ""
        }`,
        requestOptions
    );
    return res;
}

async function getFinishedInventory(
    category: string,
    limit?: number,
    page?: number,
    search?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${
            config.API_URL
        }/api/v1/ldb/inventory/list/finished-products?category=${category}&limit=${limit}&page=${page}${
            Boolean(search) ? "&search=" + search : ""
        }`,
        requestOptions
    );
    return res;
}

async function createProductionPlan(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/create-plan`,
        requestOptions
    );
    return res;
}

async function updateProductionPlan(
    data: Record<string, any> | Array<Record<string, any>>,
    planId: string
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/update-plan/${planId}`,
        requestOptions
    );
    return res;
}

async function getProductionPlanList(
    limit?: number,
    page?: number,
    search?: string,
    status?: string,
    assigneeId?: string,
    startDate?: string,
    endDate?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        search: search as string,
        status,
        assigneeId,
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/list/plans?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleProductionPlan(planId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/plan/${planId}/details`,
        requestOptions
    );
    return res;
}

async function getSingleProductionPlanBasic(planId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/plan/${planId}/basic-details`,
        requestOptions
    );
    return res;
}

async function getProductionPlanTab(
    planId: string,
    query?: string,
    limit?: number,
    page?: number,
    search?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        search,
        query,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/plan/${planId}/details?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
async function getProductionPlanTabTwo(
    planId: string,
    query?: string,
    limit?: number,
    page?: number
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        query,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/plan/${planId}/details?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function updatePlanSteps(
    data: Record<string, any> | Array<Record<string, any>>,
    stepId: string
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/update-step/${stepId}`,
        requestOptions
    );
    return res;
}

async function createPlanSteps(data: Record<string, any> | Array<Record<string, any>>, planId) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/add-step/${planId}`,
        requestOptions
    );
    return res;
}

async function createProductionSettings(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/add-settings`,
        requestOptions
    );
    return res;
}

async function getProductionSettings() {
    const requestOptions = {
        method: "GET",
    };
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/settings`,
        requestOptions
    );
    return res;
}

async function updateProductionSettings(
    data: Record<string, any> | Array<Record<string, any>>,
    id: string
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/update-settings/${id}`,
        requestOptions
    );
    return res;
}

async function updateApprovalStatus(
    data: Record<string, any> | Array<Record<string, any>>,
    id: string
) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/update-approval-status/${id}`,
        requestOptions
    );
    return res;
}

async function validateProductionBatchId(id: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/validate/product-plan-batch/${id}`,
        requestOptions
    );
    return res;
}

async function getWorkOrderList(
    limit?: number,
    page?: number,
    search?: string,
    status?: string,
    assigneeId?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        search: search as string,
        status,
        assigneeId,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/work-order?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleWorkOrder(workOrderId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/work-order/${workOrderId}`,
        requestOptions
    );
    return res;
}

async function getWorkOrderTab(
    workOrderId: string,
    query?: string,
    limit?: number,
    page?: number,
    search?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        search,
        query,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/work-order/${workOrderId}?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getWorkOrderTabTwo(
    workOrderId: string,
    query?: string,
    limit?: number,
    page?: number
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        query,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/work-order/${workOrderId}?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
async function switchProductionRun(workOrderId: string, data: Record<string, any>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/work-order/${workOrderId}/switch-production`,
        requestOptions
    );
    return res;
}

async function updateWorkOrderStatus(workOrderId: string, data: Record<string, any>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/work-order/update-status/${workOrderId}`,
        requestOptions
    );
    return res;
}

async function updateWorkOrderApprovalStatus(
    data: Record<string, any> | Array<Record<string, any>>,
    workOrderId: string
) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/work-order/update-approval-status/${workOrderId}`,
        requestOptions
    );
    return res;
}

async function getPendingWorkOrder(limit?: number, page?: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${
            config.API_URL
        }/api/v1/ldb/production/work-order/list/pending?limit=${limit}&page=${page}${
            Boolean(search) ? "&search=" + search : ""
        }`,
        requestOptions
    );
    return res;
}

async function getRejectedWorkOrder(limit?: number, page?: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${
            config.API_URL
        }/api/v1/ldb/production/work-order/list/rejected?limit=${limit}&page=${page}${
            Boolean(search) ? "&search=" + search : ""
        }`,
        requestOptions
    );
    return res;
}

async function submitYieldAndLoss(data: Record<string, any>, workOrderId: string) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/work-order/yeild-loss/${workOrderId}`,
        requestOptions
    );
    return res;
}

async function updateProductionStep(
    data: Record<string, any>,
    workOrderId: string,
    stepId: string
) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/work-order/${workOrderId}/update-step/${stepId}`,
        requestOptions
    );
    return res;
}

async function getReportList(limit?: number, page?: number, search?: string, status?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        search,
        status,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/list/report?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleReport(workOrderId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/work-order/${workOrderId}/report`,
        requestOptions
    );
    return res;
}

async function saveReport(data: Record<string, any>, workOrderId: string) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/work-order/${workOrderId}/report`,
        requestOptions
    );
    return res;
}

async function addToInventory(data: Record<string, any>, workOrderId: string) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/work-order/${workOrderId}/update-stock`,
        requestOptions
    );
    return res;
}

async function getProductionForecastList(
    limit: number,
    page: number,
    search?: string,
    startDate?: string,
    endDate?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        search,
        startDate,
        endDate,
    });
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/list/production-schedule?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function deleteProductionPlan(planId: string) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/delete-plan/${planId}`,
        requestOptions
    );
    return res;
}
