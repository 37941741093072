import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";

const useGetBrandWarehouseList = () => {
    const dispatch = useAppDispatch();
    const [brandWarehouseList, setBrandWarehouseList] = useState<{ [key: string]: any }[]>([]);

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");

    const {
        fetchingBrandWarehouseList,
        fetchBrandWarehouseListSuccess,
        fetchBrandWarehouseListFailure,
    } = useAppSelector((state) => state.multibrand);

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setDebouncedSearch(e.target.value);
    }, 800);

    useEffect(() => {
        if (Boolean(fetchBrandWarehouseListSuccess)) {
            const channelData = fetchBrandWarehouseListSuccess?.uniqueWarehouseChannels?.map(
                (item) => ({ label: item, value: item })
            );
            const warehouseData = fetchBrandWarehouseListSuccess?.warehouses?.map((item) => ({
                label: item?.warehouseName,
                value: item?._id,
            }));

            setBrandWarehouseList((prev) =>
                updatedPage === 1
                    ? [...channelData, ...warehouseData]
                    : [...prev, ...channelData, ...warehouseData]
            );
            setPagination({
                currentPage: fetchBrandWarehouseListSuccess.pagination?.current,
                noOfPages: fetchBrandWarehouseListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchBrandWarehouseListSuccess]);

    useEffect(() => {
        dispatch(multibrandActions?.getBrandWarehousList(debouncedSearch));
    }, [dispatch, debouncedSearch, updatedPage]);

    return {
        data: brandWarehouseList,
        isFetching: fetchingBrandWarehouseList,
        error: fetchBrandWarehouseListFailure,
        isLoadingMore,
        pagination,
        search,
        setSearch,
        debouncedSearch,
        setDebouncedSearch,
        handleSearch,
        handleDebouncedChange,
        setUpdatedPage,
        updatedPage,
    };
};

export default useGetBrandWarehouseList;
