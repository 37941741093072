import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";

const useGetRevenueTable = (generalBrand?: string, generalDuration?: string) => {
    const dispatch = useAppDispatch();
    const [revenueTableData, setRevenueTableData] = useState<{ [key: string]: any }[]>([]);
    const [dateRange, setDateRange] = useState<{ label: string; value: string }>({
        label: "",
        value: "yearly",
    });
    const [brand, setBrand] = useState<{ label: string; value: string }>({
        label: "",
        value: "",
    });

    const { fetchingRevenueTable, fetchRevenueTableSuccess, fetchRevenueTableFailure } =
        useAppSelector((state) => state.multibrand);

    const handleDurationChange = (value: { label: string; value: string }) => {
        setDateRange(value);
    };

    const handleBrandChangeFilter = (value: { label: string; value: string }) => {
        setBrand(value);
    };

    useEffect(() => {
        if (Boolean(fetchRevenueTableSuccess)) {
            setRevenueTableData(fetchRevenueTableSuccess?.metrics);
        }
    }, [fetchRevenueTableSuccess]);

    useEffect(() => {
        dispatch(
            multibrandActions?.getRevenueTable(
                dateRange?.value !== generalDuration ? dateRange?.value : generalDuration,
                brand?.value !== generalBrand ? brand?.value : generalBrand
            )
        );
    }, [dispatch, dateRange?.value, brand?.value, generalBrand, generalDuration]);

    return {
        data: revenueTableData,
        isFetching: fetchingRevenueTable,
        error: fetchRevenueTableFailure,
        handleDurationChange,
        dateRange,
        brand,
        handleBrandChangeFilter,
    };
};

export default useGetRevenueTable;
