import React, { useState } from "react";
import ReactECharts from "echarts-for-react";
import useGetIncidentAnalytics from "hooks/multibrandHooks/useGetIncidentAnalytics";
import useGetBrandAnalytics from "hooks/multibrandHooks/useGetBrandAnalytics";
import useGetBrandComparism from "hooks/multibrandHooks/useGetBrandComparism";
import Dropdown from "components/common/Dropdown";
import SalesCard from "components/common/SalesCard";
import TabsContainer from "components/common/TabsContainer";
import TextInput from "components/common/InputField/TextInput";
import useGetBrandList from "hooks/multibrandHooks/useGetBrandList";
import CustomTable from "components/common/CustomTable";
import EmptyStateComp from "components/common/EmptyStateComp";
import CustomTableLoader from "components/common/CustomTableLoader";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import { piechartColors } from "variables/chartColors";
import { formatMoney } from "helpers";
import { Tooltip } from "react-tooltip";
import millify from "millify";
import jsonToCsvExport from "json-to-csv-export";
import { useNavigate } from "react-router-dom";

type PortfolioMetricsType = {
    generalBrand?: string;
    generalDuration?: string;
};

const PortfolioMetrics = ({ generalBrand, generalDuration }: PortfolioMetricsType) => {
    const [activeTab, setActiveTab] = useState(0);
    const [brandOne, setBrandOne] = useState("");
    const [brandTwo, setBrandTwo] = useState("");
    const [brandOneId, setBrandOneId] = useState("");
    const [brandTwoId, setBrandTwoId] = useState("");
    const navigate = useNavigate();

    const {
        data: allBrandList,
        brandListSearch: brandList,
        search,
        handleDebouncedChange,
        handleSearch,
        handleDebouncedChangeTwo,
        handleSearchTwo,
        searchTwo,
        setDebouncedSearchTwo,
        setSearchTwo,
    } = useGetBrandList();
    const { data: incidentData } = useGetIncidentAnalytics(generalBrand, generalDuration);
    const { data: brandAnalytics } = useGetBrandAnalytics(generalBrand, generalDuration);

    const compareBrandOne = allBrandList?.[0]?.brand;

    const compareBrandTwo = allBrandList?.[1]?.brand;

    const { data: brandComparism } = useGetBrandComparism(
        brandOneId || compareBrandOne?._id,
        brandTwoId || compareBrandTwo?._id,
        generalDuration
    );

    const brandsToCompare = Object.keys(brandComparism)?.map((item) => item);
    const brandInfoOne = brandComparism[brandOne || brandsToCompare?.[0]] || {};
    const brandInfoTwo = brandComparism[brandTwo || brandsToCompare?.[1]] || {};

    const handleExportBrandComparism = () => {
        const exportData = [
            {
                "Brand name": brandOne || brandsToCompare?.[0],
                "Total cost": brandInfoOne?.totalCost,
                "Total revenue": brandInfoOne?.totalRevenue,
                "Delivery date": brandInfoOne?.deliveryRate,
                "Product quality": brandInfoOne?.productQuality,
                "Supplier efficiency": brandInfoOne?.supplierEfficiency,
                "Production efficiency": brandInfoOne?.productionEfficiency,
                "Inventory velocity": brandInfoOne?.inventoryVelocity,
                Profit: brandInfoOne?.profit,
            },
            {
                "Brand name": brandTwo || brandsToCompare?.[1],
                "Total cost": brandInfoTwo?.totalCost,
                "Total revenue": brandInfoTwo?.totalRevenue,
                "Delivery date": brandInfoTwo?.deliveryRate,
                "Product quality": brandInfoTwo?.productQuality,
                "Supplier efficiency": brandInfoTwo?.supplierEfficiency,
                "Production efficiency": brandInfoTwo?.productionEfficiency,
                "Inventory velocity": brandInfoTwo?.inventoryVelocity,
                Profit: brandInfoTwo?.profit,
            },
        ];

        jsonToCsvExport({
            data: exportData,
            filename: "brand_comparism",
        });
    };

    const determineTrendDirection = (value: string | number) => {
        return String(value)?.[0] === "-" ? "down" : "up";
    };

    const salesCardData: {
        icon: JSX.Element;
        title: string;
        value: string | number;
        trendDirection?: "up" | "down";
        trendValue?: string | number;
    }[] = [
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-carribean-green">
                    <i className="ri-file-line text-lg text-white"></i>
                </div>
            ),
            title: "All product sales",
            value: formatMoney().format(brandAnalytics?.totalProductSales?.value?.toFixed(2) || 0),
            trendDirection: determineTrendDirection(
                brandAnalytics?.totalProductSales?.percentageChange
            ),
            trendValue: formatMoney().format(brandAnalytics?.totalProductSales?.percentageChange),
        },
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-r-50">
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1726602779/line_vector_k6cwfy.svg"
                        className="w-[17px] h-[9px]"
                    />
                </div>
            ),
            title: "Total revenue",
            value: "$" + formatMoney().format(brandAnalytics?.totalRevenue?.value?.toFixed(2) || 0),
            trendDirection: determineTrendDirection(brandAnalytics?.totalRevenue?.percentageChange),
            trendValue: formatMoney().format(brandAnalytics?.totalRevenue?.percentageChange),
        },
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-p-50">
                    <i className="ri-bar-chart-2-fill text-lg text-white"></i>
                </div>
            ),
            title: "Total COGS",
            value: "$" + formatMoney().format(brandAnalytics?.totalCOGS?.value?.toFixed(2) || 0),
            trendDirection: determineTrendDirection(brandAnalytics?.totalCOGS?.percentageChange),
            trendValue: formatMoney().format(brandAnalytics?.totalCOGS?.percentageChange),
        },
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-p-50">
                    <i className="ri-bar-chart-2-fill text-lg text-white"></i>
                </div>
            ),
            title: "COGS to Revenue margin",
            value:
                formatMoney().format(
                    brandAnalytics?.cogsToRevenuePercentage?.value?.toFixed(2) || 0
                ) + "%",
            trendDirection: determineTrendDirection(
                brandAnalytics?.cogsToRevenuePercentage?.percentageChange
            ),
            trendValue: formatMoney().format(
                brandAnalytics?.cogsToRevenuePercentage?.percentageChange
            ),
        },
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-y-10">
                    <i className="ri-money-dollar-circle-line text-lg text-white"></i>
                </div>
            ),
            title: "Av. cost per brand",
            value:
                "$" +
                formatMoney().format(brandAnalytics?.averageCostPerBrand?.value?.toFixed(2) || 0),
            trendDirection: determineTrendDirection(
                brandAnalytics?.averageCostPerBrand?.percentageChange
            ),
            trendValue: formatMoney().format(brandAnalytics?.averageCostPerBrand?.percentageChange),
        },
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-y-10">
                    <i className="ri-money-dollar-circle-line text-lg text-white"></i>
                </div>
            ),
            title: "Av. gross margin per brand",
            value:
                formatMoney().format(brandAnalytics?.averageGrossPerBrand?.value?.toFixed(2) || 0) +
                "%",
            trendDirection: determineTrendDirection(
                brandAnalytics?.averageGrossPerBrand?.percentageChange
            ),
            trendValue: formatMoney().format(
                brandAnalytics?.averageGrossPerBrand?.percentageChange
            ),
        },
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-slate-400">
                    <i className="ri-box-3-line text-lg text-white"></i>
                </div>
            ),
            title: "Av. revenue per SKU per brand",
            value:
                "$" +
                formatMoney().format(
                    brandAnalytics?.averageRevenuePerSKUPerBrand?.value?.toFixed(2) || 0
                ),
            trendDirection: determineTrendDirection(
                brandAnalytics?.averageRevenuePerSKUPerBrand?.percentageChange
            ),
            trendValue: formatMoney().format(
                brandAnalytics?.averageRevenuePerSKUPerBrand?.percentageChange
            ),
        },
        {
            icon: (
                <div className="w-[38px] h-[38px] mb-2 rounded-full shadow-boxShadow-10 flex items-center justify-center bg-[#A243DC]">
                    <i className="ri-line-chart-line text-lg text-white"></i>
                </div>
            ),
            title: "Av. revenue growth rate per brand",
            value: formatMoney().format(brandAnalytics?.averageBrandGrowth || 0) + "%",
            // trendDirection: determineTrendDirection(statsData?.sellThroughRate?.percentIncrease),
            // trendValue: statsData?.sellThroughRate?.percentIncrease?.toFixed(2),
        },
    ];

    const radarIndicators = [
        { name: "Cost", max: brandInfoOne?.totalCost + brandInfoTwo?.totalCost },
        { name: "Sales revenue", max: brandInfoOne?.totalRevenue + brandInfoTwo?.totalRevenue },
        { name: "Delivery rate", max: brandInfoOne?.deliveryRate + brandInfoTwo?.deliveryRate },
        {
            name: "Product quality",
            max: brandInfoOne?.productQuality + brandInfoTwo?.productQuality,
        },
        {
            name: "Supplier efficiency",
            max: brandInfoOne?.supplierEfficiency + brandInfoTwo?.supplierEfficiency,
        },
        {
            name: "Production efficiency",
            max: brandInfoOne?.productionEfficiency + brandInfoTwo?.productionEfficiency,
        },
        {
            name: "Inventory velocity",
            max: brandInfoOne?.inventoryVelocity + brandInfoTwo?.inventoryVelocity,
        },
        { name: "Profitability", max: brandInfoOne?.profit + brandInfoTwo?.profit },
    ];

    const radarOptions = {
        tooltip: {
            show: true,
            formatter: function (params) {
                return `
                    <div>
                        <p style="margin-bottom: 10px; font-weight: 600;">${
                            params.seriesName + " (" + params.name + ")"
                        }:</p>

                        <div>
                            ${radarIndicators
                                ?.map((item, idx) => {
                                    return `
                                        <p style="font-size: 12px;">
                                            <span>
                                                ${item?.name}:
                                            </span>
                                            <span>
                                                ${formatMoney()?.format(params.value?.[idx])}
                                            </span>
                                        </p>
                                    `;
                                })
                                ?.join("")}
                        </div>  

                    </div>
                 `;
            },
        },
        radar: {
            // shape: 'circle',
            indicator: radarIndicators,
        },
        series: [
            {
                name: "Brand comparison",
                type: "radar",
                symbol: "none",
                data: [
                    {
                        value: [
                            brandInfoOne?.totalCost,
                            brandInfoOne?.totalRevenue,
                            brandInfoOne?.deliveryRate,
                            brandInfoOne?.productQuality,
                            brandInfoOne?.supplierEfficiency,
                            brandInfoOne?.productionEfficiency,
                            brandInfoOne?.inventoryVelocity,
                            brandInfoOne?.profit,
                        ],
                        name: brandOne || brandsToCompare?.[0],
                        lineStyle: {
                            color: "#6562E5", // Set custom color for radar lines
                        },

                        areaStyle: {
                            color: "#6562E51A", // Set custom color with transparency
                        },
                    },
                    {
                        value: [
                            brandInfoTwo?.totalCost,
                            brandInfoTwo?.totalRevenue,
                            brandInfoTwo?.deliveryRate,
                            brandInfoTwo?.productQuality,
                            brandInfoTwo?.supplierEfficiency,
                            brandInfoTwo?.productionEfficiency,
                            brandInfoTwo?.inventoryVelocity,
                            brandInfoTwo?.profit,
                        ],
                        name: brandTwo || brandsToCompare?.[1],

                        lineStyle: {
                            color: "#12A589", // Set custom color for radar lines
                        },

                        areaStyle: {
                            color: "#62CC001A", // Set custom color with transparency
                        },
                    },
                ],
            },
        ],
    };

    const incidentsPieChartOptions = {
        tooltip: {
            trigger: "item",
        },
        color: piechartColors,
        series: [
            {
                name: "Latest incident",
                type: "pie",
                radius: ["50%", "70%"],
                avoidLabelOverlap: false,
                padAngle: 2,
                label: {
                    show: false,
                    position: "center",
                },
                itemStyle: {
                    borderRadius: 5,
                    borderWidth: 2,
                    borderColor: "#fff",
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 40,
                        fontWeight: "bold",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: incidentData?.cumulativeData?.slice(0, 5)?.map((item) => {
                    return {
                        value: item?.outOfStock + item?.overStock,
                        name: item?.brand?.brandName,
                    };
                }),
            },
        ],
    };

    const summaryTableHeader = [
        { title: "Brand name", widthClass: "w-[19%] font-rocGroteskMedium" },
        { title: "No of SKUs", widthClass: "w-[8.4%] font-rocGroteskMedium" },
        { title: "Current Inventory", widthClass: "w-[11%] font-rocGroteskMedium" },
        { title: "Total revenue", widthClass: "w-[12%] font-rocGroteskMedium" },
        { title: "Total cost", widthClass: "w-[13%] font-rocGroteskMedium" },
        { title: "Gross margin", widthClass: "w-[10.6%] font-rocGroteskMedium" },
        { title: "Sales channels", widthClass: "w-[9.5%] font-rocGroteskMedium" },
        { title: "Inventory status", widthClass: "w-[15.6%] font-rocGroteskMedium" },
    ];

    const summaryTableBody = [...allBrandList]?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-1.5`}
                    >
                        <div>
                            <p className="text-[13px]">{item?.brand?.brandName}</p>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-1.5`}
                    >
                        <p className="text-[13px]">
                            {formatMoney()?.format(item?.totalNumberOfSku)}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-1.5`}
                    >
                        <p className="text-[13px]">
                            {formatMoney()?.format(item?.totalStockLevel)}
                        </p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-1.5`}
                    >
                        <div className="flex items-center space-x-3">
                            <p className="text-[13px]">
                                ${formatMoney()?.format(item?.totalRevenue?.toFixed(2))}
                            </p>
                            {String(item?.percentageRevenueChange)?.[0] === "-" ? (
                                <span className="text-[10px] bg-r-25 px-1.5 py-[2px] rounded-[16px] flex items-center  text-r-50">
                                    <i className="ri-arrow-up-line mt-[-2px]"></i>
                                    {item?.percentageRevenueChange?.toFixed(0)}%
                                </span>
                            ) : (
                                <span className="text-[10px] bg-g-25 px-1.5 py-[2px] rounded-[16px] flex items-center  text-g-60">
                                    <i className="ri-arrow-up-line mt-[-2px]"></i>
                                    {item?.percentageRevenueChange?.toFixed(0)}%
                                </span>
                            )}
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-1.5`}
                    >
                        <div className="flex items-center space-x-3">
                            <p className="text-[13px]">
                                ${formatMoney()?.format(item?.totalCost?.toFixed(2))}
                            </p>
                            {String(item?.percentageTotalCostChange)?.[0] === "-" ? (
                                <span className="text-[10px] bg-r-25 px-1.5 py-[2px] rounded-[16px] flex items-center  text-r-50">
                                    <i className="ri-arrow-up-line mt-[-2px]"></i>
                                    {item?.percentageTotalCostChange?.toFixed(0)}%
                                </span>
                            ) : (
                                <span className="text-[10px] bg-g-25 px-1.5 py-[2px] rounded-[16px] flex items-center  text-g-60">
                                    <i className="ri-arrow-up-line mt-[-2px]"></i>
                                    {item?.percentageTotalCostChange?.toFixed(0)}%
                                </span>
                            )}
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-1.5`}
                    >
                        <p className="text-[13px]">{item?.grossMarginPercentage?.toFixed(0)}%</p>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-1.5`}
                    >
                        <div className="w-fit">
                            <a id={`channel-column-${idx}`}>
                                <div className="flex items-center">
                                    {item?.uniqueChannels
                                        ?.slice(0, 2)
                                        ?.map((channel) => displayProductChannelIcon(channel))}
                                </div>
                            </a>
                            <Tooltip
                                anchorSelect={`#channel-column-${idx}`}
                                place={"right"}
                                content={item?.uniqueChannels
                                    ?.map((channel) => channel)
                                    ?.join(", ")}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-1.5`}
                    >
                        <div className="flex items-center flex-wrap gap-1.5">
                            <span className="text-[10px] bg-g-25 text-g-60 px-1 h-[16px] rounded-[16px] flex items-center justify-center">
                                {item?.fullyStockedPercentage}% fully stocked
                            </span>
                            <span className="text-[10px] bg-[#FCC34E26] text-[#F4B000] px-1 h-[16px] rounded-[16px] flex items-center justify-center">
                                {item?.totalPercentageOfOverStock}% overstocked
                            </span>
                            <span className="text-[10px] bg-r-25 text-r-55 px-1 h-[16px] rounded-[16px] flex items-center justify-center">
                                {item?.totalOutOfStockPercentage}% out of stock
                            </span>
                        </div>
                    </div>
                ),
            },
        ];
    });

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return false ? (
                    <CustomTableLoader tableHeader={summaryTableHeader} />
                ) : [1, 2, 3, 4, 5]?.length > 0 ? (
                    <div>
                        <CustomTable
                            tableBody={summaryTableBody}
                            tableHeader={summaryTableHeader}
                            isAllSelectable={false}
                            isCellBordered={true}
                            headerContainerClass="!bg-slate-50  !border-slate-100 "
                            bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                            headerItemClass="font-rocGroteskMedium !text-slate-700"
                        />
                    </div>
                ) : (
                    <EmptyStateComp
                        title="No sales order data available"
                        description="There are no sales order data available for this section"
                        contentContainerClass="!w-fit"
                        icon={<i className="ri-box-3-line text-slate-500 text-[40px]"></i>}
                    />
                );

            default:
                return <div></div>;
        }
    };

    return (
        <div>
            <div className="flex space-x-4 mb-6 font-rocGroteskMedium">
                <div className="w-1/2 rounded-lg border border-slate-200 pt-4 ">
                    <div className="flex items-start justify-between pb-[13px] px-[13px] border-b border-slate-200">
                        <div className="">
                            <div className="flex items-center space-x-2 mb-2">
                                <div
                                    className={`w-6 h-6 bg-[#FEF2F2] rounded flex items-center justify-center`}
                                >
                                    <i className="ri-alarm-warning-fill text-[#FF0000] text-[12px]"></i>
                                </div>
                                <span className="text-base ">Latest incidents</span>
                            </div>
                            <p className="text-sm text-slate-500">
                                These are the latest inventory incidents across your brands
                            </p>
                        </div>
                        <i
                            className="ri-arrow-right-s-line text-2xl cursor-pointer"
                            onClick={() => navigate("/dashboard/portfolio/incidents")}
                        ></i>
                    </div>

                    <div className="px-[13px]">
                        <div className="flex items-center space-x-1.5 py-3">
                            <i className="ri-time-line text-base"></i>
                            <span className="text-sm text-slate-500">Incident breakdown</span>
                        </div>
                        <div className="flex items-center py-4">
                            <div className="w-1/2">
                                <div className="relative">
                                    <ReactECharts option={incidentsPieChartOptions} />
                                    <div className="absolute top-[30%] left-0 right-0 m-auto w-[120px] h-[120px] rounded-full font-rocGroteskMedium flex flex-col items-center justify-center bg-white shadow-[0px_4px_15.6px_-4px_#1018281A]">
                                        <span className="block text-[20px] text-g-75 leading-[40px]">
                                            {millify(incidentData?.totalIncidents, {
                                                precision: 2,
                                            })}
                                        </span>
                                        <span className="block text-[12px] text-slate-500">
                                            Total incidents
                                        </span>
                                        <span
                                            className={`text-[10px] ${
                                                determineTrendDirection(
                                                    incidentData?.percentageChange
                                                ) === "up"
                                                    ? "bg-g-25 text-g-60"
                                                    : "text-r-55 bg-r-25"
                                            } px-1.5 py-[2px] rounded-[3px] flex items-center space-x-1.5 `}
                                        >
                                            {determineTrendDirection(
                                                incidentData?.percentageChange
                                            ) === "up" ? (
                                                <i className="ri-arrow-up-line "></i>
                                            ) : (
                                                <i className="ri-arrow-down-line "></i>
                                            )}
                                            <span>
                                                {incidentData?.percentageChange?.toFixed(0)}% MoM
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/2 space-y-5">
                                {incidentData?.cumulativeData?.slice(0, 5)?.map((data, idx) => {
                                    return (
                                        <div key={idx} className="flex items-start justify-between">
                                            <div className="flex items-start space-x-2.5">
                                                <span
                                                    className={`w-2.5 h-2.5 rounded-full bg-[#7C3AED] mt-[4px]`}
                                                    style={{
                                                        backgroundColor: piechartColors[idx],
                                                    }}
                                                ></span>
                                                <div className="font-rocGroteskMedium">
                                                    <span className="block text-sm mb-1 text-slate-500">
                                                        {data?.brand?.brandName}
                                                    </span>
                                                    <div className="flex items-center space-x-1">
                                                        <span className="flex items-center justify-center rounded text-[10px] text-[#FFB903] bg-[#FFB9031A] px-1.5 h-[22px]">
                                                            {data?.overStock} SKUs overstocked
                                                        </span>
                                                        <span className="flex items-center justify-center rounded text-[10px] text-[#AA2913] bg-r-25 px-1.5 h-[22px]">
                                                            {data?.outOfStock} SKUs out of stock
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {/* <div className="flex justify-center items-center my-16">
                            <span className="text-xl font-rocGroteskMedium text-center ">
                                No Data available
                            </span>
                        </div> */}
                    </div>

                    <div className="flex bg-slate-50 border-t border-slate-200 rounded-b-lg">
                        <div className="w-1/2 border-r border-slate-200 px-6 py-3">
                            <p className="text-xs ">Forecasted stock needed</p>
                            <p className="text-base">
                                {formatMoney().format(incidentData?.totalForecastedQty)}
                            </p>
                        </div>
                        <div className="w-1/2 px-6 py-3">
                            <p className="text-xs ">Potential revenue loss</p>
                            <p className="text-base">
                                ${formatMoney().format(incidentData?.totalStockOutCost)}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="w-1/2 rounded-lg border border-slate-200 py-4 px-[13px]">
                    <div>
                        <div className="flex items-center justify-between pb-[13px]">
                            <div className="w-fit rounded p-1 flex items-center space-x-2.5 ">
                                <span className="text-base font-rocGroteskMedium text-slate-500">
                                    Brand comparison
                                </span>
                            </div>

                            {(brandOne || compareBrandOne?.brandName) &&
                                (brandTwo || compareBrandTwo?.brandName) && (
                                    <div className="flex items-center space-x-2.5">
                                        <div
                                            onClick={handleExportBrandComparism}
                                            className="w-8 h-8 rounded border border-slate-200 flex items-center justify-center cursor-pointer"
                                        >
                                            <i className="ri-share-forward-box-fill text-base"></i>
                                        </div>
                                    </div>
                                )}
                        </div>

                        <div className="flex items-center space-x-[14px]">
                            {(brandOne || compareBrandOne?.brandName) && (
                                <div className="flex items-center space-x-1">
                                    <span className={`w-2.5 h-2.5 rounded-[3px] bg-p-50`}></span>

                                    <Dropdown
                                        handleChange={(name, value) => {
                                            setBrandOne(value?.split("-")?.[0]);
                                            setBrandOneId(value?.split("-")?.[1]);
                                        }}
                                        dropdown={
                                            <div className="flex items-center justify-between space-x-1">
                                                <div className="font-rocGroteskMedium">
                                                    <span className="block text-[13px] text-slate-500">
                                                        {brandOne || compareBrandOne?.brandName}
                                                    </span>
                                                </div>
                                                <i className="ri-arrow-down-s-line text-[#142837]"></i>
                                            </div>
                                        }
                                        dropdownItemsClasses="!px-0 !py-0"
                                        dropdownClassName="!w-full !mb-10 !-mt-7"
                                        dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[243px] !w-full !pt-0`}
                                        name="valueRange"
                                        onClose={() => {
                                            setSearchTwo("");
                                            setDebouncedSearchTwo("");
                                            // setSearchTwoAssigned("");
                                            // setDebouncedSearchTwoAssigned("");
                                        }}
                                        search={
                                            <div>
                                                <TextInput
                                                    value={searchTwo}
                                                    name={"search"}
                                                    type={"text"}
                                                    required={false}
                                                    onChange={(e) => handleSearchTwo(e)}
                                                    onInput={handleDebouncedChangeTwo}
                                                    inputPlaceholder="Search brands"
                                                    // containerClassname=""
                                                    inputContainerClassname={"!h-[40px]"}
                                                    inputClassName="w-full !h-[40px]"
                                                />
                                            </div>
                                        }
                                        dropdownOptions={allBrandList
                                            ?.filter((brand) => {
                                                return (
                                                    brand?.brand?.brandName !==
                                                    (brandTwo || compareBrandTwo?.brandName)
                                                );
                                            })
                                            ?.map((brand) => {
                                                return {
                                                    label: (
                                                        <div
                                                            className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                    border-b border-b-slate-100 px-4 py-[10px]`}
                                                        >
                                                            {brand?.brand?.brandName}
                                                        </div>
                                                    ),
                                                    value: `${brand?.brand?.brandName}-${brand?.brand?._id}`,
                                                };
                                            })}
                                    />
                                </div>
                            )}

                            {(brandTwo || compareBrandTwo?.brandName) && (
                                <div className="flex items-center space-x-1">
                                    <span className={`w-2.5 h-2.5 rounded-[3px] bg-g-55`}></span>

                                    <Dropdown
                                        handleChange={(name, value) => {
                                            setBrandTwo(value?.split("-")?.[0]);
                                            setBrandTwoId(value?.split("-")?.[1]);
                                        }}
                                        dropdown={
                                            <div className="flex items-center justify-between space-x-1">
                                                <div className="font-rocGroteskMedium">
                                                    <span className="block text-[13px] text-slate-500">
                                                        {brandTwo || compareBrandTwo?.brandName}
                                                    </span>
                                                </div>
                                                <i className="ri-arrow-down-s-line text-[#142837]"></i>
                                            </div>
                                        }
                                        dropdownItemsClasses="!px-0 !py-0"
                                        dropdownClassName="!w-full !mb-10 !-mt-7"
                                        dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[243px] !w-full !pt-0`}
                                        name="valueRange"
                                        onClose={() => {
                                            setSearchTwo("");
                                            setDebouncedSearchTwo("");
                                        }}
                                        search={
                                            <div>
                                                <TextInput
                                                    value={searchTwo}
                                                    name={"search"}
                                                    type={"text"}
                                                    required={false}
                                                    onChange={(e) => handleSearchTwo(e)}
                                                    onInput={handleDebouncedChangeTwo}
                                                    inputPlaceholder="Search brands"
                                                    // containerClassname=""
                                                    inputContainerClassname={"!h-[40px]"}
                                                    inputClassName="w-full !h-[40px]"
                                                />
                                            </div>
                                        }
                                        dropdownOptions={allBrandList
                                            ?.filter((brand) => {
                                                return (
                                                    brand?.brand?.brandName !==
                                                    (brandOne || compareBrandOne?.brandName)
                                                );
                                            })
                                            ?.map((brand) => {
                                                return {
                                                    label: (
                                                        <div
                                                            className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                        border-b border-b-slate-100 px-4 py-[10px]`}
                                                        >
                                                            {brand?.brand?.brandName}
                                                        </div>
                                                    ),
                                                    value: `${brand?.brand?.brandName}-${brand?.brand?._id}`,
                                                };
                                            })}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex items-center py-5">
                        <div className="w-full">
                            <div>
                                <ReactECharts option={radarOptions} />
                            </div>
                        </div>
                    </div>

                    {/* <div className="flex justify-center items-center my-16">
                            <span className="text-xl font-rocGroteskMedium text-center ">
                                No Data available
                            </span>
                        </div> */}
                </div>
            </div>

            <div className="grid grid-cols-4 gap-x-2.5 gap-y-6 mb-6">
                {salesCardData?.map((cardData, idx) => {
                    return (
                        <SalesCard
                            key={cardData.title + String(idx)}
                            title={cardData?.title}
                            value={cardData.value}
                            icon={cardData?.icon}
                            bottomComp={
                                cardData?.trendValue ? (
                                    <span
                                        className={`block font-rocGroteskMedium text-sm space-x-1 ${
                                            cardData?.trendDirection === "up"
                                                ? "text-g-60 bg-g-25"
                                                : "text-r-55 bg-r-25"
                                        } px-1.5 h-6 rounded-[3px] w-fit`}
                                    >
                                        <i
                                            className={`${
                                                cardData?.trendDirection === "up"
                                                    ? "ri-arrow-up-line"
                                                    : "ri-arrow-down-line"
                                            } text-sm`}
                                        ></i>{" "}
                                        {cardData?.trendValue}%
                                    </span>
                                ) : null
                            }
                        />
                    );
                })}
            </div>

            <div>
                <TabsContainer
                    tabs={["All Brands"]}
                    activeTab={activeTab}
                    setActiveTab={(idx) => {
                        setActiveTab(idx);
                    }}
                    component={displayActiveTab()}
                    className="!px-0"
                    itemClassName="!pb-2"
                    borderLineClase={"!text-slate-100 w-[45%] max-lg:w-full mb-6"}
                    tabRowClassname="!overflow-visible"
                    showButton={
                        <div className=" flex items-center space-x-4">
                            <TextInput
                                name={"search"}
                                value={search}
                                type={"text"}
                                inputPlaceholder={"Search brand name"}
                                inputClassName={
                                    "!h-[38px] !w-[300px] pl-[0px] !bg-white text-sm !mb-0"
                                }
                                onChange={handleSearch}
                                onInput={handleDebouncedChange}
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px]  max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-[0px_1px_2px_0px_#5B687126] !border !border-n-20"
                                }
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default PortfolioMetrics;
