import React, { useEffect, useState } from "react";
import CustomTable from "../../../../../common/CustomTable";
import Dropdown from "components/common/Dropdown";
import { useNavigate } from "react-router-dom";
import InventoryAnalyticsCard from "components/common/InventoryAnalyticsCard";
import VendorAvatar from "components/common/VendorAvatar";
import { truncate } from "lodash";
import { Tooltip } from "react-tooltip";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import moment from "moment";
import CustomTableLoader from "components/common/CustomTableLoader";
import Loader from "components/common/Loader";
import EmptyStateComp from "components/common/EmptyStateComp";

import * as XLSX from "xlsx";
import useGetGroupInventorySnapshot from "hooks/inventoryHooks/useGetGroupInventorySnapshot";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";

type Timelineprops = {
    data: Record<string, any> | Record<string, any>[];
    isLoading: boolean;
    isLoadingMore: boolean;
    pagination: any;
    dates: any;
    search: string;
    warehouseId: string;
    selectedSnapshot: any;
    setSelectedSnapshot: any;
    groupDate: string;
    setGroupDate: (data: string) => void;
    handleExport: (data:string) => void;
    exportLoading: boolean

};
const Timeline = ({
    data,
    isLoading,
    isLoadingMore,
    pagination,
    dates,
    search,
    warehouseId,
    selectedSnapshot,
    setSelectedSnapshot,
    groupDate,
    setGroupDate,
    handleExport,
    exportLoading
}: Timelineprops) => {
    const [expandedRowIdx, setExpandedRowIdx] = useState<number>(-1);

    const {
        data: snapshotsList,
        snapshotStats,
        setDate,
        isLoading: isLoadingGroup,
        isLoadingMore: isLoadingMoreGroup,
        pagination: paginationGroup,
    } = useGetGroupInventorySnapshot();

    const tableHeader = [
        {
            title: "Snapshot date",
            widthClass: "w-[50%] !border-slate-200 pl-2",
        },
        { title: "Total skus", widthClass: "w-[50%] !border-slate-200" },
        { title: "", widthClass: "w-[5%] !border-slate-200" },
    ];

    const tableBody = data?.map((snapshot, idx) => {
        const isChecked = snapshot?.date === groupDate;

        return [
            {
                content: (
                    <div
                        className={`py-4 pr-3 flex font-rocGroteskMedium items-center h-full text-sm text-g-75 `}
                    >
                        <p className="">
                            {snapshot?.date && moment(snapshot?.date).format("MMMM D, YYYY")}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
                expandedRowAction: () => {
                    idx === expandedRowIdx ? setExpandedRowIdx(-1) : setExpandedRowIdx(idx);
                    setDate(snapshot?.date);
                },
                isItemChecked: isChecked,
                onCheckBoxClick: () => {
                    snapshot?.date !== groupDate ? setGroupDate(snapshot?.date) : setGroupDate("");
                },
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-g-75 `}>
                        <p className="">{snapshot?.totalSkus}</p>
                    </div>
                ),
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`} onClick={(e) => e.stopPropagation()}>
                        <Dropdown
                            dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-2 z-[1400] border-0 rounded !max-h-fit right-[0px] `}
                            name={"dropdown"}
                             dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100 "
                            dropdownOptions={[
                                {
                                    label: idx === expandedRowIdx ? "Close snapshot" : "View snapshot",
                                    value: "View",
                                    icon: <i className="ri-eye-line text-gm-50"> </i>,
                                    action: () => {
                                        idx === expandedRowIdx
                                            ? setExpandedRowIdx(-1)
                                            : setExpandedRowIdx(idx);
                                        setGroupDate(snapshot?.date);
                                        setDate(snapshot?.date);
                                    },
                                    labelClassName: "!text-slate-600 !text-sm",
                                },
                                {
                                    label: `${(exportLoading && groupDate === snapshot?.date) ? "Loading snapshot" :"Export snapshot"}`,
                                    value: "Export",
                                    icon: <i className="ri-arrow-go-forward-fill text-slate-600"> </i>,

                                    action: () => {
                                      if(!exportLoading ){
                                        setGroupDate(snapshot?.date);
                                          handleExport(snapshot?.date)

                                      }
                                    },
                                    labelClassName: "!text-slate-600 !text-sm",
                                },
                            ]}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200 !border-b",
            },
        ];
    });

    const expandedTableHeader = [
        {
            title: "Product",
            widthClass: "!border-slate-200 w-[290px]",
        },
        { title: "SKUs", widthClass: "!border-slate-200 w-[150px]" },
        { title: "Sales channels", widthClass: "!border-slate-200 w-[172px]" },
        {
            title: "Categories",
            widthClass: "!border-slate-200 w-[140px]",
        },
        { title: "End-of-month stock level", widthClass: "!border-slate-200 w-[172px]" },
        { title: "End-of-month SKU cost", widthClass: "!border-slate-200 w-[172px]" },
        { title: "Warehouse", widthClass: "!border-slate-200 w-[240px]" },
    ];

    

    const expandedTableBody = snapshotsList?.map((product, idx) => {
        return [
            {
                content: (
                    <div
                        className={`py-2 px-3 flex font-rocGroteskMedium items-center h-full text-sm `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.products?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.products?.productImageDetails?.productAvatar ||
                                        product?.products?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={product?.products?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <span className="">
                                {truncate(product?.products?.productName, {
                                    length: 55,
                                })}
                            </span>
                        </div>
                    </div>
                ),

                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-xs text-g-75 `}>
                        <p className=""> {product?.products?.sku}</p>
                    </div>
                ),
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-200 text-sm text-g-75 `}
                    >
                        <div>
                            <a id={`channel-column-${idx}`}>
                                {product?.products?.channels?.length > 1 ? (
                                    <div className="flex items-center space-x-2">
                                        {product?.products?.channels.map((channel) => (
                                            <span key={channel}>
                                                {displayProductChannelIcon(channel)}
                                            </span>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="flex items-center space-x-2">
                                        {displayProductChannelIcon(product?.products?.channels[0])}
                                        <span>
                                            {truncate(product?.products?.channels[0], {
                                                length: 15,
                                            })}
                                        </span>
                                    </div>
                                )}
                            </a>

                            <Tooltip
                                anchorSelect={`#channel-column-${idx}`}
                                place={"top"}
                                content={
                                    product?.products?.channels?.length > 1
                                        ? product.products.channels
                                              .map((channel) => channel)
                                              ?.join(", ")
                                        : product?.products?.channels[0]
                                }
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>
                    </div>
                ),
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-g-75 `}>
                        <p className=""> {product?.products?.inventoryCategory}</p>
                    </div>
                ),
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-g-75 `}>
                        <p className="">
                            {product?.products?.lastStockDetails?.stockLevel !== undefined
                                ? formatMoney()?.format(
                                      product?.products?.lastStockDetails?.stockLevel
                                  )
                                : "N/A"}
                            {` `}
                            {product?.products?.lastStockDetails?.stockLevel !== undefined
                                ? product?.products?.lastStockDetails?.unitOfMeasurement
                                : ""}
                        </p>
                    </div>
                ),
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-g-75 `}>
                        <p className="">
                            {product?.products?.lastSkuCost?.amount !== undefined
                                ? getCurrencyFromCurrencyCode(
                                      product?.products?.lastSkuCost?.currency
                                  )
                                : ""}
                            {product?.products?.lastSkuCost?.amount !== undefined
                                ? formatMoney()?.format(product?.products?.lastSkuCost?.amount)
                                : "N/A"}
                        </p>
                    </div>
                ),
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-g-75 `}>
                        <p className="">
                            {truncate(product?.products?.warehouseName, { length: 30 })}
                        </p>
                    </div>
                ),
                widthClass: "!border-slate-200 !border-b ",
            },
        ];
    });
  

    return (
        <div>
            <div className="overflow-x-scroll mb-6">



                
                {isLoading && !search && warehouseId === "" && data?.length === 0 ? (
                    <div className="overflow-x-hidden">
                        <CustomTableLoader tableHeader={tableHeader} />
                    </div>
                ) : data?.length >= 1 ? (
                    <div>
                        <CustomTable
                            tableBody={tableBody}
                            tableHeader={tableHeader}
                            isCellBordered={true}
                            isCellSelectable={true}
                            stickyHeader={true}
                            headerContainerClass="!bg-slate-50 !border-slate-200"
                            headerItemClass="font-rocGroteskMedium "
                            tableClass="mb-12 !border-slate-200"
                            isCollapseable={true}
                            expandedRowIdx={expandedRowIdx}
                            // bodyItemClass="!border-slate-200"
                            rowExpanded={
                                <div className="relative z-[1300]">
                                    {isLoadingGroup && snapshotsList?.length === 0 ? (
                                        <div className="flex justify-center my-4">
                                            <Loader color="gm-25" size={8} />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="pt-2.5 px-3 bg-slate-50">
                                                <div className="grid grid-cols-3 gap-4">
                                                    <div className="">
                                                        <InventoryAnalyticsCard
                                                            title="Ending Stock Level"
                                                            containerClassName="bg-[#fff] border border-slate-200"
                                                            icon={
                                                                <i className="ri-box-2-line text-2xl text-[#7C3AED] mb-2.5"></i>
                                                            }
                                                            value={
                                                                snapshotStats?.totalInventoryStock &&
                                                                formatMoney()?.format(
                                                                    snapshotStats?.totalInventoryStock
                                                                )
                                                            }
                                                            tooltipIcon={
                                                                <a id="ending-stock">
                                                                    <i className="ri-information-fill text-slate-500 text-base"></i>
                                                                </a>
                                                            }
                                                        />
                                                        <Tooltip
                                                            anchorSelect="#ending-stock"
                                                            place={"top"}
                                                            content="This shows the ending stock level for previous month"
                                                            style={{ width: "250px", zIndex: 9999 }}
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <InventoryAnalyticsCard
                                                            title="Average inventory per warehouse"
                                                            containerClassName="bg-[#fff] border border-slate-200"
                                                            icon={
                                                                <i className="ri-box-2-fill text-2xl text-[#16A34A] mb-2.5"></i>
                                                            }
                                                            value={
                                                                snapshotStats?.averageInventoryPerWarehouse &&
                                                                formatMoney()?.format(
                                                                    snapshotStats?.averageInventoryPerWarehouse
                                                                )
                                                            }
                                                            tooltipIcon={
                                                                <a id="average-inventory">
                                                                    <i className="ri-information-fill text-slate-500 text-base"></i>
                                                                </a>
                                                            }
                                                        />
                                                        <Tooltip
                                                            anchorSelect="#average-inventory"
                                                            place={"top"}
                                                            content=""
                                                            style={{ width: "250px", zIndex: 9999 }}
                                                        />
                                                    </div>

                                                    <div className="">
                                                        <InventoryAnalyticsCard
                                                            title="Ending Inventory Value"
                                                            containerClassName="bg-[#fff] border border-slate-200"
                                                            icon={
                                                                <i className="ri-money-dollar-circle-fill text-2xl text-y-10 mb-2.5"></i>
                                                            }
                                                            value={`${getCurrencyFromCurrencyCode(
                                                                snapshotStats?.currency
                                                            )}
                                                             ${
                                                                 snapshotStats?.totalInventoryValue &&
                                                                 formatMoney()?.format(
                                                                     snapshotStats?.totalInventoryValue
                                                                 )
                                                             }`}
                                                            tooltipIcon={
                                                                <a id="ending-inventory">
                                                                    <i className="ri-information-fill text-slate-500 text-base"></i>
                                                                </a>
                                                            }
                                                        />
                                                        <Tooltip
                                                            anchorSelect="#ending-inventory"
                                                            place={"top"}
                                                            content="This shows the ending stock value for previous month"
                                                            style={{ width: "250px", zIndex: 9999 }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="overflow-x-scroll">
                                                    <CustomTable
                                                        tableBody={expandedTableBody}
                                                        tableHeader={expandedTableHeader}
                                                        isCellBordered={true}
                                                        isInifiniteScroll={true}
                                                        // isAllSelectable={true}
                                                        stickyHeader={true}
                                                        headerContainerClass="!bg-slate-50 !border-slate-200"
                                                        headerItemClass="font-rocGroteskMedium "
                                                        tableClass="my-3 !border-t-slate-200 !border-x-slate-200"
                                                        isScrollable={true}
                                                        tableContainerClass=""
                                                        // bodyItemClass="!border-slate-200"
                                                        expandedRowTClass="!z-[1300]"
                                                    />
                                                </div>
                                            </div>

                                            {isLoadingMoreGroup && (
                                                <div className="flex justify-center my-4">
                                                    <Loader color="gm-25" size={4} />
                                                </div>
                                            )}

                                            {paginationGroup?.currentPage ===
                                                paginationGroup?.noOfPages && (
                                                <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                                    End of list
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            }
                        />

                        {isLoadingMore && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}

                        {pagination?.currentPage === pagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (search || warehouseId || dates) && data?.length === 0 ? (
                    <div className="h-[200px] flex items-center justify-center ">
                        <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                            No data available
                        </p>
                    </div>
                ) : (
                    // <div className="border border-slate-200 rounded-lg py-8">
                    //     <EmptyStateComp
                    //         title="No Work Orders Available"
                    //         description="Once a production plan is created, it will appear here as a work order, allowing you to track and manage each step in the production process. Start by creating a production plan"
                    //         icon={<i className="ri-file-2-line text-[#64748B] text-[40px]"></i>}
                    //         hasButton={true}
                    //         btnText="Create production plan"
                    //         btnAction={() => {
                    //             navigate(`/dashboard/production/create-plan?currTab=1`);
                    //         }}
                    //         contentContainerClass="flex flex-col items-center max-w-[700px] !bg-white"
                    //         descriptionClass="flex flex-col items-center justify-center"
                    //         textClass="text-center"
                    //     />
                    // </div>
                    <div className="h-[200px] flex items-center justify-center ">
                        <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                            No data available
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Timeline;
