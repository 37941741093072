import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { authActions, trackTraceActions, vendorActions } from "redux/Ldb/actions";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "../useInfinteScroll";
import { debounce } from "lodash";

const useGetTeam = () => {
    const dispatch = useAppDispatch();
    const [teamList, setTeamList] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [search, setSearch] = useState<string>("");

    const {
        fetchingTeamMember,
        fetchedTeamMemberSuccess,
        fetchedTeamMemberFailure,
        addTeamMemberSuccess,
    } = useAppSelector((state) => state.auth);

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            15
        );

    const handleSearch = (value: string) => {
        setSearch(value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setDebouncedSearch(e.target.value);
    }, 1000);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamList((prev) =>
                updatedPage === 1
                    ? [...fetchedTeamMemberSuccess?.teamMembers]
                    : [...prev, ...fetchedTeamMemberSuccess?.teamMembers]
            );

            setPagination({
                currentPage: fetchedTeamMemberSuccess?.pagination?.current,
                noOfPages: fetchedTeamMemberSuccess?.pagination?.number_of_pages,
            });

            setLoadMore(false);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers(15, debouncedSearch, updatedPage));
    }, [dispatch, updatedPage, debouncedSearch, addTeamMemberSuccess]);

    return {
        data: teamList,
        isFetching: fetchingTeamMember,
        error: fetchedTeamMemberFailure,
        search,
        handleSearch,
        handleDebouncedChange,
        isLoadingMore,
        pagination,
        setTeamList,
    };
};

export default useGetTeam;
