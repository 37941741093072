import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";
import { getFromStorage } from "helpers";

const useGetAsignedBrands = (scrollContent?: HTMLElement) => {
    const dispatch = useAppDispatch();
    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-user"));
    const [brandList, setBrandList] = useState<{ [key: string]: any }[]>([]);
    const [brandListSearch, setBrandListSearch] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [searchTwo, setSearchTwo] = useState<string>("");
    const [debouncedSearchTwo, setDebouncedSearchTwo] = useState<string>("");

    const {
        fetchingAssignedBrands,
        fetchAssignedBrandsSuccess,
        fetchAssignedBrandsFailure,
        fetchBrandTokenSuccess,
        fetchingAssignedBrandsSearch,
        fetchAssignedBrandsSearchSuccess,
    } = useAppSelector((state) => state.multibrand);
    const { fetchUserProfileSuccess } = useAppSelector((state) => state.auth);

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10,
            scrollContent
        );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setDebouncedSearch(e.target.value);
    }, 800);

    const handleSearchTwo = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTwo(e.target.value);
    };

    const handleDebouncedChangeTwo = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setDebouncedSearchTwo(e.target.value);
    }, 800);

    useEffect(() => {
        if (Boolean(fetchAssignedBrandsSuccess)) {
            setBrandList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchAssignedBrandsSuccess?.brands], "_id")
                    : uniqBy([...prev, ...fetchAssignedBrandsSuccess?.brands], "_id")
            );
            setPagination({
                currentPage: fetchAssignedBrandsSuccess.pagination?.current,
                noOfPages: fetchAssignedBrandsSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchAssignedBrandsSuccess]);

    useEffect(() => {
        dispatch(multibrandActions?.getAssignedBrands(updatedPage, debouncedSearch));
    }, [dispatch, fetchUserProfileSuccess, updatedPage, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchAssignedBrandsSearchSuccess)) {
            setBrandListSearch(uniqBy([...fetchAssignedBrandsSearchSuccess?.brands], "_id"));
        }
    }, [fetchAssignedBrandsSearchSuccess]);

    useEffect(() => {
        dispatch(multibrandActions?.getAssignedBrandsSearch(updatedPage, debouncedSearchTwo));
    }, [dispatch, fetchUserProfileSuccess, debouncedSearchTwo]);

    return {
        data: brandList,
        isFetching: fetchingAssignedBrands,
        error: fetchAssignedBrandsFailure,
        isLoadingMore,
        pagination,
        search,
        handleSearch,
        handleDebouncedChange,
        brandListSearch,
        searchTwo,
        setSearchTwo,
        setDebouncedSearchTwo,
        handleSearchTwo,
        handleDebouncedChangeTwo,
        setUpdatedPage,
    };
};

export default useGetAsignedBrands;
