import Dropdown from "components/common/Dropdown";
import TrackTraceMap from "components/common/TrackTraceMap";
import PageTitleSection from "components/common/PageTitleSection";
import TabsContainer from "components/common/TabsContainer";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Dashboard from "components/views/Synth2.0/Inventory/Tabs/Dashboard";
import ProductList from "components/views/Synth2.0/Inventory/Tabs/ProductList";
import Warehouse from "components/views/Synth2.0/Inventory/Tabs/Warehouse";
import Alerts from "components/views/Synth2.0/Inventory/Tabs/Alerts";
import StockControl from "components/views/Synth2.0/Inventory/Tabs/StockControl";
import ExpirationTracking from "components/views/Synth2.0/Inventory/Tabs/ExpirationTracking";
import SkuMapping from "components/views/Synth2.0/Inventory/Tabs/SkuMapping";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import Button from "components/common/Button";
import useGetInventoryIncidents from "hooks/inventoryHooks/useGetInventoryIncidents";
import useGetAllWarehouses from "hooks/useGetAllWarehouses";
import { truncate } from "lodash";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import Snapshots from "components/views/Synth2.0/Inventory/Tabs/InventorySnapshots"

const Inventory = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [incidentType, setIncidentType] = useState("");

    const tabs = [
        // "Dashboard",
        "All Products",
        "Stock Control",
        "Incidents",
        "Warehouse",
        "Expiration Tracking",
        "SKU Mapping",
        "Inventory Snapshot"
    ];
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const currTab = param.get("currTab");
    const dispatch = useAppDispatch();
    const {
        data,
        isFetching,
        pagination,
        isLoadingMore,
        handleClearFilter,
        handleIncidentTypeChange,
        handleWarehouseChange,
    } = useGetInventoryIncidents();

    const {
        data: warehouseList,
        warehouseValue: warehouseDataValue,
        searchTwo,
        handleDebouncedChangeTwo,
        handleSearchTwo,
        searchResult,
        handleFilterChange: filterChange,
        handleClearFilter: clearFilter,
    } = useGetAllWarehouses();

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return <ProductList />;

            case 1:
                return <StockControl />;

            case 2:
                return (
                    <Alerts
                        data={data}
                        isFetching={isFetching}
                        pagination={pagination}
                        isLoadingMore={isLoadingMore}
                    />
                );

            case 3:
                return <Warehouse />;

            case 4:
                return <ExpirationTracking />;

            case 5:
                return <SkuMapping />;
            case 6:
                return <Snapshots />;

            default:
                return <ProductList />;
        }
    };

    useEffect(() => {
        setActiveTab(Number(currTab));
    }, [currTab]);

    return (
        <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
            <div className="mb-6">
                <PageTitleSection
                    leftComp={
                        <div className="flex items-center space-x-3">
                            <h3 className="text-xl font-rocGroteskMedium">Inventory</h3>
                        </div>
                    }
                    rightComp={
                        <div className="flex items-center space-x-1.5 bg-white border border-slate-200 rounded py-2 px-3 h-[33px]">
                            <i className="ri-signal-tower-fill text-base text-[#15803D] "></i>
                            <span className="text-xs text-[#15803DDD] font-rocGroteskMedium">
                                Real time monitoring
                            </span>
                        </div>
                    }
                />
            </div>

            <div className="relative">
                <TabsContainer
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={(idx) => {
                        setActiveTab(idx);
                        dispatch(inventoryActions.resetFetchInventoryList());
                        navigate(`/dashboard/inventory?currTab=${idx}`, { replace: true });
                    }}
                    component={displayActiveTab()}
                    className="!px-0"
                    showButtonClassName
                    itemClassName="!pb-2 !whitespace-nowrap"
                    borderLineClase={"!text-slate-100 w-[67%] max-lg:w-full mb-6"}
                    showButton={
                        <div className="">
                            {activeTab === 3 && (
                                <div className="flex  items-center space-x-3 max-lg:mb-4">
                                    {/* <Button
                                btnText={"Initiate return"}
                                type={"button"}
                                btnClassname="!py-2 !px-4 !w-fit max-sm:w-full !whitespace-nowrap !bg-slate-100"
                                icon={<i className="ri-arrow-turn-back-fill text-base"></i>}
                                onClick={() => navigate("/dashboard/purchase")}
                            /> */}
                                    <Button
                                        btnText={"Add Warehouses"}
                                        type={"button"}
                                        btnClassname="!py-2 !px-4 !w-fit !whitespace-nowrap max-sm:w-full"
                                        onClick={() =>
                                            navigate("/dashboard/inventory/add-warehouse")
                                        }
                                    />
                                </div>
                            )}
                            {activeTab === 2 && (
                                <div className="flex max-lg:mb-4 max-sm:space-x-0 max-sm:flex-col max-sm:space-y-3 items-center space-x-3">
                                    <Dropdown
                                        name="filter"
                                        value={""}
                                        dropdownTriggerClassName={" "}
                                        handleChange={(name, value) => {
                                            setIncidentType(value);
                                            handleIncidentTypeChange(value);
                                        }}
                                        dropdown={
                                            <div className="bg-white flex items-center max-sm:justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    {incidentType || "All Incidents types"}
                                                </span>

                                                <div className="flex items-center space-x-2">
                                                    {incidentType !== "" && (
                                                        <i
                                                            onClick={(e) => {
                                                                e?.stopPropagation();
                                                                setIncidentType("");
                                                                handleClearFilter();
                                                            }}
                                                            className="ri-close-circle-fill text-sm text-slate-500"
                                                        ></i>
                                                    )}
                                                    <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                                </div>
                                            </div>
                                        }
                                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                        dropdownContainerClasses={`shadow-cardShadow-4  !mt-0  !w-[159px] max-sm:!w-full  rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                        wholeContainerClass={`max-sm:!w-full`}
                                        dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                        dropdownOptions={[
                                            {
                                                label: "Overstocking",
                                                value: "overstocking",
                                            },
                                            {
                                                label: "Stock Out",
                                                value: "stockout",
                                            },
                                        ]}
                                    />

                                    <div className="border rounded border-slate-200 max-sm:w-full">
                                        <Dropdown
                                            handleChange={(name, value) => {
                                                handleWarehouseChange(value);
                                            }}
                                            dropdown={
                                                <div className="flex items-center space-x-2 whitespace-nowrap h-[40px] py-2 px-4 justify-between ">
                                                    <div className="flex items-center space-x-1">
                                                        <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                                        <span
                                                            className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                                        >
                                                            {warehouseDataValue?.name ||
                                                                "All Warehouses / Outlets"}
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center space-x-2">
                                                        {warehouseDataValue?.name && (
                                                            <i
                                                                onClick={() => {
                                                                    handleWarehouseChange("");
                                                                    clearFilter();
                                                                }}
                                                                className="ri-close-circle-line text-slate-500"
                                                            ></i>
                                                        )}
                                                        <i className="ri-arrow-down-s-line text-g-75"></i>
                                                    </div>
                                                </div>
                                            }
                                            search={
                                                <div>
                                                    <TextInput
                                                        value={searchTwo}
                                                        name={"search"}
                                                        type={"text"}
                                                        required={false}
                                                        onChange={handleSearchTwo}
                                                        onInput={handleDebouncedChangeTwo}
                                                        inputPlaceholder="Search warehouse"
                                                        containerClassname=""
                                                        // inputContainerClassname={"!border-none !rounded-none "}
                                                        // inputClassName="w-[50%]"
                                                    />
                                                </div>
                                            }
                                            dropdownContainerClasses={`shadow-cardShadow-4 !right-0 max-sm:!w-full  border border-slate-100  rounded `}
                                            wholeContainerClass={`max-sm:!w-full`}
                                            dropdownOptions={[
                                                {
                                                    customChild: (
                                                        <div>
                                                            <div>
                                                                {searchResult?.map((warehouse) => {
                                                                    return (
                                                                        <div
                                                                            key={warehouse?._id}
                                                                            onClick={() => {
                                                                                filterChange(
                                                                                    warehouse?.warehouseName,
                                                                                    warehouse?._id
                                                                                );

                                                                                handleWarehouseChange(
                                                                                    warehouse?._id
                                                                                );
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className={`flex items-center space-x-3 max-w-[300px] px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                                                    warehouseDataValue?.value ===
                                                                                        warehouse?._id &&
                                                                                    "bg-slate-100"
                                                                                }`}
                                                                            >
                                                                                <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                                                <div>
                                                                                    <span
                                                                                        className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                                            warehouse
                                                                                                ?.address
                                                                                                ?.address &&
                                                                                            "mb-[2px]"
                                                                                        }`}
                                                                                    >
                                                                                        {
                                                                                            warehouse?.warehouseName
                                                                                        }
                                                                                    </span>
                                                                                    <span className="block  text-g-75 text-sm font-rocGroteskMedium ">
                                                                                        {
                                                                                            warehouse
                                                                                                ?.address
                                                                                                ?.address
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    ),
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default Inventory;
