import { getFromStorage } from "helpers";
import useGetUserProfile from "hooks/useGetUserProfile";

const Navlinks = () => {
    const [profile] = useGetUserProfile();
    const userProfile = getFromStorage("ally-user");
    const acctType: string = "multi";

    const singleBrandLinks = [
        {
            title: "Dashboard",
            link: "/dashboard/home",
            childrenLinks: [""],
            iconClass: "",
        },
        {
            title: "Inventory",
            link: "/dashboard/inventory",
            childrenLinks: [""],
            iconClass: "ri-shopping-basket-line",
        },
        {
            title: "Purchase",
            link: "/dashboard/purchase",
            childrenLinks: [""],
            iconClass: "ri-file-list-3-line",
        },
        {
            title: "Suppliers",
            link: "/dashboard/suppliers",
            childrenLinks: [""],
            iconClass: "ri-building-line",
        },
        {
            title: "Production",
            link: "/dashboard/production",
            childrenLinks: [""],
            iconClass: "",
        },
        {
            title: "WorkforceIQ",
            link: "/dashboard/workforce",
            childrenLinks: [""],
            iconClass: "ri-building-line",
        },
        {
            title: "Tasks",
            link: "/dashboard/task",
            childrenLinks: [""],
            iconClass: "ri-building-line",
        },

        {
            title: "Sales",
            link: "/dashboard/order",
            childrenLinks: [""],
            iconClass: "ri-file-line",
        },

        // {
        //     title: "Teams",
        //     link: "/dashboard/teams",
        //     childrenLinks: [""],
        //     iconClass: "",
        // },

        // {
        //     title: "Playground",
        //     link: "/dashboard/playground",
        //     childrenLinks: [""],
        //     iconClass: "ri-task-line",
        // },
    ]?.filter((item) => {
        if (
            profile?.role === "owner" ||
            userProfile?.user?.role === "owner" ||
            userProfile?.user?.parent?.role === "owner"
        ) {
            return true;
        } else {
            return !item?.title?.toLowerCase()?.includes("workforce");
        }
    });

    const multiBrandLinks = [
        {
            title: "Dashboard",
            link: "/dashboard/portfolio/home",
            childrenLinks: [""],
            iconClass: "",
        },
        {
            title: "Inventory",
            link: "/dashboard/portfolio/inventory",
            childrenLinks: [""],
            iconClass: "ri-shopping-basket-line",
        },
        {
            title: "Portfolio Management",
            link: "/dashboard/portfolio/management",
            childrenLinks: [""],
            iconClass: "ri-file-list-3-line",
        },
        {
            title: "Team Management",
            link: "/dashboard/portfolio/team",
            childrenLinks: [""],
            iconClass: "ri-building-line",
        },
    ];

    return profile?.isMultiBrandAccount || profile?.parent?.isMultiBrandAccount
        ? multiBrandLinks
        : singleBrandLinks;
};

export default Navlinks;
