import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";

const useGetGroupInventorySnapshot = () => {
    const dispatch = useAppDispatch();
    const [date, setDate] = useState<string>("");
    const [stats, setStats] = useState<{ [key: string]: any }>({});

    const [groupInventorySnapshot, setGroupInventorySnapshot] = useState<{ [key: string]: any }[]>(
        []
    );

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { fetchingGroupInventorySnapshot, fetchGroupInventorySnapshotSuccess } = useAppSelector(
        (state) => state.inventory
    );

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    useEffect(() => {
        setUpdatedPage(1);
    }, [date]);

    useEffect(() => {
        date && dispatch(inventoryActions.fetchGroupInventorySnapshot(10, updatedPage, date));
    }, [dispatch, updatedPage, date]);

    useEffect(() => {
        if (Boolean(fetchGroupInventorySnapshotSuccess)) {
            setGroupInventorySnapshot((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchGroupInventorySnapshotSuccess?.inventorySnapShots], "_id")
                    : uniqBy(
                          [...prev, ...fetchGroupInventorySnapshotSuccess?.inventorySnapShots],
                          "_id"
                      )
            );
            setStats(fetchGroupInventorySnapshotSuccess?.groupStats);
            setPagination({
                currentPage: fetchGroupInventorySnapshotSuccess.pagination?.current,
                noOfPages: fetchGroupInventorySnapshotSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
            dispatch(inventoryActions.resetGroupInventorySnapshot());
        }
    }, [dispatch, fetchGroupInventorySnapshotSuccess, updatedPage]);

    return {
        data: groupInventorySnapshot,
        snapshotStats: stats,
        isLoading: fetchingGroupInventorySnapshot,
        isLoadingMore,
        pagination,
        date,
        setDate,
    };
};

export default useGetGroupInventorySnapshot;
