import React, { useEffect, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

const BigCalendarComp = ({
    setGridDate,
    gridDate,
    setView,
    view,
    viewType,
    setViewType,
    plans,
}) => {
    const navigate = useNavigate();
    const localizer = momentLocalizer(moment);
    const period = ["Day", "Week", "Month"];

    const calendarRef = useRef(null);
    const [tooltipContent, setTooltipContent] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({});

    const [tooltip, setTooltip] = useState({ visible: false, event: null, position: {} });

    const handleDateMouseOver = () => {
        // Hide the tooltip when hovering over a date
        setTooltip({ visible: false, event: null, position: {} });
    };

    const CustomToolbar = ({ label, onNavigate }) => {
        return (
            <div className="flex items-center justify-between p-4 border-t border-x border-slate-200 rounded-t-lg w-full">
                <div className="flex gap-2">
                    <button
                        onClick={() => onNavigate("PREV")}
                        className="bg-slate-100 rounded-md w-8 h-8 flex items-center justify-center"
                    >
                        <i className="ri-arrow-left-line text-gm-50 text-sm"></i>
                    </button>

                    <button
                        onClick={() => onNavigate("NEXT")}
                        className="bg-slate-100 rounded-md w-8 h-8 flex items-center justify-center"
                    >
                        <i className="ri-arrow-right-line text-gm-50 text-sm"></i>
                    </button>
                </div>
                <p className="text-base text-g-75 ">{moment(gridDate).format("MMM Do, YYYY")}</p>

                <div className="flex gap-2 items-center">
                    <div className="flex">
                        <button
                            className={`${
                                viewType === "chart" && "bg-slate-200"
                            } py-[6px] px-3 text-gm-50 text-[13px] border-l  border border-slate-200 rounded-l`}
                            onClick={() => setViewType("chart")}
                        >
                            <i className="ri-line-chart-line"></i>
                        </button>
                        <div className="border-slate-200 border rounded">
                            <button
                                className={`${
                                    viewType === "list" && "bg-slate-200"
                                } py-[6px] px-3 text-gm-50 text-[13px] border-l`}
                                onClick={() => setViewType("list")}
                            >
                                <i className="ri-list-check"></i>
                            </button>
                            <button
                                className={`${
                                    viewType === "grid" && "bg-slate-200"
                                } py-[6px] px-3 text-gm-50 text-[13px] border-l border-slate-200`}
                                onClick={() => setViewType("grid")}
                            >
                                <i className="ri-layout-grid-line"></i>
                            </button>
                        </div>
                    </div>

                    {viewType !== "list" && (
                        <div className="border-slate-200 border rounded">
                            {period?.map((item, idx) => {
                                return (
                                    <button
                                        key={idx}
                                        className={`${idx === 1 && "border-x border-x-slate-200"} ${
                                            view === item.toLowerCase() && "bg-slate-200"
                                        } py-[6px] px-3 text-gm-50 text-[13px] leading-normal`}
                                        onClick={() => setView(item.toLowerCase())}
                                    >
                                        {item}
                                    </button>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const CustomEvent = ({ event }) => (
        <div
            style={{
                color: "#000", // Text color
                padding: "5px",
                fontSize: "14px",
            }}
            //   className={`${event.type === "normal" ? "bg-[#ffcc00]" : "bg-[#000]"}`}
        >
            <p>{`${event.productName} production`}</p>
        </div>
    );

    const handleClickOutside = () => {
        setTooltipContent(null); // Hide tooltip when clicking outside
    };

    // Add an event listener to handle clicks outside of the tooltip
    useEffect(() => {
        const handleClick = (e) => {
            if (!tooltipContent) return;
            const tooltipElement = document.getElementById("tooltip");
            if (tooltipElement && !tooltipElement.contains(e.target)) {
                handleClickOutside();
            }
        };

        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [tooltipContent]);

    console.log(tooltipPosition);
    return (
        <div>
            <Calendar
                localizer={localizer}
                events={plans}
                ref={calendarRef}
                startAccessor="start"
                endAccessor="end"
                initialView="dayGridDAY"
                style={{ height: 900 }}
                date={gridDate}
                onNavigate={(newDate) => setGridDate(newDate)}
                view={view}
                onView={setView}
                selectable={false}
                onSelectSlot={handleDateMouseOver} // Prevent tooltip on date hover
                components={{
                    //   event: ({ event }) => (
                    //     <div
                    //       onMouseOver={(e) => handleEventMouseOver(event, e)}
                    //       onMouseOut={handleEventMouseOut}
                    //       style={{ cursor: 'pointer' }}
                    //     >
                    //       {event.title}
                    //     </div>
                    //   ),

                    event: CustomEvent,
                    toolbar: CustomToolbar, // Use the custom toolbar
                }}
                // tooltipAccessor={tooltipAccessor} // Use the tooltip accessor

                onSelectEvent={(event, e) => {
                    e.stopPropagation(); // Prevent default behavior
                    setTooltipContent(event);
                    console.log(event);
                    setTooltipPosition({ top: e.clientY - 200, left: e.clientX - 100 });
                }}
                formats={{
                    timeGutterFormat: "h a", // Format for the time gutter (e.g., "8am")
                    dayFormat: "ddd D", // Customize day format if needed
                    //   weekdayFormat: (date, culture, localizer) =>
                    //   localizer.format(date, 'dddd', culture),

                    agendaTimeFormat: {
                        hour: "numeric",
                        minute: "numeric",
                        meridiem: "short",
                    },
                    eventTimeRangeFormat: ({ start, end }) => {
                        return `${moment(start).format("ha")} - ${moment(end).format("ha")}`;
                    },
                }}
            />
            {tooltipContent && (
                <div
                    id="tooltip"
                    style={{
                        position: "absolute",
                        top: tooltipPosition.top,
                        left: tooltipPosition.left < 400 ? tooltipPosition.left : "",
                        right: tooltipPosition.left > 400 ? 10 : "",

                        backgroundColor: "#fff",
                        // border: "1px solid #ccc",
                        zIndex: 1000,
                    }}
                    className={` rounded bg-white max-w-[800px] !shadow-[0px_24px_32px_0px_rgba(91,104,113,0.10)]`}
                >
                    <div className="p-4 border-b border-slate-200 flex justify-between items-start">
                        <div className="border-l-2 border-[#FFB902] rounded-[2px] pl-2">
                            <p className="text-[#042821] text-base">{tooltipContent?.event}</p>
                            <p className="text-slate-500 text-sm">PO-{tooltipContent?.po}</p>
                        </div>
                        <i
                            className="ri-close-fill text-[#142837] cursor-pointer"
                            onClick={() => setTooltipContent(null)}
                        ></i>
                    </div>

                    <div className="grid grid-cols-3 p-4 border-slate-200 gap-y-4">
                        <div className="">
                            <p className="text-slate-500 text-sm">Product</p>
                            <p className="text-[#142837] text-sm">{tooltipContent?.productName}</p>
                        </div>

                        <div className="">
                            <p className="text-slate-500 text-sm">Production Start Date:</p>
                            <p className="text-[#142837] text-sm">
                                {tooltipContent?.startDate
                                    ? moment(tooltipContent?.startDate)?.format("MMM DD, YYYY")
                                    : "N/A"}
                            </p>
                        </div>
                        <div className="">
                            <p className="text-slate-500 text-sm">Estimated Completion Date</p>
                            <p className="text-[#142837] text-sm">
                                {" "}
                                {tooltipContent?.endDate
                                    ? moment(tooltipContent?.endDate)?.format("MMM DD, YYYY")
                                    : "N/A"}
                            </p>
                        </div>
                        <div className="">
                            <p className="text-slate-500 text-sm">Production Quantity</p>
                            <p className="text-[#142837] text-sm">{tooltipContent?.quantity}</p>
                        </div>

                        <div className="">
                            <p className="text-slate-500 text-sm">Due Date:</p>
                            <p className="text-[#142837] text-sm">
                                {tooltipContent?.startDate
                                    ? moment(tooltipContent?.startDate)?.format("MMM DD, YYYY")
                                    : "N/A"}
                            </p>
                        </div>
                        <div className="">
                            <p className="text-slate-500 text-sm">Production Facility</p>
                            <p className="text-[#142837] text-sm">N/A</p>
                        </div>
                    </div>

                    {/* <div className="p-4 border-slate-200">
                        <p className="text-[#142837] text-sm mb-1">5:30 am </p>

                        <p className="text-slate-500 text-sm">August 31,2024 - August 31,2024</p>
                    </div> */}
                    {/* <div className="p-4">
                        <p className="text-slate-500 text-sm mb-2">Assigned to</p>

                        <div className="flex gap-2">
                            <div className="w-5 h-5 rounded-full shrink-0 flex items-center justify-center bg-slate-100 ">
                                <i className="ri-user-follow-line text-sm text-gm-50"></i>
                            </div>
                            <div className="w-5 h-5 rounded-full shrink-0 flex items-center justify-center bg-slate-100 ">
                                <i className="ri-user-follow-line text-sm text-gm-50"></i>
                            </div>
                            <p className="text-[#142837] text-sm mb-1">
                                Keoni Kanoa, Leilani Mahelona and 2 others{" "}
                            </p>
                        </div>
                    </div> */}
                    <div className=" border-t border-slate-200 text-sm">
                        <div className="p-4 flex justify-between">
                            <div className="flex items-center gap-2">
                                <div
                                    className={`w-2 h-2 rounded-full shrink-0 flex items-center justify-center captialize
                                  ${
                                      tooltipContent?.status === "pending"
                                          ? "bg-[#6366F1]"
                                          : tooltipContent?.status === "completed"
                                          ? "bg-[#22C55E]"
                                          : tooltipContent?.status === "in-progress"
                                          ? "bg-[#3B82F6]"
                                          : tooltipContent?.status === "cancelled"
                                          ? "bg-[#EF4444]"
                                          : tooltipContent?.status === "on-hold"
                                          ? "bg-[#EAB308]"
                                          : tooltipContent?.status === "rescheduled"
                                          ? "bg-[#8B5CF6]"
                                          : tooltipContent?.status === "planned"
                                          ? "bg-[#14B8A6]"
                                          : tooltipContent?.status === "delayed"
                                          ? "bg-[#F97316]"
                                          : tooltipContent?.status === "partial-completed"
                                          ? "bg-[#0EA5E9]"
                                          : tooltipContent?.status && "bg-[#6B7280]"
                                  }
                                    `}
                                ></div>

                                <span className="capitalize">
                                    {tooltipContent?.status === "in-progress"
                                        ? "In progress"
                                        : tooltipContent?.status === "on-hold"
                                        ? "On hold"
                                        : tooltipContent?.status}
                                </span>
                            </div>
                            <Button
                                btnText="View more details"
                                onClick={() =>
                                    navigate(`/dashboard/production/${tooltipContent?.id}`)
                                }
                                btnClassname="!px-4 !py-3 !text-g-75 !w-fit text-[13px]"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BigCalendarComp;
