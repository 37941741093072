import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { productionActions } from "redux/Ldb/actions";

const useGetSingleWorkOrder = () => {
    const { workOrderId } = useParams();
    const [params] = useSearchParams();
    const dispatch = useAppDispatch();
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [workOrderDetails, setWorkOrderDetails] = useState<{ [key: string]: any }>({});

    const { fetchingSingleWorkOrder, fetchedSingleWorkOrderSuccess } = useAppSelector(
        (state) => state.production
    );

    useEffect(() => {
        if (Boolean(fetchedSingleWorkOrderSuccess)) {
            setWorkOrderDetails(fetchedSingleWorkOrderSuccess?.workOrder);
        }
    }, [fetchedSingleWorkOrderSuccess]);

    useEffect(() => {
        if (workOrderId) {
            dispatch(productionActions.fetchSingleWorkOrder(workOrderId));
        }
    }, [dispatch, workOrderId]);

    return {
        data: workOrderDetails,
        isLoading: fetchingSingleWorkOrder,
    };
};

export default useGetSingleWorkOrder;
