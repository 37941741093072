import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";
import moment, { months } from "moment";
import React, { useEffect, useState } from "react";
import { productionActions } from "redux/Ldb/actions";

const useGetProductionForecastList = () => {
    const dispatch = useAppDispatch();
    // const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [productionForecastList, setProductionForecastList] = useState<{ [key: string]: any }[]>(
        []
    );
    const [dates, setDates] = useState<{ [key: string]: any }>({});

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { fetchingProductionForecastList, fetchedProductionForecastListSuccess } = useAppSelector(
        (state) => state.production
    );

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    useEffect(() => {
        setUpdatedPage(1);
    }, [debouncedSearch, dates]);

    useEffect(() => {
        const currentMonth = moment()?.startOf("month");
        const lastMonth = moment().add(11, "months")?.endOf("month");
        setDates({
            startDate: currentMonth.toISOString(),
            endDate: lastMonth.toISOString(),
        });
    }, []);

    useEffect(() => {
        dates?.startDate &&
            dates?.endDate &&
            dispatch(
                productionActions.fetchProductionForecastList(
                    20,
                    updatedPage,
                    debouncedSearch,
                    dates?.startDate,
                    dates?.endDate
                )
            );
    }, [dispatch, updatedPage, debouncedSearch, dates]);

    useEffect(() => {
        if (Boolean(fetchedProductionForecastListSuccess)) {
            setProductionForecastList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchedProductionForecastListSuccess?.schedules], "_id")
                    : uniqBy([...prev, ...fetchedProductionForecastListSuccess?.schedules], "_id")
            );
            setPagination({
                currentPage: fetchedProductionForecastListSuccess.pagination?.current,
                noOfPages: fetchedProductionForecastListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
            dispatch(productionActions.resetFetchProductionForecastList());
        }
    }, [dispatch, fetchedProductionForecastListSuccess, updatedPage]);

    return {
        data: productionForecastList,
        isLoading: fetchingProductionForecastList,
        isLoadingMore,
        pagination,
        debouncedSearch,
        setDebouncedSearch,
        dates,
        setDates,
    };
};

export default useGetProductionForecastList;
