import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";

const useGetBrandInventory = () => {
    const dispatch = useAppDispatch();
    const [brandInventoryList, setBrandInventoryList] = useState<{ [key: string]: any }[]>([]);

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");

    const { fetchingBrandInventory, fetchBrandInventorySuccess, fetchBrandInventoryFailure } =
        useAppSelector((state) => state.multibrand);

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setDebouncedSearch(e.target.value);
    }, 800);

    useEffect(() => {
        if (Boolean(fetchBrandInventorySuccess)) {
            setBrandInventoryList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchBrandInventorySuccess?.inventory?.data], "_id")
                    : uniqBy([...prev, ...fetchBrandInventorySuccess?.inventory?.data], "_id")
            );
            setPagination({
                currentPage: fetchBrandInventorySuccess.pagination?.current,
                noOfPages: fetchBrandInventorySuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchBrandInventorySuccess]);

    useEffect(() => {
        dispatch(multibrandActions?.getBrandInventory(updatedPage, debouncedSearch));
    }, [dispatch, debouncedSearch, updatedPage]);

    return {
        data: brandInventoryList,
        isFetching: fetchingBrandInventory,
        error: fetchBrandInventoryFailure,
        isLoadingMore,
        pagination,
        search,
        debouncedSearch,
        handleSearch,
        handleDebouncedChange,
        setUpdatedPage,
        updatedPage,
    };
};

export default useGetBrandInventory;
