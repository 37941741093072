import React from "react";
import Button from "./Button";

type EmptyStateCompProps = {
    title: string;
    description?: string;
    icon?: React.ReactNode;
    hasButton?: boolean;
    btnText?: string;
    btnAction?: () => void;
    hasButton2?: boolean;
    btn2Text?: string;
    btn2Action?: () => void;
    contentContainerClass?: string;
    className?: string;
    descriptionClass?: string;
    btn2Classname?: string;
    textClass?: string;
};

const EmptyStateComp = ({
    title,
    description,
    icon,
    hasButton,
    btnText,
    btnAction,
    hasButton2,
    btn2Text,
    btn2Action,
    contentContainerClass,
    className,
    descriptionClass,
    btn2Classname,
    textClass,
}: EmptyStateCompProps) => {
    return (
        <div className={`flex justify-center w-full my-12 ${className} `}>
            <div
                className={`flex space-x-6 ${
                    hasButton ? "items-start" : "items-center"
                } p-10 w-[59%] ${contentContainerClass} border border-slate-200 rounded-lg shadow-[0px_8px_8px_-4px_#10182808] max-sm:w-full max-sm:p-5`}
            >
                {icon}
                <div className={`${descriptionClass}`}>
                    <h6 className="mb-2 text-base font-rocGroteskMedium">{title}</h6>
                    {description && <p className={`text-sm text-slate-500 mb-4 ${textClass}`}>{description}</p>}
                    <div className={`${hasButton2 ? "flex justify-between gap-4" : ""}`}>
                        {hasButton && (
                            <Button
                                btnClassname="!py-2 !px-4 !w-fit !h-10"
                                btnText={btnText}
                                onClick={() => btnAction?.()}
                            />
                        )}

                        {hasButton2 && (
                            <Button
                                btnClassname={`${btn2Classname} !py-2 !px-4 !w-fit !h-10`}
                                btnText={btn2Text}
                                onClick={() => btn2Action?.()}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmptyStateComp;
