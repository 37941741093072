import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";

const useGetInventorySnapshotList = () => {
    const dispatch = useAppDispatch();
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [stats, setStats] = useState<{ [key: string]: any }>({});
    const [dates, setDates] = useState<{ [key: string]: any }>({
        startDate: "",
        endDate: "",
    });
    const [InventorySnapshotList, setInventorySnapshotList] = useState<{ [key: string]: any }[]>(
        []
    );

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { fetchingInventorySnapshotList, fetchInventorySnapshotListSuccess } = useAppSelector(
        (state) => state.inventory
    );

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    useEffect(() => {
        setUpdatedPage(1);
    }, [dates, debouncedSearch, warehouseId]);

    useEffect(() => {
        dispatch(
            inventoryActions.fetchInventorySnapshotList(
                10,
                updatedPage,
                debouncedSearch,
                warehouseId,
                dates?.startDate,
                dates?.endDate
            )
        );
    }, [dispatch, updatedPage, debouncedSearch, warehouseId, dates]);

    useEffect(() => {
        if (Boolean(fetchInventorySnapshotListSuccess)) {
            setInventorySnapshotList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchInventorySnapshotListSuccess?.inventorySnapShots], "date")
                    : uniqBy(
                          [...prev, ...fetchInventorySnapshotListSuccess?.inventorySnapShots],
                          "date"
                      )
            );
            setStats(fetchInventorySnapshotListSuccess?.snapShotStats);
            setPagination({
                currentPage: fetchInventorySnapshotListSuccess.pagination?.current,
                noOfPages: fetchInventorySnapshotListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
            dispatch(inventoryActions.resetInventorySnapshotList());
        }
    }, [dispatch, fetchInventorySnapshotListSuccess, updatedPage]);

    return {
        data: InventorySnapshotList,
        snapshotStats: stats,
        isLoading: fetchingInventorySnapshotList,
        isLoadingMore,
        pagination,
        debouncedSearch,
        setDebouncedSearch,
        warehouseId,
        setWarehouseId,
        setDates,
        dates,
    };
};

export default useGetInventorySnapshotList;
