import EmptyStateComp from "components/common/EmptyStateComp";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import { convertDaysToWeeks } from "helpers/convertDaysToWeeks";
import useGetInventoryIncidents from "hooks/inventoryHooks/useGetInventoryIncidents";
import { truncate } from "lodash";
import moment from "moment";
import { format } from "path";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
    data: any;
    isFetching: boolean;
    pagination: any;
    isLoadingMore: boolean;
}

const Alerts = ({ data, isFetching, pagination, isLoadingMore }: Props) => {
    const navigate = useNavigate();

    return (
        <PageFrame
            isLoading={isFetching && Number(pagination.currentPage) === 1 && data.length === 0}
            containerClassName={"!min-h-[60vh]"}
        >
            <>
                {data?.length > 0 ? (
                    <>
                        <div className="border border-slate-200 rounded ">
                            {data?.map((item, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className={`p-4  ${
                                            idx < data?.length - 1 && "border-b border-slate-200"
                                        } flex items-start space-x-3 cursor-pointer max-sm:flex-col max-sm:space-x-0 max-sm:space-y-2`}
                                        onClick={() => {
                                            navigate(
                                                `/dashboard/inventory/alert/${item?._id}/${idx + 1}`
                                            );
                                        }}
                                    >
                                        <div
                                            className={`w-8 h-8 rounded-full ${
                                                item.incidentType?.toLowerCase() === "stockout"
                                                    ? "bg-[#FEF2F2] border-[#FF0000] "
                                                    : "bg-[#FFFBEB] border-[#FA9600] "
                                            } border  flex items-center justify-center`}
                                        >
                                            <i
                                                className={`ri-alarm-warning-fill ${
                                                    item.incidentType?.toLowerCase() === "stockout"
                                                        ? "text-[#FF0000]"
                                                        : "text-[#FA9600]"
                                                } text-[17px]`}
                                            ></i>
                                        </div>
                                        <div className="w-full">
                                            <div className="flex items-center space-x-2 mb-1">
                                                <span className="flex items-center text-g-75 text-sm font-rocGroteskMedium mb-1">
                                                    <p
                                                        className={`h-2 w-2 rounded-full max-sm:hidden ${
                                                            item.incidentType.toLowerCase() ===
                                                            "stockout"
                                                                ? "bg-[#FF3347]"
                                                                : "bg-[#FA9600]"
                                                        } mr-1.5`}
                                                    ></p>
                                                    {idx + 1} Alert - Potential{" "}
                                                    {item.incidentType?.toLowerCase() === "stockout"
                                                        ? "stockout"
                                                        : "overstocking"}{" "}
                                                    for{" "}
                                                    {item?.inventory?.productName
                                                        ?.charAt(0)
                                                        .toUpperCase() +
                                                        item?.inventory?.productName.slice(1) ||
                                                        "----"}
                                                </span>
                                                <div
                                                    className={` ${
                                                        item.incidentType?.toLowerCase() ===
                                                        "stockout"
                                                            ? "bg-[#FEF2F2] border-[#FF0000]"
                                                            : "bg-[#FFFBEB] border-[#FA9600]"
                                                    } flex items-center border  py-[1px] rounded-[3px]  px-[6px]`}
                                                >
                                                    <p
                                                        className={`text-xs font-rocGroteskMedium ${
                                                            item?.incidentType.toLowerCase() ===
                                                            "stockout"
                                                                ? "text-[#DC2626] "
                                                                : "text-[#EA580C]"
                                                        }`}
                                                    >
                                                        {item?.incidentType
                                                            ?.charAt(0)
                                                            .toUpperCase() +
                                                            item?.incidentType.slice(1) || "----"}
                                                    </p>
                                                </div>
                                            </div>
                                            <span className="block text-slate-500 text-sm font-rocGroteskMedium mb-2">
                                                {/* Stockout qty: {formatMoney().format(item?.qty) || 0}{" "}
                                                {item?.inventory?.productStockDetails
                                                    ?.unitOfMeasurement || "N/A"}{" "} */}
                                                Potential revenue loss:{" "}
                                                {getCurrencyFromCurrencyCode(
                                                    item?.inventory?.currency
                                                )}
                                                {formatMoney().format(
                                                    item?.inventory?.unitCost?.amount * item?.qty
                                                ) || 0}{" "}
                                                | Forecasted stock needed:{" "}
                                                {formatMoney().format(item?.forecastedQty) || 0}{" "}
                                                {item?.inventory?.productStockDetails
                                                    ?.unitOfMeasurement || "N/A"}{" "}
                                            </span>
                                            <div className="flex max-lg:grid max-lg:grid-cols-2   max-lg:gap-4 w-full items-center justify-between">
                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 bg-slate-50 border border-slate-200 rounded py-1 px-3 h-[28px] w-fit">
                                                    {item?.risk === "high" && (
                                                        <img
                                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1716189612/bar-chart-2-fill_2_h0x1km.svg"
                                                            alt="risk-icon"
                                                        />
                                                    )}
                                                    {item?.risk === "medium" && (
                                                        <img
                                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1716189612/bar-chart-2-fill_mlbycq.svg"
                                                            alt="risk-icon"
                                                        />
                                                    )}
                                                    {item?.risk === "low" && (
                                                        <img
                                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1716189612/bar-chart-2-fill_1_kxmng6.svg"
                                                            alt="risk-icon"
                                                        />
                                                    )}

                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        {item?.risk} risk
                                                    </span>
                                                </div>
                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 w-fit">
                                                    <i className="ri-time-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        {item?.incidentType.toLowerCase() ===
                                                        "stockout"
                                                            ? "Stockout date"
                                                            : "Overstocking date"}
                                                        :{" "}
                                                        {item?.incidentType.toLowerCase() ===
                                                        "stockout"
                                                            ? `${moment(item?.stockoutDate).format(
                                                                  "DD MMM YYYY"
                                                              )}`
                                                            : `${moment(item?.stockoutDate).format(
                                                                  "DD MMM YYYY"
                                                              )}`}
                                                    </span>
                                                </div>
                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 w-fit">
                                                    <i className="ri-time-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        {item?.incidentType.toLowerCase() ===
                                                        "stockout"
                                                            ? "Forecasted stock duration"
                                                            : "Overstocking duration"}
                                                        :{" "}
                                                        {(item?.forecastLength || 0)?.toFixed(0) ||
                                                            "N/A"}{" "}
                                                        weeks
                                                    </span>
                                                </div>
                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 w-fit">
                                                    <i className="ri-time-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        Reported {moment(item?.createdAt).fromNow()}
                                                    </span>
                                                </div>

                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 w-fit">
                                                    <i className="ri-check-fill text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        {item?.status}
                                                    </span>
                                                </div>

                                                <div className="flex max-lg:shrink-0 items-center space-x-1.5 w-fit">
                                                    <i className="ri-map-pin-line text-base text-slate-500 "></i>
                                                    <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                        Target -{" "}
                                                        {truncate(item?.warehouse?.warehouseName, {
                                                            length: 12,
                                                        })}
                                                    </span>
                                                </div>

                                                {/* <div className="flex items-center space-x-1.5 w-fit">
                                 <VendorAvatar
                                     name="Augustine Asiuwhu"
                                     imageSrc=""
                                     size={20}
                                     textClassname="!text-[8px]"
                                 />
                                 <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                     Augustine Asiuwhu
                                 </span>
                             </div> */}
                                                <div className="max-lg:hidden">
                                                    <i className="ri-arrow-right-s-line text-slate-500 text-lg"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {isFetching && isLoadingMore && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {pagination?.currentPage === pagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </>
                ) : (
                    <EmptyStateComp
                        contentContainerClass="max-w-[611px]"
                        title="No incidents reported"
                        description="There are currently no incidents to display. All systems are operating smoothly"
                        icon={<i className="ri-alarm-warning-line text-slate-500 text-[40px]"></i>}
                    />
                )}
            </>
        </PageFrame>
    );
};

export default Alerts;
