import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { productionActions } from "redux/Ldb/actions";

const useGetWorkOrderTabDetails = (scrollContent?: HTMLElement) => {
    const { workOrderId } = useParams();
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [workOrderDetails, setWorkOrderDetails] = useState<any>();
    const [query, setQuery] = useState<string>("");
    const [pagination, setPagination] = useState({
        current: "",
        number_of_pages: "",
    });

    const { fetchingWorkOrderTab, fetchedWorkOrderTabSuccess } = useAppSelector(
        (state) => state.production
    );

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.current, number_of_pages: pagination.number_of_pages },
            10,
            scrollContent
        );

    const handleSearch = (e) => {
        setSearch(e?.target?.value);
    };

    const handleDebouncedSearch = debounce((e) => {
        setUpdatedPage(1);
        setDebouncedSearch(e?.target?.value);
    }, 3000);

    useEffect(() => {
        setUpdatedPage(1);
        setWorkOrderDetails({});
    }, [query]);

    useEffect(() => {
        if (workOrderId && (query === "bom" || query === "approval_workflow")) {
            dispatch(
                productionActions.fetchWorkOrderTabDetails(
                    workOrderId,
                    query,
                    10,
                    updatedPage,
                    debouncedSearch
                )
            );
        }
    }, [dispatch, workOrderId, query, debouncedSearch, updatedPage]);

    useEffect(() => {
        if (Boolean(fetchedWorkOrderTabSuccess)) {
            setWorkOrderDetails(fetchedWorkOrderTabSuccess?.workOrder);

            dispatch(productionActions.resetFetchWorkOrderTabDetails());

            setPagination({
                current: fetchedWorkOrderTabSuccess.pagination?.current,
                number_of_pages: fetchedWorkOrderTabSuccess.pagination?.number_of_pages,
            });
        }
    }, [dispatch, fetchedWorkOrderTabSuccess, query, updatedPage]);

    return {
        data: workOrderDetails,
        setWorkOrderDetails,
        isLoading: fetchingWorkOrderTab,
        setQuery,
        debouncedSearch,
        setDebouncedSearch,
        handleDebouncedSearch,
        search,
        setSearch,
        handleSearch,
        isLoadingMore,
        pagination,
        query,
    };
};

export default useGetWorkOrderTabDetails;
