import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { productionActions } from "redux/Ldb/actions";

const useGetSingleProductionPlan = () => {
    const { productionId } = useParams();
    const [param] = useSearchParams();
    const duplicateId = param.get("duplicateId");
    const dispatch = useAppDispatch();
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [planDetails, setPlanDetails] = useState<{ [key: string]: any }>({});

    const { fetchingSingleProductionPlan, fetchedSingleProductionPlanSuccess } = useAppSelector(
        (state) => state.production
    );

    useEffect(() => {
        if (Boolean(fetchedSingleProductionPlanSuccess)) {
            setPlanDetails(fetchedSingleProductionPlanSuccess?.plan);
        }
    }, [fetchedSingleProductionPlanSuccess]);

    useEffect(() => {
        if (productionId || duplicateId) {
            dispatch(productionActions.fetchSingleProductionPlan(productionId || duplicateId));
        }
    }, [dispatch, productionId, duplicateId]);

    return {
        data: planDetails,
        isLoading: fetchingSingleProductionPlan,
    };
};

export default useGetSingleProductionPlan;
