import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { productionActions } from "redux/Ldb/actions";

const useGetPendingWorkOrderList = () => {
    const dispatch = useAppDispatch();
    const [pendingList, setpendingList] = useState<{ [key: string]: any }[]>([]);
    const [settings, setSettings] = useState<{ [key: string]: any }>({});

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { fetchingPendingWorkOrderList, fetchedPendingWorkOrderListSuccess } = useAppSelector(
        (state) => state.production
    );

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    useEffect(() => {
        dispatch(productionActions.fetchPendingWorkOrder(10, updatedPage));
    }, [dispatch, updatedPage]);

    // useEffect(() => {
    //     if (Boolean(fetchedPendingWorkOrderListSuccess)) {
    //         setpendingList(fetchedPendingWorkOrderListSuccess?.workOrders);
    //         setSettings(fetchedPendingWorkOrderListSuccess?.settings);
    //     }
    // }, [fetchedPendingWorkOrderListSuccess]);

    useEffect(() => {
        if (Boolean(fetchedPendingWorkOrderListSuccess)) {
            setpendingList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchedPendingWorkOrderListSuccess?.workOrders], "_id")
                    : uniqBy([...prev, ...fetchedPendingWorkOrderListSuccess?.workOrders], "_id")
            );
            setPagination({
                currentPage: fetchedPendingWorkOrderListSuccess.pagination?.current,
                noOfPages: fetchedPendingWorkOrderListSuccess.pagination?.number_of_pages,
            });
            setSettings(fetchedPendingWorkOrderListSuccess?.settings);
            setLoadMore(false);
            dispatch(productionActions.resetPendingWorkOrderList());
        }
    }, [dispatch, fetchedPendingWorkOrderListSuccess, updatedPage]);

    return {
        data: pendingList,
        settings,
        isLoading: fetchingPendingWorkOrderList,
        isLoadingMore,
        pagination,
    };
};

export default useGetPendingWorkOrderList;
