import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";

const useGetBrandStockDetails = (generalBrand?: string, generalDuration?: string) => {
    const dispatch = useAppDispatch();
    const [brandStockDetails, setBrandStockDetails] = useState<{ [key: string]: any }>();
    const [dateRange, setDateRange] = useState<{ label: string; value: string }>({
        label: "",
        value: "yearly",
    });

    const {
        fetchingBrandStockDetails,
        fetchBrandStockDetailsSuccess,
        fetchBrandStockDetailsFailure,
    } = useAppSelector((state) => state.multibrand);

    const handleDurationChange = (value: { label: string; value: string }) => {
        setDateRange(value);
    };

    useEffect(() => {
        if (Boolean(fetchBrandStockDetailsSuccess)) {
            setBrandStockDetails(fetchBrandStockDetailsSuccess?.stockDetails);
        }
    }, [fetchBrandStockDetailsSuccess]);

    useEffect(() => {
        dispatch(multibrandActions?.getBrandStockDetails());
    }, [dispatch, dateRange?.value, generalBrand, generalDuration]);

    return {
        data: brandStockDetails,
        isFetching: fetchingBrandStockDetails,
        error: fetchBrandStockDetailsFailure,
        handleDurationChange,
        dateRange,
    };
};

export default useGetBrandStockDetails;
