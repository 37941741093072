import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import InventoryAnalyticsCard from "components/common/InventoryAnalyticsCard";
import PageTitleSection from "components/common/PageTitleSection";
import VendorAvatar from "components/common/VendorAvatar";
import { truncate } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { COLORS } from "variables/chartColors";
import ReactECharts from "echarts-for-react";
import TabsContainer from "components/common/TabsContainer";
import EmptyStateComp from "components/common/EmptyStateComp";
import CustomTableLoader from "components/common/CustomTableLoader";
import CustomTable from "components/common/CustomTable";
import Loader from "components/common/Loader";
import { Tooltip } from "react-tooltip";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import useGetBrandInventory from "hooks/multibrandHooks/useGetBrandInventory";
import moment from "moment";
import Button from "components/common/Button";
import useGetBrandStockDetails from "hooks/multibrandHooks/useGetBrandStockDetails";
import { formatMoney } from "helpers";
import useGetBrandWarehouseStockBreakdown from "hooks/multibrandHooks/useGetBrandWarehouseStockBreakdown";
import useGetBrandList from "hooks/multibrandHooks/useGetBrandList";
import MultiselectInput from "components/common/InputField/MultiselectInput";
import jsonToCsvExport from "json-to-csv-export";
import useGetBrandWarehouseList from "hooks/multibrandHooks/useGetBrandWarehouseList";
import ModalContainer from "components/common/ModalContainer";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";
import useGetBrandsInventoryInStockValue from "hooks/multibrandHooks/useGetBrandsInventoryInStockValue";
import useGetBrandsInventoryTotalStockValue from "hooks/multibrandHooks/useGetBrandsInventoryTotalStockValue";
import useGetBrandsInventoryOutfStockValue from "hooks/multibrandHooks/useGetBrandsInventoryOutfStockValue";
import useGetBrandsInventoryRestockValue from "hooks/multibrandHooks/useGetBrandsInventoryRestockValue";
import { useNavigate } from "react-router-dom";
import ResponsivePaginationComponent from "react-responsive-pagination";

const Inventory = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [pieChartModal, setPieChartModal] = useState(false);
    const [expandAnalyticsCard, setExpandAnalyticsCard] = useState<string>("");
    const [channelFilter, setChannelFilter] = useState<string>("");
    const [warehouse, setWarehouse] = useState<string>("");
    const [modalDurationFilter, setModalDurationFilter] = useState<string>("This year");
    const [customFilter, setCustomFilter] = useState<string>("");
    const [getProductId, setGetProductId] = useState<string>("");

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const warehouseRef = useRef(null);
    const {
        exportingBrandInventoryInStock,
        exportBrandInventoryInStockSuccess,
        exportingBrandInventoryOutOfStock,
        exportBrandInventoryOutOfStockSuccess,
        exportingBrandInventoryTotalStock,
        exportBrandInventoryTotalStockSuccess,
    } = useAppSelector((state) => state.multibrand);

    const dateValues2 = [
        {
            period: "This year",
            value: "yearly",
        },
        {
            period: "This month",
            value: "monthly",
        },
        {
            period: "This week",
            value: "weekly",
        },

        {
            period: "Today",
            value: "daily",
        },
        {
            period: "All times",
            value: "",
        },
    ];

    const {
        data: allBrandList,
        isFetching: fetchingBrands,
        brandListSearch: brandList,
        search: brandSearch,
        handleDebouncedChange: brandHandleDebouncedChange,
        handleSearch: brandHandleSearch,
        handleDebouncedChangeTwo,
    } = useGetBrandList();
    const {
        data: inventoryList,
        isFetching: isFetchingInventory,
        handleSearch,
        handleDebouncedChange,
        search,
        pagination,
        setUpdatedPage,
        updatedPage,
        isLoadingMore,
    } = useGetBrandInventory();
    const {
        data: warehouseList,
        search: warehouseSearch,
        handleSearch: warehouseHandleSearch,
        setDebouncedSearch: warehouseSetDebouncedSearch,
        setSearch: warehouseSetSearch,
        handleDebouncedChange: warehouseHandleDebouncedChange,
        isFetching: isFetchingWarehouse,
    } = useGetBrandWarehouseList();

    const { data: stockDetails } = useGetBrandStockDetails();
    const {
        data: breakdown,
        brands,
        handleBrandChangeFilter,
        handleLocationChangeFilter,
        channels,
        warehouses,
    } = useGetBrandWarehouseStockBreakdown();
    const { exportingBrandInventoryList, exportBrandInventoryListSuccess } = useAppSelector(
        (state) => state.multibrand
    );

    const {
        rawData: rawTotalStockValue,
        data: totalStockValue,
        error: totalStockValueError,
        handleDebouncedChange: handleTopDebouncedChange,
        isFetching: totalStockValueDataLoading,
        pagination: totalStockValuePagination,
        setWarehouseId: setTopWarehouseId,
        setChannel: setTotalStockValueChannel,
        channel: totalStockValueChannel,
        setSortValues,
        setEndDateRange,
        setStartDateRange,
        debouncedSearch,
        setDebouncedSearch,
        isLoadingMore: istotalStockLoadMore,
        setUpdatedPage: totalStockSetUpdatedPage,
    } = useGetBrandsInventoryTotalStockValue(Boolean(expandAnalyticsCard === "totalStockValue"));

    const {
        rawData: rawInStockValue,
        data: inStockValue,
        error: inStockValueError,
        handleDebouncedChange: handleInStockDebouncedChange,
        isFetching: inStockValueDataLoading,
        pagination: inStockValuePagination,
        setWarehouseId: setInStockWarehouseId,
        setChannel: setInStockValueChannel,
        channel: inStockValueChannel,
        setSortValues: setInStockSortValues,
        setEndDateRange: setInStockEndDateRange,
        setStartDateRange: setInStockStartDateRange,
        debouncedSearch: inStockDebouncedSearch,
        setDebouncedSearch: setInStockDebouncedSearch,
        isLoadingMore: isInStockLoadMore,
        setUpdatedPage: inStockSetUpdatedPage,
    } = useGetBrandsInventoryInStockValue(Boolean(expandAnalyticsCard === "inStock"));

    const {
        rawData: rawRestockValue,
        data: reStockValue,
        error: reStockValueError,
        handleDebouncedChange: handleReStockDebouncedChange,
        isFetching: reStockValueDataLoading,
        pagination: reStockValuePagination,
        setWarehouseId: setReStockWarehouseId,
        setChannel: setReStockValueChannel,
        channel: restockValueChannel,
        setSortValues: setReStockSortValues,
        setEndDateRange: setReStockEndDateRange,
        setStartDateRange: setReStockStartDateRange,
        debouncedSearch: reStockDebouncedSearch,
        setDebouncedSearch: setReStockDebouncedSearch,
        isLoadingMore: isReStockLoadMore,
        setUpdatedPage: reStockSetUpdatedPage,
    } = useGetBrandsInventoryRestockValue(Boolean(expandAnalyticsCard === "belowMinimum"));

    const {
        rawData: rawOutStockValue,
        data: outStockValue,
        error: outStockValueError,
        handleDebouncedChange: handleOutStockDebouncedChange,
        isFetching: outStockValueDataLoading,
        pagination: outStockValuePagination,
        setWarehouseId: setOutStockWarehouseId,
        setChannel: setOutStockValueChannel,
        channel: outStockValueChannel,
        setSortValues: setOutStockSortValues,
        setEndDateRange: setOutStockEndDateRange,
        setStartDateRange: setOutStockStartDateRange,
        debouncedSearch: outStockDebouncedSearch,
        setDebouncedSearch: setOutStockDebouncedSearch,
        isLoadingMore: isOutStockLoadMore,
        setUpdatedPage: outStockSetUpdatedPage,
    } = useGetBrandsInventoryOutfStockValue(Boolean(expandAnalyticsCard === "outOfStock"));

    const channelsValue = warehouseList?.filter((item) => isNaN(Number(item?.value?.[0])));
    const warehouseValue = warehouseList?.filter((item) => !isNaN(Number(item?.value?.[0])));

    const handleChannelsChange = (value) => {
        setChannelFilter(value);

        if (value === "All channels") {
            if (expandAnalyticsCard === "totalStockValue") {
                setTotalStockValueChannel("");
            } else if (expandAnalyticsCard === "inStock") {
                setInStockValueChannel("");
            } else if (expandAnalyticsCard === "belowMinimum") {
                setReStockValueChannel("");
            } else if (expandAnalyticsCard === "outOfStock") {
                setOutStockValueChannel("");
            }
        } else {
            if (expandAnalyticsCard === "totalStockValue") {
                setTotalStockValueChannel(value);
            } else if (expandAnalyticsCard === "inStock") {
                setInStockValueChannel(value);
            } else if (expandAnalyticsCard === "belowMinimum") {
                setReStockValueChannel(value);
            } else if (expandAnalyticsCard === "outOfStock") {
                setOutStockValueChannel(value);
            }
        }
    };

    const handleWarehouseChange = (e) => {
        const { value } = e.target;
        const arrayValue = value !== "" && value?.split("-");
        if (expandAnalyticsCard === "totalStockValue") {
            setTopWarehouseId(arrayValue[1]);
        } else if (expandAnalyticsCard === "inStock") {
            setInStockWarehouseId(arrayValue[1]);
        } else if (expandAnalyticsCard === "belowMinimum") {
            setReStockWarehouseId(arrayValue[1]);
        } else if (expandAnalyticsCard === "outOfStock") {
            setOutStockWarehouseId(arrayValue[1]);
        }

        setWarehouse(value);
    };

    const handleDateChange = (e) => {
        const { value } = e.target;
        const [val, period] = value.split("-");
        setModalDurationFilter(period);
        setCustomFilter(val);
        let startDate, endDate;
        switch (val) {
            case "yearly":
                startDate = moment().startOf("year").format("YYYY-MM-DD");
                endDate = moment().endOf("year").format("YYYY-MM-DD");
                break;
            case "monthly":
                startDate = moment().startOf("month").format("YYYY-MM-DD");
                endDate = moment().endOf("month").format("YYYY-MM-DD");
                break;
            case "weekly":
                startDate = moment().startOf("week").format("YYYY-MM-DD");
                endDate = moment().endOf("week").format("YYYY-MM-DD");
                break;
            case "daily":
                startDate = moment().startOf("day").format("YYYY-MM-DD");
                endDate = moment().endOf("day").format("YYYY-MM-DD");
                break;
            default:
                startDate = null;
                endDate = null;
        }
        if (expandAnalyticsCard === "totalStockValue") {
            setStartDateRange(startDate);
            setEndDateRange(endDate);
        } else if (expandAnalyticsCard === "inStock") {
            setInStockStartDateRange(startDate);
            setInStockEndDateRange(endDate);
        } else if (expandAnalyticsCard === "belowMinimum") {
            setReStockStartDateRange(startDate);
            setReStockEndDateRange(endDate);
        } else if (expandAnalyticsCard === "outOfStock") {
            setOutStockStartDateRange(startDate);
            setOutStockEndDateRange(endDate);
        }
    };

    const handleTotalValueSortChange = (idx: number, sortValue: number) => {
        let sortKey;
        if (expandAnalyticsCard === "totalStockValue") {
            if (idx === 0) {
                sortKey = "productName";
            } else if (idx === 2) {
                sortKey = "quantity";
            } else if (idx === 3) {
                sortKey = "unitPrice";
            } else if (idx === 4) {
                sortKey = "totalValue";
            } else if (idx === 6) {
                sortKey = "lastRestocked";
            }

            setSortValues({ [sortKey]: sortValue });
        } else if (expandAnalyticsCard === "inStock") {
            if (idx === 0) {
                sortKey = "productName";
            } else if (idx === 2) {
                sortKey = "quantity";
            } else if (idx === 3) {
                sortKey = "unitPrice";
            } else if (idx === 4) {
                sortKey = "totalValue";
            } else if (idx === 7) {
                sortKey = "lastRestocked";
            }
            setInStockSortValues({ [sortKey]: sortValue });
        } else if (expandAnalyticsCard === "belowMinimum") {
            if (idx === 0) {
                sortKey = "productName";
            } else if (idx === 2) {
                sortKey = "quantity";
            } else if (idx === 3) {
                sortKey = "lastRestocked";
            } else if (idx === 6) {
                sortKey = "reorderQty";
            } else if (idx === 7) {
                sortKey = "unitPrice";
            } else if (idx === 8) {
                sortKey = "totalValue";
            }
            setReStockSortValues({ [sortKey]: sortValue });
        } else if (expandAnalyticsCard === "outOfStock") {
            if (idx === 0) {
                sortKey = "productName";
            } else if (idx === 1) {
                sortKey = "quantity";
            } else if (idx === 2) {
                sortKey = "lastRestocked";
            } else if (idx === 6) {
                sortKey = "unitPrice";
            } else if (idx === 7) {
                sortKey = "totalValue";
            }
            setOutStockSortValues({ [sortKey]: sortValue });
        }
    };

    const ChannelDisplay = ({ channels }) => {
        const maxDisplay = 2;
        const displayedChannels = channels?.slice(0, maxDisplay);
        const remainingCount = channels?.length > maxDisplay ? channels?.length - maxDisplay : 0;

        return (
            <>
                {channels?.length > 0 ? (
                    <div className="flex items-center space-x-3">
                        <div className="flex items-center">
                            {displayedChannels?.map((channel) => (
                                <span key={channel}>
                                    {displayProductChannelIcon(channel, "!w-[14px] !h-[14px]")}
                                </span>
                            ))}
                            {remainingCount > 0 && (
                                <span className="text-sm">+{remainingCount}</span>
                            )}
                        </div>
                        <span>
                            {displayedChannels?.join(", ")}
                            {remainingCount > 0 && `, +${remainingCount}`}
                        </span>
                    </div>
                ) : (
                    <div>
                        <p>N/A</p>
                    </div>
                )}
            </>
        );
    };

    const totalStockValueTableHeader = [
        { title: "Product", widthClass: "w-[25%]", sortIcon: true },
        { title: "Channels", widthClass: "w-[18.1%]" },
        { title: "Quantity", widthClass: "w-[10.6%]", sortIcon: true },
        { title: "Unit price", widthClass: "w-[8.7%]", sortIcon: true },
        { title: "Total Value", widthClass: "w-[11%]", sortIcon: true },
        { title: "Supplier", widthClass: "w-[14.6%]" },
        { title: "Last restocked", widthClass: "w-[11%]", sortIcon: true },
    ];

    const totalStockValueTableBody = rawTotalStockValue?.map((product, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>
                            <div>
                                <a id={`product-column-${idx}`}>
                                    <span className="block text-sm">
                                        {truncate(product?.productName, {
                                            length: 55,
                                        })}
                                    </span>
                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                        SKU: {product?.sku}
                                    </span>
                                </a>
                                <Tooltip
                                    anchorSelect={`#product-column-${idx}`}
                                    place={"top"}
                                    content={product?.productName}
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#1E293B",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/inventory/products/${product?._id}`);
                },
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        {ChannelDisplay(product?.channels || [])}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-2">
                            <span>{formatMoney().format(product?.quantity)}</span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>${formatMoney().format(product?.unitPrice)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>${formatMoney().format(product?.totalValue)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.supplierDetails?.[0]?.profile?.logo ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.supplierDetails?.[0]?.profile?.avatar ||
                                        product?.supplierDetails?.[0]?.profile?.logo
                                    }
                                    size={16}
                                    name={product?.supplierName}
                                    containerClassname="!rounded-none "
                                />
                            ) : (
                                <div className="w-4 h-4 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <span className="block text-[13px]">
                                    {product?.supplierName
                                        ? truncate(product?.supplierName, { length: 23 })
                                        : "N/A"}
                                </span>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>{formatMoney().format(product?.lastRestocked)}</span>
                    </div>
                ),
            },
        ];
    });

    const inStockValueTableHeader = [
        { title: "Product", widthClass: "w-[17%]", sortIcon: true },
        { title: "Channel", widthClass: "w-[17.8%]" },
        { title: "Quantity", widthClass: "w-[8.6%]", sortIcon: true },
        { title: "Unit price", widthClass: "w-[8.7%]", sortIcon: true },
        { title: "Total Value", widthClass: "w-[11%]", sortIcon: true },
        { title: "In Stock", widthClass: "w-[8.8%]", sortIcon: true },
        { title: "Supplier", widthClass: "w-[14.6%]" },
        { title: "Last restocked", widthClass: "w-[11%]", sortIcon: true },
    ];

    const inStockValueTableBody = rawInStockValue?.map((product, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>

                            <div>
                                <a id={`product-column-${idx}`}>
                                    <span className="block text-sm">
                                        {truncate(product?.productName, {
                                            length: 55,
                                        })}
                                    </span>
                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                        SKU: {product?.sku}
                                    </span>
                                </a>
                                <Tooltip
                                    anchorSelect={`#product-column-${idx}`}
                                    place={"top"}
                                    content={product?.productName}
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#1E293B",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/inventory/products/${product?._id}`);
                },
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {ChannelDisplay(product?.channels || [])}
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-2">
                            <span>{formatMoney().format(product?.quantity)}</span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>${formatMoney().format(product?.unitPrice)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span className="">$ {formatMoney().format(product?.totalValue)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span className="text-[#22C55E]">{product?.instock}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.supplierDetails?.[0]?.profile?.logo ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.supplierDetails?.[0]?.profile?.avatar ||
                                        product?.supplierDetails?.[0]?.profile?.logo
                                    }
                                    size={16}
                                    name={product?.supplierName}
                                    containerClassname="!rounded-none "
                                />
                            ) : (
                                <div className="w-4 h-4 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <span className="block text-[13px]">
                                    {product?.supplierName
                                        ? truncate(product?.supplierName, { length: 23 })
                                        : "N/A"}
                                </span>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>{formatMoney().format(product?.lastRestocked)}</span>
                    </div>
                ),
            },
        ];
    });

    const belowMinValueTableHeader = [
        { title: "Product", widthClass: "w-[18.6%]", sortIcon: true },
        { title: "Current Qty", widthClass: "w-[10%]", sortIcon: true },
        { title: "Last Restocked", widthClass: "w-[12%]", sortIcon: true },
        { title: "Supplier", widthClass: "w-[9.1%]" },
        { title: "Supplier lead time", widthClass: "w-[10.8%]" },
        { title: "Reorder Qty", widthClass: "w-[10%]", sortIcon: true },
        { title: "Unit price", widthClass: "w-[10%]", sortIcon: true },
        { title: "Total value", widthClass: "w-[10%]", sortIcon: true },
    ];

    const belowMinValueTableBody = rawRestockValue?.map((product, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>

                            <div>
                                <a id={`product-column-${idx}`}>
                                    <span className="block text-sm">
                                        {truncate(product?.productName, {
                                            length: 55,
                                        })}
                                    </span>
                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                        SKU: {product?.sku}
                                    </span>
                                </a>
                                <Tooltip
                                    anchorSelect={`#product-column-${idx}`}
                                    place={"top"}
                                    content={product?.productName}
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#1E293B",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/inventory/products/${product?._id}`);
                },
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-[#FF3D1D] `}
                    >
                        <span className="r-50">
                            {formatMoney().format(product?.currentStockQty)}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>{moment(product?.lastRestocked).format("MMM DD, YYYY")}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.supplierLogo ? (
                                <VendorAvatar
                                    imageSrc={product?.supplierLogo}
                                    size={16}
                                    name={product?.supplierName}
                                    containerClassname="!rounded-none "
                                />
                            ) : (
                                <div className="w-4 h-4 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <span className="block text-[13px]">
                                    {product?.supplierName
                                        ? truncate(product?.supplierName, { length: 23 })
                                        : "N/A"}
                                </span>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        {product?.leadTime ? (
                            <span className="">
                                {`${product?.leadTime?.value}  ${product?.leadTime?.duration} `}
                            </span>
                        ) : (
                            <span className="">N/A</span>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>{formatMoney().format(product?.reorderQty)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>${formatMoney().format(product?.unitPrice)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>${formatMoney().format(product?.totalValue)}</span>
                    </div>
                ),
            },
        ];
    });

    const outOfStockValueTableHeader = [
        { title: "Product", widthClass: "w-[18.6%]", sortIcon: true },
        { title: "Current Qty", widthClass: "w-[10%]", sortIcon: true },
        { title: "Last Restocked", widthClass: "w-[12%]", sortIcon: true },
        { title: "Supplier", widthClass: "w-[9.1%]" },
        { title: "Supplier lead time", widthClass: "w-[10.8%]" },
        { title: "Forecasted needs", widthClass: "w-[10%]" },
        { title: "Unit price", widthClass: "w-[10%]", sortIcon: true },
        { title: "Total value", widthClass: "w-[10%]", sortIcon: true },
    ];

    const outOfStockValueTableBody = rawOutStockValue?.map((product, idx) => {
        return [
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>

                            <div>
                                <a id={`product-column-${idx}`}>
                                    <span className="block text-sm">
                                        {truncate(product?.productName, {
                                            length: 55,
                                        })}
                                    </span>
                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                        SKU: {product?.sku}
                                    </span>
                                </a>
                                <Tooltip
                                    anchorSelect={`#product-column-${idx}`}
                                    place={"top"}
                                    content={product?.productName}
                                    style={{
                                        maxWidth: "250px",
                                        zIndex: 9999,
                                        background: "#fff",
                                        borderRadius: "6px",
                                        border: "1px solid #E2E8F0",
                                        color: "#1E293B",
                                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ),
                cellClickAction: () => {
                    navigate(`/dashboard/inventory/products/${product?._id}`);
                },
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span className="r-50">
                            {formatMoney().format(product?.currentStockQty)}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>{moment(product?.lastRestocked).format("MMM DD, YYYY")}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.supplierLogo ? (
                                <VendorAvatar
                                    imageSrc={product?.supplierLogo}
                                    size={16}
                                    name={product?.supplierName}
                                    containerClassname="!rounded-none "
                                />
                            ) : (
                                <div className="w-4 h-4 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <span className="block text-[13px]">
                                    {product?.supplierName
                                        ? truncate(product?.supplierName, { length: 23 })
                                        : "N/A"}
                                </span>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        {product?.leadTime ? (
                            <span className="">
                                {`${product?.leadTime?.value}  ${product?.leadTime?.duration} `}
                            </span>
                        ) : (
                            <span className="">N/A</span>
                        )}
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <div className="flex items-center space-x-1.5">
                            <span className="material-symbols-outlined text-[14px] text-r-50">
                                warning
                            </span>
                            <span>{product?.forecastNeeds} ea</span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>${formatMoney().format(product?.unitPrice)}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-[13px] text-slate-800 `}
                    >
                        <span>${formatMoney().format(product?.totalValue)}</span>
                    </div>
                ),
            },
        ];
    });

    const pieChartOneOptions = {
        tooltip: {
            trigger: "item",
        },
        color: COLORS,
        series: [
            {
                name: "Inventory per location",
                type: "pie",
                radius: ["55%", "70%"],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: "center",
                },
                itemStyle: {
                    borderRadius: 0,
                    borderWidth: 0,
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 40,
                        fontWeight: "bold",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: breakdown?.channels?.slice(0, 5)?.map((item) => {
                    return { value: item?.totalStock, name: item?.brandName };
                }),
            },
        ],
    };

    const pieChartTwoOptions = {
        tooltip: {
            trigger: "item",
        },
        color: COLORS,
        series: [
            {
                name: "Inventory per location",
                type: "pie",
                radius: ["55%", "70%"],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: "center",
                },
                itemStyle: {
                    borderRadius: 0,
                    borderWidth: 0,
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 40,
                        fontWeight: "bold",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: breakdown?.channels?.map((item) => {
                    return { value: item?.totalStock, name: item?.brandName };
                }),
            },
        ],
    };

    const exportInventoryList = () => {
        dispatch(multibrandActions.exportBrandInventoryList());
    };

    const tableHeader = [
        {
            title: "Product name",
            widthClass: "w-[300px]",
        },
        { title: "Brand name", widthClass: "w-[250px]" },
        { title: "SKU No.", widthClass: "w-[200px]" },
        { title: "Current stock", widthClass: "w-[200px]" },
        {
            title: (
                <div className="flex items-center space-x-1.5">
                    <span>Stockout date</span>
                </div>
            ),
            widthClass: "w-[200px]",
        },
        { title: "Forecasted needs", widthClass: "w-[200px]" },
        { title: "Sales channels", widthClass: "w-[200px]" },
        { title: "Categories", widthClass: "w-[200px]" },

        { title: "FC transfer", widthClass: "w-[200px]" },
        { title: "Inbound QTY", widthClass: "w-[200px]" },
        { title: "On order", widthClass: "w-[150px]" },
        { title: "Ongoing sales", widthClass: "w-[150px]" },

        // { title: "", widthClass: "w-[160px]" },
    ];

    const tableBody = inventoryList?.map((product, idx) => {
        // const isChecked = [...selectedInventories].find(
        //     (inventory) => inventory._id === product?._id
        // );
        const givenDate = moment(product?.stockoutDate);
        const today = moment().startOf("day");
        let differenceInDays = 0;

        if (givenDate.isValid()) {
            differenceInDays = givenDate.diff(today, "days");
        }

        return [
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.productImageDetails?.productAvatar ||
                                        product?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <div>
                                    <a id={`product-column-${idx}`}>
                                        <span className="block text-sm">
                                            {truncate(product?.productName, {
                                                length: 55,
                                            })}
                                        </span>
                                    </a>
                                    <Tooltip
                                        anchorSelect={`#product-column-${idx}`}
                                        place={"right"}
                                        content={product?.productName}
                                        style={{
                                            maxWidth: "250px",
                                            zIndex: 9999,
                                            background: "#fff",
                                            borderRadius: "6px",
                                            border: "1px solid #E2E8F0",
                                            color: "#1E293B",
                                            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ),

                // tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-3">
                            {product?.ldb?.profile?.avatar ? (
                                <VendorAvatar
                                    imageSrc={product?.ldb?.profile?.avatar}
                                    size={32}
                                    name={product?.ldb?.brandName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-[14px] h-[14px] rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}
                            <div>
                                <div>
                                    <a id={`brand-column-${idx}`}>
                                        <span className="block text-sm">
                                            {truncate(product?.ldb?.brandName, {
                                                length: 55,
                                            })}
                                        </span>
                                    </a>
                                    <Tooltip
                                        anchorSelect={`#brnad-column-${idx}`}
                                        place={"right"}
                                        content={product?.ldb?.brandName}
                                        style={{
                                            maxWidth: "250px",
                                            zIndex: 9999,
                                            background: "#fff",
                                            borderRadius: "6px",
                                            border: "1px solid #E2E8F0",
                                            color: "#1E293B",
                                            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-xs text-slate-800 `}
                    >
                        <div>
                            <a id={`sku-column-${idx}`}>
                                <span className="text-sm font-rocGroteskMedium text-slate-500">
                                    {truncate(product?.sku, { length: 14 })}
                                </span>
                            </a>
                            <Tooltip
                                anchorSelect={`#sku-column-${idx}`}
                                place={"top"}
                                content={product?.sku}
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.productStockDetails?.stockLevel?.toLocaleString() || "0"}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <div>
                            <span className="text-sm font-rocGroteskMedium">
                                {product?.productStockDetails?.stockLevel === 0 ? (
                                    "Sold out"
                                ) : differenceInDays <= 0 ? (
                                    product?.productStockDetails?.stockLevel >
                                    product?.unfulfilledSale ? (
                                        "N/A"
                                    ) : (
                                        "Sold out"
                                    )
                                ) : (
                                    <>
                                        <span
                                            className={`block ${
                                                differenceInDays > 10
                                                    ? "text-carribean-green"
                                                    : "text-[#E4281D]"
                                            }`}
                                        >
                                            {differenceInDays} days
                                        </span>
                                        <span className={`block text-slate-500`}>
                                            {moment(product?.stockoutDate).format("MMM DD, YYYY")}
                                        </span>
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <div className="flex items-center space-x-1.5">
                            {Boolean(product?.replenishment) && (
                                <div>
                                    <a id={`forecast-needs-${idx}`}>
                                        <span className="material-symbols-outlined text-[14px] text-r-50">
                                            warning
                                        </span>
                                    </a>

                                    <Tooltip
                                        anchorSelect={`#forecast-needs-${idx}`}
                                        place={"top"}
                                        content={
                                            Number(product?.productStockDetails?.stockLevel) < 1
                                                ? "Product is out of stock"
                                                : "Stock for this product may soon run out."
                                        }
                                        style={{ width: "180px", zIndex: 9999 }}
                                    />
                                </div>
                            )}
                            <span>
                                {product?.replenishment
                                    ? Math.round(product?.replenishment)?.toLocaleString()
                                    : "N/A"}
                            </span>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <div>
                            <a id={`channel-column-${idx}`}>
                                {product?.channels?.length > 1 ? (
                                    <div className="flex items-center space-x-2">
                                        {product?.channels.map((channel) => (
                                            <span key={channel}>
                                                {displayProductChannelIcon(channel)}
                                            </span>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="flex items-center space-x-2">
                                        {displayProductChannelIcon(product?.channels?.[0])}
                                        <span>
                                            {truncate(
                                                product?.channels?.[0] === "quickbooks"
                                                    ? "QB"
                                                    : product?.channels?.[0],
                                                { length: 10 }
                                            )}
                                        </span>
                                    </div>
                                )}
                            </a>

                            <Tooltip
                                anchorSelect={`#channel-column-${idx}`}
                                place={"top"}
                                content={
                                    product?.channels?.length > 1
                                        ? product?.channels.map((channel) => channel)?.join(", ")
                                        : product?.channels?.[0]
                                }
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "#fff",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "#1E293B",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                }}
                            />
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{product?.inventoryCategory || "N/A"}</span>
                    </div>
                ),
            },

            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.amazon?.fcProcessingQuantity
                                ? Math.round(
                                      product?.amazon?.fcProcessingQuantity
                                  )?.toLocaleString()
                                : "N/A"}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>
                            {product?.amazon?.inboundShippedQuantity
                                ? Math.round(
                                      product?.amazon?.inboundShippedQuantity
                                  )?.toLocaleString()
                                : "N/A"}
                        </span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{Math.round(product?.onOrder)?.toLocaleString()}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                    >
                        <span>{product?.unfulfilledSale?.toLocaleString()}</span>
                    </div>
                ),
            },
        ];
    });

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return isFetchingInventory ? (
                    <CustomTableLoader tableHeader={tableHeader?.slice(0, 5)} />
                ) : inventoryList?.length > 0 ? (
                    <div className="overflow-x-scroll mb-6">
                        <div id="table-container-one" className="max-lg:hidden ">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isCellBordered={true}
                                // isInifiniteScroll={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                stickyHeader={true}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-12"
                                rightFixedColumnCount={0}
                                leftFixedColumnCount={0}
                                isScrollable={true}
                                isInifiniteScroll={false}
                            />
                        </div>

                        {false && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}

                        {false && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <EmptyStateComp
                        title="No inventory data available"
                        description="There are no inventory data available for this section"
                        contentContainerClass="!w-fit"
                        icon={<i className="ri-box-3-line text-slate-500 text-[40px]"></i>}
                    />
                );

            default:
                return <div></div>;
        }
    };

    const handleExportInventoryByBrand = () => {
        const exportData = breakdown?.channels?.map((item) => ({
            "Brand name": item?.brandName,
            "Total stock": formatMoney()?.format(Number(item?.totalStock)) + " unit(s)",
        }));

        jsonToCsvExport({
            data: exportData,
            filename: "inventory_by_brand",
        });
    };

    useEffect(() => {
        if (Boolean(exportBrandInventoryListSuccess)) {
            const exportData = exportBrandInventoryListSuccess?.inventory?.map((product) => {
                const givenDate = moment(product?.stockoutDate);
                const today = moment().startOf("day");
                let differenceInDays = 0;

                if (givenDate.isValid()) {
                    differenceInDays = givenDate.diff(today, "days");
                }

                return {
                    "Product name": product?.productName,
                    "Brand name": product?.ldb?.brandName,
                    "SKU No.": product?.sku,
                    "Current stock":
                        product?.productStockDetails?.stockLevel?.toLocaleString() || "0",
                    "Stockout date":
                        product?.productStockDetails?.stockLevel === 0 ? (
                            "Sold out"
                        ) : differenceInDays <= 0 ? (
                            product?.productStockDetails?.stockLevel > product?.unfulfilledSale ? (
                                "N/A"
                            ) : (
                                "Sold out"
                            )
                        ) : (
                            <>
                                <span
                                    className={`block ${
                                        differenceInDays > 10
                                            ? "text-carribean-green"
                                            : "text-[#E4281D]"
                                    }`}
                                >
                                    {differenceInDays} days
                                </span>
                                <span className={`block text-slate-500`}>
                                    {moment(product?.stockoutDate).format("MMM DD, YYYY")}
                                </span>
                            </>
                        ),
                    "Forecasted needs": product?.replenishment
                        ? Math.round(product?.replenishment)?.toLocaleString()
                        : "N/A",
                    "Sales channels":
                        product?.channels?.length > 1
                            ? product?.channels.map((channel) => channel)?.join(", ")
                            : product?.channels?.[0],
                    Categories: product?.inventoryCategory || "N/A",
                    "FC transfer": product?.amazon?.fcProcessingQuantity
                        ? Math.round(product?.amazon?.fcProcessingQuantity)?.toLocaleString()
                        : "N/A",
                    "Inbound QTY": product?.amazon?.inboundShippedQuantity
                        ? Math.round(product?.amazon?.inboundShippedQuantity)?.toLocaleString()
                        : "N/A",
                    "On order": Math.round(product?.onOrder)?.toLocaleString(),
                    "Ongoing sales": product?.unfulfilledSale?.toLocaleString(),
                };
            });

            jsonToCsvExport({
                data: exportData,
                filename: "multibrand_inventory_list",
            });

            dispatch(multibrandActions.resetExportBrandInventoryListSuccess());
        }
    }, [exportBrandInventoryListSuccess]);

    useEffect(() => {
        if (Boolean(exportBrandInventoryOutOfStockSuccess)) {
            const exportData = exportBrandInventoryOutOfStockSuccess?.stockInfo?.map((data) => {
                const { supplierDetails, channel, lastSupplier, ...rest } = data;

                return {
                    ...rest,
                    lastRestocked: formatMoney()?.format(rest?.lastRestocked),
                    quantity: formatMoney()?.format(rest?.quantity),
                    totalValue: formatMoney()?.format(rest?.totalValue),
                    unitPrice: formatMoney()?.format(rest?.unitPrice),
                    createdAt: moment(rest?.createdAt).format("DD MMM, YYYY"),
                };
            });

            jsonToCsvExport({
                data: exportData,
                filename: "out_of_stock_products",
            });
            setExpandAnalyticsCard("");
            dispatch(multibrandActions.resetExportInventoryOutOfStockSuccess());
        }
        if (Boolean(exportBrandInventoryInStockSuccess)) {
            const exportData = exportBrandInventoryInStockSuccess?.stockInfo?.map((data) => {
                const { supplierDetails, channel, lastSupplier, ...rest } = data;

                return {
                    ...rest,
                    lastRestocked: formatMoney()?.format(rest?.lastRestocked),
                    quantity: formatMoney()?.format(rest?.quantity),
                    totalValue: formatMoney()?.format(rest?.totalValue),
                    unitPrice: formatMoney()?.format(rest?.unitPrice),
                    createdAt: moment(rest?.createdAt).format("DD MMM, YYYY"),
                };
            });

            jsonToCsvExport({
                data: exportData,
                filename: "in_stock_products",
            });
            setExpandAnalyticsCard("");
            dispatch(multibrandActions.resetExportInventoryInStockSuccess());
        }
        if (Boolean(exportBrandInventoryTotalStockSuccess)) {
            const exportData = exportBrandInventoryTotalStockSuccess?.stockInfo?.map((data) => {
                const { supplierDetails, channel, lastSupplier, ...rest } = data;

                return {
                    ...rest,
                    lastRestocked: formatMoney()?.format(rest?.lastRestocked),
                    quantity: formatMoney()?.format(rest?.quantity),
                    totalValue: formatMoney()?.format(rest?.totalValue),
                    unitPrice: formatMoney()?.format(rest?.unitPrice),
                    createdAt: moment(rest?.createdAt).format("DD MMM, YYYY"),
                };
            });

            jsonToCsvExport({
                data: exportData,
                filename: "total_products",
            });
            dispatch(multibrandActions.resetExportInventoryTotalStockSuccess());
        }
    }, [
        dispatch,
        exportBrandInventoryInStockSuccess,
        exportBrandInventoryOutOfStockSuccess,
        exportBrandInventoryTotalStockSuccess,
    ]);

    return (
        <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
            <div className="mb-6">
                <PageTitleSection
                    leftComp={
                        <div className="flex items-center space-x-3">
                            <h3 className="text-xl font-rocGroteskMedium">Inventory</h3>
                        </div>
                    }
                />
            </div>

            <div className="grid grid-cols-[1fr_1.2fr] max-lg:grid-cols-1 max-lg:space-x-0 max-lg:space-y-6 space-x-6 mb-6">
                <div className="grid grid-rows-2 gap-4 max-sm:grid-cols-1">
                    <div className="grid grid-cols-2 gap-4">
                        <InventoryAnalyticsCard
                            title="Total Stock value"
                            containerClassName="bg-[#FFFBEB] cursor-pointer hover:box-content hover:border-[2px] hover:border-[#0428214D]"
                            icon={
                                <i className="ri-money-dollar-circle-fill text-2xl text-y-10 mb-2.5"></i>
                            }
                            onClick={() => setExpandAnalyticsCard("totalStockValue")}
                            value={`$${formatMoney()?.format(stockDetails?.totalStockValue)}`}
                        />
                        <InventoryAnalyticsCard
                            title="Total In Stock SKUs"
                            containerClassName="bg-[#F0FDF4] cursor-pointer hover:box-content hover:border-[2px] hover:border-[#0428214D]"
                            icon={<i className="ri-box-2-fill text-2xl text-[#16A34A] mb-2.5"></i>}
                            onClick={() => setExpandAnalyticsCard("inStock")}
                            value={`${stockDetails?.totalPositiveStock || 0}`}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        {/* //change to grid-cols-3 when uncomment the avgCog */}
                        <InventoryAnalyticsCard
                            title="SKUs to Restock"
                            containerClassName="bg-[#FAF5FF] cursor-pointer hover:box-content hover:border-[2px] hover:border-[#0428214D]"
                            icon={
                                <i className="ri-arrow-down-line text-2xl text-[#7C3AED] mb-2.5"></i>
                            }
                            onClick={() => setExpandAnalyticsCard("belowMinimum")}
                            value={`${stockDetails?.totalBelowMinimumStock || 0}`}
                        />
                        <InventoryAnalyticsCard
                            title="SKUs Out of Stock"
                            containerClassName="bg-[#FEF2F2] cursor-pointer hover:box-content hover:border-[2px] hover:border-[#0428214D]"
                            icon={<i className="ri-box-3-line text-2xl text-[#DC2626] mb-2.5"></i>}
                            onClick={() => setExpandAnalyticsCard("outOfStock")}
                            value={`${stockDetails?.totalNegetiveStock || 0}`}
                        />
                        {/* <div
                            onClick={() => setExpandAnalyticsCard("averageCogs")}
                            className="p-6 bg-slate-50 rounded-md flex flex-col justify-center cursor-pointer hover:box-content hover:border-[2px] hover:border-[#0428214D]"
                        >
                            <i className="ri-money-dollar-box-line text-2xl text-slate-600 mb-2.5"></i>
                            <span className="block text-sm font-rocGroteskMedium">Av. COGS</span>
                            <span className="block text-[28px] max-sm:text-xl max-sm:leading-[28.8px] leading-[40.32px] font-rocGroteskMedium">
                                $42k
                            </span>
                        </div> */}
                    </div>
                </div>

                <div className="border border-[#E2E8F080] rounded-lg p-6 max-sm:px-3 shadow-[-1px_6px_15px_0px_#00000005] h-[350px] max-sm:h-auto">
                    <div className="flex items-center space-x-2 max-sm:grid max-sm:grid-cols-1 max-sm:space-x-0 max-sm:space-y-2">
                        <div className="w-1/2">
                            <MultiselectInput
                                value={brands}
                                name="brandList"
                                placeholder={"All brands"}
                                handleChange={(name, value, selectedItems) => {
                                    handleBrandChangeFilter(selectedItems);
                                }}
                                searchLoading={fetchingBrands}
                                handleInputChange={handleDebouncedChangeTwo}
                                //   switchOptions={assignees}
                                // isRequired={true}
                                className="!h-[45px]"
                                containerClassName={`!h-[43px] ${
                                    brands?.length > 1 && "custom-y-scrollbar"
                                }`}
                                dropdownOptions={brandList?.map((brand) => ({
                                    label: brand?.brand?.brandName,
                                    value: brand?.brand._id,
                                    icon: (
                                        <img
                                            src={brand?.brand?.profile?.avatar}
                                            alt={`${brand?.brand?.brandName}`}
                                            className="w-8 h-8 rounded-full"
                                        ></img>
                                    ),
                                }))}
                            />
                        </div>

                        <div className="w-1/2">
                            <MultiselectInput
                                value={[...channels, ...warehouses]}
                                name="location"
                                placeholder={"All locations"}
                                handleChange={(name, value, selectedItems) => {
                                    handleLocationChangeFilter(selectedItems);
                                }}
                                searchLoading={isFetchingWarehouse}
                                handleInputChange={warehouseHandleDebouncedChange}
                                //   switchOptions={assignees}
                                // isRequired={true}
                                className="!h-[45px]"
                                containerClassName={`!h-[43px] ${
                                    [...channels, ...warehouses]?.length > 1 && "custom-y-scrollbar"
                                }`}
                                dropdownOptions={
                                    warehouseList as { label: string; value: string }[]
                                }
                            />
                        </div>

                        <div
                            onClick={handleExportInventoryByBrand}
                            className="w-[45px] h-[45px] rounded shadow-buttonShadow2 border border-slate-200 flex items-center justify-center cursor-pointer"
                        >
                            <i className="ri-share-forward-box-fill text-base"></i>
                        </div>
                    </div>

                    <div className="grid grid-cols-2 max-sm:grid-cols-1 items-center">
                        <div className="relative ">
                            <ReactECharts option={pieChartOneOptions} />

                            <div className="absolute top-[28%] left-0 right-0 m-auto w-[130px] h-[130px] rounded-full font-rocGroteskMedium flex flex-col items-center justify-center bg-white shadow-[0px_4px_15.6px_-4px_#1018281A]">
                                <span className="block text-[12px] text-slate-500">
                                    {brands?.length > 0 ? "" : "All brands"}
                                </span>
                                <span className="block text-[20px] text-g-75 leading-[40px]">
                                    {formatMoney()?.format(breakdown?.totalStock)}
                                </span>
                                <span className="block text-[12px] text-slate-500">Units</span>
                            </div>
                        </div>

                        <div className="">
                            {breakdown?.channels?.slice(0, 5)?.map((channel, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className="flex justify-between items-center mb-3"
                                    >
                                        <div className="flex items-center space-x-1.5">
                                            <span
                                                className={`w-[14px] h-[14px] rounded-[2px]`}
                                                style={{ backgroundColor: COLORS?.[idx] }}
                                            ></span>
                                            <span className="text-slate-500 font-rocGroteskMedium text-sm">
                                                {channel?.brandName}
                                            </span>
                                        </div>
                                        <span className="block text-center text-g-75 font-rocGroteskMedium text-base">
                                            {channel?.totalStock} units
                                        </span>
                                    </div>
                                );
                            })}
                            {breakdown?.channels?.slice(5)?.length > 0 && (
                                <div className="flex justify-end">
                                    <div
                                        onClick={() => setPieChartModal(true)}
                                        className="flex items-center space-x-1 w-fit cursor-pointer"
                                    >
                                        <span className="text-sm font-rocGroteskMedium underline text-p-50">
                                            View all
                                        </span>
                                        <i className="ri-arrow-right-line text-base text-p-50"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <TabsContainer
                    tabs={["All brands"]}
                    activeTab={activeTab}
                    setActiveTab={(idx) => {
                        setActiveTab(idx);
                    }}
                    component={displayActiveTab()}
                    className="!px-0"
                    showButtonClassName
                    itemClassName="!pb-2 max-lg:whitespace-nowrap"
                    borderLineClase={"!text-slate-100 w-[32%] max-lg:w-full mb-6"}
                    showButton={
                        <div className="flex items-center space-x-2 mb-6 max-sm:grid max-sm:space-x-0 max-sm:space-y-2 max-sm:grid-cols-1">
                            <TextInput
                                name={"search"}
                                value={search}
                                type={"text"}
                                inputPlaceholder={"Search  products"}
                                inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                onChange={handleSearch}
                                onInput={handleDebouncedChange}
                                containerClassname={"!w-[300px] max-sm:!w-full"}
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white !border !border-slate-200"
                                }
                            />

                            <div
                                onClick={exportInventoryList}
                                className="w-10 h-10 rounded border border-slate-200 flex items-center justify-center cursor-pointer"
                            >
                                {exportingBrandInventoryList ? (
                                    <Loader />
                                ) : (
                                    <i className="ri-share-forward-box-fill text-base"></i>
                                )}
                            </div>
                        </div>
                    }
                />
            </div>

            <ModalContainer
                open={pieChartModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10 max-sm:p-5"}
                tailwindClassName="w-[54%] max-lg:w-[95%]"
                closeModal={() => {
                    setPieChartModal(false);
                }}
            >
                <div className="bg-white shadow-cardShadow relative rounded-[8px]">
                    <div className="flex items-center justify-between px-6 py-4 border-b border-slate-200">
                        <h4 className="text-lg font-rocGroteskMedium">Inventory per brand</h4>
                        <i
                            onClick={() => {
                                setPieChartModal(false);
                            }}
                            className="ri-close-fill text-[24px] cursor-pointer "
                        ></i>
                    </div>

                    <div className="py-4 px-6">
                        <div className="flex items-center space-x-2 max-sm:grid max-sm:grid-cols-1 max-sm:space-x-0 max-sm:space-y-2">
                            <div className="w-1/2">
                                <MultiselectInput
                                    value={brands}
                                    name="brandList"
                                    placeholder={"All brands"}
                                    handleChange={(name, value, selectedItems) => {
                                        handleBrandChangeFilter(selectedItems);
                                    }}
                                    searchLoading={fetchingBrands}
                                    handleInputChange={handleDebouncedChangeTwo}
                                    //   switchOptions={assignees}
                                    // isRequired={true}
                                    className="!h-[45px]"
                                    containerClassName={`!h-[43px] ${
                                        brands?.length > 1 && "custom-y-scrollbar"
                                    }`}
                                    dropdownOptions={brandList?.map((brand) => ({
                                        label: brand?.brand?.brandName,
                                        value: brand?.brand._id,
                                        icon: (
                                            <img
                                                src={brand?.brand?.profile?.avatar}
                                                alt={`${brand?.brand?.brandName}`}
                                                className="w-8 h-8 rounded-full"
                                            ></img>
                                        ),
                                    }))}
                                />
                            </div>

                            <div className="w-1/2">
                                <MultiselectInput
                                    value={[...channels, ...warehouses]}
                                    name="location"
                                    placeholder={"All locations"}
                                    handleChange={(name, value, selectedItems) => {
                                        handleLocationChangeFilter(selectedItems);
                                    }}
                                    searchLoading={isFetchingWarehouse}
                                    handleInputChange={warehouseHandleDebouncedChange}
                                    //   switchOptions={assignees}
                                    // isRequired={true}
                                    className="!h-[45px]"
                                    containerClassName={`!h-[43px] ${
                                        [...channels, ...warehouses]?.length > 1 &&
                                        "custom-y-scrollbar"
                                    }`}
                                    dropdownOptions={
                                        warehouseList as { label: string; value: string }[]
                                    }
                                />
                            </div>

                            <div
                                onClick={handleExportInventoryByBrand}
                                className="w-[45px] h-[45px] rounded shadow-buttonShadow2 border border-slate-200 flex items-center justify-center cursor-pointer"
                            >
                                <i className="ri-share-forward-box-fill text-base"></i>
                            </div>
                        </div>

                        <div className="">
                            <div className="relative ">
                                <ReactECharts option={pieChartTwoOptions} />

                                <div className="absolute top-[28%] left-0 right-0 m-auto w-[130px] h-[130px] rounded-full font-rocGroteskMedium flex flex-col items-center justify-center bg-white shadow-[0px_4px_15.6px_-4px_#1018281A]">
                                    <span className="block text-[12px] text-slate-500">
                                        {brands?.length > 0 ? "" : "All brands"}
                                    </span>
                                    <span className="block text-[20px] text-g-75 leading-[40px]">
                                        {formatMoney()?.format(breakdown?.totalStock)}
                                    </span>
                                    <span className="block text-[12px] text-slate-500">Units</span>
                                </div>
                            </div>

                            <div className="max-h-[192px] overflow-y-auto">
                                {breakdown?.channels?.map((channel, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            className="flex justify-between items-center mb-3"
                                        >
                                            <div className="flex items-center space-x-1.5">
                                                <span
                                                    className={`w-[14px] h-[14px] rounded-[2px]`}
                                                    style={{ backgroundColor: COLORS?.[idx] }}
                                                ></span>
                                                <span className="text-slate-500 font-rocGroteskMedium text-sm">
                                                    {channel?.brandName}
                                                </span>
                                            </div>
                                            <span className="block text-center text-g-75 font-rocGroteskMedium text-base">
                                                {channel?.totalStock} units
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={Boolean(expandAnalyticsCard)}
                showCloseIcon={false}
                tailwindClassName="w-[80%] "
                closeModal={() => {
                    setExpandAnalyticsCard("");
                }}
            >
                <div className="bg-white flex flex-col rounded-[8px] max-h-[500px] overflow-y-auto overflow-x-hidden">
                    <div className="p-4 flex justify-between items-center border-b border-slate-200">
                        <p
                            className={`text-[18px] font-rocGroteskMedium ${
                                expandAnalyticsCard === "totalStockValue"
                                    ? "text-[#C38D00]"
                                    : expandAnalyticsCard === "inStock"
                                    ? "text-[#22C55E]"
                                    : expandAnalyticsCard === "outOfStock"
                                    ? "text-[#FF3D1D]"
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? "text-[#6562E5]"
                                    : expandAnalyticsCard === "averageCogs"
                                    ? "text-[#0F172A]"
                                    : "text-[#FF3D1D]"
                            }`}
                        >
                            {expandAnalyticsCard === "totalStockValue"
                                ? "Total Stock Value"
                                : expandAnalyticsCard === "inStock"
                                ? "In Stock"
                                : expandAnalyticsCard === "outOfStock"
                                ? "Out of Stock"
                                : expandAnalyticsCard === "belowMinimum"
                                ? "Restock Now"
                                : expandAnalyticsCard === "averageCogs"
                                ? "Average Cost Per SKUs"
                                : ""}
                        </p>
                        <i
                            onClick={() => setExpandAnalyticsCard("")}
                            className="ri-close-fill text-base cursor-pointer"
                        ></i>
                    </div>

                    <div className="flex items-center space-x-2 py-3 px-4 max-sm:grid max-sm:space-x-0 max-sm:space-y-2 max-sm:grid-cols-1">
                        <div className="w-fill-available">
                            <TextInput
                                name={"search"}
                                value={
                                    expandAnalyticsCard === "totalStockValue"
                                        ? debouncedSearch
                                        : expandAnalyticsCard === "inStock"
                                        ? inStockDebouncedSearch
                                        : expandAnalyticsCard === "belowMinimum"
                                        ? reStockDebouncedSearch
                                        : expandAnalyticsCard === "outOfStock"
                                        ? reStockDebouncedSearch
                                        : inStockDebouncedSearch
                                }
                                type={"text"}
                                inputPlaceholder={"Search product"}
                                inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                onChange={(e) => {
                                    expandAnalyticsCard === "totalStockValue"
                                        ? setDebouncedSearch(e.target.value)
                                        : expandAnalyticsCard === "inStock"
                                        ? setInStockDebouncedSearch(e.target.value)
                                        : expandAnalyticsCard === "belowMinimum"
                                        ? setReStockDebouncedSearch(e.target.value)
                                        : expandAnalyticsCard === "outOfStock"
                                        ? setOutStockDebouncedSearch(e.target.value)
                                        : setDebouncedSearch(e.target.value);
                                }}
                                onInput={(e) => {
                                    expandAnalyticsCard === "totalStockValue"
                                        ? handleTopDebouncedChange(e)
                                        : expandAnalyticsCard === "inStock"
                                        ? handleInStockDebouncedChange(e)
                                        : expandAnalyticsCard === "belowMinimum"
                                        ? handleReStockDebouncedChange(e)
                                        : expandAnalyticsCard === "outOfStock"
                                        ? handleOutStockDebouncedChange(e)
                                        : handleInStockDebouncedChange(e);
                                    expandAnalyticsCard === "totalStockValue"
                                        ? totalStockSetUpdatedPage(1)
                                        : expandAnalyticsCard === "inStock"
                                        ? inStockSetUpdatedPage(1)
                                        : expandAnalyticsCard === "belowMinimum"
                                        ? reStockSetUpdatedPage(1)
                                        : expandAnalyticsCard === "outOfStock"
                                        ? outStockSetUpdatedPage(1)
                                        : totalStockSetUpdatedPage(1);
                                }}
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] !border !border-n-20"
                                }
                            />
                        </div>
                        <Dropdown
                            name="filter"
                            value={channelFilter}
                            dropdownTriggerClassName={" "}
                            handleChange={(name, value) => {
                                expandAnalyticsCard === "totalStockValue"
                                    ? totalStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "inStock"
                                    ? inStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? reStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "outOfStock"
                                    ? outStockSetUpdatedPage(1)
                                    : totalStockSetUpdatedPage(1);
                                handleChannelsChange(value);
                            }}
                            dropdown={
                                <div className="bg-white flex items-center justify-between space-x-2 w-fit h-10 px-3 border border-tradeally-neutral-20 !rounded shadow-[0_1px_2px_0_rgba(91,104,113,0.15)]">
                                    <div className="flex items-center space-x-2">
                                        <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium capitalize">
                                            {expandAnalyticsCard === "totalStockValue"
                                                ? totalStockValueChannel || "All channels"
                                                : expandAnalyticsCard === "inStock"
                                                ? inStockValueChannel || "All channels"
                                                : expandAnalyticsCard === "belowMinimum"
                                                ? restockValueChannel || "All channels"
                                                : expandAnalyticsCard === "outOfStock"
                                                ? outStockValueChannel || "All channels"
                                                : "All channels"}
                                        </span>
                                        {Boolean(
                                            expandAnalyticsCard === "totalStockValue"
                                                ? totalStockValueChannel
                                                : expandAnalyticsCard === "inStock"
                                                ? inStockValueChannel
                                                : expandAnalyticsCard === "belowMinimum"
                                                ? restockValueChannel
                                                : expandAnalyticsCard === "outOfStock"
                                                ? outStockValueChannel
                                                : totalStockValueChannel
                                        ) && (
                                            <i
                                                onClick={(e) => {
                                                    e?.stopPropagation();
                                                    // handleClearFilter();
                                                    if (expandAnalyticsCard === "totalStockValue") {
                                                        setTotalStockValueChannel("");
                                                    } else if (expandAnalyticsCard === "inStock") {
                                                        setInStockValueChannel("");
                                                    } else if (
                                                        expandAnalyticsCard === "belowMinimum"
                                                    ) {
                                                        setReStockValueChannel("");
                                                    } else if (
                                                        expandAnalyticsCard === "outOfStock"
                                                    ) {
                                                        setOutStockValueChannel("");
                                                    }
                                                }}
                                                className="ri-close-circle-fill text-sm text-slate-500"
                                            ></i>
                                        )}
                                    </div>
                                    <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                </div>
                            }
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-4 left-[0px] !mt-0  !w-[159px] max-sm:!w-full rounded !max-h-[250px]
                                                 !p-0 !top-[105%]`}
                            dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                            dropdownOptions={[
                                {
                                    label: (
                                        <div
                                            className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                   !w-[190px]  "
                                        >
                                            <i
                                                className={`${
                                                    channelFilter === ""
                                                        ? "ri-checkbox-line text-carribean-green"
                                                        : "ri-checkbox-blank-line text-slate-200"
                                                }    text-xl`}
                                            ></i>
                                            All channels
                                        </div>
                                    ),
                                    value: "",
                                },
                                ...channelsValue
                                    ?.filter((item) => {
                                        return item?.value?.toLowerCase() !== "synth";
                                    })
                                    ?.map((item, idx) => ({
                                        label: (
                                            <div
                                                key={idx}
                                                className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                     !w-[190px]  capitalize"
                                            >
                                                <i
                                                    className={`${
                                                        channelFilter === item?.value
                                                            ? "ri-checkbox-line text-carribean-green"
                                                            : "ri-checkbox-blank-line text-slate-200"
                                                    }    text-xl`}
                                                ></i>
                                                {item?.label}
                                            </div>
                                        ),
                                        value: item?.value,
                                    })),
                            ]}
                        />
                        <Dropdown
                            handleChange={(name, value) => {
                                handleWarehouseChange({
                                    target: { name, value },
                                });
                                expandAnalyticsCard === "totalStockValue"
                                    ? totalStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "inStock"
                                    ? inStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? reStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "outOfStock"
                                    ? outStockSetUpdatedPage(1)
                                    : totalStockSetUpdatedPage(1);
                            }}
                            search={
                                <div ref={warehouseRef} className="">
                                    <TextInput
                                        value={warehouseSearch}
                                        name={"search"}
                                        type={"text"}
                                        required={false}
                                        onChange={warehouseHandleSearch}
                                        onInput={warehouseHandleDebouncedChange}
                                        inputPlaceholder="Search warehouse"
                                        containerClassname=""
                                        inputContainerClassname={"!h-[40px] "}
                                    />
                                </div>
                            }
                            onClose={() => {
                                warehouseSetSearch("");
                                warehouseSetDebouncedSearch("");
                            }}
                            dropdown={
                                <div className="flex items-center justify-between lg:rounded-[5px] lg:shadow-buttonShadow2 py-[6px] h-10 w-fit px-[10px] gap-2 !border !border-tradeally-neutral-20">
                                    <div
                                        className={`text-slate-800 font-rocGroteskMedium text-[13px] inline-block capitalize whitespace-nowrap`}
                                    >
                                        {warehouse
                                            ? truncate(warehouse?.split("-")[0], {
                                                  length: 12,
                                              })
                                            : "All warehouses"}
                                    </div>
                                    <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>
                                </div>
                            }
                            dropdownItemsClasses="!px-0 !py-0"
                            dropdownClassName="!mb-10 !-mt-7"
                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[5px] !max-h-[275px]
                                         !w-[200px] !min-w-min !pt-0
                            `}
                            name="valueRange"
                            dropdownOptions={[
                                {
                                    label: (
                                        <div
                                            className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 !w-[200px] px-4 py-[10px] capitalize"
                                        >
                                            <i
                                                className={`${
                                                    warehouse === ""
                                                        ? "ri-checkbox-line text-carribean-green"
                                                        : "ri-checkbox-blank-line text-slate-200"
                                                }    text-xl`}
                                            ></i>
                                            All Warehouses
                                        </div>
                                    ),
                                    value: "",
                                },
                                ...warehouseValue?.map((item, idx) => ({
                                    label: (
                                        <div
                                            key={idx}
                                            className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                    border-b border-b-slate-100 !w-[200px] px-4 py-[10px] capitalize"
                                        >
                                            <i
                                                className={`${
                                                    warehouse?.split("-")[1] === item?.value
                                                        ? "ri-checkbox-line text-carribean-green"
                                                        : "ri-checkbox-blank-line text-slate-200"
                                                }    text-xl`}
                                            ></i>
                                            {item?.label}
                                        </div>
                                    ),
                                    value: item?.label + "-" + item?.value,
                                })),
                            ]}
                        />
                        <Dropdown
                            handleChange={(name, value) => {
                                handleDateChange({
                                    target: { name, value },
                                });
                                expandAnalyticsCard === "totalStockValue"
                                    ? totalStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "inStock"
                                    ? inStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? reStockSetUpdatedPage(1)
                                    : expandAnalyticsCard === "outOfStock"
                                    ? outStockSetUpdatedPage(1)
                                    : totalStockSetUpdatedPage(1);
                            }}
                            dropdown={
                                <div className="flex items-center justify-between lg:rounded-[6px] lg:shadow-buttonShadow2 py-[6px] px-[10px] h-10 !w-fit gap-2 !border !border-tradeally-neutral-20">
                                    <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>

                                    <div
                                        className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block whitespace-nowrap`}
                                    >
                                        {modalDurationFilter || "This year"}
                                    </div>
                                </div>
                            }
                            dropdownItemsClasses="!px-0 !py-0"
                            dropdownClassName="!w-full !mb-10 !-mt-7"
                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                            name="valueRange"
                            dropdownOptions={[
                                ...dateValues2?.map((item, idx) => ({
                                    label: (
                                        <div
                                            key={idx}
                                            className=" text-g-75 whitespace-nowrap font-rocGroteskMedium text-[13px] flex justify-between
                                                border-b border-b-slate-100 !w-[120px] px-4 py-[10px]"
                                        >
                                            {item?.period}
                                        </div>
                                    ),
                                    value: item?.value + "-" + item?.period,
                                })),
                            ]}
                        />
                        {((expandAnalyticsCard === "inStock" && rawInStockValue?.length > 0) ||
                            (expandAnalyticsCard === "outOfStock" &&
                                rawOutStockValue?.length > 0) ||
                            (expandAnalyticsCard === "totalStockValue" &&
                                rawTotalStockValue?.length > 0)) && (
                            <Button
                                btnText="Export"
                                icon={<i className="ri-arrow-go-forward-fill text-p-50"></i>}
                                isLoading={
                                    exportingBrandInventoryInStock ||
                                    exportingBrandInventoryTotalStock ||
                                    exportingBrandInventoryOutOfStock
                                }
                                btnClassname="text-p-50 !w-fit !h-10 px-3 !bg-white shadow-buttonShadow2 !border !border-slate-100"
                                onClick={() => {
                                    if (expandAnalyticsCard === "inStock") {
                                        dispatch(multibrandActions?.exportBrandInventoryInStock());
                                    }
                                    if (expandAnalyticsCard === "outOfStock") {
                                        dispatch(
                                            multibrandActions?.exportBrandInventoryOutOfStock()
                                        );
                                    }
                                    if (expandAnalyticsCard === "totalStockValue") {
                                        dispatch(
                                            multibrandActions?.exportBrandInventoryTotalStock()
                                        );
                                    }
                                }}
                            />
                        )}
                    </div>
                    {(expandAnalyticsCard === "totalStockValue" &&
                        totalStockValueDataLoading &&
                        !isLoadingMore &&
                        !debouncedSearch) ||
                    (expandAnalyticsCard === "inStock" &&
                        inStockValueDataLoading &&
                        !inStockDebouncedSearch &&
                        !isInStockLoadMore) ||
                    (expandAnalyticsCard === "belowMinimum" &&
                        reStockValueDataLoading &&
                        !reStockDebouncedSearch &&
                        !isReStockLoadMore) ||
                    (expandAnalyticsCard === "outOfStock" &&
                        outStockValueDataLoading &&
                        !outStockDebouncedSearch &&
                        !isOutStockLoadMore) ? (
                        <CustomTableLoader
                            tableHeader={
                                expandAnalyticsCard === "totalStockValue"
                                    ? totalStockValueTableHeader
                                    : expandAnalyticsCard === "inStock"
                                    ? inStockValueTableHeader
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? belowMinValueTableHeader
                                    : expandAnalyticsCard === "outOfStock"
                                    ? outOfStockValueTableHeader
                                    : totalStockValueTableHeader
                            }
                        />
                    ) : (expandAnalyticsCard === "totalStockValue" &&
                          rawTotalStockValue?.length > 0) ||
                      (expandAnalyticsCard === "inStock" && rawInStockValue?.length > 0) ||
                      (expandAnalyticsCard === "belowMinimum" && rawRestockValue?.length > 0) ||
                      (expandAnalyticsCard === "outOfStock" && rawOutStockValue?.length > 0) ? (
                        <>
                            <div id="modalTableContent">
                                <CustomTable
                                    tableBody={
                                        expandAnalyticsCard === "totalStockValue"
                                            ? totalStockValueTableBody
                                            : expandAnalyticsCard === "inStock"
                                            ? inStockValueTableBody
                                            : expandAnalyticsCard === "belowMinimum"
                                            ? belowMinValueTableBody
                                            : expandAnalyticsCard === "outOfStock"
                                            ? outOfStockValueTableBody
                                            : totalStockValueTableBody
                                    }
                                    tableHeader={
                                        expandAnalyticsCard === "totalStockValue"
                                            ? totalStockValueTableHeader
                                            : expandAnalyticsCard === "inStock"
                                            ? inStockValueTableHeader
                                            : expandAnalyticsCard === "belowMinimum"
                                            ? belowMinValueTableHeader
                                            : expandAnalyticsCard === "outOfStock"
                                            ? outOfStockValueTableHeader
                                            : totalStockValueTableHeader
                                    }
                                    isScrollable={false}
                                    scrollWithin={false}
                                    isInifiniteScroll={false}
                                    isCellBordered={true}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    headerContainerClass="!bg-slate-50"
                                    headerItemClass="font-rocGroteskMedium "
                                    tableClass="mb-12"
                                    sort={handleTotalValueSortChange}
                                />

                                {expandAnalyticsCard === "totalStockValue"
                                    ? istotalStockLoadMore && totalStockValueDataLoading
                                    : expandAnalyticsCard === "inStock"
                                    ? isInStockLoadMore && inStockValueDataLoading
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? isReStockLoadMore && reStockValueDataLoading
                                    : expandAnalyticsCard === "outOfStock"
                                    ? isOutStockLoadMore && outStockValueDataLoading
                                    : istotalStockLoadMore &&
                                      totalStockValueDataLoading && (
                                          <div className="flex my-4 justify-center">
                                              <Loader color="gm-25" size={4} />
                                          </div>
                                      )}
                                {expandAnalyticsCard === "totalStockValue"
                                    ? totalStockValuePagination?.currentPage ===
                                      totalStockValuePagination?.noOfPages
                                    : expandAnalyticsCard === "inStock"
                                    ? inStockValuePagination?.currentPage ===
                                      inStockValuePagination?.noOfPages
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? reStockValuePagination?.currentPage ===
                                      reStockValuePagination?.noOfPages
                                    : expandAnalyticsCard === "outOfStock"
                                    ? outStockValuePagination?.currentPage ===
                                      outStockValuePagination?.noOfPages
                                    : totalStockValuePagination?.currentPage ===
                                          totalStockValuePagination?.noOfPages && (
                                          <div className="flex my-4 text-slate-500 justify-center text-sm font-rocGroteskMedium">
                                              <p className="text-center"> End of list</p>
                                          </div>
                                      )}
                            </div>
                            <div className="flex justify-end mb-14 pr-5">
                                <ResponsivePaginationComponent
                                    current={
                                        expandAnalyticsCard === "totalStockValue"
                                            ? Number(totalStockValuePagination?.currentPage)
                                            : expandAnalyticsCard === "inStock"
                                            ? Number(inStockValuePagination?.currentPage)
                                            : expandAnalyticsCard === "belowMinimum"
                                            ? Number(reStockValuePagination?.currentPage)
                                            : expandAnalyticsCard === "outOfStock"
                                            ? Number(outStockValuePagination?.currentPage)
                                            : Number(totalStockValuePagination?.currentPage)
                                    }
                                    total={
                                        expandAnalyticsCard === "totalStockValue"
                                            ? Number(totalStockValuePagination?.noOfPages)
                                            : expandAnalyticsCard === "inStock"
                                            ? Number(inStockValuePagination?.noOfPages)
                                            : expandAnalyticsCard === "belowMinimum"
                                            ? Number(reStockValuePagination?.noOfPages)
                                            : expandAnalyticsCard === "outOfStock"
                                            ? Number(outStockValuePagination?.noOfPages)
                                            : Number(totalStockValuePagination?.noOfPages)
                                    }
                                    maxWidth={350}
                                    onPageChange={(page) => {
                                        expandAnalyticsCard === "totalStockValue"
                                            ? totalStockSetUpdatedPage(page)
                                            : expandAnalyticsCard === "inStock"
                                            ? inStockSetUpdatedPage(page)
                                            : expandAnalyticsCard === "belowMinimum"
                                            ? reStockSetUpdatedPage(page)
                                            : expandAnalyticsCard === "outOfStock"
                                            ? outStockSetUpdatedPage(page)
                                            : totalStockSetUpdatedPage(page);
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <div className="items-center flex-col flex  h-[100%]  justify-center my-[8%]">
                            {(
                                expandAnalyticsCard === "totalStockValue"
                                    ? debouncedSearch
                                    : expandAnalyticsCard === "inStock"
                                    ? inStockDebouncedSearch
                                    : expandAnalyticsCard === "belowMinimum"
                                    ? reStockDebouncedSearch
                                    : expandAnalyticsCard === "outOfStock"
                                    ? outStockDebouncedSearch
                                    : debouncedSearch
                            ) ? (
                                <div className="space-y-3 w-[452px]  justify-center items-center flex flex-col  text-center">
                                    <div className="p-[5px] flex items-center justify-center w-[34px] h-[34px] rounded-lg bg-slate-100 mb-2.5">
                                        <i className="ri-search-line text-2xl text-[#142837] opacity-50"></i>
                                    </div>

                                    <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                        Sorry, no results match your search. Refine your criteria
                                        and try again.
                                    </p>
                                </div>
                            ) : (
                                <div>
                                    {expandAnalyticsCard === "totalStockValue" ? (
                                        <EmptyStateComp
                                            className="!my-0"
                                            title="No Stock Value Data"
                                            description="You currently have no stock value data available. Start by adding your products to the inventory"
                                            icon={
                                                <i className="ri-box-3-line text-slate-500 text-[40px]"></i>
                                            }
                                        />
                                    ) : expandAnalyticsCard === "inStock" ? (
                                        <EmptyStateComp
                                            className="!my-0"
                                            title="No In-Stock Products"
                                            description="There are no items currently in stock. Check your inventory or add new products to get started."
                                            icon={
                                                <i className="ri-box-3-line text-slate-500 text-[40px]"></i>
                                            }
                                        />
                                    ) : expandAnalyticsCard === "belowMinimum" ? (
                                        <EmptyStateComp
                                            className="!my-0"
                                            title="No In-Stock Products"
                                            description="There are no items that need restocking at the moment. Monitor your inventory levels to ensure timely restocking."
                                            icon={
                                                <i className="ri-box-3-line text-slate-500 text-[40px]"></i>
                                            }
                                        />
                                    ) : expandAnalyticsCard === "outOfStock" ? (
                                        <EmptyStateComp
                                            className="!my-0"
                                            title="No Out of Stock Items"
                                            description="All items are currently in stock. Monitor your inventory to stay ahead of potential stockouts."
                                            icon={
                                                <i className="ri-box-3-line text-slate-500 text-[40px]"></i>
                                            }
                                        />
                                    ) : expandAnalyticsCard === "averageCogs" ? (
                                        <EmptyStateComp
                                            className="!my-0"
                                            title="No Stock Value Data"
                                            description="You currently have no stock value data available. Start by adding your products to the inventory"
                                            icon={
                                                <i className="ri-box-3-line text-slate-500 text-[40px]"></i>
                                            }
                                        />
                                    ) : (
                                        <EmptyStateComp
                                            className="!my-0"
                                            title="No Stock Value Data"
                                            description="You currently have no stock value data available. Start by adding your products to the inventory"
                                            hasButton
                                            btnText="Add product"
                                            btnAction={() =>
                                                navigate("/dashboard/inventory/add-product")
                                            }
                                            icon={
                                                <i className="ri-box-3-line text-slate-500 text-[40px]"></i>
                                            }
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </ModalContainer>
        </div>
    );
};

export default Inventory;
