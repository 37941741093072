import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import DateInput from "components/common/DateInput";
import AddressInput from "components/common/InputField/AddressInput";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import PageTitleSection from "components/common/PageTitleSection";
import VendorAvatar from "components/common/VendorAvatar";
import React, { useEffect, useRef, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useNavigate } from "react-router-dom";
import countries from "variables/countries";
import OrderPreview from "./OrderPreview";
import Checkbox from "components/common/InputField/Checkbox";
import useGetCustomerList from "hooks/salesHooks/useGetCustomerList";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import SalesOrderDetails from "components/views/Synth2.0/Order/SalesOrderDetails";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { salesActions } from "redux/Ldb/actions";
import CustomToast from "components/common/CustomToast";
import toast from "react-hot-toast";

const CreateOrder = () => {
    const [address, setAddress] = useState<{ [key: string]: { [key: string]: string } }>({});
    const [isPreview, setIsPreview] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { createSalesOrderSuccess, createSalesOrderFailure } = useAppSelector(
        (state) => state.sales
    );

    const [customer, setCustomer] = useState("");
    const [orderDetails, setOrderDetails] = useState({
        customerName: "",
        customerEmail: "",
        customerAddress: "",
        customerPhone: "",
        currency: "USD",
        deliveryDate: "",
        orderDate: "",
        // thirdParty: "",
        // haulage: "",
        fulfillmentProvider: "",
        fulfillmentMode: "",
        fifo: false,
        inventorySync: false,
    });
    // console.log("orderDetails", orderDetails);

    const [products, setProducts] = useState([
        {
            productName: "",
            sku: "",
            inventoryId: "",
            unitPrice: "",
            quantity: "",
            discount: "",
            stockLevel: "",
            total: "",
        },
    ]);
    const [deliveryDate, setDeliveryDate] = useState<boolean>(false);
    const [orderDate, setOrderDate] = useState<boolean>(false);
    // console.log("products", products);
    const {
        data: customerData,
        isFetching: fetchingCustomers,
        handleDebounceSearch,
    } = useGetCustomerList();

    const { productSearchList, isFetching, handleDebouncedChangeTwo } = useGetProductList();

    const handleAddProduct = () => {
        setProducts((prev) => [
            ...prev,
            {
                productName: "",
                sku: "",
                inventoryId: "",
                unitPrice: "",
                quantity: "",
                discount: "",
                stockLevel: "",
                total: "",
            },
        ]);
    };

    const handleRemoveProduct = (index: number) => {
        setProducts((prevProductItems) => {
            const updatedItems = prevProductItems.filter((_, idx) => idx !== index);
            if (updatedItems.length === 0) {
                return [
                    {
                        productName: "",
                        sku: "",
                        inventoryId: "",
                        unitPrice: "",
                        quantity: "",
                        discount: "",
                        stockLevel: "",
                        total: "",
                    },
                ];
            }

            return updatedItems;
        });
    };

    const tableHeader = [
        { title: "Product SKU", widthClass: "w-[13.6%]" },
        { title: "Product name", widthClass: "w-[13.6%]" },
        { title: "Unit price ($)", widthClass: "w-[13.6%]" },
        { title: "Inventory status", widthClass: "w-[13.6%]" },
        { title: "Quantity", widthClass: "w-[13.6%]" },
        { title: "Total price ($)", widthClass: "w-[13.6%]" },
        { title: "Discount (%)", widthClass: "w-[13.6%]" },
        { title: "", widthClass: "w-[4%]" },
    ];

    const tableBody = products?.map((item, idx) => {
        return [
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 relative`}>
                        <div className="font-rocGroteskMedium items-center flex space-x-2">
                            <SelectInput
                                value={item?.inventoryId}
                                name="inventoryId"
                                inputPlaceholder={"Select"}
                                handleChange={(name, value) => {
                                    if (value) {
                                        setProducts((prevSelectedItems) => {
                                            const updatedItems = [...prevSelectedItems];
                                            const selectedItem = productSearchList.find(
                                                (item) => item._id === value
                                            );
                                            console.log("selectedItem", selectedItem);
                                            if (selectedItem) {
                                                updatedItems[idx] = {
                                                    ...updatedItems[idx],
                                                    unitPrice: selectedItem?.unitCost?.amount,
                                                    productName: selectedItem.productName,
                                                    sku: selectedItem.sku,
                                                    inventoryId: selectedItem._id,
                                                    stockLevel:
                                                        selectedItem?.productStockDetails
                                                            ?.stockLevel,
                                                };
                                            }

                                            return updatedItems;
                                        });
                                    }
                                }}
                                handleInputChange={handleDebouncedChangeTwo}
                                isRequired={true}
                                idx={idx}
                                className="!border-0 !rounded-none"
                                inputContainerClassName="!mb-0"
                                searchLoading={isFetching}
                                clearValue={true}
                                dropdownOptions={productSearchList?.map((item) => ({
                                    label: (
                                        <div className="flex gap-3 items-center">
                                            <div className="flex gap flex-col">
                                                <p className="font-rocGroteskMedium">
                                                    {item?.productName}
                                                </p>
                                            </div>
                                        </div>
                                    ),
                                    value: `${item?._id}`,
                                }))}
                            />
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div key={idx} className={` w-full border-slate-100 text-sm  text-slate-900 `}>
                        <p>{item?.sku || "----"}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 relative`}>
                        <p>{item?.unitPrice || "----"}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <p className="flex items-center space-x-2">
                            <span
                                className={`w-2 h-2 rounded-full ${
                                    Number(item?.stockLevel) > 0 ? "bg-carribean-green" : "bg-r-50"
                                }`}
                            ></span>
                            <span className="text-sm font-rocGroteskMedium">
                                {Number(item?.stockLevel) > 0 ? "Available" : "Unavailable"}
                            </span>
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div className={` w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item?.quantity}
                            name={"qty"}
                            type={"text"}
                            required={true}
                            onChange={(e) => {
                                setProducts((prevSelectedItems) => {
                                    const updatedItems = [...prevSelectedItems];

                                    updatedItems[idx] = {
                                        ...updatedItems[idx],
                                        quantity: e.target.value,
                                    };

                                    return updatedItems;
                                });
                            }}
                            inputPlaceholder={"Enter Qty"}
                            inputContainerClassname="!border-none"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 relative`}>
                        {Number(item?.quantity) * Number(item?.unitPrice) -
                            (Number(item?.quantity) *
                                Number(item?.unitPrice) *
                                Number(item?.discount || 0)) /
                                100}
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 relative`}>
                        <TextInput
                            value={item?.discount}
                            name={"discount"}
                            type={"text"}
                            required={false}
                            onChange={(e) => {
                                setProducts((prevSelectedItems) => {
                                    const updatedItems = [...prevSelectedItems];

                                    updatedItems[idx] = {
                                        ...updatedItems[idx],
                                        discount: e.target.value,
                                        total: String(
                                            Number(item?.quantity) * Number(item?.unitPrice) -
                                                (Number(item?.quantity) *
                                                    Number(item?.unitPrice) *
                                                    Number(item?.discount || 0)) /
                                                    100
                                        ),
                                    };

                                    return updatedItems;
                                });
                            }}
                            inputPlaceholder={"Enter discount"}
                            inputContainerClassname="!border-none"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 relative`}>
                        {idx > 0 && (
                            <i
                                onClick={() => handleRemoveProduct(idx)}
                                className="ri-delete-bin-5-line cursor-pointer"
                            ></i>
                        )}
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
        ];
    });

    const handleOrderDetailsChange = (name: string, value: string | boolean) => {
        setOrderDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const totalCost = products?.reduce((acc, curr) => Number(acc) + Number(curr?.total), 0);

    useEffect(() => {
        if (Boolean(createSalesOrderSuccess)) {
            toast.custom((t) => (
                <CustomToast t={t} message={"Order created successfully"} type={"success"} />
            ));
            navigate(`/dashboard/order?currTab=1`);
            dispatch(salesActions.resetCreateSalesOrderSuccess());
        }
    }, [createSalesOrderSuccess, dispatch]);

    return !isPreview ? (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                setIsPreview(true);
            }}
            className="p-4 border border-slate-100 rounded-[8px] bg-white"
        >
            <div
                onClick={() => navigate(-1)}
                className="cursor-pointer text-[#3B4C58] space-x-3 w-fit flex items-center py-4"
            >
                <i className="ri-arrow-left-line text-sm "></i>
                <p className="text-sm underline  font-rocGroteskMedium">Back</p>
            </div>
            <div className="mb-8 flex justify-between items-center py-4 border-b border-slate-200">
                <p className="text-[22px] leading-[33px] text-gm-50 font-rocGroteskMedium">
                    Sales order creation form
                </p>
                <div className="flex items-center space-x-3">
                    <Button
                        onClick={() => navigate("/dashboard/order")}
                        btnClassname="!py-2.5 !border !bg-transparent !border-[#F4F5F7] !px-4 !w-fit !h-10"
                        btnText="Cancel"
                    />
                    <Button
                        // onClick={() => setIsPreview(true)}
                        type="submit"
                        btnClassname="!py-2.5 !px-8 !w-fit !h-10"
                        btnText="Preview"
                    />
                </div>
            </div>

            <div className="py-4">
                <div>
                    <h6 className="text-base font-rocGroteskMedium mb-6">Order details</h6>

                    <div className="mb-3">
                        <SelectInput
                            value={""}
                            name="customer"
                            placeholder={"Select from customer list (optional)"}
                            handleChange={(name, value) => {
                                if (value?.startsWith("{")) {
                                    const parsedValue = JSON.parse(value || "");
                                    setCustomer(value);

                                    setOrderDetails((prev) => {
                                        return {
                                            ...prev,
                                            customerName:
                                                parsedValue?.company ||
                                                parsedValue?.name ||
                                                `${parsedValue?.firstName} ${parsedValue?.lastName}`,
                                            customerEmail: parsedValue?.email,
                                            customerAddress: parsedValue?.address1,
                                            customerPhone: parsedValue?.phone,
                                        };
                                    });

                                    setAddress({
                                        billingAddress: {
                                            address: parsedValue?.address1,
                                        },
                                    });
                                }
                            }}
                            handleInputChange={handleDebounceSearch}
                            isRequired={false}
                            searchLoading={fetchingCustomers}
                            dropdownOptions={customerData
                                ?.filter((item) => item?.email)
                                ?.map((customer) => {
                                    return {
                                        label: `${
                                            customer?.name ||
                                            customer?.firstName + " " + customer?.lastName
                                        } - ${customer?.email}`,
                                        value: JSON.stringify(customer || {}),
                                    };
                                })}
                        />
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                        <TextInput
                            value={orderDetails?.customerName}
                            name={"customerName"}
                            type={"text"}
                            required={true}
                            onChange={(e) =>
                                handleOrderDetailsChange(e.target.name, e.target.value)
                            }
                            placeholder={"Customer name"}
                            containerClassname="w-full "
                            inputContainerClassname={" "}
                            inputClassName={`w-full`}
                        />

                        <TextInput
                            value={orderDetails?.customerEmail}
                            name={"customerEmail"}
                            type={"email"}
                            required={true}
                            onChange={(e) =>
                                handleOrderDetailsChange(e.target.name, e.target.value)
                            }
                            placeholder={"Customer email"}
                            containerClassname="w-full "
                            inputContainerClassname={" "}
                            inputClassName={`w-full`}
                        />

                        <AddressInput
                            setData={setAddress}
                            savedAddress={
                                address?.billingAddress ? address?.billingAddress?.address : ""
                            }
                            placeholder="Billing address"
                            required={true}
                            name={"billingAddress"}
                        />

                        <SelectInput
                            value={orderDetails.currency}
                            name="currency"
                            placeholder={"Currency"}
                            handleChange={(name, value) => handleOrderDetailsChange(name, value)}
                            isRequired={true}
                            dropdownOptions={[
                                {
                                    label: "GBP (£)",
                                    value: "GBP",
                                },
                                {
                                    label: "USD ($)",
                                    value: "USD",
                                },
                                {
                                    label: "EUR (€)",
                                    value: "EUR",
                                },
                            ]}
                        />

                        <DateInput
                            label="Delivery date"
                            value={orderDetails?.deliveryDate}
                            onChange={(newDate) =>
                                handleOrderDetailsChange("deliveryDate", newDate as string)
                            }
                            showCalendar={deliveryDate}
                            calendarClassname="!right-0"
                            required={true}
                            onCalendarToggle={setDeliveryDate}
                        />

                        <DateInput
                            label="Order date"
                            value={orderDetails?.orderDate}
                            onChange={(newDate) =>
                                handleOrderDetailsChange("orderDate", newDate as string)
                            }
                            showCalendar={orderDate}
                            calendarClassname="!right-0"
                            required={true}
                            onCalendarToggle={setOrderDate}
                        />
                    </div>
                </div>

                <div className="border-b border-dashed border-[#E0E0E0] my-8" />

                <div>
                    <h6 className="text-base font-rocGroteskMedium mb-6">Product details</h6>

                    <div>
                        <div>
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isAllSelectable={false}
                                isCellSelectable={false}
                                headerContainerClass="!bg-[#F1F5F9] !font-rocGroteskMedium !text-[#142837] !border-[#F1F5F9]"
                                isScrollable={false}
                                isCellBordered={true}
                                scrollWithin={false}
                                bodyItemClass={"hover:!bg-transparent"}
                            />
                        </div>
                        <div className="mt-4 pt-3 flex items-center justify-between">
                            <div
                                onClick={handleAddProduct}
                                className="w-fit cursor-pointer flex items-center space-x-3"
                            >
                                <i className="ri-add-circle-line text-[#6562E5]"></i>
                                <span className="font-rocGroteskMedium text-sm text-[#6562E5] underline">
                                    Add more
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border-b border-dashed border-[#E0E0E0] my-8" />

                <div>
                    <h6 className="text-base font-rocGroteskMedium mb-6">Fulfilment & Shipping</h6>

                    <div>
                        <div className="grid grid-cols-2 gap-3 mb-6">
                            <SelectInput
                                value={orderDetails?.fulfillmentProvider}
                                name="fulfillmentProvider"
                                placeholder={"3PL"}
                                handleChange={(name, value) => {
                                    handleOrderDetailsChange(name, value);
                                }}
                                isRequired={true}
                                dropdownOptions={[
                                    { label: "3PL", value: "3PL" },
                                    { label: "SPS", value: "SPS" },
                                ]}
                            />
                            <SelectInput
                                value={orderDetails?.fulfillmentMode}
                                name="fulfillmentMode"
                                placeholder={"In-Country Haulage"}
                                handleChange={(name, value) => {
                                    handleOrderDetailsChange(name, value);
                                }}
                                isRequired={true}
                                dropdownOptions={[
                                    { label: "In-Country Haulage", value: "In-Country Haulage" },
                                    { label: "Ocean Freight", value: "Ocean Freight" },
                                    { label: "Air Freight", value: "Air Freight" },
                                ]}
                            />
                        </div>
                    </div>
                </div>

                <div className="border-b border-dashed border-[#E0E0E0] my-8" />

                <div>
                    <h6 className="text-base font-rocGroteskMedium mb-6">Approval & Processing</h6>
                    <div className="grid grid-cols-2 gap-3">
                        <div className="flex justify-between items-center border border-[#E2E8F0] rounded py-6 px-4">
                            <div>
                                <p className="text-[16px] leading-[23px] font-rocGroteskMedium text-gm-50">
                                    FIFO Assignment
                                </p>
                                <p className="text-sm text-[#64748B] font-rocGroteskMedium mt-0.5">
                                    Assigns lots on a FIFO (First In, First Out) basis.
                                </p>
                            </div>
                            <div className="border-[#6B778C] flex justify-center items-center rounded border w-6 h-6">
                                <Checkbox
                                    name="fifo"
                                    className="h-5 w-5"
                                    isChecked={orderDetails?.fifo}
                                    onChange={(e) =>
                                        handleOrderDetailsChange(e.target.name, e.target.checked)
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex justify-between items-center border border-[#E2E8F0] rounded py-6 px-4">
                            <div>
                                <p className="text-[16px] leading-[23px] font-rocGroteskMedium text-gm-50">
                                    Inventory sync
                                </p>
                                <p className="text-sm text-[#64748B] font-rocGroteskMedium mt-0.5">
                                    Updates inventory levels based on the order.
                                </p>
                            </div>
                            <div className="border-[#6B778C] flex justify-center items-center rounded border w-6 h-6">
                                <Checkbox
                                    name="inventorySync"
                                    className="h-5 w-5"
                                    isChecked={orderDetails?.inventorySync}
                                    onChange={(e) =>
                                        handleOrderDetailsChange(e.target.name, e.target.checked)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    ) : (
        // <OrderPreview setIsPreview={setIsPreview} />
        <SalesOrderDetails
            onClickOne={() => setIsPreview(false)}
            onClickTwo={() => {
                const body: { [key: string]: any } = {
                    customerDetails: {
                        name: orderDetails?.customerName,
                        //   "first_name": "John",
                        //   "last_name": "Doe",
                        address1: address?.billingAddress?.address || orderDetails?.customerAddress,
                        // phone: orderDetails?.customerPhone,
                        email: orderDetails?.customerEmail,
                        //   "city": "New York",
                        //   "zip": "10001",
                        //   "province": "NY",
                        //   "country": "USA",
                        //   "address2": "Apt 4B",
                        //   "company": "Acme Corp",
                        latitude: address?.billingAddress?.latitude,
                        longitude: address?.billingAddress?.longitude,
                        //   "country_code": "US",
                        //   "province_code": "NY"
                    },
                    // "purchaseOrderId": "667d9a762b60a559c5cd2d13",
                    saleDate: moment(orderDetails?.orderDate)
                        .utc()
                        .format("YYYY-MM-DDTHH:mm:ss[Z]"),
                    deliveryDate: moment(orderDetails?.deliveryDate)
                        .utc()
                        .format("YYYY-MM-DDTHH:mm:ss[Z]"),
                    filfilment: {
                        provider: orderDetails?.fulfillmentProvider,
                        mode: orderDetails?.fulfillmentMode,
                    },
                    orderProcessing: {
                        inventorySync: orderDetails?.inventorySync,
                        fifo: orderDetails?.fifo,
                    },
                    products: products?.map((item) => {
                        return {
                            inventoryId: item?.inventoryId,
                            quantity: item?.quantity,
                            subtotal: {
                                amount:
                                    Number(item?.quantity) * Number(item?.unitPrice) -
                                    (Number(item?.quantity) *
                                        Number(item?.unitPrice) *
                                        Number(item?.discount || 0)) /
                                        100,
                                currency: orderDetails?.currency,
                            },
                            discount: Number(item?.discount),
                            price: {
                                amount: Number(item?.unitPrice),
                                currency: orderDetails?.currency,
                            },
                        };
                    }),
                    total: totalCost,
                    currency: orderDetails?.currency,
                };

                if (orderDetails?.customerPhone) {
                    body.customerDetails = {
                        ...body.customerDetails,
                        phone: orderDetails?.customerPhone,
                    };
                }

                dispatch(salesActions.createSalesOrder(body));
            }}
            title="Preview"
            channelImg={displayProductChannelIcon("synth", "!w-5 !h-5")}
            channel="Synth"
            orderDate={moment(orderDetails?.orderDate).format("MMM DD, YYYY")}
            customerPO="preview"
            deliveryDate={moment(orderDetails?.deliveryDate).format("MMM DD, YYYY")}
            currency={orderDetails?.currency}
            subTotal={String(totalCost)}
            discount="%"
            total={String(totalCost)}
            customerName={orderDetails?.customerName}
            haulage={orderDetails?.fulfillmentProvider}
            email={orderDetails?.customerEmail}
            thirdParty={orderDetails?.fulfillmentMode}
            phone={orderDetails?.customerPhone}
            billAddress={orderDetails?.customerAddress || address?.billAddress?.address}
            products={products?.map((item) => {
                return {
                    ...item,
                    unitPriceCurrency: orderDetails?.currency,
                    unitPrice: { amount: item?.unitPrice },
                    qty: item?.quantity,
                };
            })}
        />
    );
};

export default CreateOrder;
