import CustomToast from "components/common/CustomToast";

import { toast } from "react-hot-toast";
import { multibrandTypes } from "../types";
import { multibrandService } from "services/Ldb";
import { Dispatch } from "redux";

export const multibrandActions = {
    getBrandList,
    getBrandListSearch,
    createBrand,
    updateBrand,
    resetCreateBrandSuccess,
    resetUpdateBrandSuccess,
    exportBrandList,
    resetExportBrandListSuccess,
    getSingleBrand,
    resetGetSingleBrandSuccess,
    getAssignedBrands,
    getAssignedBrandsSearch,
    getBrandToken,
    resetGetBrandTokenSuccess,
    getProfitAnalytics,
    getCostSummary,
    getChannelSalesAnalytics,
    getIncidentsAnalytics,
    getRevenueGraph,
    getRevenueTable,
    getBrandAnalytics,
    getBrandComparism,
    getBrandInventory,
    getBrandStockDetails,
    getBrandWarehousStockBreakdown,
    getBrandWarehousList,
    exportBrandInventoryList,
    resetExportBrandInventoryListSuccess,
    fetchInventoryInStockValue,
    fetchInventoryTotalStock,
    fetchInventoryOutOfStockValue,
    fetchInventoryRestockValue,
    resetFetchInventoryInStockValueSuccess,
    resetFetchInventoryTotalStockValueSuccess,
    resetFetchInventoryOutOfStockValueSuccess,
    resetFetchInventoryRestockValueSuccess,
    exportBrandInventoryOutOfStock,
    exportBrandInventoryTotalStock,
    exportBrandInventoryInStock,
    resetExportInventoryOutOfStockSuccess,
    resetExportInventoryTotalStockSuccess,
    resetExportInventoryInStockSuccess,
};

function getBrandList(page?: string | number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_BRAND_LIST_REQUEST));

        multibrandService.listBrands(page, search).then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_BRAND_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(multibrandTypes.FETCH_BRAND_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function getBrandListSearch(page?: string | number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_BRAND_LIST_SEARCH_REQUEST));

        multibrandService.listBrands(page, search).then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_BRAND_LIST_SEARCH_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(multibrandTypes.FETCH_BRAND_LIST_SEARCH_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function createBrand(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.CREATE_BRAND_REQUEST));

        multibrandService.createBrand(data).then(
            (res) => {
                dispatch(success(multibrandTypes.CREATE_BRAND_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Brand created successfully"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(multibrandTypes.CREATE_BRAND_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateBrand(data: Record<string, any> | Array<Record<string, any>>, brandId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.UPDATE_BRAND_REQUEST));

        multibrandService.updateBrand(data, brandId).then(
            (res) => {
                dispatch(success(multibrandTypes.UPDATE_BRAND_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Update Successful"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(multibrandTypes.UPDATE_BRAND_FAILURE, error?.message));
                }
            }
        );
    };
}

function exportBrandList() {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.EXPORT_BRAND_LIST_REQUEST));

        multibrandService.exportBrandList().then(
            (res) => {
                dispatch(success(multibrandTypes.EXPORT_BRAND_LIST_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Export successful"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(multibrandTypes.EXPORT_BRAND_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleBrand(brandId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_SINGLE_BRAND_REQUEST));

        multibrandService.getSingleBrand(brandId).then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_SINGLE_BRAND_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(multibrandTypes.FETCH_SINGLE_BRAND_FAILURE, error?.message));
                }
            }
        );
    };
}

function getAssignedBrands(page?: string | number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_ASSIGNED_BRAND_LIST_REQUEST));

        multibrandService.getAssignedBrands(page, search).then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_ASSIGNED_BRAND_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(multibrandTypes.FETCH_ASSIGNED_BRAND_LIST_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getAssignedBrandsSearch(page?: string | number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_ASSIGNED_BRAND_LIST_SEARCH_REQUEST));

        multibrandService.getAssignedBrands(page, search).then(
            (res) => {
                dispatch(
                    success(multibrandTypes.FETCH_ASSIGNED_BRAND_LIST_SEARCH_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            multibrandTypes.FETCH_ASSIGNED_BRAND_LIST_SEARCH_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getBrandToken(brandId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_BRAND_TOKEN_REQUEST));

        multibrandService.getBrandToken(brandId).then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_BRAND_TOKEN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(multibrandTypes.FETCH_BRAND_TOKEN_FAILURE, error?.message));
                }
            }
        );
    };
}

function getProfitAnalytics(dateRange?: string, brandId?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_PROFIT_ANALYTICS_REQUEST));

        multibrandService.getProfitAnalytics(dateRange, brandId).then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_PROFIT_ANALYTICS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(multibrandTypes.FETCH_PROFIT_ANALYTICS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getCostSummary(dateRange?: string, brandId?: string, costType?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_COST_SUMMARY_REQUEST));

        multibrandService.getCostSummary(dateRange, brandId, costType).then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_COST_SUMMARY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(multibrandTypes.FETCH_COST_SUMMARY_FAILURE, error?.message));
                }
            }
        );
    };
}

function getChannelSalesAnalytics(dateRange?: string, brandId?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_CHANNEL_SALES_ANALYTICS_REQUEST));

        multibrandService.getChannelSalesAnalytics(dateRange, brandId).then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_CHANNEL_SALES_ANALYTICS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            multibrandTypes.FETCH_CHANNEL_SALES_ANALYTICS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getIncidentsAnalytics(dateRange?: string, brandId?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_INCIDENTS_ANALYTICS_REQUEST));

        multibrandService.getIncidentsAnalytics(dateRange, brandId).then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_INCIDENTS_ANALYTICS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(multibrandTypes.FETCH_INCIDENTS_ANALYTICS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getRevenueGraph(dateRange?: string, brandId?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_REVENUE_GRAPH_REQUEST));

        multibrandService.getRevenueGraph(dateRange, brandId).then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_REVENUE_GRAPH_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(multibrandTypes.FETCH_REVENUE_GRAPH_FAILURE, error?.message));
                }
            }
        );
    };
}

function getRevenueTable(dateRange?: string, brandId?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_REVENUE_TABLE_REQUEST));

        multibrandService.getRevenueTable(dateRange, brandId).then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_REVENUE_TABLE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(multibrandTypes.FETCH_REVENUE_TABLE_FAILURE, error?.message));
                }
            }
        );
    };
}

function getBrandAnalytics(dateRange?: string, brandId?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_BRAND_ANALYTICS_REQUEST));

        multibrandService.getBrandAnalytics(dateRange, brandId).then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_BRAND_ANALYTICS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(multibrandTypes.FETCH_BRAND_ANALYTICS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getBrandComparism(brandOneId: string, brandTwoId: string, dateRange?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_BRAND_COMPARISM_REQUEST));

        multibrandService.getBrandComparism(brandOneId, brandTwoId, dateRange).then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_BRAND_COMPARISM_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(multibrandTypes.FETCH_BRAND_COMPARISM_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getBrandInventory(page?: string | number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_BRAND_INVENTORY_REQUEST));

        multibrandService.getBrandInventory(page, search).then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_BRAND_INVENTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(multibrandTypes.FETCH_BRAND_INVENTORY_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getBrandStockDetails() {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_BRAND_STOCK_DETAILS_REQUEST));

        multibrandService.getBrandStockDetails().then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_BRAND_STOCK_DETAILS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(multibrandTypes.FETCH_BRAND_STOCK_DETAILS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getBrandWarehousStockBreakdown(brandId?: string, warehouseId?: string, channels?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_BRAND_WAREHOUSE_STOCK_BREAKDOWN_REQUEST));

        multibrandService.getBrandWarehouseStockBreakdown(brandId, warehouseId, channels).then(
            (res) => {
                dispatch(
                    success(
                        multibrandTypes.FETCH_BRAND_WAREHOUSE_STOCK_BREAKDOWN_SUCCESS,
                        res?.data
                    )
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            multibrandTypes.FETCH_BRAND_WAREHOUSE_STOCK_BREAKDOWN_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getBrandWarehousList(search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_BRAND_WAREHOUSE_LIST_REQUEST));

        multibrandService.getBrandWarehouses(search).then(
            (res) => {
                dispatch(success(multibrandTypes.FETCH_BRAND_WAREHOUSE_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(multibrandTypes.FETCH_BRAND_WAREHOUSE_LIST_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function exportBrandInventoryList() {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.EXPORT_BRAND_INVENTORY_LIST_REQUEST));

        multibrandService.exportBrandInventoryList().then(
            (res) => {
                dispatch(success(multibrandTypes.EXPORT_BRAND_INVENTORY_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(multibrandTypes.EXPORT_BRAND_INVENTORY_LIST_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchInventoryInStockValue(
    warehouseId?: string,
    channel?: string,
    limit?: number,
    page?: number,
    startDate?: string,
    endDate?: string,
    search?: string,
    sortParams?: any
) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_INVENTORY_IN_STOCK_REQUEST));

        multibrandService
            .getInventoryInStockValue(
                warehouseId,
                channel,
                limit,
                page,
                startDate,
                endDate,
                search,
                sortParams
            )
            .then(
                (res) => {
                    dispatch(success(multibrandTypes.FETCH_INVENTORY_IN_STOCK_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                multibrandTypes.FETCH_INVENTORY_IN_STOCK_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function fetchInventoryTotalStock(
    warehouseId?: string,
    channel?: string,
    limit?: number,
    page?: number,
    startDate?: string,
    endDate?: string,
    search?: string,
    sortParams?: any
) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_INVENTORY_TOTAL_STOCK_REQUEST));

        multibrandService
            .getInventoryTotalStockValue(
                warehouseId,
                channel,
                limit,
                page,
                startDate,
                endDate,
                search,
                sortParams
            )
            .then(
                (res) => {
                    dispatch(
                        success(multibrandTypes.FETCH_INVENTORY_TOTAL_STOCK_SUCCESS, res?.data)
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                multibrandTypes.FETCH_INVENTORY_TOTAL_STOCK_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function fetchInventoryOutOfStockValue(
    warehouseId?: string,
    channel?: string,
    limit?: number,
    page?: number,
    startDate?: string,
    endDate?: string,
    search?: string,
    sortParams?: any
) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_INVENTORY_OUT_OF_STOCK_REQUEST));

        multibrandService
            .getInventoryOutOfStockValue(
                warehouseId,
                channel,
                limit,
                page,
                startDate,
                endDate,
                search,
                sortParams
            )
            .then(
                (res) => {
                    dispatch(
                        success(multibrandTypes.FETCH_INVENTORY_OUT_OF_STOCK_SUCCESS, res?.data)
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                multibrandTypes.FETCH_INVENTORY_OUT_OF_STOCK_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function fetchInventoryRestockValue(
    warehouseId?: string,
    channel?: string,
    limit?: number,
    page?: number,
    startDate?: string,
    endDate?: string,
    search?: string,
    sortParams?: any
) {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.FETCH_INVENTORY_RESTOCK_REQUEST));

        multibrandService
            .getInventoryRestockValue(
                warehouseId,
                channel,
                limit,
                page,
                startDate,
                endDate,
                search,
                sortParams
            )
            .then(
                (res) => {
                    dispatch(success(multibrandTypes.FETCH_INVENTORY_RESTOCK_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(multibrandTypes.FETCH_INVENTORY_RESTOCK_FAILURE, error?.message)
                        );
                    }
                }
            );
    };
}

function exportBrandInventoryOutOfStock() {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.EXPORT_INVENTORY_OUT_OF_STOCK_REQUEST));

        multibrandService.exportBrandInventoryOutOfStock().then(
            (res) => {
                dispatch(success(multibrandTypes.EXPORT_INVENTORY_OUT_OF_STOCK_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            multibrandTypes.EXPORT_INVENTORY_OUT_OF_STOCK_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function exportBrandInventoryTotalStock() {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.EXPORT_INVENTORY_TOTAL_STOCK_REQUEST));

        multibrandService.exportBrandInventoryTotalStock().then(
            (res) => {
                dispatch(success(multibrandTypes.EXPORT_INVENTORY_TOTAL_STOCK_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            multibrandTypes.EXPORT_INVENTORY_TOTAL_STOCK_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function exportBrandInventoryInStock() {
    return (dispatch: Dispatch) => {
        dispatch(request(multibrandTypes.EXPORT_INVENTORY_IN_STOCK_REQUEST));

        multibrandService.exportBrandInventoryInStock().then(
            (res) => {
                dispatch(success(multibrandTypes.EXPORT_INVENTORY_IN_STOCK_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(multibrandTypes.EXPORT_INVENTORY_IN_STOCK_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function resetExportInventoryOutOfStockSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(multibrandTypes.EXPORT_INVENTORY_OUT_OF_STOCK_SUCCESS, null));
    };
}

function resetExportInventoryTotalStockSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(multibrandTypes.EXPORT_INVENTORY_TOTAL_STOCK_SUCCESS, null));
    };
}

function resetExportInventoryInStockSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(multibrandTypes.EXPORT_INVENTORY_IN_STOCK_SUCCESS, null));
    };
}

function resetFetchInventoryInStockValueSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(multibrandTypes.FETCH_INVENTORY_IN_STOCK_SUCCESS, null));
    };
}

function resetFetchInventoryTotalStockValueSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(multibrandTypes.FETCH_INVENTORY_TOTAL_STOCK_SUCCESS, null));
    };
}

function resetFetchInventoryOutOfStockValueSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(multibrandTypes.FETCH_INVENTORY_OUT_OF_STOCK_SUCCESS, null));
    };
}

function resetFetchInventoryRestockValueSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(multibrandTypes.FETCH_INVENTORY_RESTOCK_SUCCESS, null));
    };
}

function resetExportBrandInventoryListSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(multibrandTypes.EXPORT_BRAND_INVENTORY_LIST_SUCCESS, null));
    };
}

function resetGetBrandTokenSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(multibrandTypes.FETCH_BRAND_TOKEN_SUCCESS, null));
    };
}

function resetGetSingleBrandSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(multibrandTypes.FETCH_SINGLE_BRAND_SUCCESS, null));
    };
}

function resetExportBrandListSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(multibrandTypes.EXPORT_BRAND_LIST_SUCCESS, null));
    };
}

function resetCreateBrandSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(multibrandTypes.CREATE_BRAND_SUCCESS, null));
    };
}

function resetUpdateBrandSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(multibrandTypes.UPDATE_BRAND_SUCCESS, null));
    };
}

function request(type) {
    return { type: type };
}

function success(type, data) {
    return { type: type, payload: data };
}

function failure(type, error) {
    return { type: type, payload: error ?? "" };
}
