import Button from "components/common/Button";
import SelectInput from "components/common/InputField/SelectInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGetAiAgents from "hooks/workforceHooks/useGetAiAgents";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import AiAgentActivities from "./AiAgentActivities";
import Checkbox from "components/common/InputField/Checkbox";
import ToggleInput from "components/common/InputField/ToggleInput";
import { aiAvatarArr, AiNameSuggestions } from "variables/CreateAiTeammateDate";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import moment from "moment";
import { vendorActions, workforceActions } from "redux/Ldb/actions";
import { sortedUniq } from "lodash";
import PageFrame from "components/layout/PageFrame";
import ModalContainer from "components/common/ModalContainer";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import useGetSingleAiTeammate from "hooks/workforceHooks/useGetSingleAiTeammate";
import { firebaseService } from "services/firebaseService";
import { getFromStorage } from "helpers";
import Loader from "components/common/Loader";
import EmptyStateComp from "components/common/EmptyStateComp";

const CreateAiTeammate = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const avatarModalRef = useRef<HTMLDivElement>(null);
    const { aiTeammateId } = useParams();
    const profile = getFromStorage("ally-user");

    const [role, setRole] = useState("");
    const [avatar, setAvatar] = useState("");
    const [aiNameValue, setAiNameValue] = useState("");
    const [communicationChannels, setCommunicationChannels] = useState([]);
    const [openAvatarModal, setOpenAvatarModal] = useState<boolean>(false);
    const [uploadingAvatar, setUploadingAvatar] = useState<boolean>(false);
    const [aiStepValues, setAiStepValues] = useState<{ [key: string]: any }[]>([]);
    const [emailClientModal, setEmailClientModal] = useState<boolean>(false);
    const [emailClientSocialId, setEmailClientSocialId] = useState<string>("");

    const { data: aiAgents, isFetching: isFetchingAiAgent } = useGetAiAgents();
    const {
        creatingAiTeammate,
        createAiTeammateSuccess,
        updatingAiTeammate,
        updateAiTeammateSuccess,
    } = useAppSelector((state) => state.workforce);
    const { fetchedAllChannelsSuccess, fetchingAllChannels } = useAppSelector(
        (state) => state.vendor
    );
    const { data: singleAiTeammate, isFetching } = useGetSingleAiTeammate();

    const emailClients = fetchedAllChannelsSuccess?.channel?.filter(
        (item) =>
            (item?.channel?.toLowerCase() === "gmail" ||
                item?.channel?.toLowerCase() === "microsoft") &&
            item?.active
    );

    const activitiesStructure = aiAgents.reduce((acc, curr) => {
        acc[curr.type] = curr;
        return acc;
    }, {});

    const handleUpload = (fileName, file) => {
        setUploadingAvatar(true);
        firebaseService.uploadFile(
            `synthally/${process.env.REACT_APP_STAGE === "production" ? "production" : "staging"}/${
                profile?.user?.profile?.businessName
            }/aiTeammateAvatar/${fileName}`,
            file,
            (url) => {
                setAvatar(url);
                setUploadingAvatar(false);
                setOpenAvatarModal(false);
            }
        );
    };

    const handleCreateTeammate = () => {
        const stepArray = aiStepValues?.map((step) => {
            const stepData = {
                name: camelCaseToRegularCase(step?.name),
                key: step?.key,
                title: step?.title,
                description: step?.description,
                approvalPossible: step?.shouldApprove,
                repeatable: step?.shouldRepeat,
            };

            if (stepData.repeatable && step?.dateTime) {
                const datetime = moment(step?.dateTime);
                stepData["repeatInterval"] = {
                    frequency: step?.frequency,
                    time: {
                        day: datetime.format("dddd"),
                        time: datetime.format("HH:mm"),
                        hours: datetime.hours(),
                        minutes: datetime.minutes(),
                        seconds: datetime.seconds(),
                    },
                };
            }

            return stepData;
        });

        let body: { [key: string]: any } = {
            teamMateName: aiNameValue,
            avatar: avatar || aiAvatarArr[0],
            agentType: role,
            communicationChannel: {
                email: Boolean(communicationChannels?.find((channel) => channel === "email")),
                inApp: Boolean(communicationChannels?.find((channel) => channel === "in_app")),
                autoGenerateEmail: Boolean(
                    communicationChannels?.find((channel) => channel === "auto_generate_email")
                ),
            },
            steps: stepArray,
        };

        if (
            Boolean(communicationChannels?.find((channel) => channel === "email")) &&
            emailClientSocialId
        ) {
            body = {
                ...body,
                communicationChannel: {
                    ...body.communicationChannel,
                    emailChannels: [
                        {
                            socialId: emailClientSocialId,
                        },
                    ],
                },
            };
        }

        if (aiTeammateId) {
            dispatch(workforceActions.updateAiTeammate(body, aiTeammateId));
            return;
        }
        dispatch(workforceActions.createAiTeammate(body));
    };

    const handleRoleChange = (value: string) => {
        const aiRoleActivities = activitiesStructure?.[value]?.steps?.map((step) => {
            return {
                ...step,
                shouldApprove: false,
                shouldRepeat: false,
                frequency: "",
                dateTime: "",
            };
        });

        setRole(value);
        setAiStepValues(aiRoleActivities);
    };

    const handleChannelChange = (channelValue: string) => {
        const communicationChannelsCopy = [...communicationChannels];
        const channelIndex = communicationChannels?.findIndex(
            (channel) => channel === channelValue
        );
        const isEmailPresent = Boolean(
            communicationChannels?.find((channel) => channel === "email")
        );
        const isAutoGeneratePresent = Boolean(
            communicationChannels?.find((channel) => channel === "auto_generate_email")
        );

        if (isEmailPresent && channelValue === "auto_generate_email") {
            const upddatedArray = communicationChannelsCopy?.filter(
                (channel) => channel !== "email"
            );

            return setCommunicationChannels(sortedUniq([...upddatedArray, channelValue]));
        }

        if (isAutoGeneratePresent && channelValue === "email") {
            const upddatedArray = communicationChannelsCopy?.filter(
                (channel) => channel !== "auto_generate_email"
            );

            return setCommunicationChannels(sortedUniq([...upddatedArray, channelValue]));
        }

        if (channelIndex !== -1) {
            const upddatedArray = communicationChannelsCopy?.filter(
                (channel) => channel !== channelValue
            );
            setCommunicationChannels(sortedUniq(upddatedArray));
        } else {
            setCommunicationChannels((prev) => sortedUniq([...prev, channelValue]));
        }
    };

    const handleAiStepsChange = (idx: number, name: string, value: any) => {
        const aiStepValuesCopy = [...aiStepValues];
        aiStepValuesCopy[idx][name] = value;

        setAiStepValues(aiStepValuesCopy);
    };

    useEffect(() => {
        if (singleAiTeammate?._id) {
            const singleAiTeammatecommunicationChannels = singleAiTeammate?.communicationChannel;
            const result = [];
            const agentAtWork = aiAgents?.find(
                (agent) => agent?.type === singleAiTeammate?.agentType
            );

            const teammateSteps = singleAiTeammate?.steps?.map((step, index) => {
                return {
                    ...step,
                    repeatable: agentAtWork?.steps?.[index]?.repeatable,
                    approvalPossible: agentAtWork?.steps?.[index]?.approvalPossible,
                    shouldApprove: step?.approvalPossible,
                    shouldRepeat: step?.repeatable,
                    frequency: "",
                    dateTime: "",
                };
            });

            if (singleAiTeammatecommunicationChannels.email) result.push("email");
            if (singleAiTeammatecommunicationChannels.inApp) result.push("in_app");
            if (singleAiTeammatecommunicationChannels.autoGenerateEmail)
                result.push("auto_generate_email");

            setAiNameValue(singleAiTeammate?.teamMateName);
            setRole(singleAiTeammate?.agentType);
            setCommunicationChannels(result);
            setAvatar(singleAiTeammate?.avatar);
            setEmailClientSocialId(
                singleAiTeammate?.communicationChannel?.emailChannels?.[0]?.socialId || ""
            );
            setAiStepValues(teammateSteps);
        }
    }, [singleAiTeammate?._id, aiAgents]);

    useEffect(() => {
        if (Boolean(createAiTeammateSuccess)) {
            dispatch(workforceActions.resetCreateAiTeammateSuccess());
            navigate(`/dashboard/workforce`);
        }
    }, [createAiTeammateSuccess]);

    useEffect(() => {
        if (Boolean(updateAiTeammateSuccess)) {
            dispatch(workforceActions.resetUpdateAiTeammateSuccess());
            navigate(`/dashboard/workforce`);
        }
    }, [dispatch, navigate, updateAiTeammateSuccess]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                avatarModalRef.current &&
                !avatarModalRef.current.contains(event.target as Node) &&
                openAvatarModal
            ) {
                setOpenAvatarModal(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [avatarModalRef, openAvatarModal]);

    useEffect(() => {
        dispatch(vendorActions.getAllChannels());
    }, [dispatch]);

    return (
        <PageFrame isLoading={isFetching}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    handleCreateTeammate();
                }}
                className="bg-white rounded-lg border border-n-20 relative"
            >
                <div className="flex items-center justify-between px-8 py-5 border-b border-n-20 sticky top-[-20px] bg-white z-[9999]">
                    <div className="flex items-center space-x-1.5">
                        <i
                            onClick={() => navigate(-1)}
                            className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                        ></i>
                        <span className="text-lg font-rocGroteskMedium">
                            {aiTeammateId ? "Edit" : "Create"} your AI teammate
                        </span>
                    </div>
                    <div className="flex items-center space-x-3">
                        <Button
                            btnText="Cancel"
                            btnClassname="!px-4 !py-2 !bg-tradeally-neutral-20 !w-fit"
                            disabled={false}
                            onClick={() => navigate(-1)}
                        />
                        <Button
                            btnText={`${aiTeammateId ? "Edit" : "Create"} AI teammate`}
                            type="submit"
                            isLoading={creatingAiTeammate || updatingAiTeammate}
                            btnClassname="!px-4 !py-2 !w-fit"
                        />
                    </div>
                </div>

                <div className="py-8 flex justify-center">
                    <div className="w-[67%]">
                        <div className="relative w-fit mb-[36px] shadow-[0px_20px_24px_-4px_#10182814]">
                            {uploadingAvatar ? (
                                <div className="w-14 h-14 rounded-[10px] border flex justify-center items-center">
                                    <Loader size={6} />
                                </div>
                            ) : (
                                <img
                                    src={avatar || aiAvatarArr[0]}
                                    alt={`avatar`}
                                    className={`w-14 h-14`}
                                />
                            )}

                            <div
                                onClick={() => setOpenAvatarModal(true)}
                                className="w-6 h-6 rounded-full bg-p-50 border-[3px] border-white flex justify-center items-center absolute bottom-[-9px] right-[-12px] cursor-pointer"
                            >
                                <i className="ri-pencil-fill text-white text-[14px]"></i>
                            </div>

                            {openAvatarModal && (
                                <div
                                    ref={avatarModalRef}
                                    className="w-[464px] h-[180px] bg-white rounded-lg absolute right-[-465px] top-[52px] border border-slate-100 shadow-[0px_20px_24px_-4px_#10182814;]"
                                >
                                    <div className="px-4 py-2.5 flex items-center justify-between border-b border-slate-200">
                                        <span className="text-sm font-rocGroteskMedium">
                                            Change agent avatar
                                        </span>
                                        <i
                                            onClick={() => setOpenAvatarModal(false)}
                                            className="ri-close-fill text-2xl cursor-pointer"
                                        ></i>
                                    </div>
                                    <div className="px-4 py-3 grid grid-cols-11 gap-2">
                                        {aiAvatarArr?.map((avatarUrl, idx) => {
                                            return (
                                                <div key={idx} onClick={() => setAvatar(avatarUrl)}>
                                                    <img
                                                        src={avatarUrl}
                                                        alt={`avatar_${idx}`}
                                                        className={`w-8 h-8 cursor-pointer hover:border-[1.5px] rounded-[10px] border-black ${
                                                            avatar === avatarUrl &&
                                                            " border-[1.5px] "
                                                        }`}
                                                    />
                                                </div>
                                            );
                                        })}
                                        <div className="relative">
                                            <div
                                                onClick={() => {
                                                    const elem =
                                                        document.getElementById("uploadAiAvatar");
                                                    elem.click();
                                                }}
                                                className="w-8 h-8 border border-slate-300 rounded-lg bg-slate-200 flex justify-center items-center cursor-pointer"
                                            >
                                                <i className="ri-image-add-fill text-[18px]"></i>
                                            </div>
                                            <input
                                                id="uploadAiAvatar"
                                                type="file"
                                                accept="image/*"
                                                className="absolute top-0 w-8 h-8 hidden"
                                                onChange={(evt) => {
                                                    handleUpload(
                                                        evt.target.files[0].name,
                                                        evt.target.files[0]
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="mb-6">
                            <div className="flex items-center w-full justify-between mb-4">
                                <p className="text-sm font-rocGroteskMedium">
                                    Name your AI teammate <span className="text-r-50">*</span>
                                </p>
                            </div>
                            <div className="border-b-[1px] border-b-[#E2E8F0] pb-5">
                                <input
                                    type="text"
                                    placeholder="Enter your AI teammate name"
                                    className="!text-2xl font-rocGroteskMedium  placeholder:text-[#CBD5E1] !border-none outline-none w-full placeholder:pl-1 caret-slate-700 !leading-6"
                                    name="aiName"
                                    value={aiNameValue}
                                    onChange={(e) => setAiNameValue(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="flex space-x-2 mb-8">
                            {AiNameSuggestions?.map((aiName, idx) => (
                                <span
                                    key={aiName + String(idx)}
                                    className="text-sm font-rocGroteskMedium px-2 py-[2px] h-6 bg-slate-100 rounded cursor-pointer"
                                    onClick={() => setAiNameValue(aiName)}
                                >
                                    {aiName}
                                </span>
                            ))}

                            {/* <span className="p-1 h-6 bg-slate-100 rounded flex items-center justify-center">
                                <i className="ri-restart-line text-base "></i>
                            </span> */}
                        </div>

                        <div className="mb-8">
                            <h5 className="text-base font-rocGroteskMedium mb-3">
                                Choose AI teammate role
                            </h5>
                            <div className="mb-6">
                                <SelectInput
                                    value={role}
                                    name="role"
                                    placeholder={"Select AI teammate role"}
                                    handleChange={(name, value) => handleRoleChange(value)}
                                    isRequired={false}
                                    searchLoading={isFetchingAiAgent}
                                    dropdownOptions={aiAgents?.map((item) => {
                                        return {
                                            label: camelCaseToRegularCase(item?.type),
                                            value: item?.type,
                                        };
                                    })}
                                />
                            </div>

                            {role && (
                                <div>
                                    <TextareaInput
                                        name={"orderDescription"}
                                        value={activitiesStructure?.[role]?.description}
                                        onChange={() => console.log("first")}
                                        rows={4}
                                        disabled
                                        // placeholder={"Order description"}
                                        required={false}
                                        containerClassname=""
                                    />
                                </div>
                            )}
                        </div>

                        {/* <div className="mb-8">
                        <h5 className="text-base font-rocGroteskMedium mb-3">
                            Create production schedule from these triggers
                        </h5>
                        <div className="grid grid-cols-2 items-center space-x-3">
                            <div className="h-full px-3 py-4 font-rocGroteskMedium rounded-md border border-slate-200 shadow-[0px_4px_8px_-4px_#1018281F]">
                                <div className="flex items-center justify-between mb-1 text-sm">
                                    <span>Trigger 1</span>
                                    <ToggleInput
                                        checked={true}
                                        name={"trackProduct"}
                                        onChange={(e) => {
                                            // handleChange("trackProduct", e.target.checked);
                                        }}
                                    />
                                </div>
                                <p className="text-sm text-slate-500">
                                    From automated demand forecast and changes to current inventory
                                    levels
                                </p>
                            </div>

                            <div className="h-full px-3 py-4 font-rocGroteskMedium rounded-md border border-slate-200 shadow-[0px_4px_8px_-4px_#1018281F]">
                                <div className="flex items-center justify-between mb-1 text-sm">
                                    <span>Trigger 2</span>
                                    <ToggleInput
                                        checked={false}
                                        name={"trackProduct"}
                                        onChange={(e) => {
                                            // handleChange("trackProduct", e.target.checked);
                                        }}
                                    />
                                </div>
                                <p className="text-sm text-slate-500">
                                    From a direct message from the brand requesting it to schedule a
                                    production with all the details needed provided
                                </p>
                            </div>
                        </div>
                    </div> */}

                        {role && (
                            <div className="mb-8">
                                <h5 className="text-base font-rocGroteskMedium mb-3">
                                    Select the channels where the AI teammate will retrieve sales
                                    orders
                                </h5>
                                <div className="mb-6 p-4 border border-slate-200 rounded-lg ">
                                    <div className="flex items-center justify-between pb-4 border-b border-slate-200">
                                        <div>
                                            <div className="flex items-center space-x-3 mb-4">
                                                <Checkbox
                                                    name="email"
                                                    isChecked={Boolean(
                                                        communicationChannels?.find(
                                                            (channel) => channel === "email"
                                                        )
                                                    )}
                                                    required={
                                                        !Boolean(communicationChannels.length > 0)
                                                    }
                                                    onChange={(evt) => {
                                                        handleChannelChange("email");
                                                    }}
                                                    className={
                                                        "!h-4 !w-4 !rounded-[4px] checked:!bg-y-10 "
                                                    }
                                                />
                                                <span
                                                    className={`text-sm font-rocGroteskMedium ${
                                                        !Boolean(
                                                            communicationChannels?.find(
                                                                (channel) => channel === "email"
                                                            )
                                                        ) && "text-slate-500"
                                                    }`}
                                                >
                                                    Email
                                                </span>
                                            </div>
                                            <div className="flex items-center space-x-3">
                                                <Checkbox
                                                    name="auto_email"
                                                    isChecked={Boolean(
                                                        communicationChannels?.find(
                                                            (channel) =>
                                                                channel === "auto_generate_email"
                                                        )
                                                    )}
                                                    required={
                                                        !Boolean(communicationChannels.length > 0)
                                                    }
                                                    onChange={(evt) => {
                                                        handleChannelChange("auto_generate_email");
                                                    }}
                                                    className={
                                                        "!h-4 !w-4 !rounded-[4px] checked:!bg-y-10 "
                                                    }
                                                />
                                                <span
                                                    className={`text-sm font-rocGroteskMedium ${
                                                        !Boolean(
                                                            communicationChannels?.find(
                                                                (channel) =>
                                                                    channel ===
                                                                    "auto_generate_email"
                                                            )
                                                        ) && "text-slate-500"
                                                    }`}
                                                >
                                                    Let Synth automatically generate an email
                                                    address for this AI teammate
                                                </span>
                                            </div>
                                        </div>

                                        {Boolean(
                                            communicationChannels?.find(
                                                (channel) => channel === "email"
                                            )
                                        ) &&
                                            (!emailClientSocialId ? (
                                                <Button
                                                    btnText="Connect your email account"
                                                    btnClassname="!px-4 !py-2 !bg-tradeally-neutral-20 !w-fit"
                                                    disabled={false}
                                                    onClick={() => setEmailClientModal(true)}
                                                />
                                            ) : (
                                                <div className="px-4 py-2 w-fit space-x-2 flex items-center">
                                                    <span className="text-sm font-rocGroteskMedium">
                                                        Connected
                                                    </span>{" "}
                                                    <i className="ri-checkbox-circle-fill text-carribean-green"></i>
                                                    <i
                                                        className="ri-edit-2-fill text-slate-600 cursor-pointer"
                                                        onClick={() => setEmailClientModal(true)}
                                                    ></i>
                                                </div>
                                            ))}
                                    </div>

                                    <div className="flex items-center space-x-3 mt-4 ">
                                        <Checkbox
                                            name="in_app"
                                            isChecked={Boolean(
                                                communicationChannels?.find(
                                                    (channel) => channel === "in_app"
                                                )
                                            )}
                                            required={!Boolean(communicationChannels.length > 0)}
                                            onChange={(evt) => {
                                                handleChannelChange("in_app");
                                            }}
                                            className={"!h-4 !w-4 !rounded-[4px] checked:!bg-y-10 "}
                                        />
                                        <span
                                            className={`text-sm font-rocGroteskMedium ${
                                                !Boolean(
                                                    communicationChannels?.find(
                                                        (channel) => channel === "in_app"
                                                    )
                                                ) && "text-slate-500"
                                            }`}
                                        >
                                            Synth in app
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {role && (
                            <div className="mb-8">
                                <div className="mb-3">
                                    <h5 className="text-base font-rocGroteskMedium">
                                        AI Teammate tasks and activities
                                    </h5>
                                    <span className="block text-sm font-rocGroteskMedium text-slate-500">
                                        The following is the list of possible tasks or activities it
                                        would be able to carry out
                                    </span>
                                </div>

                                <div className="p-5 rounded-lg border border-slate-200">
                                    <AiAgentActivities
                                        structure={aiStepValues || []}
                                        handleAiStepsChange={handleAiStepsChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </form>

            <ModalContainer
                open={emailClientModal}
                showCloseIcon={false}
                tailwindClassName="w-[38%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setEmailClientModal(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-10 pt-3 pb-5">
                        <div className="flex flex-col items-center text-center mb-1">
                            <div className="flex w-full justify-end mr-[-12px]">
                                <i
                                    onClick={() => setEmailClientModal(false)}
                                    className="ri-close-fill text-2xl"
                                ></i>
                            </div>
                            <div className="mb-4 relative w-12 h-12 rounded-full flex items-center justify-center bg-[#FF3D1D17]">
                                <i className="ri-information-fill text-r-50 text-2xl"></i>
                            </div>
                            <p className="text-[22px] mb-1.5 text-center  font-rocGroteskMedium text-g-75">
                                Connect your account
                            </p>
                            <p className="text-base text-center font-rocGroteskMedium text-slate-500">
                                Please select an account to continue
                            </p>
                        </div>

                        {emailClients?.length > 0 ? (
                            <div>
                                {emailClients?.map((client, idx) => {
                                    return (
                                        <div
                                            key={client?._id}
                                            className={`flex items-center justify-between py-2.5 ${
                                                idx < emailClients?.length - 1 && "border-b"
                                            } border-slate-200 w-full`}
                                        >
                                            <div className="flex items-center space-x-3">
                                                {displayProductChannelIcon(
                                                    client?.channel,
                                                    "!w-[22px] !h-[22px]"
                                                )}
                                                <div>
                                                    <span className="block text-base text-slate-500 font-rocGroteskMedium capitalize">
                                                        {client?.channel}
                                                    </span>
                                                    <span className="block text-xs text-slate-500 font-rocGroteskMedium capitalize">
                                                        {client?.creds?.account?.email}
                                                    </span>
                                                </div>
                                            </div>

                                            {client?.socialId === emailClientSocialId ? (
                                                <div className="flex items-center space-x-2.5">
                                                    <span className="text-sm font-rocGroteskMedium">
                                                        Selected
                                                    </span>
                                                    <i className="ri-checkbox-circle-fill text-carribean-green"></i>
                                                </div>
                                            ) : (
                                                <Button
                                                    btnText="Select"
                                                    btnClassname="!px-4 !py-2 !bg-tradeally-neutral-20 !w-fit !h-[32px]"
                                                    disabled={false}
                                                    onClick={() => {
                                                        setEmailClientSocialId(client?._id);
                                                        setEmailClientModal(false);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div>
                                <EmptyStateComp
                                    title="No email accounts found"
                                    description="There are currently no email integrations found, please integrate your email account"
                                    icon={
                                        <i className="ri-mail-forbid-line text-slate-500 text-[40px]"></i>
                                    }
                                    hasButton
                                    btnText="Integrate email account"
                                    contentContainerClass="!w-full !p-6"
                                    btnAction={() => {
                                        navigate("/dashboard/integrations");
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default CreateAiTeammate;
