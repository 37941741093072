import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { productionActions } from "redux/Ldb/actions";

const useGetProductionReportList = () => {
    const dispatch = useAppDispatch();
    // const [search, setSearch] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [reportList, setReportList] = useState<{ [key: string]: any }[]>([]);

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { fetchingReportList, fetchReportListSuccess } = useAppSelector(
        (state) => state.production
    );

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

   
    useEffect(() => {
        setUpdatedPage(1);
    }, [status, debouncedSearch]);


    useEffect(() => {
        dispatch(productionActions.fetchReportList(10, updatedPage, debouncedSearch, status));
        // return () => {
        //     dispatch(productionActions.resetProductionPlanList());
        // };
    }, [dispatch, updatedPage, debouncedSearch, status]);

    useEffect(() => {
        if (Boolean(fetchReportListSuccess)) {
            setReportList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchReportListSuccess?.reports], "_id")
                    : uniqBy([...prev, ...fetchReportListSuccess?.reports], "_id")
            );
            setPagination({
                currentPage: fetchReportListSuccess.pagination?.current,
                noOfPages: fetchReportListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
            dispatch(productionActions.resetFetchReportList());
        }
    }, [fetchReportListSuccess, updatedPage]);

    return {
        data: reportList,
        isLoading: fetchingReportList,
        isLoadingMore,
        pagination,
        debouncedSearch,
        setDebouncedSearch,
        // search,
        // setSearch,
        status,
        setStatus,
    };
};

export default useGetProductionReportList;
