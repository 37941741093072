import React, { useEffect, useState } from "react";
import CustomTable from "../../../common/CustomTable";
import VendorAvatar from "../../../common/VendorAvatar";
import Dropdown from "../../../common/Dropdown";
import { useNavigate, useSearchParams } from "react-router-dom";
// import useGetBOMList from "hooks/ProductionHooks/useGetBOMList";
import { truncate } from "lodash";
// import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import CustomTableLoader from "../../../common/CustomTableLoader";
import EmptyStateComp from "../../../common/EmptyStateComp";
import Loader from "../../../common/Loader";
import moment from "moment";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";

type ReportProps = {
    search?: string;
    data?: Record<string, any> | Array<Record<string, any>>;
    isLoadingMore?: boolean;
    isLoading?: boolean;
    pagination?: any;
    statusFilter?: string;
};
const Report = ({
    search,
    data,
    isLoading,
    isLoadingMore,
    pagination,
    statusFilter,
}: ReportProps) => {
    // const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const currTab = params.get("currTab");

    const ReportTableHeader = [
        { title: "Production Report ID	", widthClass: "w-[130px]  !bg-slate-50 " },
        { title: "Product", widthClass: "w-[200px]   !bg-slate-50 " },
        { title: "Report Date", widthClass: "w-[100px]  !bg-slate-50 " },
        { title: "Batch Size", widthClass: "w-[120px]  !bg-slate-50 " },
        { title: "Quantity Produced", widthClass: "w-[120px]   !bg-slate-50 " },
        { title: "Yield (%)", widthClass: "w-[70px]  !bg-slate-50 " },
        { title: "Status", widthClass: "w-[150px]  !bg-slate-50 " },
        { title: "Total Losses", widthClass: "w-[120px]  !bg-slate-50 " },
        { title: "", widthClass: "w-[40px]  !bg-slate-50 " },
    ];

    const ReportTableBody = data?.map((report, idx) => {
        return [
            {
                content: (
                    <div className={`w-full  px-4 `}>
                        <p className="text-g-75 text-sm">PR-{report?.taId}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
                cellClickAction: () => {
                    navigate(`/dashboard/production/report/${report?.workOrderId}`);
                },
            },
            {
                content: (
                    <div className={`w-full  `}>
                        <div className="flex items-center gap-2 py-2 px-4">
                            {report?.productImageDetails?.productAvatar ||
                            report?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        report?.productImageDetails?.productAvatar ||
                                        report?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={report?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm">
                                <p className="text-g-75">
                                    {" "}
                                    {truncate(report?.productName, { length: 15 })}
                                </p>
                                <p className="text-[#64748B]">{report?.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {report?.createdAt
                                ? moment(report?.createdAt)?.format("MMM DD, YYYY")
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {`${formatMoney().format(report?.batchSize?.amount) || ""} ${
                                report?.batchSize?.amount
                                    ? report?.batchSize?.unitOfMeasurement
                                    : "N/A"
                            }`}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {` ${formatMoney().format(report?.finalQuantity?.amount) || ""} ${
                                report?.finalQuantity?.amount
                                    ? report?.finalQuantity?.unitOfMeasurement
                                    : "N/A"
                            }`}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {`${
                                report?.percentYield
                                    ? formatMoney().format(report?.percentYield)
                                    : ""
                            }${report?.percentYield !== undefined ? "%" : "N/A"}`}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <div className="flex items-center gap-2 text-sm">
                            <div
                                className={`w-2 h-2 rounded-full shrink-0 flex items-center justify-center captialize
                                  ${
                                      report?.status === "saved"
                                          ? "bg-[#22C55E]"
                                          : report?.status === "draft" && "bg-[#EAB308]"
                                  }
                                    `}
                            ></div>

                            <span className="capitalize  text-sm text-[#0F172A]">
                                {report?.status === "draft"
                                    ? "Pending"
                                    : report?.status === "saved"
                                    ? "Added to inventory"
                                    : report?.status}
                            </span>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {report?.totalLoss !== undefined &&
                                getCurrencyFromCurrencyCode(
                                    report?.costProductLoss?.currency ||
                                        report?.costBOMLoss?.currency
                                )}
                            {report?.totalLoss!== undefined ? formatMoney().format(report?.totalLoss) : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`} onClick={(e) => e.stopPropagation()}>
                        <Dropdown
                            dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit right-[0px] `}
                            dropdownItemsClasses={``}
                            name={"faq-dropdown"}
                            dropdownOptions={[
                                {
                                    label: "View Full Report",
                                    value: "View Full Report",
                                    action: () => {
                                        navigate(
                                            `/dashboard/production/report/${report?.workOrderId}`
                                        );
                                    },
                                    labelClassName: "!text-gm-50 !text-sm",
                                },
                                // {
                                //     label: "Download Report",
                                //     value: "Download Report",
                                //     action: () => {},
                                //     labelClassName: "!text-gm-50 !tedxt-sm",
                                // },
                            ]}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    return (
        <div className="font-rocGroteskMedium">
            {isLoading && !search && !statusFilter && data?.length === 0 ? (
                <div className="overflow-x-hidden">
                    <CustomTableLoader tableHeader={ReportTableHeader} />
                </div>
            ) : data?.length >= 1 ? (
                <div>
                    <CustomTable
                        tableBody={ReportTableBody}
                        tableHeader={ReportTableHeader}
                        isInifiniteScroll={true}
                        isCellSelectable={false}
                        isAllSelectable={false}
                        stickyHeader={false}
                        headerContainerClass="!bg-[#F8FAFC] !border-slate-200"
                        headerItemClass="font-rocGroteskMedium !py-[11px] !px-4 !text-slate-700"
                        tableClass="mb-12 !border-collapse"
                        isScrollable={true}
                    />

                    {isLoadingMore && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}

                    {pagination?.currentPage === pagination?.noOfPages && (
                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </div>
            ) : (search || statusFilter) && data?.length === 0 ? (
                <div className="h-[200px] flex items-center justify-center border-x border-b border-slate-200">
                    <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                        Sorry, no results match your search. Refine your criteria and try again.
                    </p>
                </div>
            ) : (
                <div>
                    <div className="border border-slate-200 rounded-lg py-8">
                        <EmptyStateComp
                            title="No Production Reports Available"
                            description="Once production is completed, reports will appear here, capturing essential details like yield, and outcomes. You can also upload a report from your co-manufacturer for easy access, with finished goods in the report added directly to inventory."
                            icon={
                                <i className="ri-file-list-3-line text-slate-500 text-[40px]"></i>
                            }
                            // hasButton={true}
                            // btnText="Upload production report"
                            // btnAction={() => {
                            //     navigate(`/dashboard/production/create-plan?currTab=1`);
                            // }}
                            contentContainerClass="flex flex-col items-center max-w-[709px] !bg-white p-5"
                            descriptionClass="flex flex-col items-center"
                            textClass="text-center"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Report;
