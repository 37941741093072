import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import CustomTableLoader from "components/common/CustomTableLoader";
import Dropdown from "components/common/Dropdown";
import EmptyStateComp from "components/common/EmptyStateComp";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import PageTitleSection from "components/common/PageTitleSection";
import TabsContainer from "components/common/TabsContainer";
import PageFrame from "components/layout/PageFrame";
import { formatMoney, getFromStorage, saveToStorage } from "helpers";
import { displayProductChannelIcon } from "helpers/displayProductChannelIcon";
import useGetBrandList from "hooks/multibrandHooks/useGetBrandList";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useGetTeam from "hooks/teamHooks/useGetTeamList";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { multibrandActions } from "redux/Ldb/actions";
import jsonToCsvExport from "json-to-csv-export";
import useGetUserProfile from "hooks/useGetUserProfile";
import ModalContainer from "components/common/ModalContainer";

const PortfolioManagement = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);

    const dispatch = useAppDispatch();
    const { fetchingBrandToken, fetchBrandTokenSuccess } = useAppSelector(
        (state) => state.multibrand
    );

    const [profile] = useGetUserProfile();
    const {
        data: brandList,
        isFetching,
        handleSearch,
        handleDebouncedChange,
        search,
        pagination,
        isLoadingMore,
    } = useGetBrandList();
    const { exportingBrandList, exportBrandListSuccess } = useAppSelector(
        (state) => state.multibrand
    );

    const handleExportBrandList = () => {
        dispatch(multibrandActions.exportBrandList());
    };

    const tableHeader = [
        { title: "Brand name", widthClass: "w-[26%] font-rocGroteskMedium" },
        { title: "No of SKUs", widthClass: "w-[8.4%] font-rocGroteskMedium" },
        { title: "Date added", widthClass: "w-[15.5%] font-rocGroteskMedium" },
        { title: "Total revenue", widthClass: "w-[12%] font-rocGroteskMedium" },
        { title: "Total cost", widthClass: "w-[13%] font-rocGroteskMedium" },
        { title: "Gross margin", widthClass: "w-[10.6%] font-rocGroteskMedium" },
        { title: "Sales channels", widthClass: "w-[9.5%] font-rocGroteskMedium" },
        { title: "", widthClass: "w-[4%] font-rocGroteskMedium" },
    ];

    const tableBody = brandList?.map((item, idx) => [
        {
            content: (
                <div
                    className={`w-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-2.5`}
                >
                    <div>
                        <p className="text-[13px]">{item?.brand?.brandName}</p>
                        {/* <p className="text-[10px] text-slate-500">{item?.brand?.email}</p> */}
                    </div>
                </div>
            ),
            cellClickAction: () => {
                // navigate(`/dashboard/portfolio/management/add-brand/${item?.brand?._id}`);
                dispatch(multibrandActions.getBrandToken(item?.brand?._id));
            },
        },
        {
            content: (
                <div
                    className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-2.5`}
                >
                    <p className="text-[13px]">{item?.totalNumberOfSku}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-2.5`}
                >
                    <p className="text-[13px]">
                        {moment(item?.brand?.createdAt)?.format("DD MMMM YYYY; h:mma")}
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-2.5`}
                >
                    <div className="flex items-center space-x-3">
                        <p className="text-[13px]">
                            ${formatMoney().format(item?.totalRevenue?.toFixed(2))}
                        </p>
                        {/* <span className="text-[10px] bg-g-25 px-1.5 py-[2px] rounded-[16px] flex items-center  text-g-60">
                            <i className="ri-arrow-up-line mt-[-2px]"></i>5-d%
                        </span> */}
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-2.5`}
                >
                    <div className="flex items-center space-x-3">
                        <p className="text-[13px]">
                            ${formatMoney().format(item?.totalCost?.toFixed(2))}
                        </p>
                        {/* <span className="text-[10px] bg-r-25 px-1.5 py-[2px] rounded-[16px] flex items-center  text-r-50">
                            <i className="ri-arrow-up-line mt-[-2px]"></i>1.5-d%
                        </span> */}
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-2.5`}
                >
                    <p className="text-[13px]">{item?.grossMarginPercentage?.toFixed(0)}%</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-2.5`}
                >
                    <div className="w-fit">
                        <a id={`channel-column-${idx}`}>
                            <div className="flex items-center w-fit space-x-1">
                                {item?.uniqueChannels
                                    ?.slice(0, 2)
                                    ?.map((channel) => displayProductChannelIcon(channel))}

                                {item?.uniqueChannels?.slice(2)?.length > 0 && (
                                    <div className="w-6 h-6 flex justify-center items-center bg-slate-200 rounded-md">
                                        <span className="text-[10px]">
                                            + {item?.uniqueChannels?.slice(2)?.length}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </a>
                        <Tooltip
                            anchorSelect={`#channel-column-${idx}`}
                            place={"right"}
                            content={item?.uniqueChannels?.map((channel) => channel)?.join(", ")}
                            style={{
                                maxWidth: "250px",
                                zIndex: 9999,
                                background: "#fff",
                                borderRadius: "6px",
                                border: "1px solid #E2E8F0",
                                color: "#1E293B",
                                boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                            }}
                        />
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`w-full h-full border-slate-100 text-sm text-slate-600 font-rocGroteskMedium py-1.5`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Dropdown
                        name="filter"
                        value={""}
                        dropdownTriggerClassName={" "}
                        // handleChange={(name, value) => handleFilterChange(value)}
                        dropdown={<i className="ri-more-2-fill"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-4 left-[-150px] !mt-0 !w-[159px] max-sm:!w-full rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                        dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100 hover:!bg-[#D0F4ED66]"
                        dropdownOptions={[
                            {
                                label: "Edit Brand",
                                value: "edit",
                                action: () => {
                                    navigate(
                                        `/dashboard/portfolio/management/add-brand/${item?.brand?._id}`
                                    );
                                },
                            },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return false ? (
                    <CustomTableLoader tableHeader={tableHeader} />
                ) : brandList?.length > 0 ? (
                    <div>
                        <div>
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isAllSelectable={false}
                                isCellBordered={true}
                                headerContainerClass="!bg-slate-50 !border-slate-100 "
                                bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                                headerItemClass="font-rocGroteskMedium !text-slate-700"
                            />
                        </div>

                        {isLoadingMore && (
                            <div className="flex justify-center my-4">
                                <Loader color="gm-25" size={4} />
                            </div>
                        )}
                        {pagination?.currentPage === pagination?.noOfPages && (
                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                End of list
                            </div>
                        )}
                    </div>
                ) : (
                    <EmptyStateComp
                        title="No sales order data available"
                        description="There are no sales order data available for this section"
                        contentContainerClass="!w-fit"
                        icon={<i className="ri-box-3-line text-slate-500 text-[40px]"></i>}
                    />
                );

            default:
                return <div></div>;
        }
    };

    useEffect(() => {
        if (Boolean(exportBrandListSuccess)) {
            const exportData = exportBrandListSuccess?.brands?.map((item) => {
                return {
                    brandName: item?.brand?.brandName,
                    dateAdded: moment(item?.brand?.createdAt).format("DD MMMM YYYY; h:mma"),
                    grossMargin: item?.grossMargin?.toFixed(2),
                    grossMarginPercentage: item?.grossMarginPercentage?.toFixed(2),
                    totalChannels: item?.totalChannels,
                    totalCost: item?.totalCost?.toFixed(2),
                    totalNumberOfSku: item?.totalNumberOfSku,
                    totalRevenue: item?.totalRevenue?.toFixed(2),
                    uniqueChannels: item?.uniqueChannels,
                };
            });

            jsonToCsvExport({
                data: exportData,
                filename: "brand_list",
            });
            dispatch(multibrandActions.resetExportBrandListSuccess());
        }
    }, [exportBrandListSuccess, dispatch]);

    useEffect(() => {
        if (Boolean(fetchBrandTokenSuccess)) {
            const userProfile = getFromStorage("ally-user");
            const userData = { ...userProfile };
            if (!userData.user?.isSwitchedBrand) {
                userData.mainToken = userData.token;
            }

            userData.token = fetchBrandTokenSuccess.token;
            userData.user = {
                ...userData.user,
                isMultiBrandAccount: false,
                isSwitchedBrand: true,
            };

            saveToStorage("ally-user", userData);
            navigate(`/dashboard/home`, { replace: true });
            dispatch(multibrandActions?.resetGetBrandTokenSuccess());
        }
    }, [fetchBrandTokenSuccess, dispatch]);

    return (
        <PageFrame
            isLoading={
                isFetching && Number(pagination.currentPage) === 1 && brandList?.length === 0
            }
        >
            <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
                <div className="mb-6">
                    <PageTitleSection
                        leftComp={
                            <div className="flex items-center space-x-3">
                                <h3 className="text-xl font-rocGroteskMedium">
                                    Portfolio management
                                </h3>
                            </div>
                        }
                        rightComp={
                            profile?.role === "owner" &&
                            !profile?.parentId && (
                                <Button
                                    btnText="Add a brand"
                                    btnClassname="!px-[8px] !py-2 !w-fit !h-[35px] !text-[13px] !whitespace-nowrap"
                                    icon={<i className="ri-store-2-fill"></i>}
                                    onClick={() => {
                                        navigate(`/dashboard/portfolio/management/add-brand`);
                                    }}
                                />
                            )
                        }
                    />
                </div>

                <div>
                    <TabsContainer
                        tabs={["All Brands"]}
                        activeTab={activeTab}
                        setActiveTab={(idx) => {
                            setActiveTab(idx);
                        }}
                        component={displayActiveTab()}
                        className="!px-0"
                        itemClassName="!pb-2"
                        borderLineClase={"!text-slate-100 w-[35%] max-lg:w-full mb-6"}
                        tabRowClassname="!overflow-visible"
                        showButton={
                            <div className="flex items-center space-x-2 max-sm:grid max-sm:space-x-0 max-sm:space-y-2 max-sm:grid-cols-1">
                                <TextInput
                                    name={"search"}
                                    value={search}
                                    type={"text"}
                                    inputPlaceholder={"Search brand name"}
                                    inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                    onChange={handleSearch}
                                    onInput={handleDebouncedChange}
                                    containerClassname={"!w-[300px] max-sm:!w-full"}
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white !border !border-slate-200"
                                    }
                                />

                                <div className="w-10 h-10 rounded border border-slate-200 flex items-center justify-center">
                                    {exportingBrandList ? (
                                        <Loader size={4} />
                                    ) : (
                                        <i
                                            onClick={handleExportBrandList}
                                            className="ri-share-forward-box-fill text-base cursor-pointer"
                                        ></i>
                                    )}
                                </div>
                            </div>
                        }
                    />
                </div>
            </div>

            <ModalContainer
                open={fetchingBrandToken}
                showCloseIcon={false}
                tailwindClassName="w-[36%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="w-full py-8 bg-white flex flex-col items-center rounded-md">
                    <Loader size={6} color={"gm-50"} />
                    <p className="text-sm text-gm-50 font-rocGroteskMedium mt-2">
                        Switching brands...
                    </p>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default PortfolioManagement;
