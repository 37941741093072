import React, { useState } from "react";

type infoProps = {
    notes: string;
    productName: string;
};
const AdditionalInfo = ({ notes, productName }: infoProps) => {
    const [open, setOpen] = useState<boolean>(true);
    return (
        <div className="font-rocGroteskMedium border-slate-200 border rounded-md">
            <div
                className={`flex items-center px-4 ${
                    open && "border-b-slate-200 border-b"
                } justify-between`}
            >
                <p className="text-gm-50 text-base py-4 ">
                    Additional info {productName && `for ${productName}`}
                </p>
                <i
                    className={`${
                        open ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"
                    } text-gm-50 text-lg cursor-pointer`}
                    onClick={() => setOpen(!open)}
                ></i>
            </div>
            {open && (
                <div className="px-4 transition-all ">
                    {/* <div className=" text-g-75 text-sm border-b border-slate-200 py-4">
                <div className="">
                    <p className="mb-4">Fermentation Stage (Days 3-14):</p>
                    <ul className="space-y-4 list-r ">
                        <li className="">Temperature Checks: Ensure the fermentation temperature stays within the optimal range (80-85°F) to promote yeast activity.</li>
                        <li className="">pH Levels: Monitor pH to ensure it remains stable, indicating a healthy fermentation process.</li>
                        <li className="">Visual Inspection: Look for signs of healthy yeast activity (e.g., bubbling, froth).</li>
                    </ul>
                </div>
                
            </div> */}
                    <div className=" text-g-75 text-sm py-4">
                        <div className="">
                            {/* <p className="mb-4">Fermentation Stage (Days 3-14):</p>
                    <ul className="space-y-4 list-r ">
                        <li className="">Temperature Checks: Ensure the fermentation temperature stays within the optimal range (80-85°F) to promote yeast activity.</li>
                        <li className="">pH Levels: Monitor pH to ensure it remains stable, indicating a healthy fermentation process.</li>
                        <li className="">Visual Inspection: Look for signs of healthy yeast activity (e.g., bubbling, froth).</li>
                    </ul> */}
                            <p className="">{notes || "N/A"}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdditionalInfo;
