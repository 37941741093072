import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { authActions } from "redux/Ldb/actions";
import { debounce } from "lodash";
import { getFromStorage } from "helpers";

const useGetTeamRoles = () => {
    const dispatch = useAppDispatch();
    const [roles, setRoles] = useState<{ [key: string]: any }[]>([]);
    const [profile, setProfile] = useState(() => getFromStorage("ally-user"));

    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");

    const { fetchingRoles, fetchedRolesSuccess, fetchedRolesFailure } = useAppSelector(
        (state) => state.auth
    );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setDebouncedSearch(e.target.value);
    }, 800);

    useEffect(() => {
        dispatch(authActions.getRoles(20, profile?.token, debouncedSearch));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchedRolesSuccess)) {
            setRoles(fetchedRolesSuccess?.rolesList);
        }
    }, [fetchedRolesSuccess]);

    return {
        data: roles,
        isFetching: fetchingRoles,
        error: fetchedRolesFailure,
        search,
        handleSearch,
        handleDebouncedChange,
    };
};

export default useGetTeamRoles;
