import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";

const useGetRevenueGraph = (generalBrand?: string, generalDuration?: string) => {
    const dispatch = useAppDispatch();
    const [revenueGraphData, setRevenueGraphData] = useState<{ [key: string]: any }[]>([]);
    const [dateRange, setDateRange] = useState<{ label: string; value: string }>({
        label: "",
        value: "yearly",
    });
    const [brand, setBrand] = useState<{ label: string; value: string }>({
        label: "",
        value: "",
    });

    const { fetchingRevenueGraph, fetchRevenueGraphSuccess, fetchRevenueGraphFailure } =
        useAppSelector((state) => state.multibrand);

    const handleDurationChange = (value: { label: string; value: string }) => {
        setDateRange(value);
    };

    const handleBrandChangeFilter = (value: { label: string; value: string }) => {
        setBrand(value);
    };

    useEffect(() => {
        if (Boolean(fetchRevenueGraphSuccess)) {
            setRevenueGraphData(fetchRevenueGraphSuccess?.metrics);
        }
    }, [fetchRevenueGraphSuccess]);

    useEffect(() => {
        dispatch(
            multibrandActions?.getRevenueGraph(
                dateRange?.value !== generalDuration ? dateRange?.value : generalDuration,
                brand?.value !== generalBrand ? brand?.value : generalBrand
            )
        );
    }, [dispatch, dateRange?.value, brand?.value, generalBrand, generalDuration]);

    return {
        data: revenueGraphData,
        isFetching: fetchingRevenueGraph,
        error: fetchRevenueGraphFailure,
        handleDurationChange,
        dateRange,
        brand,
        handleBrandChangeFilter,
    };
};

export default useGetRevenueGraph;
