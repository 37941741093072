import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import VendorAvatar from "./../../../../common/VendorAvatar";
import CustomTable from "./../../../../common/CustomTable";
import useGetProductionPlanList from "hooks/ProductionHooks/useGetProductionPlanList";
import moment from "moment";
import CustomTableLoader from "components/common/CustomTableLoader";
import Loader from "components/common/Loader";
import EmptyStateComp from "components/common/EmptyStateComp";
import { truncate } from "lodash";
import Dropdown from "components/common/Dropdown";
import ModalContainer from "components/common/ModalContainer";
import Button from "components/common/Button";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { productionActions } from "redux/Ldb/actions";

type listProps = {
    search: string;
    setSearch: (data: string) => void;
    data: any;
    isLoading: boolean;
    isLoadingMore: boolean;
    pagination: any;
    assigneeId: string;
    status: string;
};

const ListView = ({
    search,
    setSearch,
    data,
    isLoading,
    isLoadingMore,
    pagination,
    assigneeId,
    status,
}: listProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [planId, setPlanId] = useState<string>("");

    const { deletingProductionPlan, deleteProductionPlanSuccess } = useAppSelector(
        (state) => state?.production
    );
    const handleDeletePlan = () => {
        dispatch(productionActions?.deleteProductionPlan(planId));
    };

    useEffect(() => {
        if (Boolean(deleteProductionPlanSuccess)) {
            setPlanId("");
            setConfirmModal(false);
            setSuccessModal(true);
            dispatch(productionActions?.fetchProductionPlanList(10, 1));
            dispatch(productionActions?.resetDeleteProductionPlan());
        }
    }, [deleteProductionPlanSuccess]);
    const tableHeader = [
        { title: "Production ID", widthClass: "w-[165px]" },
        { title: "Product", widthClass: "w-[240px]" },
        { title: "Expected Qty", widthClass: "w-[150px]" },
        { title: "Start Date", widthClass: "w-[135px]" },
        { title: "End Date", widthClass: "w-[135px]" },
        { title: "Status", widthClass: "w-[200px]" },
        { title: "Production Manager", widthClass: "w-[183px]" },
        { title: "", widthClass: "w-[70px]" },
    ];

    const tableBody = data?.map((plan, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <p>PO-{plan?.taId}</p>
                </div>
            ),
            cellClickAction: () => {
                navigate(`/dashboard/production/${plan?._id}`);
            },
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <div className="flex items-center gap-2">
                        {!plan ? (
                            <VendorAvatar
                                imageSrc={
                                    plan?.productImageDetails?.productAvatar ||
                                    plan?.productImageDetails?.productImages?.[0]
                                }
                                size={32}
                                name={plan?.productName}
                                containerClassname="!rounded-md"
                            />
                        ) : (
                            <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>
                        )}

                        <div className="text-sm">
                            <p className="text-[#142837]">
                                {truncate(plan?.productName, { length: 20 })}
                            </p>
                            <p className="text-[#64748B]">{plan?.sku}</p>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <p>
                        {plan?.quantity?.amount.toLocaleString()}{" "}
                        {plan?.quantity?.amount !== null ? plan?.quantity?.unitOfMeasurement : ""}
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <p>
                        {plan?.startDate ? moment(plan?.startDate).format("MMM DD, YYYY") : "N/A"}
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <p>{plan?.endDate ? moment(plan?.endDate).format("MMM DD, YYYY") : "N/A"}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <div className="flex items-center gap-2 text-sm">
                        <div
                            className={`w-2 h-2 rounded-full shrink-0 flex items-center justify-center captialize
                                   ${
                                       plan?.productionStatus === "pending"
                                           ? "bg-[#6366F1]"
                                           : plan?.productionStatus === "completed"
                                           ? "bg-[#22C55E]"
                                           : plan?.productionStatus === "in-progress"
                                           ? "bg-[#3B82F6]"
                                           : plan?.productionStatus === "cancelled"
                                           ? "bg-[#EF4444]"
                                           : plan?.productionStatus === "on-hold"
                                           ? "bg-[#EAB308]"
                                           : plan?.productionStatus === "rescheduled"
                                           ? "bg-[#8B5CF6]"
                                           : plan?.productionStatus === "planned"
                                           ? "bg-[#14B8A6]"
                                           : plan?.productionStatus === "delayed"
                                           ? "bg-[#F97316]"
                                           : plan?.productionStatus === "partial-completed"
                                           ? "bg-[#0EA5E9]"
                                           : plan?.productionStatus && "bg-[#6B7280]"
                                   }
                                    `}
                        ></div>
                        <span className="capitalize">
                            {plan?.productionStatus === "in-progress"
                                ? "In progress"
                                : plan?.productionStatus === "on-hold"
                                ? "On hold"
                                : plan?.productionStatus}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <div className="flex items-center gap-2">
                        <div className="w-5 h-5 rounded-full shrink-0 flex items-center justify-center bg-slate-100 ">
                            <i className="ri-user-follow-line text-sm text-gm-50"></i>
                        </div>

                        <span>{plan?.managerName}</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div className={`w-full px-4 py-2`} onClick={(e) => e.stopPropagation()}>
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={` ${
                            data?.length - 1 === idx && "!top-[-50%]"
                        } shadow-cardShadow-2 border-0 rounded !max-h-fit right-[30px] `}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Open production plan",
                                value: "Open production plan",
                                action: () => {
                                    navigate(`/dashboard/production/${plan?._id}`);
                                },
                                labelClassName: "!text-gm-50 !text-sm",
                            },
                            {
                                label: "Duplicate production plan",
                                value: "Duplicate production plan",
                                action: () => {
                                    navigate(
                                        `/dashboard/production/create-plan?planTab=1&&duplicateId=${plan?._id}`
                                    );
                                },
                                labelClassName: "!text-gm-50 !text-sm",
                            },
                            ...(plan?.productionStatus === "pending" ||
                            plan?.productionStatus === "planned"
                                ? [
                                      {
                                          label: "Delete production plan",
                                          value: "Delete production plan",
                                          action: () => {
                                              setPlanId(plan?._id);
                                              setConfirmModal(true);
                                          },
                                          labelClassName: "!text-[#E4281D] !text-sm",
                                      },
                                  ]
                                : []),
                        ]}
                    />
                </div>
            ),
            tableDataDivClass: "!pl-0",
            widthClass: "!border-slate-200",
        },
    ]);

    return (
        <div>
            {isLoading && data?.length === 0 ? (
                <div className="overflow-x-hidden ">
                    <CustomTableLoader tableHeader={tableHeader} />
                </div>
            ) : data?.length >= 1 ? (
                <>
                    <CustomTable
                        tableBody={tableBody}
                        tableHeader={tableHeader}
                        isInifiniteScroll={true}
                        isCellSelectable={false}
                        isAllSelectable={false}
                        stickyHeader={true}
                        headerContainerClass="!bg-[#F8FAFC] !border-slate-200"
                        headerItemClass="font-rocGroteskMedium !py-[11px] !px-4 !text-slate-700"
                        tableClass="mb-12 !border-collapse !border-slate-200"
                        bodyItemClass=" !border-slate-200"
                        isScrollable={true}
                    />
                    {isLoadingMore && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}

                    {pagination?.currentPage === pagination?.noOfPages && (
                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </>
            ) : (search || assigneeId || status) && data?.length === 0 ? (
                <div className="h-[200px] flex items-center justify-center border-x border-b border-slate-200">
                    <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                        Sorry, no results match your search. Refine your criteria and try again.
                    </p>
                </div>
            ) : (
                <div className="border-x border-b border-slate-200">
                    <EmptyStateComp
                        title="No production schedule plan found"
                        description="It looks like you don't have any production schedule plans at the moment. To get started, create a new schedule plan now!"
                        icon={<i className="ri-building-3-line text text-[40px]"></i>}
                        hasButton={true}
                        btnText="Create production plan"
                        btnAction={() => {
                            navigate("/dashboard/production/create-plan");
                        }}
                        contentContainerClass="flex flex-col items-center max-w-[537px] !bg-white"
                        descriptionClass="flex flex-col items-center"
                        className=" !mt-0 !pt-12"
                        textClass="text-center"
                    />
                </div>
            )}

            <ModalContainer
                open={confirmModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setConfirmModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            setConfirmModal(false);
                        }}
                    ></i>
                    <div className="">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-6">
                            <i className="mb-4 ri-question-fill text-[40px] text-slate-400"></i>

                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] leading-[32px] text-gm-50">
                                    Are you sure you want to delete this production plan?
                                </span>
                                <p className="mt-1 text-base text-tradeally-neutral-200 text-center ">
                                    Please confirm that you want to delete this plan. Deleting a
                                    plan cannot be un-done after confirming.
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-gm-50 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setConfirmModal(false);
                                }}
                                disabled={deletingProductionPlan}
                            />
                            <Button
                                btnText={"Delete plan"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-[#FF3D1D] !font-rocGroteskMedium !px-4 !text-white"
                                onClick={() => {
                                    handleDeletePlan();
                                }}
                                disabled={deletingProductionPlan}
                                isLoading={deletingProductionPlan}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={successModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setSuccessModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            setSuccessModal(false);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 w-12 h-12 bottom-0 right-[-13px] rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-[24px] text-white"></i>
                            </div>
                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px] text-gm-50">
                                    Production plan deleted!
                                </span>
                                <p className="mt-1 text-base text-tradeally-neutral-200 text-center mb-8">
                                    The production has been successfully deleted
                                </p>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setSuccessModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default ListView;
