import { productionTypes } from "../types/productionTypes";

const initialState = {
    fetchingBOMList: false,
    fetchedBOMListSuccess: null,
    fetchedBOMListFailure: null,
    fetchingSingleBOM: false,
    fetchedSingleBOMSuccess: null,
    fetchedSingleBOMFailure: null,
    creatingBOM: false,
    createBOMSuccess: null,
    createBOMFailure: null,
    updatingBOM: false,
    updateBOMSuccess: null,
    updateBOMFailure: null,
    fetchingProductWithoutBOM: false,
    fetchedProductWithoutBOMSuccess: null,
    fetchedProductWithoutBOMFailure: null,
    fetchingNonFinishedInventory: false,
    fetchedNonFinishedInventorySuccess: null,
    fetchedNonFinishedInventoryFailure: null,
    creatingProductionPlan: false,
    createProductionPlanSuccess: null,
    createProductionPlanFailure: null,
    updatingProductionPlan: false,
    updateProductionPlanSuccess: null,
    updateProductionPlanFailure: null,
    fetchingFinishedInventory: false,
    fetchedFinishedInventorySuccess: null,
    fetchedFinishedInventoryFailure: null,
    fetchingProductionPlanList: false,
    fetchedProductionPlanListSuccess: null,
    fetchedProductionPlanListFailure: null,
    fetchingSingleProductionPlan: false,
    fetchedSingleProductionPlanSuccess: null,
    fetchedSingleProductionPlanFailure: null,
    fetchingSingleProductionPlanBasic: false,
    fetchedSingleProductionPlanBasicSuccess: null,
    fetchedSingleProductionPlanBasicFailure: null,
    fetchingProductionPlanTab: false,
    fetchedProductionPlanTabSuccess: null,
    fetchedProductionPlanTabFailure: null,
    fetchingProductionPlanTabTwo: false,
    fetchedProductionPlanTabTwoSuccess: null,
    fetchedProductionPlanTabTwoFailure: null,
    updatingPlanSteps: false,
    updatePlanStepsSuccess: null,
    updatePlanStepsFailure: null,
    creatingPlanSteps: false,
    createPlanStepsSuccess: null,
    createPlanStepsFailure: null,
    fetchingProductionAnalytics: false,
    fetchedProductionAnalyticsSuccess: null,
    fetchedProductionAnalyticsFailure: null,
    creatingProductionSettings: false,
    createProductionSettingsSuccess: null,
    createProductionSettingsFailure: null,
    updatingProductionSettings: false,
    updateProductionSettingsSuccess: null,
    updateProductionSettingsFailure: null,
    fetchingProductionSettings: false,
    fetchedProductionSettingsSuccess: null,
    fetchedProductionSettingsFailure: null,
    updatingApprovalStatus: false,
    updateApprovalStatusSuccess: null,
    updateApprovalStatusFailure: null,
    validatingBatchId: false,
    validateBatchIdSuccess: null,
    validateBatchIdFailure: null,
    fetchingWorkOrderList: false,
    fetchedWorkOrderListSuccess: null,
    fetchedWorkOrderListFailure: null,
    fetchingSingleWorkOrder: false,
    fetchedSingleWorkOrderSuccess: null,
    fetchedSingleWorkOrderFailure: null,
    fetchingWorkOrderTab: false,
    fetchedWorkOrderTabSuccess: null,
    fetchedWorkOrderTabFailure: null,
    fetchingWorkOrderTabTwo: false,
    fetchedWorkOrderTabTwoSuccess: null,
    fetchedWorkOrderTabTwoFailure: null,
    updatingProductionRun: false,
    updateProductionRunSuccess: null,
    updateProductionRunFailure: null,
    updatingWorkOrderStatus: false,
    updateWorkOrderStatusSuccess: null,
    updateWorkOrderStatusFailure: null,
    fetchingPendingWorkOrderList: false,
    fetchedPendingWorkOrderListSuccess: null,
    fetchedPendingWorkOrderListFailure: null,
    fetchingRejectedWorkOrderList: false,
    fetchedRejectedWorkOrderListSuccess: null,
    fetchedRejectedWorkOrderListFailure: null,
    updatingWorkOrderApprovalStatus: false,
    updateWorkOrderApprovalStatusSuccess: null,
    updateWorkOrderApprovalStatusFailure: null,
    submittingYieldAndLoss: false,
    submitYieldAndLossSuccess: null,
    submitYieldAndLossFailure: null,
    updatingProductionStep: false,
    updateProductionStepSuccess: null,
    updateProductionStepFailure: null,
    fetchingProductionPlanListSearch: false,
    fetchedProductionPlanListSearchSuccess: null,
    fetchedProductionPlanListSearchFailure: null,
    fetchingReportList: false,
    fetchReportListSuccess: null,
    fetchReportListFailure: null,
    fetchingSingleReport: false,
    fetchSingleReportSuccess: null,
    fetchSingleReportFailure: null,
    savingReport: false,
    saveReportSuccess: null,
    saveReportFailure: null,
    addingToInventory: false,
    addToInventorySuccess: null,
    addToInventoryFailure: null,
    fetchingProductionForecastList: false,
    fetchedProductionForecastListSuccess: null,
    fetchedProductionForecastListFailure: null,
    deletingProductionPlan: false,
    deleteProductionPlanSuccess: null,
    deleteProductionPlanFailure: null,
};

export const production = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case productionTypes.FETCH_BOM_LIST_REQUEST:
            return {
                ...state,
                fetchingBOMList: true,
                fetchedBOMListSuccess: null,
                fetchedBOMListFailure: null,
            };
        case productionTypes.FETCH_BOM_LIST_SUCCESS:
            return {
                ...state,
                fetchingBOMList: false,
                fetchedBOMListSuccess: action.payload,
                fetchedBOMListFailure: null,
            };
        case productionTypes.FETCH_BOM_LIST_FAILURE:
            return {
                ...state,
                fetchingBOMList: false,
                fetchedBOMListSuccess: null,
                fetchedBOMListFailure: action.payload,
            };
        case productionTypes.FETCH_SINGLE_BOM_REQUEST:
            return {
                ...state,
                fetchingSingleBOM: true,
                fetchedSingleBOMSuccess: null,
                fetchedSingleBOMFailure: null,
            };
        case productionTypes.FETCH_SINGLE_BOM_SUCCESS:
            return {
                ...state,
                fetchingSingleBOM: false,
                fetchedSingleBOMSuccess: action.payload,
                fetchedSingleBOMFailure: null,
            };
        case productionTypes.FETCH_SINGLE_BOM_FAILURE:
            return {
                ...state,
                fetchingSingleBOM: false,
                fetchedSingleBOMSuccess: null,
                fetchedSingleBOMFailure: action.payload,
            };
        case productionTypes.CREATE_BOM_REQUEST:
            return {
                ...state,
                creatingBOM: true,
                createBOMSuccess: null,
                createBOMFailure: null,
            };
        case productionTypes.CREATE_BOM_SUCCESS:
            return {
                ...state,
                creatingBOM: false,
                createBOMSuccess: action.payload,
                createBOMFailure: null,
            };
        case productionTypes.CREATE_BOM_FAILURE:
            return {
                ...state,
                creatingBOM: false,
                createBOMSuccess: null,
                createBOMFailure: action.payload,
            };
        case productionTypes.UPDATE_BOM_REQUEST:
            return {
                ...state,
                updatingBOM: true,
                updateBOMSuccess: null,
                updateBOMFailure: null,
            };
        case productionTypes.UPDATE_BOM_SUCCESS:
            return {
                ...state,
                updatingBOM: false,
                updateBOMSuccess: action.payload,
                updateBOMFailure: null,
            };
        case productionTypes.UPDATE_BOM_FAILURE:
            return {
                ...state,
                updatingBOM: false,
                updateBOMSuccess: null,
                updateBOMFailure: action.payload,
            };
        case productionTypes.FETCH_PRODUCT_WITHOUT_BOM_REQUEST:
            return {
                ...state,
                fetchingProductWithoutBOM: true,
                fetchedProductWithoutBOMSuccess: null,
                fetchedProductWithoutBOMFailure: null,
            };
        case productionTypes.FETCH_PRODUCT_WITHOUT_BOM_SUCCESS:
            return {
                ...state,
                fetchingProductWithoutBOM: false,
                fetchedProductWithoutBOMSuccess: action.payload,
                fetchedProductWithoutBOMFailure: null,
            };
        case productionTypes.FETCH_PRODUCT_WITHOUT_BOM_FAILURE:
            return {
                ...state,
                fetchingProductWithoutBOM: false,
                fetchedProductWithoutBOMSuccess: null,
                fetchedProductWithoutBOMFailure: action.payload,
            };
        case productionTypes.FETCH_NON_FINISHED_INVENTORY_REQUEST:
            return {
                ...state,
                fetchingNonFinishedInventory: true,
                fetchedNonFinishedInventorySuccess: null,
                fetchedNonFinishedInventoryFailure: null,
            };
        case productionTypes.FETCH_NON_FINISHED_INVENTORY_SUCCESS:
            return {
                ...state,
                fetchingNonFinishedInventory: false,
                fetchedNonFinishedInventorySuccess: action.payload,
                fetchedNonFinishedInventoryFailure: null,
            };
        case productionTypes.FETCH_NON_FINISHED_INVENTORY_FAILURE:
            return {
                ...state,
                fetchingNonFinishedInventory: false,
                fetchedNonFinishedInventorySuccess: null,
                fetchedNonFinishedInventoryFailure: action.payload,
            };
        case productionTypes.CREATE_PRODUCTION_PLAN_REQUEST:
            return {
                ...state,
                creatingProductionPlan: true,
                createProductionPlanSuccess: null,
                createProductionPlanFailure: null,
            };
        case productionTypes.CREATE_PRODUCTION_PLAN_SUCCESS:
            return {
                ...state,
                creatingProductionPlan: false,
                createProductionPlanSuccess: action.payload,
                createProductionPlanFailure: null,
            };
        case productionTypes.CREATE_PRODUCTION_PLAN_FAILURE:
            return {
                ...state,
                creatingProductionPlan: false,
                createProductionPlanSuccess: null,
                createProductionPlanFailure: action.payload,
            };
        case productionTypes.UPDATE_PRODUCTION_PLAN_REQUEST:
            return {
                ...state,
                updatingProductionPlan: true,
                updateProductionPlanSuccess: null,
                updateProductionPlanFailure: null,
            };
        case productionTypes.UPDATE_PRODUCTION_PLAN_SUCCESS:
            return {
                ...state,
                updatingProductionPlan: false,
                updateProductionPlanSuccess: action.payload,
                updateProductionPlanFailure: null,
            };
        case productionTypes.UPDATE_PRODUCTION_PLAN_FAILURE:
            return {
                ...state,
                updatingProductionPlan: false,
                updateProductionPlanSuccess: null,
                updateProductionPlanFailure: action.payload,
            };
        case productionTypes.FETCH_FINISHED_INVENTORY_REQUEST:
            return {
                ...state,
                fetchingFinishedInventory: true,
                fetchedFinishedInventorySuccess: null,
                fetchedFinishedInventoryFailure: null,
            };
        case productionTypes.FETCH_FINISHED_INVENTORY_SUCCESS:
            return {
                ...state,
                fetchingFinishedInventory: false,
                fetchedFinishedInventorySuccess: action.payload,
                fetchedFinishedInventoryFailure: null,
            };
        case productionTypes.FETCH_FINISHED_INVENTORY_FAILURE:
            return {
                ...state,
                fetchingFinishedInventory: false,
                fetchedFinishedInventorySuccess: null,
                fetchedFinishedInventoryFailure: action.payload,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_LIST_REQUEST:
            return {
                ...state,
                fetchingProductionPlanList: true,
                fetchedProductionPlanListSuccess: null,
                fetchedProductionPlanListFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_LIST_SUCCESS:
            return {
                ...state,
                fetchingProductionPlanList: false,
                fetchedProductionPlanListSuccess: action.payload,
                fetchedProductionPlanListFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_LIST_FAILURE:
            return {
                ...state,
                fetchingProductionPlanList: false,
                fetchedProductionPlanListSuccess: null,
                fetchedProductionPlanListFailure: action.payload,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_LIST_SEARCH_REQUEST:
            return {
                ...state,
                fetchingProductionPlanListSearch: true,
                fetchedProductionPlanListSearchSuccess: null,
                fetchedProductionPlanListSearchFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_LIST_SEARCH_SUCCESS:
            return {
                ...state,
                fetchingProductionPlanListSearch: false,
                fetchedProductionPlanListSearchSuccess: action.payload,
                fetchedProductionPlanListSearchFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_LIST_SEARCH_FAILURE:
            return {
                ...state,
                fetchingProductionPlanListSearch: false,
                fetchedProductionPlanListSearchSuccess: null,
                fetchedProductionPlanListSearchFailure: action.payload,
            };
        case productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_REQUEST:
            return {
                ...state,
                fetchingSingleProductionPlan: true,
                fetchedSingleProductionPlanSuccess: null,
                fetchedSingleProductionPlanFailure: null,
            };
        case productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_SUCCESS:
            return {
                ...state,
                fetchingSingleProductionPlan: false,
                fetchedSingleProductionPlanSuccess: action.payload,
                fetchedSingleProductionPlanFailure: null,
            };
        case productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_FAILURE:
            return {
                ...state,
                fetchingSingleProductionPlan: false,
                fetchedSingleProductionPlanSuccess: null,
                fetchedSingleProductionPlanFailure: action.payload,
            };
        case productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_BASIC_REQUEST:
            return {
                ...state,
                fetchingSingleProductionPlanBasic: true,
                fetchedSingleProductionPlanBasicSuccess: null,
                fetchedSingleProductionPlanBasicFailure: null,
            };
        case productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_BASIC_SUCCESS:
            return {
                ...state,
                fetchingSingleProductionPlanBasic: false,
                fetchedSingleProductionPlanBasicSuccess: action.payload,
                fetchedSingleProductionPlanBasicFailure: null,
            };
        case productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_BASIC_FAILURE:
            return {
                ...state,
                fetchingSingleProductionPlanBasic: false,
                fetchedSingleProductionPlanBasicSuccess: null,
                fetchedSingleProductionPlanBasicFailure: action.payload,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_TAB_REQUEST:
            return {
                ...state,
                fetchingProductionPlanTab: true,
                fetchedProductionPlanTabSuccess: null,
                fetchedProductionPlanTabFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_TAB_SUCCESS:
            return {
                ...state,
                fetchingProductionPlanTab: false,
                fetchedProductionPlanTabSuccess: action.payload,
                fetchedProductionPlanTabFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_TAB_FAILURE:
            return {
                ...state,
                fetchingProductionPlanTab: false,
                fetchedProductionPlanTabSuccess: null,
                fetchedProductionPlanTabFailure: action.payload,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_TAB_TWO_REQUEST:
            return {
                ...state,
                fetchingProductionPlanTabTwo: true,
                fetchedProductionPlanTabTwoSuccess: null,
                fetchedProductionPlanTabTwoFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_TAB_TWO_SUCCESS:
            return {
                ...state,
                fetchingProductionPlanTabTwo: false,
                fetchedProductionPlanTabTwoSuccess: action.payload,
                fetchedProductionPlanTabTwoFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_TAB_TWO_FAILURE:
            return {
                ...state,
                fetchingProductionPlanTabTwo: false,
                fetchedProductionPlanTabTwoSuccess: null,
                fetchedProductionPlanTabTwoFailure: action.payload,
            };
        case productionTypes.UPDATE_PLAN_STEPS_REQUEST:
            return {
                ...state,
                updatingPlanSteps: true,
                updatePlanStepsSuccess: null,
                updatePlanStepsFailure: null,
            };
        case productionTypes.UPDATE_PLAN_STEPS_SUCCESS:
            return {
                ...state,
                updatingPlanSteps: false,
                updatePlanStepsSuccess: action.payload,
                updatePlanStepsFailure: null,
            };
        case productionTypes.UPDATE_PLAN_STEPS_FAILURE:
            return {
                ...state,
                updatingPlanSteps: false,
                updatePlanStepsSuccess: null,
                updatePlanStepsFailure: action.payload,
            };
        case productionTypes.FETCH_PRODUCTION_ANALYTICS_REQUEST:
            return {
                ...state,
                fetchingProductionAnalytics: true,
                fetchedProductionAnalyticsSuccess: null,
                fetchedProductionAnalyticsFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_ANALYTICS_SUCCESS:
            return {
                ...state,
                fetchingProductionAnalytics: false,
                fetchedProductionAnalyticsSuccess: action.payload,
                fetchedProductionAnalyticsFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_ANALYTICS_FAILURE:
            return {
                ...state,
                fetchingProductionAnalytics: false,
                fetchedProductionAnalyticsSuccess: null,
                fetchedProductionAnalyticsFailure: action.payload,
            };
        case productionTypes.CREATE_PRODUCTION_STEPS_REQUEST:
            return {
                ...state,
                creatingPlanSteps: true,
                createPlanStepsSuccess: null,
                createPlanStepsFailure: null,
            };
        case productionTypes.CREATE_PRODUCTION_STEPS_SUCCESS:
            return {
                ...state,
                creatingPlanSteps: false,
                createPlanStepsSuccess: action.payload,
                createPlanStepsFailure: null,
            };
        case productionTypes.CREATE_PRODUCTION_STEPS_FAILURE:
            return {
                ...state,
                creatingPlanSteps: false,
                createPlanStepsSuccess: null,
                createPlanStepsFailure: action.payload,
            };
        case productionTypes.CREATE_PRODUCTION_SETTINGS_REQUEST:
            return {
                ...state,
                creatingProductionSettings: true,
                createProductionSettingsSuccess: null,
                createProductionSettingsFailure: null,
            };
        case productionTypes.CREATE_PRODUCTION_SETTINGS_SUCCESS:
            return {
                ...state,
                creatingProductionSettings: false,
                createProductionSettingsSuccess: action.payload,
                createProductionSettingsFailure: null,
            };
        case productionTypes.CREATE_PRODUCTION_SETTINGS_FAILURE:
            return {
                ...state,
                creatingProductionSettings: false,
                createProductionSettingsSuccess: null,
                createProductionSettingsFailure: action.payload,
            };
        case productionTypes.FETCH_PRODUCTION_SETTINGS_REQUEST:
            return {
                ...state,
                fetchingProductionSettings: true,
                fetchedProductionSettingsSuccess: null,
                fetchedProductionSettingsFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_SETTINGS_SUCCESS:
            return {
                ...state,
                fetchingProductionSettings: false,
                fetchedProductionSettingsSuccess: action.payload,
                fetchedProductionSettingsFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_SETTINGS_FAILURE:
            return {
                ...state,
                fetchingProductionSettings: false,
                fetchedProductionSettingsSuccess: null,
                fetchedProductionSettingsFailure: action.payload,
            };
        case productionTypes.UPDATE_PRODUCTION_SETTINGS_REQUEST:
            return {
                ...state,
                updatingProductionSettings: true,
                updateProductionSettingsSuccess: null,
                updateProductionSettingsFailure: null,
            };
        case productionTypes.UPDATE_PRODUCTION_SETTINGS_SUCCESS:
            return {
                ...state,
                updatingProductionSettings: false,
                updateProductionSettingsSuccess: action.payload,
                updateProductionSettingsFailure: null,
            };
        case productionTypes.UPDATE_PRODUCTION_SETTINGS_FAILURE:
            return {
                ...state,
                updatingProductionSettings: false,
                updateProductionSettingsSuccess: null,
                updateProductionSettingsFailure: action.payload,
            };
        case productionTypes.UPDATE_APPROVAL_STATUS_REQUEST:
            return {
                ...state,
                updatingApprovalStatus: true,
                updateApprovalStatusSuccess: null,
                updateApprovalStatusFailure: null,
            };
        case productionTypes.UPDATE_APPROVAL_STATUS_SUCCESS:
            return {
                ...state,
                updatingApprovalStatus: false,
                updateApprovalStatusSuccess: action.payload,
                updateApprovalStatusFailure: null,
            };
        case productionTypes.UPDATE_APPROVAL_STATUS_FAILURE:
            return {
                ...state,
                updatingApprovalStatus: false,
                updateApprovalStatusSuccess: null,
                updateApprovalStatusFailure: action.payload,
            };
        case productionTypes.VALIDATE_PRODUCTION_BATCHID_REQUEST:
            return {
                ...state,
                validatingBatchId: true,
                validateBatchIdSuccess: null,
                validateBatchIdFailure: null,
            };
        case productionTypes.VALIDATE_PRODUCTION_BATCHID_SUCCESS:
            return {
                ...state,
                validatingBatchId: false,
                validateBatchIdSuccess: action.payload,
                validateBatchIdFailure: null,
            };
        case productionTypes.VALIDATE_PRODUCTION_BATCHID_FAILURE:
            return {
                ...state,
                validatingBatchId: false,
                validateBatchIdSuccess: null,
                validateBatchIdFailure: action.payload,
            };
        case productionTypes.FETCH_WORK_ORDER_LIST_REQUEST:
            return {
                ...state,
                fetchingWorkOrderList: true,
                fetchedWorkOrderListSuccess: null,
                fetchedWorkOrderListFailure: null,
            };
        case productionTypes.FETCH_WORK_ORDER_LIST_SUCCESS:
            return {
                ...state,
                fetchingWorkOrderList: false,
                fetchedWorkOrderListSuccess: action.payload,
                fetchedWorkOrderListFailure: null,
            };
        case productionTypes.FETCH_WORK_ORDER_LIST_FAILURE:
            return {
                ...state,
                fetchingWorkOrderList: false,
                fetchedWorkOrderListSuccess: null,
                fetchedWorkOrderListFailure: action.payload,
            };
        case productionTypes.FETCH_SINGLE_WORK_ORDER_REQUEST:
            return {
                ...state,
                fetchingSingleWorkOrder: true,
                fetchedSingleWorkOrderSuccess: null,
                fetchedSingleWorkOrderFailure: null,
            };
        case productionTypes.FETCH_SINGLE_WORK_ORDER_SUCCESS:
            return {
                ...state,
                fetchingSingleWorkOrder: false,
                fetchedSingleWorkOrderSuccess: action.payload,
                fetchedSingleWorkOrderFailure: null,
            };
        case productionTypes.FETCH_SINGLE_WORK_ORDER_FAILURE:
            return {
                ...state,
                fetchingSingleWorkOrder: false,
                fetchedSingleWorkOrderSuccess: null,
                fetchedSingleWorkOrderFailure: action.payload,
            };
        case productionTypes.FETCH_WORK_ORDER_TAB_REQUEST:
            return {
                ...state,
                fetchingWorkOrderTab: true,
                fetchedWorkOrderTabSuccess: null,
                fetchedWorkOrderTabFailure: null,
            };
        case productionTypes.FETCH_WORK_ORDER_TAB_SUCCESS:
            return {
                ...state,
                fetchingWorkOrderTab: false,
                fetchedWorkOrderTabSuccess: action.payload,
                fetchedWorkOrderTabFailure: null,
            };
        case productionTypes.FETCH_WORK_ORDER_TAB_FAILURE:
            return {
                ...state,
                fetchingWorkOrderTab: false,
                fetchedWorkOrderTabSuccess: null,
                fetchedWorkOrderTabFailure: action.payload,
            };
        case productionTypes.FETCH_WORK_ORDER_TAB_TWO_REQUEST:
            return {
                ...state,
                fetchingWorkOrderTabTwo: true,
                fetchedWorkOrderTabTwoSuccess: null,
                fetchedWorkOrderTabTwoFailure: null,
            };
        case productionTypes.FETCH_WORK_ORDER_TAB_TWO_SUCCESS:
            return {
                ...state,
                fetchingWorkOrderTabTwo: false,
                fetchedWorkOrderTabTwoSuccess: action.payload,
                fetchedWorkOrderTabTwoFailure: null,
            };
        case productionTypes.FETCH_WORK_ORDER_TAB_TWO_FAILURE:
            return {
                ...state,
                fetchingWorkOrderTabTwo: false,
                fetchedWorkOrderTabTwoSuccess: null,
                fetchedWorkOrderTabTwoFailure: action.payload,
            };
        case productionTypes.UPDATE_PRODUCTION_RUN_REQUEST:
            return {
                ...state,
                updatingProductionRun: true,
                updateProductionRunSuccess: null,
                updateProductionRunFailure: null,
            };
        case productionTypes.UPDATE_PRODUCTION_RUN_SUCCESS:
            return {
                ...state,
                updatingProductionRun: false,
                updateProductionRunSuccess: action.payload,
                updateProductionRunFailure: null,
            };
        case productionTypes.UPDATE_PRODUCTION_RUN_FAILURE:
            return {
                ...state,
                updatingProductionRun: false,
                updateProductionRunSuccess: null,
                updateProductionRunFailure: action.payload,
            };
        case productionTypes.UPDATE_WORK_ORDER_STATUS_REQUEST:
            return {
                ...state,
                updatingWorkOrderStatus: true,
                updateWorkOrderStatusSuccess: null,
                updateWorkOrderStatusFailure: null,
            };
        case productionTypes.UPDATE_WORK_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                updatingWorkOrderStatus: false,
                updateWorkOrderStatusSuccess: action.payload,
                updateWorkOrderStatusFailure: null,
            };
        case productionTypes.UPDATE_WORK_ORDER_STATUS_FAILURE:
            return {
                ...state,
                updatingWorkOrderStatus: false,
                updateWorkOrderStatusSuccess: null,
                updateWorkOrderStatusFailure: action.payload,
            };
        case productionTypes.UPDATE_WORK_ORDER_APPROVAL_STATUS_REQUEST:
            return {
                ...state,
                updatingWorkOrderApprovalStatus: true,
                updateWorkOrderApprovalStatusSuccess: null,
                updateWorkOrderApprovalStatusFailure: null,
            };
        case productionTypes.UPDATE_WORK_ORDER_APPROVAL_STATUS_SUCCESS:
            return {
                ...state,
                updatingWorkOrderApprovalStatus: false,
                updateWorkOrderApprovalStatusSuccess: action.payload,
                updateWorkOrderApprovalStatusFailure: null,
            };
        case productionTypes.UPDATE_WORK_ORDER_APPROVAL_STATUS_FAILURE:
            return {
                ...state,
                updatingWorkOrderApprovalStatus: false,
                updateWorkOrderApprovalStatusSuccess: null,
                updateWorkOrderApprovalStatusFailure: action.payload,
            };
        case productionTypes.FETCH_PENDING_WORK_ORDER_LIST_REQUEST:
            return {
                ...state,
                fetchingPendingWorkOrderList: true,
                fetchedPendingWorkOrderListSuccess: null,
                fetchedPendingWorkOrderListFailure: null,
            };
        case productionTypes.FETCH_PENDING_WORK_ORDER_LIST_SUCCESS:
            return {
                ...state,
                fetchingPendingWorkOrderList: false,
                fetchedPendingWorkOrderListSuccess: action.payload,
                fetchedPendingWorkOrderListFailure: null,
            };
        case productionTypes.FETCH_PENDING_WORK_ORDER_LIST_FAILURE:
            return {
                ...state,
                fetchingPendingWorkOrderList: false,
                fetchedPendingWorkOrderListSuccess: null,
                fetchedPendingWorkOrderListFailure: action.payload,
            };
        case productionTypes.FETCH_REJECTED_WORK_ORDER_LIST_REQUEST:
            return {
                ...state,
                fetchingRejectedWorkOrderList: true,
                fetchedRejectedWorkOrderListSuccess: null,
                fetchedRejectedWorkOrderListFailure: null,
            };
        case productionTypes.FETCH_REJECTED_WORK_ORDER_LIST_SUCCESS:
            return {
                ...state,
                fetchingRejectedWorkOrderList: false,
                fetchedRejectedWorkOrderListSuccess: action.payload,
                fetchedRejectedWorkOrderListFailure: null,
            };
        case productionTypes.FETCH_REJECTED_WORK_ORDER_LIST_FAILURE:
            return {
                ...state,
                fetchingRejectedWorkOrderList: false,
                fetchedRejectedWorkOrderListSuccess: null,
                fetchedRejectedWorkOrderListFailure: action.payload,
            };
        case productionTypes.SUBMIT_YIELD_AND_LOSS_REQUEST:
            return {
                ...state,
                submittingYieldAndLoss: true,
                submitYieldAndLossSuccess: null,
                submitYieldAndLossFailure: null,
            };
        case productionTypes.SUBMIT_YIELD_AND_LOSS_SUCCESS:
            return {
                ...state,
                submittingYieldAndLoss: false,
                submitYieldAndLossSuccess: action.payload,
                submitYieldAndLossFailure: null,
            };
        case productionTypes.SUBMIT_YIELD_AND_LOSS_FAILURE:
            return {
                ...state,
                submittingYieldAndLoss: false,
                submitYieldAndLossSuccess: null,
                submitYieldAndLossFailure: action.payload,
            };
        case productionTypes.UPDATE_PRODUCTION_STEP_REQUEST:
            return {
                ...state,
                updatingProductionStep: true,
                updateProductionStepSuccess: null,
                updateProductionStepFailure: null,
            };
        case productionTypes.UPDATE_PRODUCTION_STEP_SUCCESS:
            return {
                ...state,
                updatingProductionStep: false,
                updateProductionStepSuccess: action.payload,
                updateProductionStepFailure: null,
            };
        case productionTypes.UPDATE_PRODUCTION_STEP_FAILURE:
            return {
                ...state,
                updatingProductionStep: false,
                updateProductionStepSuccess: null,
                updateProductionStepFailure: action.payload,
            };
        case productionTypes.FETCH_REPORT_LIST_REQUEST:
            return {
                ...state,
                fetchingReportList: true,
                fetchReportListSuccess: null,
                fetchReportListFailure: null,
            };
        case productionTypes.FETCH_REPORT_LIST_SUCCESS:
            return {
                ...state,
                fetchingReportList: false,
                fetchReportListSuccess: action.payload,
                fetchReportListFailure: null,
            };
        case productionTypes.FETCH_REPORT_LIST_FAILURE:
            return {
                ...state,
                fetchingReportList: false,
                fetchReportListSuccess: null,
                fetchReportListFailure: action.payload,
            };
        case productionTypes.FETCH_SINGLE_REPORT_REQUEST:
            return {
                ...state,
                fetchingSingleReport: true,
                fetchSingleReportSuccess: null,
                fetchSingleReportFailure: null,
            };
        case productionTypes.FETCH_SINGLE_REPORT_SUCCESS:
            return {
                ...state,
                fetchingSingleReport: false,
                fetchSingleReportSuccess: action.payload,
                fetchSingleReportFailure: null,
            };
        case productionTypes.FETCH_SINGLE_REPORT_FAILURE:
            return {
                ...state,
                fetchingSingleReport: false,
                fetchSingleReportSuccess: null,
                fetchSingleReportFailure: action.payload,
            };
        case productionTypes.SAVE_REPORT_REQUEST:
            return {
                ...state,
                savingReport: true,
                saveReportSuccess: null,
                saveReportFailure: null,
            };
        case productionTypes.SAVE_REPORT_SUCCESS:
            return {
                ...state,
                savingReport: false,
                saveReportSuccess: action.payload,
                saveReportFailure: null,
            };
        case productionTypes.SAVE_REPORT_FAILURE:
            return {
                ...state,
                savingReport: false,
                saveReportSuccess: null,
                saveReportFailure: action.payload,
            };
        case productionTypes.ADD_TO_INVENTORY_REQUEST:
            return {
                ...state,
                addingToInventory: true,
                addToInventorySuccess: null,
                addToInventoryFailure: null,
            };
        case productionTypes.ADD_TO_INVENTORY_SUCCESS:
            return {
                ...state,
                addingToInventory: false,
                addToInventorySuccess: action.payload,
                addToInventoryFailure: null,
            };
        case productionTypes.ADD_TO_INVENTORY_FAILURE:
            return {
                ...state,
                addingToInventory: false,
                addToInventorySuccess: null,
                addToInventoryFailure: action.payload,
            };
        case productionTypes.FETCH_PRODUCTION_FORECAST_LIST_REQUEST:
            return {
                ...state,
                fetchingProductionForecastList: true,
                fetchedProductionForecastListSuccess: null,
                fetchedProductionForecastListFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_FORECAST_LIST_SUCCESS:
            return {
                ...state,
                fetchingProductionForecastList: false,
                fetchedProductionForecastListSuccess: action.payload,
                fetchedProductionForecastListFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_FORECAST_LIST_FAILURE:
            return {
                ...state,
                fetchingProductionForecastList: false,
                fetchedProductionForecastListSuccess: null,
                fetchedProductionForecastListFailure: action.payload,
            };
        case productionTypes.DELETE_PRODUCTION_PLAN_REQUEST:
            return {
                ...state,
                deletingProductionPlan: true,
                deleteProductionPlanSuccess: null,
                deleteProductionPlanFailure: null,
            };
        case productionTypes.DELETE_PRODUCTION_PLAN_SUCCESS:
            return {
                ...state,
                deletingProductionPlan: false,
                deleteProductionPlanSuccess: action.payload,
                deleteProductionPlanFailure: null,
            };
        case productionTypes.DELETE_PRODUCTION_PLAN_FAILURE:
            return {
                ...state,
                deletingProductionPlan: false,
                deleteProductionPlanSuccess: null,
                deleteProductionPlanFailure: action.payload,
            };
        default:
            return state;
    }
};
