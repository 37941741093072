import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { multibrandActions } from "redux/Ldb/actions";

const useGetBrandWarehouseStockBreakdown = () => {
    const dispatch = useAppDispatch();
    const [warehouseStockBreakdown, setWarehouseStockBreakdown] = useState<{
        [key: string]: any;
    }>();
    const [dateRange, setDateRange] = useState<{ label: string; value: string }>({
        label: "",
        value: "yearly",
    });
    const [brands, setBrands] = useState<string[]>([]);
    const [warehouses, setWarehouses] = useState<string[]>([]);
    const [channels, setChannels] = useState<string[]>([]);

    const {
        fetchingBrandWarehouseStockBreakdown,
        fetchBrandWarehouseStockBreakdownSuccess,
        fetchBrandWarehouseStockBreakdownFailure,
    } = useAppSelector((state) => state.multibrand);

    const handleDurationChange = (value: { label: string; value: string }) => {
        setDateRange(value);
    };

    const handleBrandChangeFilter = (value: string[]) => {
        setBrands(value);
    };

    const handleLocationChangeFilter = (value: string[]) => {
        const channelsValue = value?.filter((item) => isNaN(Number(item?.[0])));
        const warehouseValue = value?.filter((item) => !isNaN(Number(item?.[0])));

        setWarehouses(warehouseValue);
        setChannels(channelsValue);
    };

    useEffect(() => {
        if (Boolean(fetchBrandWarehouseStockBreakdownSuccess)) {
            setWarehouseStockBreakdown(fetchBrandWarehouseStockBreakdownSuccess);
        }
    }, [fetchBrandWarehouseStockBreakdownSuccess]);

    useEffect(() => {
        dispatch(
            multibrandActions?.getBrandWarehousStockBreakdown(
                brands?.join(","),
                warehouses?.join(","),
                channels?.join(",")
            )
        );
    }, [dispatch, dateRange?.value, brands, warehouses, channels]);

    return {
        data: warehouseStockBreakdown,
        isFetching: fetchingBrandWarehouseStockBreakdown,
        error: fetchBrandWarehouseStockBreakdownFailure,
        handleDurationChange,
        dateRange,
        handleBrandChangeFilter,
        brands,
        handleLocationChangeFilter,
        warehouses,
        channels,
    };
};

export default useGetBrandWarehouseStockBreakdown;
