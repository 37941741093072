import React from "react";
import InventoryAnalyticsCard from "../../../../../common/InventoryAnalyticsCard";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import Skeleton from "react-loading-skeleton";

type analyticsProps = {
    snapshotStats: Record<string, any>;
    isFetchingAnalytics: boolean;
    data: Record<string, any>[];
    dates: Record<string, any>;
};
const SnapshotsAnalytics = ({
    snapshotStats,
    isFetchingAnalytics,
    data,
    dates,
}: analyticsProps) => {
    return (
        <div>
            {isFetchingAnalytics && data?.length === 0 ? (
                <div className="grid grid-cols-3 items-center space-x-3 w-full mb-8">
                    {[1, 2, 3]?.map((_, idx) => (
                        <Skeleton
                            key={idx}
                            width={"100%"}
                            height={127}
                            highlightColor="#E2E8F0"
                            borderRadius={8}
                            baseColor="#F1F5F9"
                            className="custom-skeleton"
                        />
                    ))}
                </div>
            ) : (
                <div className="grid grid-cols-3 gap-4">
                    <InventoryAnalyticsCard
                        title={`Inventory value ${!dates?.startDate ? "at last month-end" : ""}`}
                        containerClassName="bg-[#FFFBEB]"
                        icon={
                            <i className="ri-money-dollar-circle-fill text-2xl text-y-10 mb-2.5"></i>
                        }
                        value={`${getCurrencyFromCurrencyCode(snapshotStats?.currency)}${
                            snapshotStats?.totalInventoryValue &&
                            formatMoney()?.format(snapshotStats?.totalInventoryValue)
                        }
                    `}
                    />
                    <InventoryAnalyticsCard
                        title={`Inventory ${!dates?.startDate ? "at last month-end" : ""}`}
                        containerClassName="bg-[#F0FDF4]"
                        icon={<i className="ri-box-2-fill text-2xl text-[#16A34A] mb-2.5"></i>}
                        value={
                            snapshotStats?.totalInventoryStock &&
                            formatMoney()?.format(snapshotStats?.totalInventoryStock)
                        }
                    />
                    <InventoryAnalyticsCard
                        title={`Average monthly inventory ${
                            !dates?.startDate ? "at last month-end" : ""
                        }`}
                        containerClassName="bg-[#FAF5FF]"
                        icon={<i className="ri-box-2-line text-2xl text-[#7C3AED] mb-2.5"></i>}
                        value={
                            snapshotStats?.averageInventory &&
                            formatMoney()?.format(snapshotStats?.averageInventory)
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default SnapshotsAnalytics;
